import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import _ from 'underscore';

import {STORAGE_KEY} from '../../utils/api';
import {
    isDemo,
    formatNavigationQuery
} from '../../utils/routing';
import DropdownNavMenu from '../dropdown_nav_menu/DropdownNavMenu';

import './GlobalHeader.css';


const HIDDEN_PATHS = [
    '/compose-post/',
    '/topic-filters/',
];


const NavIcon = ({
    icon='',
    pathname='',
    badgeValue='',
}) => {
    let badge = null;

    if (badgeValue > 0) {
        badge = (
            <span className="icon-button-badge">{badgeValue}</span>
        );
    }

    return (
        <Link
            className="nav-item"
            to={{
                pathname: pathname,
                search: formatNavigationQuery()
            }}
        >
            <FontAwesomeIcon
                icon={icon}
            />
            {badge}
        </Link>
    );
};


const DropdownNavWithRouter = withRouter(DropdownNavMenu);

export class GlobalHeader extends Component {

    static propTypes = {
        authUser: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        numberOfUnreadNotifications: PropTypes.number,
        onLoad: PropTypes.func.isRequired,
        unsetAuthUser: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showMenu: false
        };

        this.onNavIconClick = this.onNavIconClick.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    componentDidMount() {
        this.props.onLoad();
    }

    onNavIconClick() {
        this.setState({
            showMenu: !this.state.showMenu
        });
    }

    handleNavClick = () => {
        this.setState({showMenu: false});
    };

    handleLogout() {
        let {
            history,
            unsetAuthUser,
        } = this.props;

        localStorage.removeItem(STORAGE_KEY);

        this.setState({showMenu: false});
        unsetAuthUser();

        history.replace('/login/');
    }

    exitDemo = () => {
        let {history} = this.props;

        history.push('/login/');
    };

    render() {
        let {showMenu} = this.state;
        let {
            authUser,
            location,
            numberOfUnreadNotifications,
        } = this.props;
        let menu = null;
        let exitDemoButton = null;
        let profileNavLinkTitle = authUser['first_name'];
        let profileNavLinkPath = `/profile/${authUser['slug_hash']}/`;

        if (HIDDEN_PATHS.indexOf(location.pathname) >= 0) {
            return null;
        }

        if (showMenu) {
            menu = (
                <div className="dropdown-container">
                    <DropdownNavWithRouter
                        authUser={authUser}
                        onLinkClick={this.handleNavClick}
                        onLogout={this.handleLogout}
                    />
                </div>
            );
        }

        if (isDemo()) {
            profileNavLinkTitle = 'Zach';
            profileNavLinkPath = '/profile/demo/';
            exitDemoButton = (
                <button
                    className="exit-demo-button"
                    onClick={this.exitDemo}
                >
                    Exit Demo
                </button>
            );
        }

        return (
            <div className="global-header">
                <div className="global-header-container">
                    <div className="logo-row">
                        <Link
                            className="logo-container"
                            to={{
                                pathname: '/',
                                search: formatNavigationQuery()
                            }}
                        >
                            <h1 className="logo">Boeiie</h1>
                        </Link>

                        <div className="right-side">
                            {
                                isDemo() ?
                                <div className="nav-item">
                                    {exitDemoButton}
                                </div> : null
                            }
                            {
                                !_.isEmpty(authUser) ?
                                <Link
                                    className="nav-item profile-button"
                                    to={{
                                        pathname: profileNavLinkPath,
                                        search: formatNavigationQuery()
                                    }}
                                >
                                    <span>{profileNavLinkTitle}</span>
                                </Link> : null
                            }
                            <Link
                                className="nav-item"
                                to={{
                                    pathname: `/`,
                                    search: formatNavigationQuery()
                                }}
                            >
                                <FontAwesomeIcon
                                    icon="home"
                                />
                            </Link>
                            {
                                !_.isEmpty(authUser) ?
                                <NavIcon
                                    icon="bell"
                                    pathname="/activity/"
                                    badgeValue={numberOfUnreadNotifications}
                                /> : null
                            }
                            <Link
                                className="nav-item"
                                to={{
                                    pathname: `/groups/`,
                                    search: formatNavigationQuery()
                                }}
                            >
                                <FontAwesomeIcon
                                    icon="users"
                                />
                            </Link>
                            {
                                _.isEmpty(authUser) ?
                                <Link
                                    className="nav-item button"
                                    to={{
                                        pathname: `/login/`,
                                        search: formatNavigationQuery()
                                    }}
                                >
                                    Login
                                </Link> : null
                            }
                            {
                                _.isEmpty(authUser) ?
                                <Link
                                    className="nav-item button signup"
                                    to={{
                                        pathname: `/signup/`,
                                        search: formatNavigationQuery()
                                    }}
                                >
                                    Sign up
                                </Link> : null
                            }
                            <div className="nav-item show-more-button">
                                <div className="showmore-nav-icon-container">
                                    <img
                                        className="showmore-nav-icon" src="/media/ui_icons/navicon-round.png"
                                        alt="Menu"
                                        onClick={this.onNavIconClick}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {menu}
                </div>
            </div>
        );
    }
}

export default withRouter(GlobalHeader);
