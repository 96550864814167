export const LOAD_MORE_COMMENTS_START = 'load-more-comments-start';
export const LOAD_MORE_COMMENTS_SUCCESS = 'load-more-comments-success';
export const LOAD_MORE_COMMENTS_FAIL = 'load-more-comments-fail';

export const loadMoreCommentsStart = (postId) => ({
    type: LOAD_MORE_COMMENTS_START,
    postId
});

export const loadMoreCommentsSuccess = (postId, comments, hasMoreComments) => ({
    type: LOAD_MORE_COMMENTS_SUCCESS,
    postId,
    comments,
    hasMoreComments
});

export const loadMoreCommentsFail = (postId, errors) => ({
    type: LOAD_MORE_COMMENTS_FAIL,
    postId,
    errors
});
