import React from 'react';

import GlobalHeader from '../../common/components/global_header/connector';

import './AcceptableUsePolicy.css';


function AcceptableUsePolicy() {
    return (
        <div id="acceptable-use-policy">
            <GlobalHeader />
            <div className="page-body-container">
                <div className="content-container">
                    <p><span></span></p>
                    <h1 className="center"><span>BOEIIE ACCEPTABLE USE POLICY</span></h1>
                    <p><i>Last updated: October 3, 2020</i></p>
                    <br />
                    <p><span></span></p>
                    <p><strong>PLEASE READ THIS ACCEPTABLE USE POLICY CAREFULLY TO UNDERSTAND OUR POLICIES AND PRACTICES REGARDING THE WAY OUR USERS MAY INTERACT WITH THE SERVICES WE PROVIDE.</strong></p>
                    <p><strong>IF YOU DO NOT AGREE WITH OUR POLICIES AND PRACTICES, YOU SHOULD NOT USE OR ACCESS THE SITE.</strong></p>
                    <p>
                        <span>Boeiie, LLC, a Missouri limited liability company (&ldquo;</span><span>Boeiie</span><span>&rdquo;, &ldquo;</span><span>us</span><span>&rdquo;, &ldquo;</span><span>our</span><span>&rdquo;, or &ldquo;</span><span>we</span>
                        <span>&rdquo;), operates the website located at www.Boeiie.com,&nbsp;its subdomains and derivative URLs, and subdomains and derivative URLs that we host for our users (each, and collectively, the &ldquo;</span><span>Services</span>
                        <span>&rdquo;). We understand your concerns about how users interact through the Services and we are committed to protecting each user through our compliance with this Acceptable Use Policy (this &ldquo;</span><span>AUP</span>
                        <span>&rdquo;).</span>
                    </p>
                    <p>
                        <span>
                            This AUP describes how you or your child must comport yourself when using or visiting the Services and how other users must act and behave. We encourage families to discuss their household guidelines regarding what is considered
                            generally acceptable online behavior and especially suggest that visitors under the age of 18 check with their parents or guardians before engaging with any website or mobile application.
                        </span>
                    </p>
                    <p><span>Boeiie requires that you and your child observe the following rules in your use of the Services.</span></p>

                    <h4>Section 1. &nbsp;&nbsp;&nbsp;&nbsp; Changes to this AUP</h4>

                    <ul>
                        <li style={{'listStyle': 'none'}}>
                            <p>
                                <span>
                                    We will make changes to this AUP from time to time, at our sole discretion. When we make changes, we will notify you, at a minimum, by updating this AUP and the Effective Date at the top of the page. You are expected to carefully
                                    review this AUP frequently in order to be aware of any changes.
                                </span>
                            </p>
                            <p>
                                <span>
                                    However, if we make material changes to this AUP, we will notify you either through the email address you have provided us or by a prominent notice on the Services before the change becomes effective. You are responsible for
                                    ensuring we have an up-to-date active and deliverable email address for you.
                                </span>
                            </p>
                            <p>
                                <span>
                                    Any changes to this AUP&nbsp;will be in effect as&nbsp;of&nbsp;the Effective Date that is posted at the top of the page. By continuing to use the Services, you agree to be bound by the changes and to our collection and sharing of
                                    your personally identifiable information according to the terms of our then-current AUP.
                                </span>
                            </p>
                        </li>
                    </ul>

                    <h4>Section 2. &nbsp;&nbsp;&nbsp;&nbsp; Violations of this AUP</h4>

                    <ul style={{'listStyle': 'none'}}>
                        <li>
                            <p>
                                Violations of this AUP may lead to suspension or termination of you or your child&rsquo;s account in addition to other legal action. In addition, the user may be required to pay for the costs of investigation and remedial action
                                related to AUP violations. Failure to follow these rules may lead to us taking remedial action up to and including suspension or termination of your account or other legal action as we deem appropriate. We reserve the right to take
                                any remedial action we see fit.
                            </p>
                        </li>
                    </ul>

                    <h4>Section 3. &nbsp;&nbsp;&nbsp;&nbsp; What is Acceptable Use</h4>

                    <ul style={{'listStyle': 'none'}}>
                        <li>
                            <p>Acceptable Use is the behavior that Boeiie requires of all users of the Services. Simply put, all users must conduct themselves with respect for others at all times.</p>
                        </li>
                    </ul>

                    <h4>Section 4. &nbsp;&nbsp;&nbsp;&nbsp; What is Unacceptable Use</h4>

                    <ul style={{'listStyle': 'none'}}>
                        <li>
                            <p>Any of the following constitute unacceptable behavior for users of the Services, therefore, you or your child may not propagate any:</p>

                            <ul>
                                <li>
                                    <p>
                                        <i>Abusive behavior</i>
                                        <span>
                                            , which means you or your child may not (i) harass, threaten, or defame any person or entity; (ii) contact any person who has requested no further contact; or (iii) use ethnic, racial, or religious slurs against any person
                                            or group.
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i>Privacy, </i>
                                        <span>
                                            which means you or your child may not (i) violate the privacy rights of any person; (ii) collect or disclose any personal address, social security number, or other personally identifiable information without each
                                            holder&rsquo;s written permission; or (iii) cooperate in or facilitate identity theft.
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i>Hacking, Viruses, Network Attacks, or Phishing,</i>
                                        <span>
                                            &nbsp;which means you or your child may not (i) access any computer or communications system without authorization, including the computers used to provide the Services; (ii) attempt to penetrate or disable any security
                                            system; (iii) intentionally distribute a computer virus, launch a denial of service attack, or in any other way attempt to interfere with the functioning of any computer, communications system, or website; (iv) attempt to
                                            access or otherwise interfere with the accounts of other users of the Services; or (v) attempt any phishing including, without limitation, the use any misleading or incorrect names, addresses, email addresses, return email
                                            addresses, subject lines, or other information residing on or in the Services or in any emails created or sent using the Services.
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i>Spam,</i><span>&nbsp;which means you or your child may not (i) send unsolicited bulk email (&ldquo;</span><span>Spam</span>
                                        <span>
                                            &rdquo;) or sell or market any product or service advertised by or connected with Spam; (ii) facilitate or cooperate in the dissemination of Spam in any way; or (iii) violate the CAN-Spam Act of 2003. Users must be able to
                                            provide evidence of consent for bulk email upon request
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i>Bulk Email Violations,</i>
                                        <span>
                                            &nbsp;which means you or your child may not (i) use an outside unsubscribe process when sending bulk content to a list hosted within the Services; (ii) set up multiple accounts for any person or entity in order to send
                                            similar content, unless as part of a franchise or agency; (iii) import or incorporate Social Security numbers, passwords, security credentials, driver&rsquo;s license numbers, credit or debit card numbers, or other sensitive
                                            personal information into any lists, emails, or uploads to our Services; (iv) send email that will be delivered to recipients as text, SMS, or MMS messages unless using a feature designed specifically for that purpose; (v)
                                            upload or send to purchased, rented, third-party, publicly available, or partner lists of any kind; (vi) send or forward personal mail; (vii) send content created with the Services through another service; (viii) host
                                            images, video, multimedia, or any other content for any purpose other than for use with the Services; or (ix) delete, bulk unsubscribe, or otherwise modify lists in order to avoid billing thresholds.
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i>Intellectual Property Violations,</i>
                                        <span>
                                            &nbsp;which means you or your child may not (i) infringe upon the copyrights, trademarks, trade secrets, or other intellectual property rights of any person or entity; (ii) reproduce, publish, or disseminate software, audio
                                            recordings, video recordings, photographs, articles, or other works of authorship without the written permission of the copyright holder; (iii) share non-public Services features or content including, without limitation,
                                            screenshots, screen sharing, screen capture, or graphics, with any third party; or (iv) access the Services in order to build a competitive product or service, a product using similar ideas, features, functions, or graphics,
                                            or to copy any ideas, features, functions, or graphics of the Services.
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i>Fraud and Violations of Law</i>
                                        <span>
                                            , which means you or your child may not (i) issue fraudulent offers to sell or buy products, services, or investments; (ii) mislead anyone about the details or nature of a commercial transaction; (iii) commit fraud in any
                                            other way; or (iv) violate any law.
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i>Other Violations</i>
                                        <span>
                                            , which means you or your child may not: (i) use the Services for service bureau or time-sharing purposes or in any other way allow third parties to exploit the Services, except as specifically authorized in a Services
                                            Agreement; (ii) provide Services passwords or other log-in information to any third party; or (iii) engage in web scraping or data scraping on or related to the Services including, but not limited to, collection of
                                            information through any software that simulates human activity or any bot or web crawler.
                                        </span>
                                    </p>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <h4>Section 5. &nbsp;&nbsp;&nbsp;&nbsp; Bounce and Spam Complaint Rates</h4>

                    <ul style={{'listStyle': 'none'}}>
                        <li>
                            <p>In general, we require hard bounce rates to be less than 5%, and Spam complaint rates less than 0.1%. For that reason, we reserve the right to closely review, suspend, throttle, or disable accounts that exceed these rates.</p>
                        </li>
                    </ul>

                    <h4>Section 6. &nbsp;&nbsp;&nbsp;&nbsp; Reporting Unacceptable Use</h4>

                    <ul style={{'listStyle': 'none'}}>
                        <li>
                            <p>
                                <span>Boeiie requests that anyone with information about a violation of this AUP report it via e-mail to the following address: <strong>team@boeiie.com</strong></span>
                                <span>
                                    . Please provide the date and time (with time zone) of the violation and any identifying information regarding the violator, including e-mail or IP (Internet Protocol) address if available, as well as details of the violation.
                                </span>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default AcceptableUsePolicy;
