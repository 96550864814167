import React, {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Form, Field} from 'react-final-form';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
    fetchApiGet,
    fetchApiPost,
} from '../../common/utils/api';
import {
    isRequired,
    minLength,
    maxLength,
    composeValidators
} from '../../common/utils/validation';

import './PasswordReset.css';


export default function PasswordReset() {
    let {token} = useParams();
    let [tokenIsVerified, setTokenVerification] = useState(false);
    let [isLoading, setLoading] = useState(true);
    let [showConfirmationDisplay, setConfirmationDisplay] = useState(false);

    const handleFormSubmit = (formValues) => (
        fetchApiPost('forgot-password-reset/', {...formValues, token})
            .then(({message}) => {
                if (message) {
                    setConfirmationDisplay(true);
                }
            })
    );

    const validateForm = (formValues) => {
        let errors = {};

        if (formValues.password !== formValues.confirm_password) {
            errors['confirm_password'] = 'Must match with password';
        }

        return errors;
    };

    let bodyContent = (
        <div className="loading-container">
            <CircularProgress />
        </div>
    );
    let resetForm = (
        <div>
            <h2>Reset your password</h2>
            <p>You can reset your password below by entering a new password. You will receive a confirmation email.</p>
            <Form
                onSubmit={handleFormSubmit}
                validate={validateForm}
            >
                {
                    ({handleSubmit, touched, errors}) => (
                        <form
                            className="form-wrapper"
                            onSubmit={handleSubmit}
                        >
                            <div className="form-field-container">
                                <p className="label">New password</p>
                                <Field
                                    className="input-field"
                                    component="input"
                                    name="password"
                                    placeholder="Password"
                                    validate={
                                        composeValidators(
                                            isRequired,
                                            minLength(8, 'password'),
                                            maxLength(50, 'password')
                                        )
                                    }
                                />
                                {
                                    touched.password &&
                                    errors.password &&
                                    <span className="error">{errors.password}</span>
                                }
                            </div>
                            <div className="form-field-container">
                                <p className="label">Confirm password</p>
                                <Field
                                    className="input-field"
                                    component="input"
                                    name="confirm_password"
                                    placeholder="Confirm password"
                                />
                                {
                                    touched.confirm_password &&
                                    errors.confirm_password &&
                                    <span className="error">{errors.confirm_password}</span>
                                }
                            </div>
                            <div className="form-field-container">
                                <button
                                    className="submit-button"
                                    type="submit"
                                >
                                    Reset Password
                                </button>
                            </div>
                            <div>
                                <Link className="form-link" to="/login/">
                                    Return to login
                                </Link>
                            </div>
                        </form>
                    )
                }
            </Form>
        </div>
    );

    let invalidTokenMessage = (
        <div className="invalid-link-message-container">
            <h2 className="invalid-link-message">This link is not valid.</h2>
            <Link className="login-link" to="/login/">Return to login</Link>
        </div>
    );

    let confirmationDisplay = (
        <div>
            <h2>Success!</h2>
            <p>Your password was successfully reset. Return back to the login page, and try to log back into your account with your new password.</p>
            <p><Link className="login-link" to="/login/">Return to login</Link></p>
        </div>
    );

    useEffect(() => {

        if (isLoading) {
            fetchApiGet(`forgot-password-token-verification/?token=${token}`)
                .then((result) => {
                    if (result['is_valid']) {
                        setTokenVerification(true);
                    } else {
                        setTokenVerification(false);
                    };

                    setLoading(false);
                })
                .catch(() => {
                    setTokenVerification(false);
                    setLoading(false);
                });
        }
    // Empty dependency list needed to make sure API call is only called once on mount.
    // eslint-disable-next-line
    }, []);

    if (tokenIsVerified && !isLoading) {
        bodyContent = resetForm;
    } else if (!tokenIsVerified && !isLoading) {
        bodyContent = invalidTokenMessage;
    }

    if (showConfirmationDisplay && !isLoading) {
        bodyContent = confirmationDisplay;
    }

    return (
        <div id="password-reset">
            <div className="header">
                <div className="header-inner-container">
                    <div>
                        <Link
                            to={{
                                pathname: '/'
                            }}
                        >
                            <h1 className="logo">Boeiie</h1>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="page-body-container">
                {bodyContent}
            </div>
        </div>
    );
};
