import React, {useState, useEffect} from 'react';
import {Form, Field} from 'react-final-form';
import {Link} from 'react-router-dom';

import GlobalHeader from '../../common/components/global_header/connector';
import {
    fetchApiGet,
    fetchApiPost
} from '../../common/utils/api';

import './DoNotSell.css';


function DoNotSell() {
    let [doNotSellIsSet, setDoNotSell] = useState(false);
    const handleFormSubmit = (formValues) => {
        return fetchApiPost('do-not-sell-my-information/', formValues)
            .then((result) => {
                setDoNotSell(true);
            });
    };

    useEffect(() => {
        function getDoNotSellStatus() {
            fetchApiGet(`do-not-sell-my-information/`)
                .then((result) => {
                    if (result.id) {
                        setDoNotSell(true);
                    }
                });
        }

        getDoNotSellStatus();
    }, [doNotSellIsSet]);

    let optedOutComponent = (
        <div className="submit-button-container">
            <button
                className="submit-button"
                type="submit"
                disabled={doNotSellIsSet}
            >
                You chose to opt-out
            </button>
        </div>
    );

    return (
        <div id="do-not-sell">
            <GlobalHeader />
            <div className="page-body-container">
                <h2 className="header">Do Not Sell My Information</h2>
                <p>
                    If you are a California resident, the California Consumer Privacy Act (“CCPA”) provides you with the right to opt out of the “sale” of your personal information.
                    Submit the form below to opt-out.
                </p>
                <p>
                    If you are not a California resident, we still want to grant all users control over their personal information, so you also have the ability to opt-out.
                </p>
                <p>
                    <strong>Note: </strong>We do not sell personal information to third-parties, but we still want to grant users direct control.
                    If you would like more details in how we collect, use, and share data and personal information, view the <Link to="privacy-policy">privacy policy</Link>.
                    If you have questions in regards to your data, feel free to contact us at privacy@boeiie.com.
                </p>
                {
                    doNotSellIsSet ?
                    optedOutComponent :
                    <div>
                        <p>In order to opt-out of the sale of personal information, submit the form below.</p>

                        <Form
                            onSubmit={handleFormSubmit}
                        >
                            {
                                (props) => (
                                    <form onSubmit={props.handleSubmit}>
                                        <div>
                                            <Field
                                                id="is_a_cali_resident"
                                                name="is_california_resident"
                                                component="input"
                                                type="checkbox"
                                                disabled={doNotSellIsSet}
                                            />
                                            <label
                                                htmlFor="is_a_cali_resident"
                                            >
                                                I am a California resident
                                            </label>
                                        </div>
                                        <div className="submit-button-container">
                                            <button
                                                className="submit-button"
                                                type="submit"
                                                disabled={doNotSellIsSet}
                                            >
                                                Do Not Sell My Personal Information
                                            </button>
                                        </div>
                                    </form>
                                )
                            }
                        </Form>
                    </div>
                }
            </div>
        </div>
    );
}

export default DoNotSell;
