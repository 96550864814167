import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import MaterialDropdown from '../../common/components/material_dropdown/MaterialDropdown';
import {formatNavigationQuery} from '../../common/utils/routing';

import './GroupMembers.css';


export default class GroupMembers extends Component {

    componentDidMount() {
        this.props.onPageLoad();
    }

    handleRemoveFromGroup = (memberId) => this.props.handleRemoveGroupMember(memberId);

    render() {
        let {
            authUser,
            admins,
            members,
            group,
            hasMoreAdmin,
            hasMoreMembers,
            handleUpdateMemberRole,
            handleUnjoinGroup,
            handleLoadMoreMembers,
            handleLoadMoreAdmins,
        } = this.props;
        let membersList = null;
        let adminList = null;

        adminList = admins.map((admin, index) => {
            let primaryIdentifier = '';
            let isLoggedInUser = authUser.user_id === admin.id;
            let memberEditOptions = [];

            if (group.is_primary_admin && isLoggedInUser) {
                memberEditOptions = [
                    {
                        'display': 'Remove admin status',
                        handleOptionClick: () => handleUpdateMemberRole(admin.id, 'basic')
                    },
                    {
                        'display': 'Leave group',
                        handleOptionClick: () => handleUnjoinGroup()
                    }
                ];

            } else if (group.is_primary_admin && !isLoggedInUser) {

                memberEditOptions = [
                    {
                        'display': 'Remove admin status',
                        handleOptionClick: () => handleUpdateMemberRole(admin.id, 'basic')
                    },
                    {
                        'display': 'Remove from group',
                        handleOptionClick: () => this.handleRemoveFromGroup(admin.id)
                    },
                    {
                        'display': 'Make primary admin',
                        handleOptionClick: () => handleUpdateMemberRole(admin.id, 'primary_admin')
                    },
                ];

            } else if (!group.is_admin) {
                memberEditOptions = [];
            } else {

                if (isLoggedInUser && admin.is_primary_admin) {
                    memberEditOptions = [
                        {
                            'display': 'Remove admin status',
                            handleOptionClick: () => handleUpdateMemberRole(admin.id, 'basic')
                        },
                        {
                            'display': 'Leave group',
                            handleOptionClick: () => handleUnjoinGroup()
                        }
                    ];
                } else if (isLoggedInUser && !admin.is_primary_admin) {
                    memberEditOptions = [
                        {
                            'display': 'Remove admin status',
                            handleOptionClick: () => handleUpdateMemberRole(admin.id, 'basic')
                        },
                        {
                            'display': 'Leave group',
                            handleOptionClick: () => handleUnjoinGroup()
                        }
                    ];

                } else if (!isLoggedInUser && !admin.is_primary_admin) {
                    memberEditOptions = [
                        {
                            'display': 'Remove admin status',
                            handleOptionClick: () => handleUpdateMemberRole(admin.id, 'basic')
                        },
                        {
                            'display': 'Remove from group',
                            handleOptionClick: () => this.handleRemoveFromGroup(admin.id)
                        }
                    ];
                }

            }

            if (admin.is_primary_admin) {
                primaryIdentifier = "(Primary) ";
            }

            return (
                <div key={index} className="member-row">
                    <div className="member-name">
                        <Link
                            key={admin.id}
                            className="member-link"
                            to={{
                                pathname: `/profile/${admin['slug_hash']}/`,
                                search: formatNavigationQuery()
                            }}
                        >
                            {`${primaryIdentifier}${admin['first_name']} ${admin['last_name']}`}
                        </Link>
                    </div>
                    {
                        !_.isEmpty(memberEditOptions) ?
                        <div className="member-edit-button">
                            <MaterialDropdown
                                menuOptions={memberEditOptions}
                                AnchorComponent={(props) => (
                                    <IconButton {...props} style={{padding: '0px'}}>
                                        <MoreHorizIcon style={{color: '#000'}} />
                                    </IconButton>
                                )}
                            />
                        </div> : null
                    }
                </div>
            );
        });

        if (!_.isEmpty(members)) {
            membersList = members.map((member, index) => {
                let memberEditOptions = [];
                let isLoggedInUser = authUser.user_id === member.id;

                if (group.is_primary_admin) {
                    memberEditOptions = [
                        {
                            'display': 'Make admin',
                            handleOptionClick: () => handleUpdateMemberRole(member.id, 'admin')
                        },
                        {
                            'display': 'Make primary admin',
                            handleOptionClick: () => handleUpdateMemberRole(member.id, 'primary_admin')
                        },
                        {
                            'display': 'Remove from group',
                            handleOptionClick: () => this.handleRemoveFromGroup(member.id)
                        }
                    ];
                } else if (group.is_admin) {
                    memberEditOptions = [
                        {
                            'display': 'Make admin',
                            handleOptionClick: () => handleUpdateMemberRole(member.id, 'admin')
                        },
                        {
                            'display': 'Remove from group',
                            handleOptionClick: () => this.handleRemoveFromGroup(member.id)
                        }
                    ];
                } else if (isLoggedInUser) {
                    memberEditOptions = [
                        {
                            'display': 'Leave group',
                            handleOptionClick: () => handleUnjoinGroup()
                        }
                    ];
                }

                return (
                    <div key={index} className="member-row">
                        <div className="member-name">
                            <Link
                                key={member.id}
                                className="member-link"
                                to={{
                                    pathname: `/profile/${member['slug_hash']}/`,
                                    search: formatNavigationQuery()
                                }}
                            >
                                {`${member['first_name']} ${member['last_name']}`}
                            </Link>
                        </div>
                        {
                            !_.isEmpty(memberEditOptions) ?
                            <div className="member-edit-button">
                                <MaterialDropdown
                                    menuOptions={memberEditOptions}
                                    AnchorComponent={(props) => (
                                        <IconButton {...props} style={{padding: '0px'}}>
                                            <MoreHorizIcon style={{color: '#000'}} />
                                        </IconButton>
                                    )}
                                />
                            </div> : null
                        }
                    </div>
                );
            });
        }

        return (
            <div id="group-members">
                <div className="admin-section">
                    <h4>Admins</h4>
                    {adminList}
                    {
                        hasMoreAdmin ?
                        <div className="more-admins-button-row">
                            <button onClick={handleLoadMoreAdmins}>More admins</button>
                        </div> : null
                    }
                </div>
                <div className="members-section">
                    {membersList}
                    {
                        hasMoreMembers ?
                        <div className="more-members-button-row">
                            <button onClick={handleLoadMoreMembers}>More members</button>
                        </div> : null
                    }
                </div>
            </div>
        );
    }

}

GroupMembers.propTypes = {
    authUser: PropTypes.object.isRequired,
    members: PropTypes.array.isRequired,
    admins: PropTypes.array.isRequired,
    group: PropTypes.object.isRequired,
    hasMoreAdmin: PropTypes.bool.isRequired,
    hasMoreMembers: PropTypes.bool.isRequired,
    handleCreatePost: PropTypes.func,
    handleJoinGroup: PropTypes.func,
    handleEditGroup: PropTypes.func,
    handleInviteMember: PropTypes.func,
    handleUpdateMemberRole: PropTypes.func,
    handleRemoveGroupMember: PropTypes.func,
    handleLoadMoreAdmins: PropTypes.func.isRequired,
    handleLoadMoreMembers: PropTypes.func.isRequired,
};
