import {
    NOTIFICATION_ROUTE_GENERATOR_MAP,
    NOTIFICATION_ACTION_TYPE_GROUP_NEW_POST,
    NOTIFICATION_ACTION_TYPE_GROUP_MEMBER_JOINED,
    NOTIFICATION_ACTION_TYPE_GROUP_MEMBER_ROLE_ASSIGNMENT,
    NOTIFICATION_ACTION_TYPE_GROUP_DETAILS_CHANGED,
    NOTIFICATION_ACTION_TYPE_COMMENT,
    NOTIFICATION_ACTION_TYPE_COMMENT_REACTION,
    NOTIFICATION_ACTION_TYPE_POST_REACTION,
    NOTIFICATION_ACTION_TYPE_POST,
    NOTIFICATION_TARGET_ENTITY_TYPE_COMMENT,
} from '../constants/notifications';


export const generateNotificationRoute = (notification) => {
    let actionKey = notification['action_type'];
    let targetEntity = notification['target_entity'];
    let targetEntityType = notification['target_entity_type'];
    let actionEntity = notification['action_entity'];
    let generateRoute = NOTIFICATION_ROUTE_GENERATOR_MAP[actionKey];

    if (!generateRoute) {
        return '';
    }

    if (actionKey === NOTIFICATION_ACTION_TYPE_GROUP_NEW_POST) {
        return generateRoute(actionEntity['slug_hash'], actionEntity['slug']);
    }

    if (actionKey === NOTIFICATION_ACTION_TYPE_GROUP_MEMBER_JOINED) {
        return generateRoute(targetEntity['slug_hash'], targetEntity['slug']);
    }

    if (actionKey === NOTIFICATION_ACTION_TYPE_GROUP_MEMBER_ROLE_ASSIGNMENT) {
        return generateRoute(targetEntity['slug_hash'], targetEntity['slug']);
    }

    if (actionKey === NOTIFICATION_ACTION_TYPE_GROUP_DETAILS_CHANGED) {
        return generateRoute(targetEntity['slug_hash'], targetEntity['slug']);
    }

    if (actionKey === NOTIFICATION_ACTION_TYPE_COMMENT && targetEntityType === NOTIFICATION_TARGET_ENTITY_TYPE_COMMENT) {
        return generateRoute(targetEntity['post']['slug_hash'], targetEntity['post']['slug']);
    }

    if (actionKey === NOTIFICATION_ACTION_TYPE_COMMENT && targetEntityType !== NOTIFICATION_TARGET_ENTITY_TYPE_COMMENT) {
        return generateRoute(targetEntity['slug_hash'], targetEntity['slug']);
    }

    if (actionKey === NOTIFICATION_ACTION_TYPE_COMMENT_REACTION) {
        return generateRoute(targetEntity['post']['slug_hash'], targetEntity['post']['slug']);
    }

    if (actionKey === NOTIFICATION_ACTION_TYPE_POST_REACTION) {
        return generateRoute(targetEntity['slug_hash'], targetEntity['slug']);
    }

    if (actionKey === NOTIFICATION_ACTION_TYPE_POST) {
        return generateRoute(actionEntity['slug_hash'], actionEntity['slug']);
    }

    return '';
};
