import {connect} from 'react-redux';
import _ from 'underscore';

import {
    createReaction,
    removeReaction
} from '../../common/action_creators/reactions';
import {deletePost} from '../../common/action_creators/posts';
import {editPostStart} from '../../common/components/compose_post_form/actions';
import {onSubmitHidePostForm} from '../../common/components/post_card/connector';
import {POSTS} from '../../common/constants/demo';
import {fetchApiGet} from '../../common/utils/api';
import {isDemo} from '../../common/utils/routing';

import * as actions from './actions';
import PostDetails from './PostDetails';


const getPost = (slugHash) => (fetchApiGet(`posts/${slugHash}/`));

const initializePage = (slugHash) => (dispatch) => {
    dispatch(actions.loadPostStart());

    return getPost(slugHash)
        .then((post) => {
            if (!post.error) {
                dispatch(actions.loadPostSuccess(post));
                return post
            }
        });
};

const addReaction = (emoji, postId) => (dispatch, getState) => {
    let state = getState();
    let authUserId = state.app.authUser['user_id'];

    return dispatch(createReaction('post', postId, emoji, authUserId));
};

const deleteReaction = (reactionId, postId, targetEntityType) => (dispatch, getState) => {
    let state = getState();
    let authUserId = state.app.authUser['user_id'];

    return dispatch(removeReaction(reactionId, postId, authUserId, targetEntityType));
};

const mapStateToProps = (state, {history}) => {
    let {post={}} = state.postDetails;
    let commentThreadKey = `posts/${post.id}/`;
    let commentThread = state.commentThread[commentThreadKey] || {};
    let numberOfComments = commentThread.totalCount || 0;
    let authUser = state.app.authUser || {};

    if (isDemo() && history.location.pathname === '/post/demo/') {
        post = POSTS['results'][0];
        numberOfComments = 0;
    }

    return {
        authUser,
        isAuthenticated: !_.isEmpty(authUser),
        isVerified: !_.isEmpty(authUser) && authUser.is_verified,
        post,
        numberOfComments
    };
};

const handleEditPost = (post, push) => (dispatch) => {
    dispatch(editPostStart(post));
};

const mapDispatchToProps = (dispatch, {push, match: {params: {slugHash}}}) => {
    return {
        handleAddReaction: (emoji, postId) => dispatch(addReaction(emoji, postId)),
        handleRemoveReaction: (reactionId, postId, targetEntityType) => dispatch(deleteReaction(reactionId, postId, targetEntityType)),
        onLoad: () => dispatch(initializePage(slugHash)),
        onEditPost: (post) => dispatch(handleEditPost(post, push)),
        onHidePostSubmit: (postId, formValues) => dispatch(onSubmitHidePostForm(postId, formValues)),
        onDeletePost: (postId) => dispatch(deletePost(postId)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PostDetails);