import _ from 'underscore';

import {LOAD_UNREAD_NOTIFICATIONS_COUNT_SUCCESS} from '../../common/actions/notifications';
import {
    LOAD_MY_POSTS_START,
    LOAD_MY_POSTS_SUCCESS,
    LOAD_MY_POSTS_FAIL,
} from '../../common/actions/posts';
import {
    CREATE_REACTION_SUCCESS,
    REMOVE_REACTION_SUCCESS,
} from '../../common/actions/reactions';

import {
    LOAD_NOTIFICATIONS_START,
    LOAD_NOTIFICATIONS_SUCCESS,
    LOAD_NOTIFICATIONS_FAIL,
    VIEW_NOTIFICATION_SUCCESS,
} from './actions';


const loadNotificationsStartReducer = (state) => ({
    ...state
});

const loadNotificationsSuccessReducer = (state, {
    notifications,
    notificationsNextLink,
    notificationsPreviousLink
}) => ({
    ...state,
    notifications,
    notificationsNextLink,
    notificationsPreviousLink
});

const loadNotificationsFailReducer = (state, {errors}) => ({
    ...state,
    errors
});

const loadMyPostsStartReducer = (state) => ({
    ...state
});

const loadMyPostsSuccessReducer = (state, {posts, postsNextLink, postsPreviousLink}) => ({
    ...state,
    posts,
    postsNextLink,
    postsPreviousLink
});

const loadMyPostsFailReducer = (state, {errors}) => ({
    ...state,
    errors
});

const createReactionSuccessReducer = (state, {reaction, authUserId}) => {
    // Check if a reaction is already set.
    if (reaction['target_entity_type'] === 'post') {
        let oldPostIndex = _.findIndex(state.posts, (post) => post.id === reaction['target_entity_id']);

        if (oldPostIndex === -1) {
            return state;
        }

        let oldPost = {...state.posts[oldPostIndex]};

        let oldPostReactionSummaryIndex = _.findIndex(oldPost['meta_data']['reaction_summary'], (reaction) => {
            return reaction['user_id'] === authUserId;
        });
        let postHasExistingReaction = oldPostReactionSummaryIndex > -1;

        if (postHasExistingReaction) {
            let oldPostReactionSummary = oldPost['meta_data']['reaction_summary'][oldPostReactionSummaryIndex];

            oldPostReactionSummary['type'] = reaction['reaction_emoji_type'];
            oldPost['meta_data']['reaction_summary'][oldPostReactionSummaryIndex] = oldPostReactionSummary;

            state.posts[oldPostIndex] = oldPost;

            return {
                ...state,
                posts: [...state.posts],
            };
        } else {
            oldPost['meta_data']['reaction_summary'].push({
                type: reaction['reaction_emoji_type'],
                total: 1,
                'user_id': authUserId,
            });
            oldPost['meta_data']['reaction_id'] = reaction.id
            state.posts[oldPostIndex] = oldPost;

            return {
                ...state,
                posts: [...state.posts]
            };
        }
    }

    return state;
};

const removeReactionSuccessReducer = (state, {reactionId, targetEntityId, authUserId, targetEntityType}) => {
    if (targetEntityType === 'post') {
        let postIndex = _.findIndex(state.posts, (post) => post.id.toString() === targetEntityId);

        if (postIndex === -1) {
            return state;
        }

        let post = {...state.posts[postIndex]};

        post['meta_data']['reaction_summary'] = _.filter(post['meta_data']['reaction_summary'], (summary) => {
            return summary['user_id'] !== authUserId;
        });
        post['meta_data']['reaction_id'] = null;

        state.posts[postIndex] = post;

        return {
            ...state,
            posts: [...state.posts]
        };
    }

    return state;
};

const viewNotificationSuccessReducer = (state, {notificationId}) => {
    let notificationIndex = _.find(state.notifications, (notification) => notification.id.toString() === notificationId);

    if (notificationIndex === -1) {
        return state;
    }

    let notification = {...state.notifications[notificationIndex]};

    notification['viewed'] = true;

    state.notifications[notificationIndex] = notification;

    let numberOfUnreadNotifications = state.numberOfUnreadNotifications;

    if (numberOfUnreadNotifications > 0) {
        numberOfUnreadNotifications -= 1;
    }

    return {
        ...state,
        numberOfUnreadNotifications,
        notifications: [...state.notifications],
    };
};

const loadUnreadNotificationsCountReducer = (state, {unreadCount}) => {
    return {
        ...state,
        numberOfUnreadNotifications: unreadCount
    };
};

const ACTIVITY_REDUCER_MAP = {
    [LOAD_NOTIFICATIONS_START]: loadNotificationsStartReducer,
    [LOAD_NOTIFICATIONS_SUCCESS]: loadNotificationsSuccessReducer,
    [LOAD_NOTIFICATIONS_FAIL]: loadNotificationsFailReducer,
    [LOAD_MY_POSTS_START]: loadMyPostsStartReducer,
    [LOAD_MY_POSTS_SUCCESS]: loadMyPostsSuccessReducer,
    [LOAD_MY_POSTS_FAIL]: loadMyPostsFailReducer,
    [CREATE_REACTION_SUCCESS]: createReactionSuccessReducer,
    [REMOVE_REACTION_SUCCESS]: removeReactionSuccessReducer,
    [VIEW_NOTIFICATION_SUCCESS]: viewNotificationSuccessReducer,
    [LOAD_UNREAD_NOTIFICATIONS_COUNT_SUCCESS]: loadUnreadNotificationsCountReducer,
};

const activityReducer = (initialState={}, action={}) => {
    let reducer = ACTIVITY_REDUCER_MAP[action.type];
    let nextState = initialState;

    if (reducer) {
        nextState = reducer(initialState, action);
    }

    return nextState;
};

export default activityReducer;
