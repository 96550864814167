export const formatOccupation = (position, company) => {
    let occupation = '';

    if (position && company) {
        occupation = `${position} at ${company}`;
    } else if (position && !company) {
        occupation = position;
    } else if (!position && company) {
        occupation = `Works at ${company}`;
    }

    return occupation;
};

export const formatHomeTown = (city, state) => {
    let hometown = '';

    if (city && state) {
        hometown = `${city}, ${state}`;
    } else if (city && !state) {
        hometown = city;
    } else if (!city && state) {
        hometown = state;
    }

    return hometown;
};
