import {
    createReactionStart,
    createReactionSuccess,
    createReactionFail,
    removeReactionStart,
    removeReactionSuccess,
    removeReactionFail,
} from '../actions/reactions';
import {
    fetchApiPost,
    fetchApiDelete,
} from '../utils/api';


export const createReaction = (targetEntityType, targetEntityId, reactionEmojiType, authUserId) => (dispatch) => {
    dispatch(createReactionStart(targetEntityType, targetEntityId, reactionEmojiType));

    return fetchApiPost('create_reaction/', {
        target_entity_type: targetEntityType,
        target_entity_id: targetEntityId,
        reaction_emoji_type: reactionEmojiType
    }).then((reaction) => {
        return dispatch(createReactionSuccess(reaction, authUserId));
    }).catch((errors) => {
        return dispatch(createReactionFail(errors));
    });
};

export const removeReaction = (reactionId, targetEntityId, authUserId, targetEntityType) => (dispatch) => {
    dispatch(removeReactionStart(reactionId));

    return fetchApiDelete(`delete_reaction/${reactionId}/`)
        .then(() => dispatch(removeReactionSuccess(reactionId, targetEntityId, authUserId, targetEntityType)))
        .catch((errors) => dispatch(removeReactionFail(errors)));
};
