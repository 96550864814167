import _ from 'underscore';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Form, Field} from 'react-final-form';
import {Link} from 'react-router-dom';

import Dropdown from '../../common/components/dropdown/Dropdown';
import PreviewLink from '../../common/components/preview_link/PreviewLink';
import {formatDateTime} from '../../common/utils/datetime';
import {formatNavigationQuery} from '../../common/utils/routing';
import {isDemo} from '../../common/utils/routing';
import {kebabCase} from '../../common/utils/strings';
import AccountVerificationPrompt from '../../common/components/account_verification_prompt/AccountVerificationPrompt';
import GlobalHeader from '../../common/components/global_header/connector';
import CommentThread from '../../common/components/comment_thread/connector';
import EmojiSelector from '../../common/components/emoji_selector/EmojiSelector';
import {
    DEFAULT_OG_DESCRIPTION,
    DEFAULT_OG_IMAGE_URL
} from '../../common/constants/app';
import {EMOJI_MAP} from '../../common/constants/emoji';
import {setOGTagsToDefault} from '../../common/utils/app';
import {getCroppedText} from '../../common/utils/strings';

import './PostDetails.css';


const updateOGTagsForPost = (post) => {
    let ogElements = document.getElementsByClassName('boeiie-og-element');
    let ogElementsLength = ogElements.length;

    for (let i=0; i < ogElementsLength; i++) {
        let element = ogElements[i];
        let attribute = element.getAttribute('property');

        if (attribute === 'og:url') {
            element.content = `https://boeiie.com/post/${post.slug_hash}/${post.slug}/`;
        } else if (attribute === 'og:title') {
            if (post.title) {
                element.content = `${post.title} | Boeiie.com`;
            } else {
                element.content = `${getCroppedText(post.body,0,100)} | Boeiie.com`;
            }

        } else if (attribute === 'og:description') {
            if (!post.title) {
                element.content = `${getCroppedText(post.body,0,100)} | Boeiie.com`;
            } else {
                element.content = DEFAULT_OG_DESCRIPTION;
            }
        } else if (attribute === 'og:image') {
            if (post.photo) {
                element.content = post.photo;
            } else {
                element.content = DEFAULT_OG_IMAGE_URL;
            }
        }
    }
};

const HideForm = () => {
    return (
        <form>
            <div className="other-reason-input-container">
                <Field
                    className="other-reason-input"
                    name="other"
                    component="input"
                    placeholder="Other reason..."
                />
            </div>
        </form>
    );
};

class OptionsDropdown extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showHideForm: false,
            showOtherReasonForm: false,
            selectedReasons: {}
        };
    }

    handleHideClick = () => {
        this.setState({showHideForm: true});
    };

    handleRemoveHideFormClick = () => {
        this.setState({showHideForm: false});
    };

    handleHideReasonClick = (reason) => {
        let {
            showOtherReasonForm,
            selectedReasons
        } = this.state;

        selectedReasons[reason] = !selectedReasons[reason];

        if (reason === 'other') {
            showOtherReasonForm = !showOtherReasonForm;
        }

        this.setState({
            showOtherReasonForm,
            selectedReasons
        });
    };

    handleFormSubmit = (otherReasonInputValue) => {
        let {selectedReasons} = this.state;
        let {
            onHideOptionFormSubmit,
            onHidePostSubmit,
            postId
        } = this.props;
        let formValues = {};
        let selectedReasonKeys = Object.keys(selectedReasons);
        let reasonTypes = [];

        if (otherReasonInputValue) {
            formValues['reason_description'] = otherReasonInputValue['other'];
        }

        selectedReasonKeys.forEach((key) => {
            if (selectedReasons[key]) {
                reasonTypes.push(key);
            }
        });

        formValues['reason_types'] = reasonTypes.join(',');

        onHidePostSubmit(postId, formValues)
            .then((post) => {
                this.setState({
                    showHideForm: false,
                    showOtherReasonForm: false,
                    selectedReasons: {}
                });

                let postWasHidden = post.id !== null || post.id !== undefined;

                onHideOptionFormSubmit(postWasHidden);
            });
    };

    render() {
        let {
            showHideForm,
            showOtherReasonForm,
            selectedReasons,
        } = this.state;
        let {
            postId,
            hideEditPostButton,
            hideHidePostButton,
            hideDeletePostButton,
            onOptionsClick
        } = this.props;
        let selectedReasonStyle = {backgroundColor: '#888', color: '#fff'};
        let editPostButton = (
            <button
                className="option-button"
                onClick={() => onOptionsClick('edit')}
            >
                <img
                    className="option-icon"
                    src="/media/ui_icons/edit.png"
                    alt=""
                />
                <span>Edit Post</span>
            </button>
        );
        let hidePostButton = (
            <button
                className="option-button"
                onClick={() => this.handleHideClick('hide')}
            >
                <img
                    className="option-icon"
                    src="/media/ui_icons/eye-disabled.png"
                    alt=""
                />
                <span>Hide Post</span>
            </button>
        );
        let deletePostButton = (
            <button
                className="option-button"
                onClick={() => onOptionsClick('delete', postId)}
            >
                <img
                    className="option-icon"
                    src="/media/ui_icons/trash.png"
                    alt=""
                />
                <span>Delete Post</span>
            </button>
        );

        if (hideEditPostButton) {
            editPostButton = null;
        }

        if (hideHidePostButton) {
            hidePostButton = null;
        }

        if (hideDeletePostButton) {
            deletePostButton = null;
        }

        let options = (
            <div>
                {editPostButton}
                {hidePostButton}
                {deletePostButton}
            </div>
        );
        let content = options;
        let submit = this.handleFormSubmit;

        if (showHideForm) {
            content = (
                <div className="hide-options-form">
                    <div className="hide-form-header">
                        <button onClick={this.handleRemoveHideFormClick}>
                            <img
                                className="back-arrow-icon"
                                src="/media/ui_icons/arrow-left.png"
                                alt="Back"
                            />
                        </button>
                    </div>
                    <div className="hide-reasons-container">
                        <button
                            className="hide-reason-button"
                            style={
                                selectedReasons['spam'] ? selectedReasonStyle : null
                            }
                            onClick={() => this.handleHideReasonClick('spam')}
                        >
                            Spam
                        </button>
                        <button
                            className="hide-reason-button"
                            style={
                                selectedReasons['not-relevant'] ? selectedReasonStyle : null
                            }
                            onClick={() => this.handleHideReasonClick('not-relevant')}
                        >
                            Not relevant
                        </button>
                        <button
                            className="hide-reason-button"
                            style={
                                selectedReasons['violence'] ? selectedReasonStyle : null
                            }
                            onClick={() => this.handleHideReasonClick('violence')}
                        >
                            Violence
                        </button>
                        <button
                            className="hide-reason-button"
                            style={
                                selectedReasons['illegal'] ? selectedReasonStyle : null
                            }
                            onClick={() => this.handleHideReasonClick('illegal')}
                        >
                            Illegal
                        </button>
                        <button
                            className="hide-reason-button"
                            style={
                                selectedReasons['other'] ? selectedReasonStyle : null
                            }
                            onClick={() => this.handleHideReasonClick('other')}
                        >
                            Other
                        </button>
                    </div>
                    {
                        !showOtherReasonForm ? null :
                            <Form
                                onSubmit={(otherInput) => this.handleFormSubmit(otherInput)}
                                render={({handleSubmit}) => {
                                    submit = handleSubmit;
                                    return (
                                        <HideForm
                                        />
                                    );
                                }}
                            />
                    }
                    <div className="hide-form-footer">
                        <button
                            className="submit-button"
                            onClick={() => submit()}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            );
        }

        if (isDemo()) {
            return (
                <Dropdown>
                    <div>
                        <p style={{padding: '10px', margin: '0px'}}>
                            <strong>Demo Mode:</strong> You cannot access edit controls unless you are logged-in.
                        </p>
                    </div>
                </Dropdown>
            );
        }

        return (
            <Dropdown>
                {content}
            </Dropdown>
        );
    }
};

OptionsDropdown.propTypes = {
    postId: PropTypes.string,
    hideEditPostButton: PropTypes.bool,
    hideHidePostButton: PropTypes.bool,
    hideDeletePostButton: PropTypes.bool,
    onOptionsClick: PropTypes.func,
    onHideOptionFormSubmit: PropTypes.func,
    onHidePostSubmit: PropTypes.func
};


export default class PostDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showOptionsDropdown: false,
            accountVerifyPromptIsOpen: false,
        };
    }

    componentDidMount() {
        let {
            onLoad,
            history,
        } = this.props;

        if (!(isDemo() && history.location.pathname === '/post/demo/')) {
            onLoad()
                .then(({post}) => {
                    if (post.title) {
                        document.title = `${post.title} | Boeiie`;
                    } else if (post.body) {
                        document.title = `${post.body} | Boeiie`;
                    }

                    updateOGTagsForPost(post);
                });
        }

        document.addEventListener('mousedown', this.handleInsideClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleInsideClick, false);
        setOGTagsToDefault();
    }

    handleInsideClick = (e) => {
        if (this.node && !this.node.contains(e.target)) {
            this.handleClickOutside();
        }
    };

    handleClickOutside = () => {
        this.setState({
            showOptionsDropdown: false
        });
    };

    handleMoreOptionsClick = () => {
        let {
            isVerified,
            isAuthenticated,
        } = this.props;
        let {showOptionsDropdown} = this.state;

        if (!showOptionsDropdown && isAuthenticated && !isVerified) {
            this.handleOpenAccountVerifyPrompt();
            return;
        }

        this.setState({
            showOptionsDropdown: !showOptionsDropdown,
        });
    };

    handleOptionClick = (option, postId) => {
        let {
            post,
            onDeletePost,
            onEditPost,
            history
        } = this.props;

        if (option === 'delete') {
            onDeletePost(postId)
                .then((result) => {
                    if (result.post) {
                        history.push('/');
                    }

                    this.setState({
                        showOptionsDropdown: false,
                    });
                });
        } else if (option === 'edit') {
            this.setState({
                showOptionsDropdown: false
            }, () => {
                let ref = history.location.pathname;
                let {
                    id: postId,
                    tags,
                    title,
                    body,
                    photo = '',
                    'shared_link': sharedLink
                } = post;
                let linkPreviewData = null;

                if (!_.isEmpty(sharedLink)) {
                    linkPreviewData = {
                        linkUrl: sharedLink['link_url'],
                        imageUrl: sharedLink['image_url'],
                        title: sharedLink['title'],
                        description: sharedLink['description']
                    };
                }

                onEditPost({
                    post,
                    postId,
                    tags,
                    linkPreviewData,
                    photo,
                    initialValues: {
                        title,
                        body,
                    }
                });

                history.push(`/compose_post/?ref=${ref}`);
            });
        }
    };

    closePostForm = (postFormKey) => {
        this.setState({[postFormKey]: false});
    };

    onHideOptionFormSubmit = (postWasHidden) => {
        let {history} = this.props;

        this.setState({showOptionsDropdown: false});

        if (postWasHidden) {
            history.push('/');
        }
    };

    handleCloseAccountVerifyPrompt = () => {
        this.setState({accountVerifyPromptIsOpen: false});
    };

    handleOpenAccountVerifyPrompt = () => {
        let {
            isAuthenticated,
            isVerified,
        } = this.props;

        if (isAuthenticated && !isVerified) {
            this.setState({accountVerifyPromptIsOpen: true});
        }
    };

    render() {
        let {
            authUser,
            isAuthenticated,
            isVerified,
            post={},
            numberOfComments,
            match: {params},
            onHidePostSubmit,
            handleAddReaction,
            handleRemoveReaction,
        } = this.props;
        let {
            user={},
            tags=[],
            group={},
            'shared_link': sharedLink,
            'meta_data': metaData = {},
        } = post;
        let {'profile_image_url': authorProfileImageUrl} = user;
        let {
            showOptionsDropdown,
            accountVerifyPromptIsOpen,
        } = this.state;
        let authorName = `${user['first_name']} ${user['last_name']}`;
        let authorSlugHash = user['slug_hash'];
        let commentsSection = null;
        let commentsTitle = `${numberOfComments} comments`;
        let tag_buttons = tags.map((tag) => (
            <div key={tag.id} className="tag-button-container">
                <Link to={{
                    pathname: '/',
                    search: formatNavigationQuery({tags: kebabCase(tag.name)})
                }}>
                    <div
                        className="tag-button"
                    >
                        {tag.name}
                    </div>
                </Link>
            </div>
        ));
        let moreOptionsDropdown = null;
        let linkPreview = null;
        let postPhoto = null;
        let groupLink = null;
        let headerTextComponent = null;
        let currentUserReaction = {};
        let consolidatedReaction = _.reduce(metaData['reaction_summary'], (memo, reaction) => {
            if (reaction.type in memo) {
                memo[reaction.type]['total'] += reaction.total;
                if (!memo[reaction.type]['has_reacted']) {
                    memo[reaction.type]['has_reacted'] = reaction['user_id'] === authUser['user_id'];
                }

            } else {
                memo[reaction.type] = {
                    'total': reaction.total,
                    'has_reacted': reaction['user_id'] === authUser['user_id'],
                };
            }

            if (memo[reaction.type]['has_reacted']) {
                currentUserReaction = {
                    'emojiType': EMOJI_MAP[reaction.type],
                    'emojiTitle': reaction.type,
                    'reactionId': metaData['reaction_id'],
                    'targetEntityType': 'post',
                };
            }

            return memo;
        }, {});
        let reactionSummaryIcons = Object.keys(consolidatedReaction).map((emojiType, index) => (
            <span key={index} className={`reaction-emoji ${consolidatedReaction[emojiType]['has_reacted'] ? 'has-reacted' : ''}`}>
                <span className="emoji-icon">{EMOJI_MAP[emojiType]}</span>
                <span className="reaction-count">{consolidatedReaction[emojiType]['total']}</span>
            </span>
        ));

        if (!_.isEmpty(sharedLink)) {
            let linkUrlObj = new URL(sharedLink['link_url']);

            linkPreview = (
                <div className="link-preview-section">
                    <PreviewLink
                        linkUrl={sharedLink['link_url']}
                        hostname={linkUrlObj.hostname}
                        imageUrl={sharedLink['image_url']}
                        title={sharedLink['title']}
                        description={sharedLink['description']}
                    />
                </div>
            );
        }

        if (post['photo']) {
            postPhoto = (
                <div className="post-photo-container">
                    <img className="post-photo" src={post['photo']} alt="" />
                </div>
            );
        }

        if (showOptionsDropdown) {
            moreOptionsDropdown = (
                <OptionsDropdown
                    hideEditPostButton={authUser['user_id'] !== post.user.id}
                    hideHidePostButton={authUser['user_id'] === post.user.id}
                    hideDeletePostButton={authUser['user_id'] !== post.user.id}
                    onOptionsClick={this.handleOptionClick}
                    onHideOptionFormSubmit={this.onHideOptionFormSubmit}
                    onHidePostSubmit={onHidePostSubmit}
                    postId={params.postId}
                />
            );
        }

        if (post.id) {
            commentsSection = (
                <div className="comments-section">
                    <CommentThread
                        postId={post.id}
                        postSlugHash={params.slugHash}
                    />
                </div>
            );
        }

        if (group) {
            groupLink = (
                <span>
                    <span className="dot-separator">&#183;</span>
                    <Link
                        className="post-group-link"
                        to={{
                            pathname: `/groups/${group['slug_hash']}/${group['slug']}/`,
                            search: formatNavigationQuery()
                        }}
                    >
                        <span>community/ </span>
                        <span className="group-name">{group['name']}</span>
                    </Link>
                </span>
            );
        }

        if (post.title) {
            headerTextComponent = (
                <h2 className="title">{post.title}</h2>
            );
        }

        return (
            <div id="post-details">
                <GlobalHeader />
                <div className="page-body-container">
                    <div className="main-post-section">
                        <div className="post-card">
                            <div className="first-row">
                                <div>
                                    <span className="post-creation-date">{formatDateTime(post['creation_date'])}</span>
                                    {groupLink}
                                </div>
                                {
                                    !_.isEmpty(authUser) ?
                                    <div className="more-options-icon-wrapper" ref={node => this.node = node}>
                                        <button onClick={this.handleMoreOptionsClick}>
                                            <img
                                                className="more-options-icon"
                                                src="/media/ui_icons/more.png"
                                                alt="More"
                                            />
                                        </button>
                                        {moreOptionsDropdown}
                                    </div> : null
                                }
                            </div>
                            <div className="header-text-section">
                                {headerTextComponent}
                            </div>
                            <div className="tags-section">
                                {tag_buttons}
                            </div>
                            <Link to={`/profile/${authorSlugHash}/`}>
                                <div className="author-section">
                                    <div className="avatar-section">
                                        <div className="post-card-avatar">
                                            {
                                                authorProfileImageUrl ?
                                                <img className="post-card-avatar-img" src={authorProfileImageUrl} alt="" />
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="author-data-section">
                                        <p className="author-name">{authorName}</p>
                                        {
                                            user['profile_headline'] ?
                                            (<p className="author-summary">{user['profile_headline']}</p>)
                                            : null
                                        }
                                    </div>
                                </div>
                            </Link>
                            {postPhoto}
                            {linkPreview}
                            {
                                post.body ?
                                <div className="description-section">
                                    <p className="description">{post.body}</p>
                                </div> : null

                            }
                            <div className="post-meta-data-section">
                                <div className="reaction-summary">
                                    {reactionSummaryIcons}
                                </div>
                                <div className="num-comments-button">
                                    <img
                                        className="comment-box-icon"
                                        src="/media/ui_icons/chatbox.png"
                                        alt="Leave a comment"
                                    />
                                    <p>{commentsTitle}</p>
                                </div>
                            </div>
                            <div className="reaction-select-section-container">
                                <div className="reaction-select-section">
                                    <div className="reaction-selector-button-container">
                                        <EmojiSelector
                                            isAuthenticated={isAuthenticated}
                                            isVerified={isVerified}
                                            currentReaction={currentUserReaction}
                                            handleAddReaction={(emoji) => handleAddReaction(emoji, post.id)}
                                            handleRemoveReaction={(reactionId) => handleRemoveReaction(reactionId, post.id, 'post')}
                                        />
                                    </div>
                                    <div className="reaction-selector-button-container">
                                        <Link
                                            className="comment-button"
                                            to={{
                                                pathname: `/post/${post['slug_hash']}/${post['slug'] || ''}/`,
                                                search: formatNavigationQuery()
                                            }}
                                        >
                                            <div>
                                                <span>
                                                    <img
                                                        className="comment-box-icon"
                                                        src="/media/ui_icons/chatbox.png"
                                                        alt="Leave a comment"
                                                    />
                                                </span>
                                                <span>Comment</span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="reaction-selector-button-container">
                                        <Link
                                            className="view-post-link"
                                            to={{
                                                pathname: `/post/${post['slug_hash']}/${post['slug'] || ''}/`,
                                                search: formatNavigationQuery()
                                            }}
                                        >
                                            <span>View Post</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {commentsSection}
                </div>
                <AccountVerificationPrompt
                    isOpen={accountVerifyPromptIsOpen}
                    title="Verify your account."
                    subtitle="In order to complete this action, verify your account by clicking the button below, and visit the email."
                    handleClose={this.handleCloseAccountVerifyPrompt}
                />
            </div>
        );
    }
}

PostDetails.propTypes = {
    authUser: PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired,
    isVerified: PropTypes.bool.isRequired,
    post: PropTypes.object,
    numberOfComments: PropTypes.number,
    handleAddReaction: PropTypes.func.isRequired,
    handleRemoveReaction: PropTypes.func.isRequired,
    onEditPost: PropTypes.func,
    onHidePostSubmit: PropTypes.func,
    onDeletePost: PropTypes.func
};
