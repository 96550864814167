import PropTypes from 'prop-types';
import React, {Component} from 'react';

import './TagButton.css';


export default class TagButton extends Component {

    render() {
        let {
            tag,
            onClick
        } = this.props;

        return (
            <div
                className="tag-button"
                onClick={(e) => onClick(tag)}
            >
                {tag.name}
            </div>
        );
    }
}

TagButton.propTypes = {
    tag: PropTypes.object,
    onClick: PropTypes.func
};
