export const DAYS_PER_MONTH = {
    'January': 31,
    'February': 28,
    'March': 31,
    'April': 30,
    'May': 31,
    'June': 30,
    'July': 31,
    'August': 31,
    'September': 30,
    'October': 31,
    'November': 30,
    'December': 31
};

export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

export const MONTH_TO_NUMBER = {
    'January': 1,
    'February': 2,
    'March': 3,
    'April': 4,
    'May': 5,
    'June': 6,
    'July': 7,
    'August': 8,
    'September': 9,
    'October': 10,
    'November': 11,
    'December': 12
};

export const NUMBER_TO_MONTH = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
};

export const MONTH_CHOICES = MONTHS.map((month) => (
    {
        'display': month,
        'value': MONTH_TO_NUMBER[month],
    }
));

export const YEAR_CHOICES = (() => {
    let now = new Date();
    let currentYear = now.getFullYear();
    let years = [];

    for (var i = 0; i < 120; i++) {
        years.push(currentYear - i);
    }

    let choices = years.map((year) => ({'display': `${year}`, 'value': year}));

    return choices;
})();
