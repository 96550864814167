export const USER = {
    'first_name': 'Zach',
    'last_name': 'Mcknight',
    'email': 'zmcknight@boeiie.com'
};


export const POSTS = {
    "count": 5,
    "next": null,
    "previous": null,
    "results": [
        {
            "id": "demo",
            "user": {
                "first_name": "Zach",
                "last_name": "Mcknight",
                "is_admin": false,
                "profile_image_url": "https://boeiie.nyc3.cdn.digitaloceanspaces.com/prod/demo/joshua-jordan-ZfiV8EgglsY-unsplash.jpg",
                "occupations": [
                    {
                        "company": "Boeiie",
                        "position": "Boxing Coach & Reporter"
                    }
                ]
            },
            "body": "A discount first year subscription for all those who attend the grand opening. It will feature an indoor football field, outdoor soccer field, olympic size swimming pool, a three story gym, 4 race tracks, 16 studios, 4 saunas, and more.",
            "title": "There city's largest sports rec center is opening downtown this weekend. Who's going to the grand opening?",
            "tags": [],
            "privacy": "public",
            "is_deleted": false,
            "creation_date": "2019-10-06T02:47:31.634494Z",
            "last_updated": "2019-10-06T02:47:31.634545Z",
            "number_of_comments": 0
        }
    ]
};


export const NOTIFICATIONS = {
    count: 1,
    next: null,
    previous: null,
    results: [
        {
            "id": 6,
            "target_entity_id": 'demo',
            "target_entity_type": "comment",
            "actor": {
                "id": 2,
                "first_name": "Rashad",
                "last_name": "Russell",
                "occupations": []
            },
            "action_type": "comment",
            "action_id": 2,
            "creation_date": "2019-09-20T05:08:46.449376Z",
            "last_updated": "2019-09-20T05:08:46.449425Z",
            "target_entity": {
                "id": 'demo',
                "user": {
                    "id": 1,
                    "first_name": "Zach",
                    "last_name": "Mcknight",
                },
                "body": "I'll be there.",
                "privacy": "public",
                "is_deleted": false,
                "creation_date": "2019-09-20T00:10:02.345322Z",
                "last_updated": "2019-09-20T00:23:02.013331Z",
                "number_of_comments": 2
            },
            "action_entity": {
                "id": "demo",
                "body": "I'll get there at 9:30a.",
                "author": {
                    "id": "demo",
                    "first_name": "Rashad",
                    "last_name": "Russell"
                },
                "post": "demo",
                "reply_to": "demo",
                "is_deleted": 0,
                "creation_date": "2019-09-20T05:08:46.445359Z",
                "last_updated": "2019-09-20T05:08:46.445393Z",
                "number_of_replies": 0
            }
        }
    ]
};


export const PROFILE = {
    "first_name": "Zach",
    "last_name": "Mcknight",
    "city": "Nashville",
    "state": "Tennessee",
    "profile_header_summary": "To learn, improve, and inspire.",
    "profile_image_url": "https://boeiie.nyc3.cdn.digitaloceanspaces.com/prod/demo/joshua-jordan-ZfiV8EgglsY-unsplash.jpg",
    "occupation": {
        "id": 2,
        "company": "Boeiie",
        "position": "Boxing Coach & Reporter"
    }
};
