import PropTypes from 'prop-types';
import React, {Component} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'underscore';

import {isDemo} from '../../utils/routing';

import CommentCard from '../comment_card/connector';
import CommentForm from '../comment_form/CommentForm';
import LoginPrompt from '../login_prompt/LoginPrompt';

import './CommentThread.css';


export default class CommentThread extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hideShowRepliesButtonMap: {},
            loginPromptIsOpen: false,
            hasMoreComments: true
        };
    }

    componentDidMount() {
        let {
            postId,
            postSlugHash,
            onLoad,
        } = this.props;

        onLoad(postId, postSlugHash);
    }

    handleCommentSubmit = (postId, formValues) => {
        let {
            onNewCommentSubmit,
            postSlugHash,
        } = this.props;

        return onNewCommentSubmit(postSlugHash, formValues)
            .then((comment) => {
                return Promise.resolve(comment);
            });
    };

    handleViewRepliesClick = (postId, parentCommentId) => {
        let {
            postSlugHash,
            onLoadReplies
        } = this.props;

        return onLoadReplies(postId, postSlugHash, parentCommentId)
            .then(() => {
                this.setState({
                    hideShowRepliesButtonMap: {
                        [parentCommentId]: true
                    }
                });

                return Promise.resolve();
            });
    };

    handleReplySend = (value, replyToId=null) => {
        if (isDemo()) {
            return;
        }

        let {
            postSlugHash,
            onNewCommentSubmit
        } = this.props;
        let data = {body: value};

        if (replyToId) {
            data['reply_to'] = replyToId;
        }

        return onNewCommentSubmit(postSlugHash, data)
            .then((comment) => {
                return Promise.resolve(comment);
            });
    };

    handleLoadMoreComments = (page) => {
        let {
            postId,
            postSlugHash,
            onLoadComments,
        } = this.props;

        onLoadComments(postId, postSlugHash, page);
    };

    handleCloseLoginPrompt = () => {
        this.setState({loginPromptIsOpen: false});
    };

    handleOpenLoginPrompt = () => {
        let {authUser} = this.props;
        let isAuthenticated = !_.isEmpty(authUser);

        if (!isAuthenticated) {
            this.setState({loginPromptIsOpen: true});
        }
    };

    render() {
        let {
            authUser={},
            comments,
            postId,
            replyMap={},
            hasMoreComments,
            isAuthenticated,
            isVerified,
            onEditCommentSubmit,
            onHideCommentSubmit,
            onDeleteComment
        } = this.props;
        let {
            loginPromptIsOpen,
            hideShowRepliesButtonMap,
        } = this.state;
        // eslint-disable-next-line
        let submitNewComment;
        let threadList = comments.map((parentComment, commentThreadIndex) => {
            let {
                author: parentAuthor={},
                body: parentCommentBody,
                'creation_date': parentCreationDate,
                id: parentCommentId
            } = parentComment || {};
            let parentAuthorName = `${parentAuthor['first_name']} ${parentAuthor['last_name']}`;
            let replies = replyMap[`posts/${postId}/comments/${parentCommentId}/`];
            let replyThread = null;

            if (replies) {
                replyThread = (
                    <div className="children-comment-section">
                        {
                            replies.map((reply, replyIndex) => {
                                let {author} = reply;
                                let authorName = `${author['first_name']} ${author['last_name']}`;

                                return (
                                    <div key={replyIndex} className="child-comment">
                                        <CommentCard
                                            postId={postId}
                                            comment={reply}
                                            commentId={reply.id}
                                            authorId={author.id}
                                            authorName={authorName}
                                            authorTitle={author['profile_headline']}
                                            authorProfileImageUrl={author['profile_image_url']}
                                            commentBody={reply.body}
                                            commentCreationDate={reply['creation_date']}
                                            replyToId={parentCommentId}
                                            metaData={reply['meta_data']}
                                            hideShowRepliesButton={true}
                                            hideEditCommentButton={authUser['user_id'] !== author.id}
                                            hideDeleteCommentButton={authUser['user_id'] !== author.id}
                                            hideHideCommentButton={authUser['user_id'] === author.id}
                                            onReply={(value) => this.handleReplySend(value, parentCommentId)}
                                            onEditCommentSubmit={onEditCommentSubmit}
                                            onHideCommentSubmit={onHideCommentSubmit}
                                            onDeleteComment={onDeleteComment}
                                        />
                                    </div>
                                );
                            })
                        }
                    </div>
                );
            }

            return (
                <div key={commentThreadIndex} className="parent-thread">
                    <div className="parent-comment-section">
                        <CommentCard
                            postId={postId}
                            comment={parentComment}
                            commentId={parentCommentId}
                            authorId={parentAuthor.id}
                            authorName={parentAuthorName}
                            authorTitle={parentAuthor['profile_headline']}
                            authorProfileImageUrl={parentAuthor['profile_image_url']}
                            commentBody={parentCommentBody}
                            commentCreationDate={parentCreationDate}
                            replyToId={parentCommentId}
                            numberOfReplies={parentComment['number_of_replies']}
                            metaData={parentComment['meta_data']}
                            hideShowRepliesButton={hideShowRepliesButtonMap[parentCommentId]}
                            hideEditCommentButton={authUser['user_id'] !== parentAuthor.id}
                            hideDeleteCommentButton={authUser['user_id'] !== parentAuthor.id}
                            hideHideCommentButton={authUser['user_id'] === parentAuthor.id}
                            onReply={(value) => this.handleReplySend(value, parentCommentId)}
                            onViewRepliesClick={() => this.handleViewRepliesClick(postId, parentCommentId)}
                            onEditCommentSubmit={onEditCommentSubmit}
                            onHideCommentSubmit={onHideCommentSubmit}
                            onDeleteComment={onDeleteComment}
                        />
                    </div>
                    {replyThread}
                </div>
            );

        });

        return (
            <div className="comment-thread">
                <InfiniteScroll
                    pageStart={0}
                    loadMore={this.handleLoadMoreComments}
                    hasMore={hasMoreComments}
                    initialLoad={true}
                    loader={<div className="loader" key={0}>Loading ...</div>}
                >
                    {threadList}
                </InfiniteScroll>
                <div className="comment-form-container">
                    <CommentForm
                        postId={postId}
                        isAuthenticated={isAuthenticated}
                        isVerified={isVerified}
                        onSubmit={this.handleCommentSubmit}
                    />
                </div>
                <LoginPrompt
                    isOpen={loginPromptIsOpen}
                    title="Join this group to keep up with the conversation."
                    subtitle="After logging in, you can join this group."
                    handleClose={this.handleCloseLoginPrompt}
                />
            </div>
        );
    }
}

CommentThread.propTypes = {
    authUser: PropTypes.object,
    postSlugHash: PropTypes.string,
    postId: PropTypes.number,
    comments: PropTypes.array,
    replyMap: PropTypes.object,
    hasMoreComments: PropTypes.bool,
    onLoad: PropTypes.func,
    onLoadComments: PropTypes.func.isRequired,
    onLoadReplies: PropTypes.func,
    onLoadMoreComments: PropTypes.func,
    onNewCommentSubmit: PropTypes.func,
    onEditCommentSubmit: PropTypes.func,
    onHideCommentSubmit: PropTypes.func,
    onDeleteComment: PropTypes.func,
    handleNewCommentFromSocket: PropTypes.func,
};
