import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import GlobalHeader from '../../common/components/global_header/connector';
import {fetchApiGet} from '../../common/utils/api';

import './AccountVerification.css';


function AccountVerification({
    setAuthUserIsVerified,
}) {
    let {token} = useParams();
    const [verificationState, setVerificationState] = useState({
        'isLoading': true,
        'accountIsVerified': false
    });
    let bodyContent = (
        <div className="body-content">
            <CircularProgress />
        </div>
    );
    let {
        isLoading,
        accountIsVerified
    } = verificationState;


    useEffect(() => {
        const handleAccountVerification = () => {
            fetchApiGet(`account-verification/${token}/`)
                .then((result) => {
                    if (result.success) {
                        setVerificationState({
                            'isLoading': false,
                            'accountIsVerified': true
                        });
                    }
                })
                .catch(() => {
                    setVerificationState({
                        'isLoading': false,
                        'accountIsVerified': false
                    });
                });
        }

        handleAccountVerification();
    // Needed to mimic componentDidMount for single render
    // eslint-disable-next-line
    }, []);

    if (!isLoading && accountIsVerified) {
        setAuthUserIsVerified();

        bodyContent = (
            <div className="body-content">
                <h3 className="account-verified-message">Success! This account has been verified.</h3>
            </div>
        );
    } else if (!isLoading && !accountIsVerified) {
        bodyContent = (
            <div className="body-content">
                <h3>This link is invalid.</h3>
            </div>
        );
    } else if (!isLoading) {
        bodyContent = (
            <div className="bodyContent">
                <h3>Something went wrong.</h3>
            </div>
        );
    }

    return (
        <div id="account-verification">
            <GlobalHeader />
            <div className="page-body-container">
                <h2>Account Verification</h2>
                {bodyContent}
            </div>
        </div>
    );
}

AccountVerification.propTypes = {
    setAuthUserIsVerified: PropTypes.func.isRequired,
};

export default AccountVerification;
