import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Textarea from 'react-textarea-autosize';

import AccountVerificationPrompt from '../account_verification_prompt/AccountVerificationPrompt';
import LoginPrompt from '../login_prompt/LoginPrompt';

import './ReplyInput.css';


export default class ReplyInput extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: '',
            loginPromptIsOpen: false,
            accountVerifyPromptIsOpen: false,
        };
    }

    handleChange = (e) => {
        this.setState({value: e.target.value});
    };

    handleSend = () => {
        let {value} = this.state;
        let {
            isVerified,
            isAuthenticated,
            onSend
        } = this.props;

        if (!isAuthenticated) {
            this.handleOpenLoginPrompt();
            return;
        }

        if (isAuthenticated && !isVerified) {
            this.handleOpenAccountVerifyPrompt();
            return;
        }

        onSend(value.trim());

        this.setState({value: ''});
    };

    handleCloseLoginPrompt = () => {
        this.setState({loginPromptIsOpen: false});
    };

    handleOpenLoginPrompt = () => {
        let {isAuthenticated} = this.props;

        if (!isAuthenticated) {
            this.setState({loginPromptIsOpen: true});
        }
    };

    handleCloseAccountVerifyPrompt = () => {
        this.setState({accountVerifyPromptIsOpen: false});
    };

    handleOpenAccountVerifyPrompt = () => {
        let {
            isAuthenticated,
            isVerified,
        } = this.props;

        if (isAuthenticated && !isVerified) {
            this.setState({accountVerifyPromptIsOpen: true});
        }
    };

    render() {
        let {placeholder} = this.props;
        let {
            value,
            loginPromptIsOpen,
            accountVerifyPromptIsOpen,
        } = this.state;

        return (
            <div className="reply-input">
                <div className="input-box-container">
                    <Textarea
                        type="text"
                        placeholder={placeholder}
                        value={value}
                        onChange={this.handleChange}
                        onClick={this.handleOpenLoginPrompt}
                    />
                </div>
                <div>
                    <button className="reply-comment-button" onClick={this.handleSend}>Send</button>
                </div>
                <LoginPrompt
                    isOpen={loginPromptIsOpen}
                    title="Reply to this comment to add to the conversation."
                    subtitle="After logging in, you can reply to this comment."
                    handleClose={this.handleCloseLoginPrompt}
                />
                <AccountVerificationPrompt
                    isOpen={accountVerifyPromptIsOpen}
                    title="Verify your account."
                    subtitle="In order to complete this action, verify your account by clicking the button below, and visit the email."
                    handleClose={this.handleCloseAccountVerifyPrompt}
                />
            </div>
        );
    }

}

ReplyInput.propTypes = {
    isVerified: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    placeholder: PropTypes.string,
    onSend: PropTypes.func
};
