import {FORM_ERROR} from 'final-form';
import {connect} from 'react-redux';
import _ from 'underscore';

import {
    signupUserSubmitStart,
    signupUserSubmitSuccess,
    signupUserSubmitFail
} from '../../common/actions/signup';
import {
    STORAGE_KEY,
    fetchApiPost
} from '../../common/utils/api';

import Signup from './Signup';


const signup = (formValues, history) => (dispatch) => {
    dispatch(signupUserSubmitStart());

    return fetchApiPost('signup/', formValues)
        .then((authData) => {
            dispatch(signupUserSubmitSuccess(authData));

            localStorage.setItem(STORAGE_KEY, JSON.stringify(authData));

            history.push('/signup-confirmation/');

            return authData;
        })
        .catch(({errors}) => {
            dispatch(signupUserSubmitFail(errors));
            let errorMessage = 'Your email or password is invalid.';

            if (errors['email'] && errors['email'][0] === 'This field must be unique.') {
                errorMessage = 'This email is already taken.';
            }

            return {
                [FORM_ERROR]: errorMessage,
            };
        });
};

const mapStateToProps = ({app}) => ({
    isAuthenticated: !_.isEmpty(app.authUser)
});

const mapDispatchToProps = (dispatch, {history}) => ({
    onSubmit: (formValues) => dispatch(signup(formValues, history)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Signup);
