import {
    createCategoryPreferencesStart,
    createCategoryPreferencesSuccess,
    createCategoryPreferencesFail,
    removeCategoryPreferencesStart,
    removeCategoryPreferencesSuccess,
    removeCategoryPreferencesFail,
    loadCategoriesStart,
    loadCategoriesSuccess,
    loadCategoriesFail,
    loadMyCategoriesStart,
    loadMyCategoriesSuccess,
    loadMyCategoriesFail
} from '../actions/categories';
import {
    fetchApiGet,
    fetchApiPost,
    fetchApiDelete,
} from '../utils/api';


export const loadCategories = () => (dispatch) => {
    let url = `tags/`;

    dispatch(loadCategoriesStart());

    return fetchApiGet(url)
        .then(({tags}) => dispatch(loadCategoriesSuccess(tags)))
        .catch((errors) => dispatch(loadCategoriesFail(errors)));
};

export const loadMyCategories = () => (dispatch) => {
    let url = 'users/topic_preferences/';

    dispatch(loadMyCategoriesStart());

    return fetchApiGet(url)
        .then(({topic_preferences}) => dispatch(loadMyCategoriesSuccess(topic_preferences)))
        .catch((errors) => dispatch(loadMyCategoriesFail(errors)));
};

export const createCategoryUserPreferences = (preferences) => (dispatch) => {
    let url = 'users/topic_preferences/';
    let data = {'topic_preferences': preferences};

    dispatch(createCategoryPreferencesStart());

    return fetchApiPost(url, data)
        .then(({topic_preferences}) => dispatch(createCategoryPreferencesSuccess(topic_preferences)))
        .catch((errors) => dispatch(createCategoryPreferencesFail(errors)));
};

export const removeCategoryUserPreferences = (preference) => (dispatch) => {
    let url = `users/topic_preferences/delete/${preference.id}/`;

    dispatch(removeCategoryPreferencesStart());

    return fetchApiDelete(url)
        .then(() => dispatch(removeCategoryPreferencesSuccess(preference)))
        .catch((errors) => dispatch(removeCategoryPreferencesFail(errors)));
};
