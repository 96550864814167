import {FORM_ERROR} from 'final-form';
import {connect} from 'react-redux';
import _ from 'underscore';

import {
    STORAGE_KEY,
    fetchApiPost
} from '../../common/utils/api';
import {getRedirectRoute} from '../../common/utils/routing';

import {
    loginUserSubmitStart,
    loginUserSubmitSuccess,
    loginUserSubmitFail
} from './actions';
import Login from './Login';


const login = (formValues, history) => (dispatch) => {
    dispatch(loginUserSubmitStart());

    return fetchApiPost('login/', formValues)
        .then((authData) => {
            dispatch(loginUserSubmitSuccess(authData));

            localStorage.setItem(STORAGE_KEY, JSON.stringify(authData));

            let redirectRoute = getRedirectRoute(history.location.search);

            history.push(redirectRoute);

            return authData;
        })
        .catch(({errors}) => {
            dispatch(loginUserSubmitFail(errors));

            return {
                [FORM_ERROR]: 'Your email or password is invalid.'
            };
        });
};

const mapStateToProps = ({app}) => ({
    isAuthenticated: !_.isEmpty(app.authUser),
});

const mapDispatchToProps = (dispatch, {history}) => {
    return {
        onSubmit: (formValues) => dispatch(login(formValues, history)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
