import React from 'react';
import {Link} from 'react-router-dom';

import GlobalHeader from '../../common/components/global_header/connector';

import './AccountPrivacy.css';


function AccountPrivacy() {
    return (
        <div id="account-privacy">
            <GlobalHeader />
            <div className="page-body-container">
                <h2>Privacy and personal information</h2>
                <p>Below are highlights on how we collect and utilize user data. If you would like more information about your data and our policies, see the Section (4) below for more resources. This page contains four parts:</p>
                <ol>
                    <li>We do not sell your personal information</li>
                    <li>How user data is collected</li>
                    <li>How user data is used in features</li>
                    <li>CCPA Opt-out of the sale of your personal information</li>
                    <li>More resources about your privacy and information</li>
                </ol>

                <div className="section">
                    <h3>We do not sell your personal information</h3>
                    <p>
                        The CCPA requires us to disclose whether we sell your personal information. We do not sell your personal information.
                        The CCPA grants California residents the certain rights to control how their data is collected, utilized, and the rights to request information on their data.
                        Furthermore, we do not sell any personally identifiable information (PII) with third-parties such as name, email, birthday, address, etc.
                    </p>
                    <p>It is our mission to protect all of our users' data and privacy regardless if they are residents of California or not. We do not sell personal information of any of our users regardless of their residence.</p>
                    <p>If you have any questions or requests regarding your data, email us at privacy@boeiie.com.</p>
                </div>

                <div className="section">
                    <h3>How user data is collected</h3>
                    <p>
                        We collect user data for operational purposes in order to control the primary features of the app, present in-app ads and sponsorships, fix bugs, and analytics to improve features.
                        We serve relevant ads and promotional campaigns on this platform based on anonymized and aggregated data, and from information shared publicly.
                    </p>
                    <p>How we collect user data is shared below:</p>
                    <ul>
                        <li>User registration</li>
                        <li>Google Analytics</li>
                        <li>In-app user activity</li>
                    </ul>
                </div>

                <div className="section">
                    <h3>How user data is used in features</h3>
                    <p>
                        As mentioned above, we collect user data for operational purposes in order to control the primary features of the app, present in-app ads and sponsorships, fix bugs, and analytics to improve features.
                    </p>
                    <p>How we utilize user data in features is shared below:</p>
                    <ul>
                        <li>Serve relavant posts on the main post listing page</li>
                        <li>Fix bugs</li>
                        <li>Serve relavant ads and promotional campaigns</li>
                    </ul>
                </div>

                <div className="section">
                    <h3>CCPA Opt-out of the sale of your personal information</h3>
                    <p>
                        According to CCPA, you have the right to opt-out of the sale of any personal information.
                        As mentioned above, we do not sell personal information of any of our users; however, we give all users the ability to opt-out the sale of their personal information.
                        To opt-out, click on the link below:
                    </p>
                    <ul>
                        <li>
                            <Link
                                to="/account/do-not-sell-my-information/"
                                style={{'textDecoration': 'underline'}}
                            >
                                Do not sell my information
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="section">
                    <h3>More resources about your privacy and information</h3>
                    <p>
                        If you would like more information on how we manage user privacy or have any questions, see the resources below:
                    </p>
                    <ul>
                        <li>Privacy Policy</li>
                        <li>Terms of Service</li>
                        <li>Acceptable Use Policy</li>
                        <li>Email use if you have questions about privacy and your information: privacy@boeiie.com</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default AccountPrivacy;
