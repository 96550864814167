import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Form, Field} from 'react-final-form';
import Textarea from 'react-textarea-autosize';

import AccountVerificationPrompt from '../account_verification_prompt/AccountVerificationPrompt';
import LoginPropmt from '../login_prompt/LoginPrompt';
import {isDemo} from '../../utils/routing';

import './CommentForm.css';


export default class CommentForm extends Component {

    static propTypes = {
        isVerified: PropTypes.bool.isRequired,
        isAuthenticated: PropTypes.bool.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            commentInput: '',
            loginPromptIsOpen: false,
            accountVerifyPromptIsOpen: false,
        };
    }

    onChange = (e) => {
        let commentInput = e.target.value;

        this.setState({commentInput});
    };

    openLoginPrompt = () => {
        this.setState({loginPromptIsOpen: true});
    };

    handleCloseLoginPrompt = () => {
        this.setState({loginPromptIsOpen: false});
    };

    handleOpenLoginPrompt = () => {
        let {isAuthenticated} = this.props;

        if (!isAuthenticated) {
            this.openLoginPrompt();
        }
    };

    sendComment = (value) => {
        let {commentInput} = this.state;
        let {
            postId,
            onSubmit,
            isVerified,
            isAuthenticated
        } = this.props;

        if (isDemo() || !isAuthenticated) {
            this.handleOpenLoginPrompt();
            return;
        }

        if (!isDemo() && isAuthenticated && !isVerified) {
            this.handleOpenAccountVerifyPrompt();
            return;
        }

        return onSubmit(postId, {'body': commentInput.trim()})
            .then(() => this.setState({commentInput: ''}));
    };

    handleCloseAccountVerifyPrompt = () => {
        this.setState({accountVerifyPromptIsOpen: false});
    };

    handleOpenAccountVerifyPrompt = () => {
        let {
            isAuthenticated,
            isVerified,
        } = this.props;

        if (isAuthenticated && !isVerified) {
            this.setState({accountVerifyPromptIsOpen: true});
        }
    };

    render() {
        let submit;
        let {
            commentInput,
            loginPromptIsOpen,
            accountVerifyPromptIsOpen
        } = this.state;

        return (
            <Form
                onSubmit={this.sendComment}
                render={({handleSubmit, submitting}) => {
                    submit = handleSubmit;

                    return (
                        <div className="comment-input-row">
                            <div className="form-container">
                                <form className="comment-form">
                                    <Field
                                        name="comment"
                                        render={({input}) => (
                                            <Textarea
                                                {...input}
                                                value={this.state.commentInput}
                                                className="new-comment-textarea"
                                                placeholder="Write a comment..."
                                                onChange={this.onChange}
                                                onClick={this.handleOpenLoginPrompt}
                                            />
                                        )}
                                    />
                                </form>
                            </div>
                            <div className="comment-button-container">
                                <button
                                    className="create-comment-button"
                                    onClick={(e) => submit(e)}
                                    disabled={submitting || !commentInput}
                                >
                                    Send
                                </button>
                            </div>
                            <LoginPropmt
                                isOpen={loginPromptIsOpen}
                                handleClose={this.handleCloseLoginPrompt}
                                title="Comment to join the conversation."
                                subtitle="After logging in, you can share your comment."
                            />
                            <AccountVerificationPrompt
                                isOpen={accountVerifyPromptIsOpen}
                                title="Verify your account."
                                subtitle="In order to complete this action, verify your account by clicking the button below, and visit the email."
                                handleClose={this.handleCloseAccountVerifyPrompt}
                            />
                        </div>
                    );
                }}
            />
        );
    }
};