import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

import './GlobalFooter.css';


function GlobalFooter({
    isAuthenticated,
}) {
    let date = new Date();
    let currentYear = date.getFullYear();

    return (
        <div className="global-footer">
            <span className="text-block">Boeiie &#169; {currentYear}</span>
            <span className="text-block">&#8226;</span>
            <Link to="/" className="text-block">Home</Link>
            <Link to="/about/" className="text-block">About</Link>
            {
                !isAuthenticated ?
                <Link to="/login/" className="text-block">Login</Link> : null
            }
            {
                !isAuthenticated ?
                <Link to="/signup/" className="text-block">Sign up</Link> : null
            }
            <Link to="/privacy-policy/" className="text-block">Privacy Policy</Link>
            <Link to="/terms-of-service/" className="text-block">Terms of Service</Link>
            <Link to="/acceptable-use/" className="text-block">Acceptable Use</Link>
        </div>
    );
}

GlobalFooter.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
};

export default GlobalFooter;
