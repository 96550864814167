import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


export default function MaterialDropdown({
    menuOptions = [],
    AnchorComponent
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOptionClick = (callback) => {
        handleClose();
        callback();
    }

    return (
        <div>
            <AnchorComponent aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} />
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    menuOptions.map((option, index) => (
                        <MenuItem key={index} onClick={() => handleOptionClick(option.handleOptionClick)}>{option.display}</MenuItem>
                    ))
                }
            </Menu>
        </div>
    );
}
