import React from 'react';
import {Field} from 'react-final-form';
import PropTypes from 'prop-types';
import _ from 'underscore';

import './CategorySelector.css';


const ALPHABETS = [
'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K',
'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V',
'W', 'X', 'Y', 'Z'
];

const CategorySelector = ({
    categories=[],
    defaultSelectedCategories=[],
    onChange,
}) => {
    let alphabeticalSectionMap = {};

    categories.forEach((category) => {
        if (!alphabeticalSectionMap[category.name[0]]) {
            alphabeticalSectionMap[category.name[0]] = [category];
        } else {
            alphabeticalSectionMap[category.name[0]].push(category);
        }
    });

    let alphabeticalSections = ALPHABETS.map((alphabet) => {
            if (!alphabeticalSectionMap[alphabet]) return null;

            return (
                <div key={alphabet} className="alphabet-section">
                    <h3 id={alphabet}>{alphabet}</h3>
                    {
                        alphabeticalSectionMap[alphabet].map((category) => {
                            let chosen = '';
                            let isChosen = _.find(defaultSelectedCategories, (chosenCategory) => {
                                return chosenCategory.id === category.id;
                            });

                            if (isChosen) {
                                chosen = 'chosen';
                            }

                            if (category.name[0] === alphabet) {
                                return (
                                    <span key={category.id}>
                                        <Field
                                            name={`category-${category.id}`}
                                            type="checkbox"
                                            render={({input}) => (
                                                <span className="category-button-container">
                                                    <input
                                                        {...input}
                                                        className="category-checkbox"
                                                        id={category.id}
                                                        type="checkbox"
                                                        checked={Boolean(chosen)}
                                                        onClick={() => onChange(category)}
                                                    />
                                                    <label
                                                        htmlFor={category.id}
                                                        className={`category-button ${chosen}`}
                                                    >
                                                        {category.name}
                                                    </label>
                                                </span>
                                            )}
                                        />
                                    </span>
                                );
                            }

                            return null;
                        })
                    }
                </div>
            );
        });

    return (
        <div className="category-selector">
            {alphabeticalSections}
        </div>
    );
}

CategorySelector.propTypes = {
    categories: PropTypes.array.isRequired,
    selectedCategories: PropTypes.array,
    getSelectedCategories: PropTypes.func,
    getUnselectedCategories: PropTypes.func,
};

export default CategorySelector;
