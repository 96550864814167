import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-final-form';

import AccountVerificationPrompt from '../../common/components/account_verification_prompt/AccountVerificationPrompt';
import CategorySelector from '../../common/components/category_selector/CategorySelector';
import GlobalHeader from '../../common/components/global_header/connector';
import {fetchApiGet} from '../../common/utils/api';

import './CategoryPreferences.css';


export default class CategoryPreferences extends Component {
    static propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
        isVerified: PropTypes.bool.isRequired,
        categoryPreferences: PropTypes.array.isRequired,
        handlePageMount: PropTypes.func.isRequired,
        handleCategorySelect: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
            categories: [],
        };
    }

    componentDidMount() {
        let {handlePageMount} = this.props;

        handlePageMount();

        fetchApiGet('tags/')
            .then(({tags}) => (
                this.setState({categories: tags})
            ));
    };

    handleCloseAccountVerifyPrompt = () => {
        this.setState({accountVerifyPromptIsOpen: false});
    };

    handleOpenAccountVerifyPrompt = () => {
        let {
            isAuthenticated,
            isVerified,
        } = this.props;

        if (isAuthenticated && !isVerified) {
            this.setState({accountVerifyPromptIsOpen: true});
        }
    };

    handleSelect = (category) => {
        let {
            isAuthenticated,
            isVerified,
            handleCategorySelect,
        } = this.props;

        if (isAuthenticated && !isVerified) {
            this.handleOpenAccountVerifyPrompt();
            return;
        }

        handleCategorySelect(category);
    };

    render() {
        let {
            categories,
            accountVerifyPromptIsOpen,
        } = this.state;
        let {categoryPreferences} = this.props;

        return (
            <div id="category-preferences">
                <GlobalHeader />
                <div className="page-body-container">
                    <h2 className="page-title">Select your category preferences.</h2>
                    <Form
                        onSubmit={() => {}}
                        render={() => (
                            <form>
                                <CategorySelector
                                    categories={categories}
                                    defaultSelectedCategories={categoryPreferences}
                                    onChange={this.handleSelect}
                                />
                            </form>
                        )}
                    />
                </div>
                <AccountVerificationPrompt
                    isOpen={accountVerifyPromptIsOpen}
                    title="Verify your account."
                    subtitle="In order to complete this action, verify your account by clicking the button below, and visit the email."
                    handleClose={this.handleCloseAccountVerifyPrompt}
                />
            </div>
        );
    }
}
