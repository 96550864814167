import {ConnectedRouter} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, {Component} from 'react';
import {
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';

import ComposePostForm from '../common/components/compose_post_form/connector';
import GlobalFooter from '../common/components/global_footer/connector';
import PostListing from '../pages/post_listing/connector';
import PostDetails from '../pages/post_details/connector';
import ProfilePage from '../pages/profile_page/connector';
import CategoryPreferences from '../pages/category_preferences/connector';
import Categories from '../pages/categories/connector';
import About from '../pages/about/About';
import Activity from '../pages/activity/connector';
import Groups from '../pages/groups/connector';
import GroupPage from '../pages/group_page/connector';
import AllGroups from '../pages/all_groups/connector';
import MyGroups from '../pages/my_groups/connector';
import GroupInvitation from '../pages/group_invitation/connector';
import Account from '../pages/account/connector';
import AccountPrivacy from '../pages/account_privacy/connector';
import DoNotSell from '../pages/do_not_sell/connector';
import Admin from '../pages/admin/connector';
import Login from '../pages/login/connector';
import Signup from '../pages/signup/connector';
import SignupConfirmation from '../pages/signup_confirmation/connector';
import AccountVerification from '../pages/account_verification/connector';
import ForgottenPassword from '../pages/forgotten_password/ForgottenPassword';
import PasswordReset from '../pages/password_reset/PasswordReset';
import NoMatchPage from '../pages/no_match/NoMatch';
import Main from '../pages/main/Main';
import PrivacyPolicy from '../pages/privacy_policy/PrivacyPolicy';
import TermsOfService from '../pages/terms_of_service/TermsOfService';
import AcceptableUsePolicy from '../pages/acceptable_use_policy/AcceptableUsePolicy';

import './App.css';


export const history = createBrowserHistory();

const DOCUMENT_TITLE_UPDATE_ROUTES = [
    '/groups/',
    '/profile/',
    '/post/'
];


const updateDocumentTitle = (urlPathname) => {
    let matched = false;

    DOCUMENT_TITLE_UPDATE_ROUTES.forEach((path) => {
        if (path.indexOf(urlPathname) < 0) {
            matched = true;
        }
    });

    if (!matched) {
        document.title = 'Boeiie - A Sports Community';
    }
};

history.listen((params) => {
    updateDocumentTitle(params.pathname);
});

const PrivateRoute = ({
    component: Component,
    isAuthenticated,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                let params = queryString.parse(props.location.search);
                let isDemo = params['app_mode'] === 'demo';

                if (isAuthenticated || isDemo) {
                    return (
                        <Component isAuthenticated={isAuthenticated} {...props} />
                    );
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname:"/login/",
                                search: props.location.search,
                                state: {from: props.location}
                            }}
                        />
                    );
                }
            }}
        />
    );
};

const MainPageWrapper = ({
    isAuthenticated,
    location,
    ...rest
}) => (
    <div>
        <Main />
        <Switch>
            <Route exact isAuthenticated={isAuthenticated} path={['/', '/groups/']} component={Groups} />
            <Route exact isAuthenticated={isAuthenticated} path="/categories/" component={Categories} />
            <Route
                isAuthenticated={isAuthenticated}
                path="/posts/"
                component={PostListing}
            />
        </Switch>
    </div>
);

export default class App extends Component {

    componentDidMount() {
        let {onLoad} = this.props;

        onLoad();
    }

    render() {
        let {
            isAuthenticated,
            isLoading
        } = this.props;

        if (isLoading) {
            return null;
        }

        return (
            <div className="App">
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route
                            exact
                            path={['/', '/groups/', '/posts/', '/categories/']}
                            render={renderProps => (
                                <MainPageWrapper isAuthenticated={isAuthenticated} {...renderProps} />
                            )}
                        />
                        <PrivateRoute exact isAuthenticated={isAuthenticated} path="/activity/" component={Activity} />
                        <Route
                            isAuthenticated={isAuthenticated}
                            path={'/groups/:slugHash/:groupSlug/'}
                            component={GroupPage}
                        />
                        <Route exact isAuthenticated={isAuthenticated} path={`/all-groups/`} component={AllGroups} />
                        <PrivateRoute exact isAuthenticated={isAuthenticated} path={`/my-groups/`} component={MyGroups} />
                        <PrivateRoute exact isAuthenticated={isAuthenticated} path="/account/" component={Account} />
                        <PrivateRoute exact isAuthenticated={isAuthenticated} path="/account/privacy/" component={AccountPrivacy} />
                        <PrivateRoute exact isAuthenticated={isAuthenticated} path="/account/do-not-sell-my-information/" component={DoNotSell} />
                        <PrivateRoute exact isAuthenticated={isAuthenticated} path="/admin/" component={Admin} />
                        <Route exact isAuthenticated={isAuthenticated} path={['/post/:slugHash/', '/post/:slugHash/:slug/']} component={PostDetails} />
                        <Route exact isAuthenticated={isAuthenticated} path={'/profile/:slugHash/'} component={ProfilePage} />
                        <PrivateRoute exact isAuthenticated={isAuthenticated} path="/category-preferences/" component={CategoryPreferences} />
                        <PrivateRoute exact isAuthenticated={isAuthenticated} path="/compose_post/" component={ComposePostForm} />
                        <Route exact path="/account/forgotten-password/" component={ForgottenPassword} />
                        <Route exact path="/account/reset/:token/set-password/" component={PasswordReset} />
                        <Route exact path="/join/invite/:inviteToken/" component={GroupInvitation} />
                        <Route exact path="/login/" component={Login} />
                        <Route exact path="/signup/" component={Signup} />
                        <PrivateRoute exact isAuthenticated={isAuthenticated} path="/signup-confirmation/" component={SignupConfirmation} />
                        <Route exact path="/account-verification/:token/" component={AccountVerification} />
                        <Route exact path="/about/" component={About} />
                        <Route exact path="/privacy-policy/" component={PrivacyPolicy} />
                        <Route exact path="/terms-of-service/" component={TermsOfService} />
                        <Route exact path="/acceptable-use/" component={AcceptableUsePolicy} />
                        <Route path="*" component={NoMatchPage} />
                    </Switch>
                    <GlobalFooter />
                </ConnectedRouter>
            </div>
        );
    }
}

App.propTypes = {
    auth: PropTypes.object,
    isLoading: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    onLoad: PropTypes.func
};
