export const EMOJI_API_LIST = [
    'thumbs_up',
    'fire',
    'one_hundred',
    'haha',
    'trash',
];

export const EMOJI_ICON_LIST = [
    '👍',
    '🔥',
    '💯',
    '😂',
    '🗑',
];

export const EMOJI_MAP = {
    'fire': '🔥',
    'one_hundred': '💯',
    'trash': '🗑',
    'haha': '😂',
    'thumbs_up': '👍'
};

export const EMOJI_TITLE = {
    'fire': 'Fire',
    'one_hundred': '',
    'trash': 'Trash',
    'haha': 'Haha',
    'thumbs_up': 'Like',
};
