import {
    POSTS,
    NOTIFICATIONS
} from '../constants/demo';


export const getDemoPosts = () => {
    return POSTS;
};

export const getDemoNotifications = () => {
    return NOTIFICATIONS;
};
