import {connect} from 'react-redux';

import {
    loadGroup,
    loadGroupAdmins,
    loadGroupMembers,
    unjoinGroup,
} from '../../common/action_creators/groups';
import {fetchApiPost} from '../../common/utils/api';

import {
    initializeGroupMembers,
    removeGroupMemberStart,
    removeGroupMemberSuccess,
    removeGroupMemberFail,
    updateGroupMemberRoleStart,
    updateGroupMemberRoleSuccess,
    updateGroupMemberRoleFail,
} from './actions';
import GroupMembers from './GroupMembers';


const initializePage = (slugHash) => (dispatch) => {
    dispatch(initializeGroupMembers());
    dispatch(loadGroupAdmins(slugHash));

    return dispatch(loadGroupMembers(slugHash));
};

const removeGroupMember = (slugHash, memberId) => (dispatch) => {
    dispatch(removeGroupMemberStart());

    return fetchApiPost(`groups/${slugHash}/members/${memberId}/delete/`)
        .then(({member}) => {
            dispatch(removeGroupMemberSuccess(member));
            dispatch(loadGroup(slugHash))
                .then(() => {
                    dispatch(loadGroupMembers(slugHash));
                });
        })
        .catch((errors) => {
            dispatch(removeGroupMemberFail(errors))
        });
};

const updateMemberRole = (slugHash, memberId, role) => (dispatch) => {
    dispatch(updateGroupMemberRoleStart());

    return fetchApiPost(`groups/${slugHash}/members/${memberId}/update_role/`, {role})
        .then(({members}) => {
            return dispatch(updateGroupMemberRoleSuccess(members));
        })
        .then(() => {
            return dispatch(loadGroup(slugHash));
        })
        .catch(({errors}) => {
            dispatch(updateGroupMemberRoleFail(errors))
        });
};

const handleUnjoinGroup = (slugHash) => (dispatch) => {
    return dispatch(unjoinGroup(slugHash))
        .then(() => {
            return dispatch(loadGroup(slugHash));
        });
};

const handleLoadMoreAdmins = (slugHash) => (dispatch, getState) => {
    let state = getState();
    let page = state.groupMembers.adminNextPaginationPage;

    return dispatch(loadGroupAdmins(slugHash, {page}));
};

const handleLoadMoreMembers = (slugHash) => (dispatch, getState) => {
    let state = getState();
    let page = state.groupMembers.membersNextPaginationPage;

    return dispatch(loadGroupMembers(slugHash, {page}));
};

const mapStateToProps = ({groupMembers, groupState, app}) => {
    let {group} = groupState;
    let {authUser} = app;

    return {
        members: groupMembers.members || [],
        admins: groupMembers.admins || [],
        hasMoreAdmin: !!groupMembers.adminNextPaginationPage,
        hasMoreMembers: !!groupMembers.membersNextPaginationPage,
        group: group || {auth_user: {}},
        authUser: authUser || {}
    };
};

const mapDispatchToProps = (dispatch, {slugHash}) => {
    return {
        onPageLoad: () => dispatch(initializePage(slugHash)),
        handleUpdateMemberRole: (memberId, role) => dispatch(updateMemberRole(slugHash, memberId, role)),
        handleUnjoinGroup: () => dispatch(handleUnjoinGroup(slugHash)),
        handleRemoveGroupMember: (memberId) => dispatch(removeGroupMember(slugHash, memberId)),
        handleLoadMoreAdmins: () => dispatch(handleLoadMoreAdmins(slugHash)),
        handleLoadMoreMembers: () => dispatch(handleLoadMoreMembers(slugHash)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupMembers);
