import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';

import './Main.css';


class Main extends Component {

    render() {
        let {location} = this.props;
        let pageIsGroups = !!(location.pathname === '/' || location.pathname === '/groups/');
        let pageIsPosts = !!(location.pathname === '/posts/');
        let pageIsCategories = !!(location.pathname === '/categories/');

        return (
            <div className='main-page'>
                <div className="page-body-container">
                    <div className="page-segment-nav">
                        <Link
                            className={`segment-button ${pageIsGroups ? 'selected': ''}`}
                            to={{
                                pathname: '/groups/'
                            }}
                        >
                            Groups
                        </Link>
                        <Link
                            className={`segment-button ${pageIsPosts ? 'selected': ''}`}
                            to={{
                                pathname: '/posts/'
                            }}
                        >
                            Posts
                        </Link>
                        <Link
                            className={`segment-button ${pageIsCategories ? 'selected': ''}`}
                            to={{
                                pathname: '/categories/'
                            }}
                        >
                            Categories
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Main);
