import queryString from 'query-string';
import {
    INITIALIZE_GROUPS,
    LOAD_GROUPS_START,
    LOAD_GROUPS_SUCCESS,
    LOAD_GROUPS_FAIL,
    LOAD_MY_GROUPS_START,
    LOAD_MY_GROUPS_SUCCESS,
    LOAD_MY_GROUPS_FAIL,
    CREATE_GROUP_START,
    CREATE_GROUP_SUCCESS,
    CREATE_GROUP_FAIL,
} from '../actions/groups';


const initializeGroupsReducer = (state) => {

    return {
        byIds: {},
        allGroups: {
            list: [],
            pagination: {
                next: {},
                previous: {}
            },
            hasMoreGroups: false,
        },
        myGroups: {
            list: [],
            pagination: {
                next: {},
                previous: {}
            },
            hasMoreGroups: false,
        },
        errors: null,
        isLoading: false
    };
};

const loadGroupsStartReducer = (state) => ({
    ...state,
    isLoading: true,
    allGroups: {
        ...state.allGroups,
        hasMoreGroups: false,
    },
});

const loadGroupsSuccessReducer = (state, {groups, groupsNextLink, groupsPreviousLink, options}) => {
    let nextState = {
        ...state,
        isLoading: false,
        errors: null
    };
    let {hasMoreGroups} = options;

    if (groupsNextLink) {
        let nextUrl = new URL(groupsNextLink);
        let nextParams = queryString.parse(nextUrl.search);
        let nextLimit = nextParams.limit;
        let nextOffset = nextParams.offset;

        nextState.allGroups.pagination.next = {
            limit: nextLimit,
            offset: nextOffset,
        };

        nextState.allGroups.hasMoreGroups = true;
    } else {
        nextState.allGroups.hasMoreGroups = false;
        nextState.allGroups.pagination.next = {};
    }

    if (groupsPreviousLink) {
        let previousUrl = new URL(groupsPreviousLink);
        let previousParams = queryString.parse(previousUrl.search);
        let previousLimit = previousParams.limit;
        let previousOffset = previousParams.offset;

        nextState.allGroups.pagination.previous = {
            limit: previousLimit,
            offset: previousOffset,
        };
    } else {
        nextState.allGroups.pagination.previous = {};
    }

    groups.forEach((group) => {
        nextState.byIds[group.id] = group;
        nextState.allGroups.list.push(group.id);
    });

    if (options.hasOwnProperty('hasMoreGroups')) {
        nextState.allGroups.hasMoreGroups = hasMoreGroups;
    }

    return nextState;
};

const loadGroupsFailReducer = (state, {errors}) => ({
    ...state,
    isLoading: false,
    allGroups: {
        ...state.allGroups,
        hasMoreGroups: false,
    },
    errors
});

const loadMyGroupsStartReducer = (state) => {
    return {
        ...state,
        isLoading: true,
        myGroups: {
            ...state.myGroups,
            hasMoreGroups: false,
        },
    };
};

const loadMyGroupsSuccessReducer = (state, {groups, groupsNextLink, groupsPreviousLink, options}) => {
    let nextState = {
        ...state,
        isLoading: false,
        errors: null
    };
    let {hasMoreGroups} = options;

    if (groupsNextLink) {
        let nextUrl = new URL(groupsNextLink);
        let nextParams = queryString.parse(nextUrl.search);
        let nextLimit = nextParams.limit;
        let nextOffset = nextParams.offset;

        nextState.myGroups.pagination.next = {
            limit: nextLimit,
            offset: nextOffset,
        };

        nextState.myGroups.hasMoreGroups = true;
    } else {
        nextState.myGroups.pagination.next = {};
        nextState.myGroups.hasMoreGroups = false;
    }

    if (groupsPreviousLink) {
        let previousUrl = new URL(groupsPreviousLink);
        let previousParams = queryString.parse(previousUrl.search);
        let previousLimit = previousParams.limit;
        let previousOffset = previousParams.offset;

        nextState.myGroups.pagination.previous = {
            limit: previousLimit,
            offset: previousOffset,
        };
    } else {
        nextState.myGroups.pagination.previous = {};
    }

    groups.forEach((group) => {
        nextState.byIds[group.id] = group;
        nextState.myGroups.list.push(group.id);
    });

    if (options.hasOwnProperty('hasMoreGroups')) {
        nextState.myGroups.hasMoreGroups = hasMoreGroups;
    }

    return nextState;
};

const loadMyGroupsFailReducer = (state, {errors}) => ({
    ...state,
    isLoading: false,
    myGroups: {
        ...state.myGroups,
        hasMoreGroups: false,
    },
    errors
});

const createGroupStartReducer = (state) => ({
    ...state
});

const createGroupSuccessReducer = (state, {group}) => {
    let nextState = {
        ...state,
        errors: null
    };

    nextState.byIds[group.id] = group;
    nextState.myGroups.list = [group.id, ...nextState.myGroups.list];

    return nextState;
};

const createGroupFailReducer = (state, {errors}) => ({
    ...state,
    errors
});

const GROUPS_REDUCER_MAP = {
    [INITIALIZE_GROUPS]: initializeGroupsReducer,
    [LOAD_GROUPS_START]: loadGroupsStartReducer,
    [LOAD_GROUPS_SUCCESS]: loadGroupsSuccessReducer,
    [LOAD_GROUPS_FAIL]: loadGroupsFailReducer,
    [LOAD_MY_GROUPS_START]: loadMyGroupsStartReducer,
    [LOAD_MY_GROUPS_SUCCESS]: loadMyGroupsSuccessReducer,
    [LOAD_MY_GROUPS_FAIL]: loadMyGroupsFailReducer,
    [CREATE_GROUP_START]: createGroupStartReducer,
    [CREATE_GROUP_SUCCESS]: createGroupSuccessReducer,
    [CREATE_GROUP_FAIL]: createGroupFailReducer,
};

const groupsReducer = (initialState={}, action={}) => {
    let reducer = GROUPS_REDUCER_MAP[action.type];
    let nextState = initialState;

    if (reducer) {
        nextState = reducer(initialState, action);
    }

    return nextState;
};

export default groupsReducer;
