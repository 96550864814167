import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import GlobalHeader from '../../common/components/global_header/connector';
import {formatNavigationQuery} from '../../common/utils/routing';
import {kebabCase} from '../../common/utils/strings';

import './Categories.css';


const ALPHABETS = [
'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K',
'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V',
'W', 'X', 'Y', 'Z'
];

class Categories extends Component {
    static propTypes = {
        categories: PropTypes.array.isRequired,
        handlePageMount: PropTypes.func.isRequired,
    };

    componentDidMount() {
        let {handlePageMount} = this.props;

        handlePageMount();
    }

    render() {
        let {categories} = this.props;
        let alphabeticalSectionMap = {};

        categories.forEach((category) => {
            if (!alphabeticalSectionMap[category.name[0]]) {
                alphabeticalSectionMap[category.name[0]] = [category];
            } else {
                alphabeticalSectionMap[category.name[0]].push(category);
            }
        });

        let alphabeticalSections = ALPHABETS.map((alphabet) => {
            if (!alphabeticalSectionMap[alphabet]) return null;

            return (
                <div key={alphabet} className="alphabet-section">
                    <h3 id={alphabet}>{alphabet}</h3>
                    {
                        alphabeticalSectionMap[alphabet].map((category) => {
                            if (category.name[0] === alphabet) {
                                return (
                                    <div key={category.name} className="category-button-container">
                                        <Link
                                            className="category-button"
                                            to={{
                                                pathname: '/posts/',
                                                search: formatNavigationQuery({
                                                    tags: kebabCase(category.name),
                                                })
                                            }}
                                        >
                                            {category.name}
                                        </Link>
                                    </div>
                                );
                            }

                            return null;
                        })
                    }
                </div>
            );
        });

        return (
            <div id="categories">
                <GlobalHeader />
                <div className="page-body-container">
                    <div className="category-list-container">
                        {alphabeticalSections}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Categories);
