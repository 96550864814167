import {connect} from 'react-redux';
import _ from 'underscore';

import {
    createReaction,
    removeReaction
} from '../../action_creators/reactions';

import CommentCard from './CommentCard';


const addReaction = (emoji, commentId) => (dispatch, getState) => {
    let state = getState();
    let authUserId = state.app.authUser['user_id'];

    return dispatch(createReaction('comment', commentId, emoji, authUserId));
};

const deleteReaction = (reactionId, commentId, targetEntityType='comment') => (dispatch, getState) => {
    let state = getState();
    let authUserId = state.app.authUser['user_id'];


    return dispatch(removeReaction(reactionId, commentId, authUserId, targetEntityType));
};

const mapStateToProps = ({app}) => ({
    authUser: app.authUser || {},
    isAuthenticated: !_.isEmpty(app.authUser),
    isVerified: !_.isEmpty(app.authUser) && !!app.authUser.is_verified,
});

const mapDispatchToProps = (dispatch) => ({
    handleAddReaction: (emoji, commentId) => dispatch(addReaction(emoji, commentId)),
    handleRemoveReaction: (reactionId, commentId) => dispatch(deleteReaction(reactionId, commentId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommentCard);
