import {connect} from 'react-redux';
import queryString from 'query-string';
import _ from 'underscore';

import {
    loadReplies,
    loadCommentsFromPosts,
} from '../../action_creators/comments';
import {initializeCommentThread} from '../../actions/comments';
import {
    loadPosts,
} from '../../action_creators/posts';
import {
    createReaction,
    removeReaction
} from '../../action_creators/reactions';

import PostCardList from './PostCardList';


const initializePage = (queryParams) => (dispatch) => {
    dispatch(initializeCommentThread());

    return dispatch(loadPosts(queryParams))
        .then((results) => {
            let postIds = _.pluck(results.posts, 'id');

            dispatch(loadCommentsFromPosts(postIds));

            return results;
        });
};

const addReaction = (emoji, postId) => (dispatch, getState) => {
    let state = getState();
    let authUserId = state.app.authUser['user_id'];

    return dispatch(createReaction('post', postId, emoji, authUserId));
};

const deleteReaction = (reactionId, postId, targetEntityType) => (dispatch, getState) => {
    let state = getState();
    let authUserId = state.app.authUser['user_id'];

    return dispatch(removeReaction(reactionId, postId, authUserId, targetEntityType));
};

const handlePageChange = (queryParams) => (dispatch) => {
    let postCardListIsRendered = !!(
        (queryParams && queryParams.page) ||
        window.location.pathname === '/posts/'
    );

    if (postCardListIsRendered) {
        dispatch(loadPosts(queryParams))
        .then(({posts}) => {
            let postIds = _.pluck(posts, 'id');

            dispatch(loadCommentsFromPosts(postIds));
        });
    }
};

const mapStateToProps = (state) => {
    let {
        comments = [],
        posts = [],
        replyMap = {},
        nextLink,
        previousLink,
    } = state.postListing;

    return {
        authUser: state.app.authUser || {},
        commentsForPosts: comments,
        posts,
        replyMap,
        nextLink,
        previousLink,
    };
};

const mapDispatchToProps = (dispatch) => {
    let currentUrlQueryParams = queryString.parse(window.location.search);

    return {
        onLoad: (queryParams) => dispatch(initializePage(queryParams)),
        handlePageChange: (queryParams) => dispatch(handlePageChange(queryParams)),
        loadCommentsForPosts: (postIds) => dispatch(loadCommentsFromPosts(postIds)),
        handleAddReaction: (emoji, postId) => dispatch(addReaction(emoji, postId)),
        handleRemoveReaction: (reactionId, postId, targetEntityType) => dispatch(deleteReaction(reactionId, postId, targetEntityType)),
        onEditPostSuccess: (queryParams) => dispatch(loadPosts(currentUrlQueryParams)),
        onDeletePostSuccess: (queryParams) => dispatch(loadPosts(currentUrlQueryParams)),
        onHidePostSuccess: (queryParams) => dispatch(loadPosts(currentUrlQueryParams)),
        onLoadReplies: (postId, parentCommentId) => dispatch(loadReplies(postId, parentCommentId)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PostCardList);
