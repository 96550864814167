import {FORM_ERROR} from 'final-form';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Form, Field} from 'react-final-form';
import {
    Link,
    Redirect,
} from 'react-router-dom';

import GlobalHeader from '../../common/components/global_header/connector';
import {
    isEmail,
    isRequired,
    composeValidators
} from '../../common/utils/validation';

import './Login.css';


const LoginForm = ({
    submitErrors
}) => {
    return (
        <form className="login-form">
            <Field
                name="email"
                validate={composeValidators(isRequired, isEmail)}
            >
                {
                    ({input, meta}) => (
                        <div>
                            <input {...input} className="form-input" placeholder="Email" />
                            {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                        </div>
                    )
                }
            </Field>
            <Field
                name="password"
                validate={isRequired}
            >
                {
                    ({input, meta}) => (
                        <div>
                            <input {...input} type="password" className="form-input" placeholder="Password" />
                            {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                        </div>
                    )
                }
            </Field>
            {submitErrors && <span className="error-text">{submitErrors}</span>}
        </form>
    );
};

export default class Login extends Component {

    constructor(props) {
        super(props);

        this.handleLogin = this.handleLogin.bind(this);
    }

    handleLogin(formValues) {
        let {onSubmit} = this.props;

        return onSubmit(formValues)
            .then((results) => {
                if (results[FORM_ERROR]) {
                    return results;
                }
            });
    }

    render() {
        let {isAuthenticated} = this.props;
        let submit;

        if (isAuthenticated) {
            return <Redirect to="/" />
        }

        return (
            <div id="login">
                <div className="login-page-container">
                    <GlobalHeader />
                    <Form
                        onSubmit={this.handleLogin}
                        render={({handleSubmit, submitErrors}) => {
                            submit = handleSubmit;

                            return <LoginForm submitErrors={submitErrors && submitErrors[FORM_ERROR]} />;
                        }}
                    />

                    <div className="submit-button-row">
                        <h2 className="submit-form-title">Log in</h2>
                        <div
                            className="submit-button"
                        >
                            <button onClick={(e) => submit(e)}>
                                <div className="login-icon">
                                    <FontAwesomeIcon icon="arrow-right" color="#fff" size="2x" />
                                </div>
                            </button>
                        </div>
                    </div>

                    <div className="extra-links-container">
                        <Link
                            className="form-link"
                            to="/signup/"
                        >
                            Sign up
                        </Link>
                        <Link
                            className="form-link"
                            to="/account/forgotten-password"
                        >
                            Forgot Password?
                        </Link>
                    </div>
                </div>
            </div>

        );
    }
}

Login.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func
};
