import PropTypes from 'prop-types';
import React, {
    Component,
    useEffect,
    useState
} from 'react';
import {useDropzone} from 'react-dropzone';
import {
    Form,
    FormSpy,
    Field
} from 'react-final-form';
import Textarea from 'react-textarea-autosize';

import {
    isRequired,
    maxLength,
    composeValidators
} from '../../utils/validation';

import ComposeHeader from '../compose_header/ComposeHeader';

import './ProfileEditForm.css';


const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

const Dropzone = ({
    onSelectImage,
    onRemoveImage,
    profileImageUrl
}) => {
    const [files, setFiles] = useState([]);
    const {getRootProps, getInputProps, open} = useDropzone({
        accept: 'image/*',
        multiple: false,
        onDrop: acceptedFiles => {
            onSelectImage(acceptedFiles[0]);
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });
    const selectedFile = files[0];

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    alt=""
                />
            </div>
        </div>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    let imageSection = (
        <div className="selected-image-section">
            <div
                {...getRootProps()}
                className="dropzone"
            >
                <input {...getInputProps()} />
                <div className="upload-icon-section">
                    <img
                        className="image-upload-icon"
                        src="/media/ui_icons/images.png"
                        alt="Upload"
                    />
                    <p>Press to upload a profile image.</p>
                </div>
            </div>
            {thumbs}
        </div>
    );

    const removeImage = () => {
        setFiles([]);
        onSelectImage(null);
        onRemoveImage()
    };

    if (selectedFile) {
        imageSection = (
            <div>
                <div className="selected-image-section">
                    <img
                        className="selected-image"
                        src={selectedFile.preview}
                        alt=""
                    />
                </div>
                <div className="edit-image-button-row">
                    <input {...getInputProps()} />
                    <button
                        className="change-image-button"
                        onClick={open}
                    >
                        Change Image
                    </button>
                    <button
                        className="remove-image-button"
                        onClick={removeImage}
                    >
                        Remove Image
                    </button>
                </div>
            </div>
        );
    } else if (!selectedFile && profileImageUrl) {
        imageSection = (
            <div>
                <div className="selected-image-section">
                    <img
                        className="selected-image"
                        src={profileImageUrl}
                        alt=""
                    />
                </div>
                <div className="edit-image-button-row">
                    <input {...getInputProps()} />
                    <button
                        className="edit-image-button"
                        onClick={open}
                    >
                        Change Image
                    </button>
                    <button
                        className="edit-image-button"
                        onClick={removeImage}
                    >
                        Remove
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div
        >
            {imageSection}
        </div>
    );
};


const EditForm = ({
    onFormUpdate
}) => {
    return (
        <form>
            <FormSpy
                subscription={{
                    pristine: true,
                    modified: true
                }}
                onChange={(state) => onFormUpdate(state)}
            />
            <div className="profile-input-section form-textarea-container">
                <div className="icon-container">
                    <img
                        className="icon note-icon"
                        src="/media/ui_icons/note.png"
                        alt=""
                    />
                </div>
                <div className="input-fields-container">
                    <p className="header-label">Headline</p>
                    <Field
                        name="profile_headline"
                        validate={maxLength(125, 'headline')}
                        render={({input, meta}) => (
                            <div className="form-input-container">
                                <Textarea
                                    {...input}
                                    className="form-textarea headline"
                                    placeholder="Public profile headline..."
                                />
                                {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                            </div>
                        )}
                    />
                </div>
            </div>
            <div className="profile-input-section form-textarea-container">
                <div className="icon-container">
                    <img
                        className="icon note-icon"
                        src="/media/ui_icons/note.png"
                        alt=""
                    />
                </div>
                <div className="input-fields-container">
                    <p className="header-label">Bio</p>
                    <Field
                        name="profile_header_summary"
                        validate={maxLength(400, 'bio')}
                        render={({input, meta}) => (
                            <div className="form-input-container">
                                <Textarea
                                    {...input}
                                    className="form-textarea"
                                    placeholder="Tell us about yourself..."
                                />
                                {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                            </div>
                        )}
                    />
                </div>
            </div>
            <div className="profile-input-section">
                <div className="icon-container">
                    <img
                        className="icon person-icon"
                        src="/media/ui_icons/person.png"
                        alt=""
                    />
                </div>
                <div className="input-fields-container">
                    <p className="header-label">Name</p>
                    <Field
                        name="first_name"
                        validate={composeValidators(isRequired, maxLength(30, 'first name'))}
                        render={({input, meta}) => (
                            <div className="form-input-container">
                                <input {...input} className="form-input" placeholder="What is your first name?" />
                                {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                            </div>
                        )}
                    />
                    <Field
                        name="last_name"
                        validate={composeValidators(isRequired, maxLength(30, 'last name'))}
                        render={({input, meta}) => (
                            <div className="form-input-container">
                                <input {...input} className="form-input" placeholder="What is your last name?" />
                                {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                            </div>
                        )}
                    />
                </div>
            </div>
            <div className="profile-input-section">
                <div className="icon-container">
                    <img
                        className="icon briefcase-icon"
                        src="/media/ui_icons/briefcase.png"
                        alt=""
                    />
                </div>
                <div className="input-fields-container">
                    <p className="header-label">Occupation</p>
                    <Field
                        name="company"
                        validate={maxLength(100, 'company')}
                        render={({input, meta}) => (
                            <div className="form-input-container">
                                <input {...input} className="form-input" placeholder="Where do you work?" />
                                {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                            </div>
                        )}
                    />
                    <Field
                        name="position"
                        validate={maxLength(100, 'position')}
                        render={({input, meta}) => (
                            <div className="form-input-container">
                                <input {...input} className="form-input" placeholder="(e.g. job position, role, etc.)" />
                                {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                            </div>
                        )}
                    />
                </div>
            </div>
            <div className="profile-input-section">
                <div className="icon-container">
                    <img
                        className="icon location-icon"
                        src="/media/ui_icons/location.png"
                        alt=""
                    />
                </div>
                <div className="input-fields-container">
                    <p className="header-label">Hometown</p>
                    <Field
                        name="city"
                        validate={maxLength(30, 'city')}
                        render={({input, meta}) => (
                            <div className="form-input-container">
                                <input {...input} className="form-input" placeholder="City" />
                                {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                            </div>
                        )}
                    />
                    <Field
                        name="state"
                        validate={maxLength(30, 'state')}
                        render={({input, meta}) => (
                            <div className="form-input-container">
                                <input {...input} className="form-input" placeholder="State" />
                                {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                            </div>
                        )}
                    />
                </div>
            </div>
        </form>
    );
};

export default class ProfileEditForm extends Component {

    constructor(props) {
        super(props);

        let {profileImageUrl} = props.initialValues || {};

        this.state = {
            imageChanged: false,
            selectedImage: profileImageUrl || null
        };
    }

    handleImageSelect = (selectedImage) => {
        this.setState({
            imageChanged: true,
            selectedImage
        });
    };

    handleRemoveImage = () => {
        this.setState({
            imageChanged: true,
            selectedImage: null
        });
    };

    includeAllFieldKeys = (formValues) => {
        if (formValues['profile_headline'] === undefined) {
            formValues['profile_headline'] = '';
        }

        if (formValues['profile_header_summary'] === undefined) {
            formValues['profile_header_summary'] = '';
        }

        if (formValues.city === undefined) {
            formValues.city = '';
        }

        if (formValues.state === undefined) {
            formValues.state = '';
        }

        if (formValues.company === undefined) {
            formValues.company = '';
        }

        if (formValues.position === undefined) {
            formValues.position = '';
        }

        return formValues;
    };

    handleSubmitForm = (formValues) => {
        let {
            imageChanged,
            selectedImage
        } = this.state;
        let {
            slugHash,
            initialValues,
            onSubmitForm,
            onSubmitFormSuccess
        } = this.props;
        let {occupation} = initialValues;
        let hasImage = selectedImage && imageChanged;

        if (hasImage) {
            formValues['profile_image'] = selectedImage;
        } else if (!selectedImage && imageChanged) {
            formValues['profile_image'] = null;
        }

        if (occupation.position || occupation.company) {
            formValues['occupation_id'] = occupation.id;
        }

        formValues = this.includeAllFieldKeys(formValues);

        onSubmitForm(slugHash, formValues, hasImage)
            .then(() => {
                onSubmitFormSuccess();
            });
    };

    render() {
        let {
            initialValues={},
            pristine,
            onCancel,
            onFormUpdate
        } = this.props;
        let {
            imageChanged,
            selectedImage
        } = this.state;
        let {
            firstName,
            lastName,
            profileHeadline,
            profileHeaderSummary,
            occupation={},
            city,
            state
        } = initialValues;
        let {
            position,
            company
        } = occupation;
        let submit;

        return (
            <div className="profile-edit-form">
                <ComposeHeader
                    primaryButtonTitle="Cancel"
                    primaryButtonOnClick={onCancel}
                    secondaryButtonTitle="Submit"
                    secondaryButtonOnClick={(e) => submit()}
                    secondaryButtonIsDisabled={pristine && !imageChanged}
                />
                <div className="banner-image-container">
                    <Dropzone
                        onSelectImage={this.handleImageSelect}
                        onRemoveImage={this.handleRemoveImage}
                        profileImageUrl={selectedImage}
                    />
                </div>
                <div className="form-container">
                    <Form
                        onSubmit={this.handleSubmitForm}
                        render={(props) => {
                            submit = props.handleSubmit;

                            return (
                                <EditForm
                                    onFormUpdate={onFormUpdate}
                                />
                            );
                        }}
                        initialValues={{
                            'first_name': firstName,
                            'last_name': lastName,
                            'profile_headline': profileHeadline,
                            'profile_header_summary': profileHeaderSummary,
                            company,
                            position,
                            city,
                            state
                        }}
                    />
                </div>
            </div>
        );
    }
}

ProfileEditForm.propTypes = {
    slugHash: PropTypes.string.isRequired,
    userId: PropTypes.string,
    profileImageUrl: PropTypes.string,
    userFirstName: PropTypes.string,
    userLastName: PropTypes.string,
    profileHeaderSummary: PropTypes.string,
    occupation: PropTypes.object,
    hometownCity: PropTypes.string,
    hometownState: PropTypes.string,
    initialValues: PropTypes.object,
    pristine: PropTypes.bool,
    onSubmitForm: PropTypes.func,
    onSubmitFormSuccess: PropTypes.func,
    onCancel: PropTypes.func,
    onFormUpdate: PropTypes.func
};
