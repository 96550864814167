import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Form, Field} from 'react-final-form';

import {fetchApiPost} from '../../common/utils/api';
import {
    isEmail,
    isRequired,
    composeValidators
} from '../../common/utils/validation';

import './ForgottenPassword.css';


export default function ForgottenPassword() {
    let [showConfirmationDisplay, setConfirmationDisplay] = useState(false);

    const handleFormSubmit = (formValues) => (
        fetchApiPost('forgot-password/', formValues)
            .then(({message}) => {
                if (message) {
                    setConfirmationDisplay(true);
                }
            })
            .catch(({errors}) => {
                if (errors.email && errors.email.key === 'EMAIL_NOT_FOUND') {
                    return Promise.resolve({'email': 'This email does not exist.'});
                }
            })
    );

    let bodyContent = (
        <div>
            <h2>Forgot your password?</h2>
            <p>Let's recover your password, so you can log back into your account. Enter your email address, and we will send you a link to reset your password.</p>
            <Form
                onSubmit={handleFormSubmit}
            >
                {
                    ({handleSubmit, form, touched, errors, submitErrors}) => {

                        return (
                            <form
                                className="form-wrapper"
                                onSubmit={handleSubmit}
                            >
                                <div className="form-field-container">
                                    <p className="label">Email</p>
                                    <Field
                                        className="email-input"
                                        component="input"
                                        validate={composeValidators(isRequired, isEmail)}
                                        type="emial"
                                        name="email"
                                        placeholder="Enter your email address"
                                    />
                                    {(errors.email || (submitErrors && submitErrors.email)) &&
                                        touched.email &&
                                        <span className="error">{errors.email || submitErrors.email}</span>
                                    }
                                </div>
                                <div className="form-field-container">
                                    <button
                                        className="submit-button"
                                        type="submit"
                                    >
                                        Reset Password
                                    </button>
                                </div>
                                <div>
                                    <Link className="form-link" to="/login/">
                                        Return to login
                                    </Link>
                                </div>
                            </form>
                        )
                    }
                }
            </Form>
        </div>
    );

    if (showConfirmationDisplay) {
        bodyContent = (
            <div>
                <h2>Verification email sent!</h2>
                <p>A verification link was emailed to you. Check your email and click the link in the email to proceed with resetting your password.</p>
                <p>
                    <Link className="form-link" to="/login/">
                        Return to login
                    </Link>
                </p>
            </div>
        );
    }

    return (
        <div id="forgotten-password">
            <div className="header">
                <div className="header-inner-container">
                    <div>
                        <Link
                            to={{
                                pathname: '/'
                            }}
                        >
                            <h1 className="logo">Boeiie</h1>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="page-body-container">
                {bodyContent}
            </div>
        </div>
    );
};
