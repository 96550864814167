import {LOGIN_USER_SUBMIT_SUCCESS} from '../pages/login/actions';

import {
    LOAD_AUTH_USER_START,
    LOAD_AUTH_USER_SUCCESS,
    LOAD_AUTH_USER_FAIL,
    UPDATE_AUTH_USER,
} from './actions';
import {
    SIGNUP_USER_SUBMIT_SUCCESS,
} from '../common/actions/signup';
import {LOGOUT_USER_SUCCESS} from '../common/actions/auth';


const loadAuthUserStartReducer = (state) => ({
    ...state,
    isLoading: true
});

const loadAuthUserSuccessReducer = (state, {authUser}) => ({
    ...state,
    isLoading: false,
    authUser
});

const loadAuthUserFailReducer = (state, {errors}) => {
    delete state['authUser'];

    return {
        ...state,
        isLoading: false,
        errors
    }
};

const updateAuthUserReducer = (state, {authUser}) => {
    return {
        ...state,
        authUser
    };
};

const loginUserReducer = (state, {authUser}) => {
    return {
        ...state,
        authUser
    }
};

const logoutSuccessReducer = (state) => {
    delete state['authUser'];

    return {
        ...state,
        isLoading: false,
    }
};

const APP_LOADING_REDUCER_MAP = {
    [LOAD_AUTH_USER_START]: loadAuthUserStartReducer,
    [LOAD_AUTH_USER_SUCCESS]: loadAuthUserSuccessReducer,
    [LOAD_AUTH_USER_FAIL]: loadAuthUserFailReducer,
    [UPDATE_AUTH_USER]: updateAuthUserReducer,
    [LOGIN_USER_SUBMIT_SUCCESS]: loginUserReducer,
    [SIGNUP_USER_SUBMIT_SUCCESS]: loginUserReducer,
    [LOGOUT_USER_SUCCESS]: logoutSuccessReducer,
};

const appLoadingReducer = (initialState={}, action={}) => {
    let reducer = APP_LOADING_REDUCER_MAP[action.type];
    let nextState = initialState;

    if (reducer) {
        nextState = reducer(initialState, action);
    }

    return nextState;
};

export default appLoadingReducer;
