export const INITIALIZE_COMMENT_THREAD = 'initialize-comment-thread';

export const initializeCommentThread = (postId) => ({
    type: INITIALIZE_COMMENT_THREAD,
    postId,
});


export const LOAD_COMMENTS_START = 'load-comments-start';
export const LOAD_COMMENTS_SUCCESS = 'load-comments-success';
export const LOAD_COMMENTS_FAIL = 'load-comments-fail';

export const loadCommentsStart = (postId) => ({
    type: LOAD_COMMENTS_START,
    postId
});

export const loadCommentsSuccess = (postId, comments, hasMoreComments, totalCount=null) => ({
    type: LOAD_COMMENTS_SUCCESS,
    postId,
    comments,
    hasMoreComments,
    totalCount
});

export const loadCommentsFail = (postId, errors) => ({
    type: LOAD_COMMENTS_FAIL,
    postId,
    errors,
});


export const CREATE_COMMENT_START = 'create-comment-start';
export const CREATE_COMMENT_SUCCESS = 'create-comment-success';
export const CREATE_COMMENT_FAIL = 'create-comment-fail';

export const createCommentStart = () => ({
    type: CREATE_COMMENT_START
});

export const createCommentSuccess = (comment) => ({
    type: CREATE_COMMENT_SUCCESS,
    comment
});

export const createCommentFail = (errors) => ({
    type: CREATE_COMMENT_FAIL,
    errors
});


export const EDIT_COMMENT_START = 'edit-comment-start';
export const EDIT_COMMENT_SUCCESS = 'edit-comment-success';
export const EDIT_COMMENT_FAIL = 'edit-comment-fail';

export const editCommentStart = () => ({
    type: EDIT_COMMENT_START
});

export const editCommentSuccess = (comment) => ({
    type: EDIT_COMMENT_SUCCESS,
    comment
});

export const editCommentFail = (errors) => ({
    type: EDIT_COMMENT_FAIL,
    errors
});


export const HIDE_COMMENT_START = 'hide-comment-start';
export const HIDE_COMMENT_SUCCESS = 'hide-comment-success';
export const HIDE_COMMENT_FAIL = 'hide-comment-fail';

export const hideCommentStart = () => ({
    type: HIDE_COMMENT_START
});

export const hideCommentSuccess = (comment) => ({
    type: HIDE_COMMENT_SUCCESS,
    comment,
});

export const hideCommentFail = (errors) => ({
    type: HIDE_COMMENT_FAIL,
    errors
});


export const DELETE_COMMENT_START = 'delete-comment-start';
export const DELETE_COMMENT_SUCCESS = 'delete-comment-success';
export const DELETE_COMMENT_FAIL = 'delete-comment-fail';

export const deleteCommentStart = () => ({
    type: DELETE_COMMENT_START
});

export const deleteCommentSuccess = (comment) => ({
    type: DELETE_COMMENT_SUCCESS,
    comment
});

export const deleteCommentFail = () => ({
    type: DELETE_COMMENT_FAIL
});


export const LOAD_COMMENTS_FOR_POST_START = 'load-comments-for-post-start';
export const LOAD_COMMENTS_FOR_POST_SUCCESS = 'load-comments-for-post-success';
export const LOAD_COMMENTS_FOR_POST_FAIL = 'load-comments-for-post-fail';

export const loadCommentsForPostStart = (postId) => ({
    type: LOAD_COMMENTS_FOR_POST_START,
    postId
});

export const loadCommentsForPostSuccess = (
    postId,
    comments,
    nextPaginationLink,
    previousPaginationLink,
    totalCount,
    order
) => ({
    type: LOAD_COMMENTS_FOR_POST_SUCCESS,
    postId,
    comments,
    nextPaginationLink,
    previousPaginationLink,
    totalCount,
    order
});

export const loadCommentsForPostFail = (errors) => ({
    type: LOAD_COMMENTS_FOR_POST_FAIL,
    errors
});


export const LOAD_COMMENTS_FROM_POSTS_START = 'load-comments-from-posts-start';
export const LOAD_COMMENTS_FROM_POSTS_SUCCESS = 'load-comments-from-posts-success';
export const LOAD_COMMENTS_FROM_POSTS_FAIL = 'load-comments-from-posts-fail';

export const loadCommentsFromPostsStart = (postIds) => ({
    type: LOAD_COMMENTS_FROM_POSTS_START,
    postIds
});

export const loadCommentsFromPostsSuccess = (comments) => ({
    type: LOAD_COMMENTS_FROM_POSTS_SUCCESS,
    comments
});

export const loadCommentsFromPostsFail = (errors) => ({
    type: LOAD_COMMENTS_FROM_POSTS_FAIL,
    errors
});


export const LOAD_REPLIES_START = 'load-replies-start';
export const LOAD_REPLIES_SUCCESS = 'load-replies-success';
export const LOAD_REPLIES_FAIL = 'load-replies-fail';

export const loadRepliesStart = (postId, parentCommentId) => ({
    type: LOAD_REPLIES_START,
    postId,
    parentCommentId
});

export const loadRepliesSuccess = (postId, parentCommentId, replies) => ({
    type: LOAD_REPLIES_SUCCESS,
    postId,
    parentCommentId,
    replies
});

export const loadRepliesFail = (error) => ({
    type: LOAD_REPLIES_FAIL,
    error
});
