import {connect} from 'react-redux';

import {tagButtonClick} from './actions';
import TagButton from './TagButton';


const tagButtonOnClick = (tag) => (dispatch) => {
    dispatch(tagButtonClick(tag));
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => {
    return {
        onClick: (tag) => dispatch(tagButtonOnClick(tag))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TagButton);
