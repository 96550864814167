import React from 'react';
import {Link} from 'react-router-dom';

import GlobalHeader from '../../common/components/global_header/connector';

import './PrivacyPolicy.css';


function PrivacyPolicy() {
    return (
        <div id="privacy-policy">
            <GlobalHeader />
            <div className="page-body-container">
                <div className="content-container">
                    <p><h1>Boeiie Privacy Policy</h1></p>
                    <p><i>Last updated: October 3, 2020</i></p>
                    <br />
                    <p><strong>PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND OUR POLICIES AND PRACTICES REGARDING YOUR INFORMATION AND HOW WE WILL TREAT IT.</strong></p>
                    <p><strong>IF YOU DO NOT AGREE WITH OUR POLICIES AND PRACTICES, YOU SHOULD NOT USE OR ACCESS THE SITE.</strong></p>
                    <p>
                        <span>Boeiie, LLC, a Missouri limited liability company (&ldquo;</span><span>Boeiie</span><span>&rdquo;, &ldquo;</span><span>us</span><span>&rdquo;, &ldquo;</span><span>our</span><span>&rdquo;, or &ldquo;</span><span>we</span>
                        <span>&rdquo;), operates the website located at www.Boeiie.com,&nbsp;its subdomains and derivative URLs, and subdomains and derivative URLs that we host for our users (each, and collectively, the &ldquo;</span>
                        <span>Services</span><span>&rdquo;). We understand your concerns about privacy and are committed to protecting it through our compliance with this policy (this &ldquo;</span><span>Privacy Policy</span><span>&rdquo;).</span>
                    </p>
                    <p>
                        <span>
                            This Privacy Policy describes what information we collect about you or your child when using or visiting the Services; how such information is used and stored; when such information may be disclosed to third parties; and how
                            users, or parents and guardians, can control the use and disclosure of information collected from themselves or their children. We encourage families to discuss their household guidelines regarding the online sharing of
                            personal information and especially suggest that visitors under the age of 18 check with their parents or guardians before entering information on any website or mobile application.
                        </span>
                    </p>
                    <p>
                        <span>This policy is in accordance with the U.S. Children&#39;s Online Privacy Protection Act (also known as &ldquo;</span><span>COPPA</span>
                        <span>&rdquo;), among other applicable privacy laws, and outlines our practices in the United States regarding children&#39;s information collected from users of the Services (see &lrm;&lrm;</span><span>Section 9</span>
                        <span>, </span><span>Protecting Children</span><span>).</span>
                    </p>
                    <p>
                        <span>
                            This Privacy Policy does not apply to: (i) information collected by Boeiie offline or through any other means, such as through an application or content (including advertising) that may link to or be accessible from or on
                            the Services.
                        </span>
                    </p>
                    <ol start="1">
                        <li>
                            <h1><span>Changes to This Privacy Policy</span></h1>
                        </li>
                    </ol>
                    <p>
                        <span>
                            We will make changes to this Privacy Policy from time to time, at our sole discretion. When we make changes, we will notify you, at a minimum, by updating this Privacy Policy and the Effective Date at the top of the page.
                            You are expected to carefully review this Privacy Policy frequently in order to be aware of any changes.
                        </span>
                    </p>
                    <p>
                        <span>
                            However, if we make material changes to this Privacy Policy, we will notify you either through the email address you have provided us or by a prominent notice on the Services before the change becomes effective. You are
                            responsible for ensuring we have an up-to-date active and deliverable email address for you.
                        </span>
                    </p>
                    <p>
                        <span>
                            Any changes to this Privacy Policy&nbsp;will be in effect as&nbsp;of&nbsp;the Effective Date that is posted at the top of the page. By continuing to use the Services, you agree to be bound by the changes and to our
                            collection and sharing of your personally identifiable information according to the terms of our then-current Privacy Policy.
                        </span>
                    </p>
                    <p>
                        <span>If you or your child are a California resident, please see </span><span>&lrm;Section 8</span><span>, </span><span>California Privacy Notice</span><span>, in order to learn more about your California Privacy Rights.</span>
                    </p>
                    <ol start="2">
                        <li>
                            <h1><span>Personal Information That We Collect</span></h1>
                        </li>
                    </ol>
                    <p><span>We may collect the following information:</span></p>
                    <ol start="1">
                        <li>
                            <h2><span>Information You (or Your Child) Provide to Us.</span></h2>
                        </li>
                    </ol>
                    <p>
                        <span>
                            You or your child can use some of the Services without actively submitting any information, but some portions of the Services may require or allow you or your child to provide personal information, such as the following:
                        </span>
                    </p>
                    <ul>
                        <li>
                            <span>Personal Identifiers and User Profile Information </span>
                            <span>such as name, postal address, email address, phone number, and demographic information (such as gender and occupation), date of birth, and gender (collectively, &ldquo;</span><span>PII</span><span>&rdquo;)</span>
                        </li>
                        <li>
                            <span>Parent or Guardian Contact Information,</span><span>&nbsp;in the case of a child</span><span>;</span>
                        </li>
                        <li>
                            <span>Payment/billing Information</span><span>&nbsp;such as a credit card number and associated identifiers, and your billing address (which may be collected by our payment processing services);</span>
                        </li>
                        <li>
                                <span>Account and Support Information</span>
                                <span>
                                    . Account login credentials (username and password), avatar, interests, or troubleshooting and support data (for example, data you provide or that we collect in connection with a support query that we receive from
                                    you including, but not limited to, contact or authentication data, the content or transcript of your communication with us, and information regarding the product or service you are using related to your help
                                    inquiry);
                                </span>
                        </li>
                        <li>
                                <span>Location Data</span>
                                <span>
                                    &nbsp;such as GPS signals, device sensors, wi-fi access points, and cell tower IDs, if you have allowed access to that information from your mobile device; however, we do not collect such information from Services
                                    that are directed to children);
                                </span>
                        </li>
                        <li>
                            <span>Marketing information </span><span>such as your contact preferences and desired frequency of emails; and</span>
                        </li>
                        <li>
                                <span>Communication Information, </span>
                                <span>
                                    if you choose to post content or communicate with us or others on a public platform we host or through user support, we will collect the information you provide in those postings; however, we do not allow children to
                                    communicate with others in public platforms.
                                </span>
                        </li>
                        <li>
                                <span>Historical Information</span><span>&nbsp;in order to maintain a record of your purchases, transactional information, history and usage, support logs and history, or any communications and responses with us.</span>
                        </li>
                    </ul>
                    <ol start="2">
                        <li>
                            <h2><span>Information We Automatically Collect.</span></h2>
                        </li>
                    </ol>
                    <p><span>There is some information that we may automatically when you or your child access and use the Services, including:</span></p>
                    <ul>
                        <li>
                                <span>Device Information </span>
                                <span>such as IP address, mobile advertising ID, media access control (MAC) address, and identifiers associated with browser cookies, web beacons, or similar technologies we deploy on the Services;</span>
                        </li>
                        <li>
                                <span>Network Information</span>
                                <span>
                                    &nbsp;such as your internet service provider (ISP), browser type, and operating system version, language settings, access times, pages viewed, the routes by which you access the Services, and your use of any
                                    hyperlinks available within the Services;
                                </span>
                        </li>
                        <li>
                                <span>Log data</span>
                                <span>
                                    &nbsp;such as the nature of each access and applicable data that we record each time a device accesses an applicable server, which may include your originating IP address and your activity while using the Services
                                    (for example, date and time stamps, pages and files viewed, searches and other actions you take such as which features you accessed), device event information (for example, system activity, error reports or crash
                                    dumps), hardware settings, and metadata (or other data about data), and other information associated with files that you upload to the Services; and
                                </span>
                        </li>
                        <li>
                                <span>Usage data</span>
                                <span>
                                    &nbsp;such as the dates and times you access the Services and browsing activities (for example, the portions of the Services you used) as well as performance data regarding the Services, including metrics related to
                                    the deliverability of emails and other communications you send through the Services that allows us to improve the content and operation of the Services and facilitate research and analysis of the Services.
                                </span>
                        </li>
                    </ul>
                    <p><span>In some jurisdictions, this information is also considered PII under applicable data protection laws. We use cookies and other tracking technologies to collect some of this information.</span></p>
                    <ol start="3">
                        <li>
                            <h2><span>Information We Get from Other Sources.</span></h2>
                        </li>
                    </ol>
                    <p>
                        <span>
                            Where we collect information from adults or from portions of the Services that are not directed to children, we may collect personal information from third parties to supplement the information we collect from you directly
                            in order to show offers that are more relevant to your interests and to better understand our users. We may add this to information we get from your use of the Services.
                        </span>
                    </p>
                    <ol start="3">
                        <li>
                            <h1><span>How We Use Personal Information</span></h1>
                        </li>
                    </ol>
                    <p><span>We may use the PII we collect or receive through the Services (alone or with other data we source) consistent with our legitimate interests to operate and administer the Services including to:</span></p>
                    <ul>
                        <li>
                            <span>Provide, maintain, and improve the Services, as well as to develop new content and features;</span>
                        </li>
                        <li>
                            <span>Create, maintain, and service your account;</span>
                        </li>
                        <li>
                            <span>Send notifications related to your account;</span>
                        </li>
                        <li>
                            <span>Enable you or your child to participate in interactive features of the Services;</span>
                        </li>
                        <li>
                            <span>Send newsletters, postcards, or messages you or your child request;</span>
                        </li>
                        <li>
                            <span>Enable you or your child to participate in sweepstakes, contests, or promotions;</span>
                        </li>
                        <li>
                            <span>Respond to your support requests;</span>
                        </li>
                        <li>
                            <span>Process or fulfill orders and transactions;</span>
                        </li>
                        <li>
                            <span>Conduct internal research and development;</span>
                        </li>
                        <li>
                            <span>Debug the Services to identify and repair errors;</span>
                        </li>
                        <li>
                            <span>Perform our contractual obligations;</span>
                        </li>
                        <li>
                            <span>Analyze traffic and user activities on the Services, identify popular areas or features, and optimize and personalize the Services;</span>
                        </li>
                        <li>
                            <span>Serve contextual advertising and limit the number of times you or your child see an advertisement on the Services;</span>
                        </li>
                        <li>
                            <span>Protect our rights and property as well as the rights and property of others; and</span>
                        </li>
                        <li>
                            <span>Comply with our legal obligations, including to detect, investigate, and prevent fraud and other illegal activities, and to enforce our agreements</span>
                        </li>
                        <li>
                            <span>Enforce our Terms of Use and applicable law or to protect the rights and safety of our users; and</span>
                        </li>
                        <li>
                            <span>Communicate suggestions and tailored features to you within the Services to optimize and personalize your experience.</span>
                        </li>
                    </ul>
                    <p><span>Boeiie takes the privacy of PII seriously, and we will continue to implement appropriate safeguards to protect this PII from misuse or unauthorized disclosure.</span></p>
                    <ol start="4">
                        <li>
                            <h1><span>Sharing of Personal Information</span></h1>
                        </li>
                    </ol>
                    <p><span>We do not sell personal information collected from children or teens we know to be under 16. We will not share the personal information we collect with any unrelated third parties other than with our:</span></p>
                    <ul>
                        <li>
                            <span>Affiliates and Subsidiaries</span><span>&nbsp;for the purposes described in </span><span>&lrm;Section 3</span><span>, </span><span>How We Use Personal Information</span><span>;</span>
                        </li>
                        <li>

                                <span>Service Providers</span>
                                <span>
                                    &nbsp;that perform services on our behalf, including customer support, payment processors, or others who assist us in fulfilling orders or transactions, web hosting, information technology, and analytics services;
                                </span>

                        </li>
                        <li>
                            <span>Potential Buyers</span><span>&nbsp;as part of, or during negotiations for, any purchase, sale, lease, merger, or any other type of acquisition, disposal, or financing involving Boeiie;</span>
                        </li>
                        <li>

                                <span>Professional Advisors</span>
                                <span>&nbsp;such as our legal, financial, insurance, or other advisors in connection with the corporate transactions described above or the management of our business and operations;</span>

                        </li>
                        <li>

                                <span>Law Enforcement Authorities and Individuals Involved in Legal Proceedings</span>
                                <span>
                                    &nbsp;when it is necessary for us to comply with applicable law or legal process, to respond to legal claims, or to protect the rights, property or personal safety of Boeiie, our users, employees, or the public;
                                </span>

                        </li>
                        <li>

                                <span>Partners for Co-Branded Sites and Services</span><span>, such as events and promotions that we put together with another company (&ldquo;</span><span>Co-Branded Partner</span>
                                <span>
                                    &rdquo;) which may be hosted in the Services and/or our Co-Branded Partner&#39;s website; however, in such cases, the Co-Branded Partner will be identified on the co-branded feature or service (if you want to opt out
                                    of the Co-Branded Partner&#39;s use of your personal information, please contact the Co-Branded Partner). We do not offer co-branded services or features on portions of the Services that are specifically designed for
                                    children; and
                                </span>

                        </li>
                        <li>
                            <span>With Your Consent or At Your Direction</span><span>&nbsp;to third parties that you or your child, if they are between 13-16, ask us to share with.</span>
                        </li>
                    </ul>
                    <p>
                        <span>
                            We reserve the right to share, license, or sell aggregated and/or anonymized data that is data after your personal information has been removed with or to third parties for their own uses. When we work with suppliers or
                            service providers, we require them to keep confidential the information received on our behalf and they are prohibited from using it for any purpose other than to carry out the services they are performing for us.
                        </span>
                    </p>
                    <ol start="5">
                        <li>
                            <h1><span>Cookie Notice</span></h1>
                        </li>
                    </ol>
                    <p>
                        <span>
                            We use cookies and similar technologies to recognize you, as well as to track and analyze your actions when you visit the Services. In addition, we use these technologies to track and analyze logged-in users of the Services.
                        </span>
                    </p>
                    <ol start="1">
                        <li>
                            <h2><span>What Are Cookies?</span></h2>
                        </li>
                    </ol>
                    <p>
                        <span>
                            Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as
                            to provide reporting information.
                        </span>
                    </p>
                    <p>
                        <span>We refer to cookies created by us as &quot;</span><span>Boeiie Cookies</span><span>&quot; and those created by parties other than us as &quot;</span><span>third-party cookies</span>
                        <span>
                            &quot;. Third-party cookies enable third-party features or functionality to be provided on or through the Services (for example, advertising, interactive content, and analytics). The parties that provide these third-party
                            cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.
                        </span>
                    </p>
                    <p>
                        <span>
                            In addition to the party which sets the cookie, the duration of the cookie&rsquo;s retention in your browser can help define its purpose. &ldquo;Session cookies&rdquo; are cookies which are stored on your device for the
                            duration of the browser session. When you close your browser, the session cookies are cleared. &ldquo;Persistent cookies&rdquo; are stored either until you manually delete them or until your browser deletes them based on the
                            duration set within the persistent cookie file.
                        </span>
                    </p>
                    <ol start="2">
                        <li>
                            <h2><span>Why We Use Cookies</span></h2>
                        </li>
                    </ol>
                    <p><span>We use both Boeiie Cookies and third-party cookies for several reasons which can be delineated into broad categories as follows:</span></p>
                    <ul>
                        <li>
                                <span>Essential</span>
                                <span>
                                    &nbsp;cookies are strictly necessary to provide you with the Services and to use the features, such as access to secure, member-only, and private areas. Refusal of these cookies may impact website functionality;
                                </span>
                        </li>
                        <li>
                                <span>Performance/Functionality</span>
                                <span>&nbsp;cookies are used to enhance performance or functionality of the Services but are non-essential. Without these cookies, however, certain functionality may become unavailable;</span>
                        </li>
                        <li>
                                <span>Analytics/Customization</span>
                                <span>
                                    &nbsp;cookies collect information in aggregate form either (1) to help us understand (A) how the Services are being used or (B) how effective marketing campaigns are or (2) to help us customize the Services for you;
                                </span>
                        </li>
                        <li>
                                <span>Advertising</span>
                                <span>
                                    &nbsp;cookies are used to ensure advertising messages are relevant to you, for instance, by preventing the same ad from reappearing continuously, ensuring that ads are properly displayed, or, in some cases, selecting
                                    advertisements based on your interests; and
                                </span>
                        </li>
                        <li>
                                <span>Social Networking</span>
                                <span>
                                    &nbsp;cookies are used to enable you to share pages and content you find interesting throughout the Services through third-party social networking or other websites (including, potentially for advertising purposes
                                    related to social networking).
                                </span>
                        </li>
                    </ul>
                    <ol start="3">
                        <li>
                            <h2><span>Boeiie Cookies</span></h2>
                        </li>
                    </ol>
                    <table>
                        <tbody>
                            <tr>
                                <td colspan="1" rowspan="1">
                                    <p><span>Name</span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span>Type</span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span>Subject</span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span>Purpose</span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span>Retention</span></p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" rowspan="1">
                                    <p><span></span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span></span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span></span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span></span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span></span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                    <ol start="4">
                        <li>
                            <h2><span>Third-Party Cookies</span></h2>
                        </li>
                    </ol>
                    <table>
                        <tbody>
                            <tr>
                                <td colspan="1" rowspan="1">
                                    <p><span>Name</span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span>Type</span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span>Subject</span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span>Purpose</span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span>Retention</span></p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" rowspan="1">
                                    <p><span>Google Analytics</span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span>Analytics</span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span>Boeiie Visitors</span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span>Google Analytics cookies help us measure and evaluate the Services&rsquo; performance by providing insights into user behavior and actions</span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span>26 months</span></p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" rowspan="1">
                                    <p><span>Facebook</span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span>Advertising</span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span>Boeiie Visitors</span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span>Display targeted, more personal ads</span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span>180 days</span></p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" rowspan="1">
                                    <p><span>Google AdWords</span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span>Advertising</span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span>Boeiie Visitors</span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span>Display targeted, more personal ads</span></p>
                                </td>
                                <td colspan="1" rowspan="1">
                                    <p><span>1 year</span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p><span></span></p>
                    <ol start="5">
                        <li>
                            <h2><span>How to Control the Use of Cookies</span></h2>
                        </li>
                    </ol>
                    <p>
                        <span>
                            Electronic devices and software applications on these devices may offer you tools to opt out of or block advertisements on the device or in specific applications. Consult the help documentation and settings specific to your
                            devices and applications to learn more about your options. You have the right to decide whether to accept or reject cookies. You can withdraw your consent at any time for any cookies or other tracking technologies used by us
                            by deleting them from your device. Should you choose to remove or block cookies, some functionality of the Services may become unavailable or unreliable.
                        </span>
                    </p>
                    <p>
                        <span>
                            In addition, most advertising networks offer you a way to opt out of targeted advertising. If you would like to find out more information, please visit http://www.aboutads.info/choices/ or http://www.youronlinechoices.com.
                        </span>
                    </p>
                    <ol start="6">
                        <li>
                            <h2><span>Other Tracking Technologies</span></h2>
                        </li>
                    </ol>
                    <p><span>Other than the cookies listed above, we have also implemented the following tracking technologies within certain parts of the Services.</span></p>
                    <ul>
                        <li>
                            <span>Page tags and web beacons</span>
                            <span>
                                &nbsp;are used for the purposes of tracking users as they navigate the Services to better understand performance of the Services, and to measure the performance of online advertising. For example, web beacons track
                                whether an email sent through the Services was delivered and opened and whether links within the email were clicked. This information is collected for the purposes of providing email campaign performance information
                                and measuring the performance of email messages they send and is processed according to this Privacy Policy; and
                            </span>
                        </li>
                        <li>
                            <span>Local and session storage</span><span>&nbsp;to temporarily store information to improve user experience while interacting with the website.</span>
                        </li>
                    </ul>
                    <ol start="7">
                        <li>
                            <h2><span>Cookies and CCPA</span></h2>
                        </li>
                    </ol>
                    <p>
                        <span>Information collected and stored by cookies and other tracking technologies will often meet the definition of personal information in the California Consumer Privacy Protection Act (&ldquo;</span><span>CCPA</span>
                        <span>&rdquo;). The CCPA defines &ldquo;personal information&rdquo; as &ldquo;information that identifies, relates to, describes, is capable of being associated with, or could </span><span>reasonably</span>
                        <span>
                            &nbsp;be linked, directly or indirectly, with a particular California resident or household.&rdquo; Personal information collected by the cookies and related technologies described may include your location, information
                            about your browsing behavior on the Services or other websites, and information you provide via forms or single fields on the Services. This personal information will be used only as described by this Cookie Notice and
                            Privacy Policy. See
                        </span>
                        <span>&lrm;Section 8</span><span>, </span><span>California Privacy Notice</span><span>, for information pertaining to California residents.</span>
                    </p>
                    <ol start="6">
                        <li>
                            <h1><span>Advertising and Analytics Technologies</span></h1>
                        </li>
                    </ol>
                    <ol start="1">
                        <li>
                            <h2><span>Third-Party Data Collection</span></h2>
                        </li>
                    </ol>
                    <p>
                        <span>
                            The Services may include links to other websites or services, such as to purchase online subscriptions or a (virtual or consumer) good or service. Personal information collected on other sites and services is subject to the
                            privacy policy and practices of the third party providing such website or service and not this Privacy Policy.
                        </span>
                    </p>
                    <p>
                        <span>With respect to the Services or portions of the Services that are not directed to children, we may give you the option to share with others on social media platforms, such as Instagram, Facebook or Twitter (&ldquo;</span>
                        <span>Social Media Links</span>
                        <span>
                            &rdquo;). When you share using Social Media Links, the privacy practices of the social media platform control who has access to your information and how it is treated by the social media platform. We ask that you consult the
                            privacy policies of any social media services (or other third party) with which you share information in this manner to learn about the social media platform&#39;s data practices.
                        </span>
                    </p>
                    <ol start="2">
                        <li>
                            <h2><span>Data Storage</span></h2>
                        </li>
                    </ol>
                    <p><span>We store the personal information we collect for as long as is necessary for the purpose(s) for which we originally collected it. We may also retain personal information as required by law.</span></p>
                    <ol start="3">
                        <li>
                            <h2><span>Analytics Technologies</span></h2>
                        </li>
                    </ol>
                    <p>
                        <span>We may use Google Analytics or other similar technologies (the &ldquo;</span><span>Analytics Technologies</span>
                        <span>
                            &rdquo;) to collect and process certain information about you and your use of the Services. The information collected by the Analytics Technologies is statistical data and does not include PII, and we do not maintain it or
                            associate it with PII we collect in other ways or receive from third parties.
                        </span>
                    </p>
                    <p><span>Our web server and/or the Analytics Technologies may collect and store the following general information about you:</span></p>
                    <ul>
                        <li>
                            <span>the originating name of the domain from which you access the internet;</span>
                        </li>
                        <li>
                            <span>the date and time you access the Services;</span>
                        </li>
                        <li>
                            <span>the pages of the Services you visit;</span>
                        </li>
                        <li>
                            <span>the internet address of the website from which you linked directly to us; and</span>
                        </li>
                        <li>
                            <span>the type of device, device ID and web browser you use to access the Services.</span>
                        </li>
                    </ul>
                    <p>
                        <span>
                            This information is collected automatically. It is used to help us improve the Services by tabulating the number of visitors to the Services in terms of new users, origin of referrers, popularity of pages, amount of use,
                            types of errors, and to make the Services more useful to you.
                        </span>
                    </p>
                    <p>
                        <span>
                            Our web server and/or the Analytics Technologies may also collect internet protocol addresses (the internet address of a device) to track a user&rsquo;s session while the user remains anonymous. We analyze this data for
                            certain trends in statistics, such as which parts of the Services users are visiting and how long they spend there.
                        </span>
                    </p>
                    <h3><span>Google Analytics.</span></h3>
                    <p>
                        <span>
                            You may learn more about how Google uses the information it collects by visiting the following site: &ldquo;How Google Uses Data When You Use Our Partners&#39; Services or Apps&rdquo; (located at
                            https://www.google.com/policies/privacy/partners/). Google&rsquo;s ability to use and share information collected by Google Analytics about your visits to the Services is restricted by the Google privacy policy (located at
                            https://www.google.com/policies/privacy/).
                        </span>
                    </p>
                    <ol start="7">
                        <li>
                            <h1><span>Choices About How We Use and </span><span>Disclose</span><span>&nbsp;Your Information</span></h1>
                        </li>
                    </ol>
                    <p><span>We strive to provide you with choices regarding the PII you provide to us. We have created mechanisms to provide you with the following control over your information:</span></p>
                    <ol start="1">
                        <li>
                            <h2><span>Marketing Communications</span></h2>
                        </li>
                    </ol>
                    <p>
                        <span>
                            Our marketing emails tell you how to &ldquo;opt out.&rdquo; You may choose to opt out of receiving promotional communications from us by following the instructions in those messages. Please note, that even if you do opt out,
                            we may still send you non-promotional communications, such as emails or text messages about your account or orders from us.
                        </span>
                    </p>
                    <p><span>We do not send marketing communications to children.</span></p>
                    <ol start="2">
                        <li>
                            <h2><span>Location Data</span></h2>
                        </li>
                    </ol>
                    <p>
                        <span>
                            When you use any of our mobile apps that collect precise location information, you may be asked to consent to the collection of this information. You can change your preferences at any time through the settings on your
                            mobile device.
                        </span>
                    </p>
                    <p><span>We do not collect precise location information from children.</span></p>
                    <ol start="3">
                        <li>
                            <h2><span>Mobile Push Notifications/Alerts and Local Notifications</span></h2>
                        </li>
                    </ol>
                    <p>
                        <span>
                            With your consent, we may send promotional or non-promotional push notifications or alerts to your mobile device. You can deactivate these messages at any time by changing the notification settings on your mobile device.
                        </span>
                    </p>
                    <p>
                        <span>
                            We do not send push notifications to children, but we may use &ldquo;local notifications&rdquo; that are sent directly through a mobile app to your or your child&#39;s device (and do not require an internet connection). We
                            may use these local notifications to notify you or your child of new content or events within the app, among other reasons.
                        </span>
                    </p>
                    <ol start="4">
                        <li>
                            <h2><span>By Request</span></h2>
                        </li>
                    </ol>
                    <p>
                        <span>You may send requests about personal information to the email in </span><span>&lrm;Section 12</span><span>, </span><span>Contact Information</span>
                        <span>. You can request to change contact choices, opt out of our sharing with others, and update your personal information.</span>
                    </p>
                    <ol start="5">
                        <li>
                            <h2><span>Cookies</span></h2>
                        </li>
                    </ol>
                    <p>
                        <span>
                            You can typically remove and reject cookies from the Services with your browser settings. Many browsers are set to accept cookies until you change your settings. If you remove or reject our cookies, it could affect how the
                            Services works for you.
                        </span>
                    </p>
                    <ol start="6">
                        <li>
                            <h2><span>Tracking Technologies</span></h2>
                        </li>
                    </ol>
                    <p>
                        <span>
                            You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of the Services may then be inaccessible or not
                            function properly.
                        </span>
                    </p>
                    <h3><span>Google Analytics Opt-out Option</span></h3>
                    <p>
                        <span>
                            If you do not want your information collected with Google Analytics, you can install the Google Analytics opt-out browser add-on. To opt out of Google Analytics, visit the Google Analytics opt-out page (located at
                            https://tools.google.com/dlpage/gaoptout) and install the add-on for your browser. For more details, visit the &ldquo;Google Analytics opt-out browser add-on&rdquo; page (located at
                            https://support.google.com/analytics/answer/181881?hl=en).
                        </span>
                    </p>
                    <ol start="8">
                        <li>
                            <h1><span>California Privacy Notice</span></h1>
                        </li>
                    </ol>
                    <p><span>If you are a California resident, you may have certain rights. For example, California law may permit you to request that we:</span></p>
                    <ul>
                        <li>
                            <span>Provide you with the categories</span><span>&nbsp;of (1) PII we have collected or disclosed about you in the last twelve months; (2) sources of such information; (3) third parties with whom we shared PII;</span>
                        </li>
                        <li>
                            <span>Provide you with the business or commercial purpose</span><span>&nbsp;for collecting or selling your PII</span>
                        </li>
                        <li>
                            <span>Provide access to and/or a copy</span><span>&nbsp;of certain information we hold about you; and</span>
                        </li>
                        <li>
                            <span>Delete certain information</span><span>&nbsp;we have about you.</span>
                        </li>
                    </ul>
                    <p>
                        <span>
                            You also have the right to receive information about the financial incentives that we offer (if any) and the right to not be discriminated against for exercising your rights. Certain information may be exempt from such
                            requests under applicable law. For example, we need certain types of information so that we can provide the Services to you. If you ask us to delete it, you may no longer be able to access or use the Services.
                        </span>
                    </p>
                    <p><span>Please review these additional privacy disclosures, which provide a summary of how we collect, use, and disclose personal information from you or your child, and explain your rights under California law.</span></p>
                    <ol start="1">
                        <li>
                            <h2><span>Categories of Information We Collect, Use, and Disclose for Business Purposes</span></h2>
                        </li>
                    </ol>
                    <p>
                        <span>We collect personal information as described in </span><span>&lrm;Section 2</span><span>, </span><span>Personal Information That We Collect</span>
                        <span>. We collect this information from you, your child, third parties, or as otherwise described in this policy.</span>
                    </p>
                    <p>
                        <span>We use these categories as described in </span><span>&lrm;Section 3</span><span>, </span><span>How We Use Personal Information</span>
                        <span>, and disclose these categories of personal information for business purposes as described above and in </span><span>&lrm;Section 4</span><span>, </span><span>Sharing of Personal Information</span><span>.</span>
                    </p>
                    <p><span>In addition, we and our service providers may collect the below categories of information for the following business or commercial purposes (as those terms are defined in applicable law):</span></p>
                    <ul>
                        <li>
                            <span>Operational purposes</span><span>&nbsp;of us or our service providers;</span>
                        </li>
                        <li>
                            <span>Auditing</span><span>&nbsp;</span><span>your interactions</span><span>&nbsp;on the Services, such as measuring analytics like frequency of use of links;</span>
                        </li>
                        <li>
                            <span>Detecting, protecting against, and prosecuting security incidents</span><span>&nbsp;and fraudulent or illegal activity;</span>
                        </li>
                        <li>
                            <span>Bug detection </span><span>and error reporting;</span>
                        </li>
                        <li>
                            <span>Short-term (or transient) use</span><span>, such as customizing content that we or our service providers display on the Services (such as contextual ads);</span>
                        </li>
                        <li>

                                <span>Providing the Services</span>
                                <span>&nbsp;including account servicing and maintenance, order processing and fulfillment, customer service, advertising and marketing, analytics, and communication about the Services;</span>

                        </li>
                        <li>
                            <span>Improving existing and developing new Services</span><span>&nbsp;(for example, by conducting internal research to develop new products or features);</span>
                        </li>
                        <li>
                            <span>Verifying or maintaining the quality or safety</span><span>&nbsp;of, or upgrading or enhancing the Services;</span>
                        </li>
                        <li>
                            <span>Other uses that advance our commercial or economic interests</span><span>, such as third-party advertising and communicating with you about relevant offers from third-party partners; and</span>
                        </li>
                        <li>
                            <span>Other uses about which we notify you</span><span>.</span>
                        </li>
                    </ul>
                    <ol start="2">
                        <li>
                            <h2><span>California Residents&rsquo; Rights</span></h2>
                        </li>
                    </ol>
                    <p>
                        <span>
                            Under the California Consumer Privacy Protection Act, California residents have the right to request access to or deletion of their personal information, to request additional details about our information practices, and to
                            not be discriminated against for exercising their rights.
                        </span>
                    </p>
                    <p>
                        <span>You may exercise your right to access, edit or delete information we have collected about you or your child by visiting <Link to="/account/do-not-sell-my-information/">https://boeiie.com/account/do-not-sell-my-information/</Link></span>
                        <span>
                            If you need further assistance regarding your rights, please contact us at privacy@boeiie.com, and we will consider your request in accordance with applicable laws. Note that even if we honor your request to delete your
                            information, we may retain certain information as required or permitted by law.
                        </span>
                    </p>
                    <p>
                        <span>
                            The CCPA sets forth certain obligations for businesses that &ldquo;sell&rdquo; PII. Based on the definition of &ldquo;sell&rdquo; under the CCPA and under current regulatory guidance, we do not believe we engage in such
                            activity.
                        </span>
                    </p>
                    <p>
                        <span>We do share certain information as stated in th</span><span>is</span>
                        <span>&nbsp;Privacy Policy and allow third parties to collect certain information about your activity, for example through cookies, as explained in </span><span>&lrm;Section 5</span><span>, </span><span>Cookie Notice</span>
                        <span>.</span>
                    </p>
                    <p><span>We do not sell personal information collected by or from any of the Services or portion of the Services that is directed to children or where we know that the user is a child under 16 years of age.</span></p>
                    <p>
                        <span>
                            For the portions of the Services that are directed to audiences older than 16, we do not sell identifying information such as name, email address, phone number, or postal address, but we may share identifiers such as cookies
                            and the advertising identifier associated with your mobile device with our advertising partners, and those partners may use and share those identifiers to show ads that are targeted to your interests.
                        </span>
                    </p>
                    <p>
                        <span>
                            If this takes place, these disclosures may constitute a &ldquo;sale&rdquo; under California law. Where we engage in this type of disclosure, we give you the option to opt out of disclosures for these purposes at any time by
                            following the &ldquo;
                        </span>
                        <span>Do Not Sell My Personal Information</span><span>&rdquo;</span>
                        <span>link (<Link to="/account/do-not-sell-my-information/">https://boeiie.com/account/do-not-sell-my-information/</Link>) in the footer or privacy page of the relevant website, mobile app, or other service.</span>
                    </p>
                    <p>
                        <span>
                            California residents who are under 18 years of age may request and obtain removal of any postings on the Services that they themselves post by emailing us at privacy@boeiie.com. Please also note that any requests for removal
                            do not ensure complete or comprehensive removal of the content or information from the Services for example, content that you or your child have posted that may be republished or reposted by another user or third party.
                        </span>
                    </p>
                    <ol start="3">
                        <li>
                            <h2><span>Exercising Your Rights</span></h2>
                        </li>
                    </ol>
                    <p>
                        <span>If would like to exercise any of your California Contact rights, please submit a request to </span><span>privacy@boeiie.com</span><span>&nbsp;or visit our&nbsp;&ldquo;Do Not Sell My Personal Information&rdquo; page at <Link to="/account/do-not-sell-my-information/">https://boeiie.com/account/do-not-sell-my-information/</Link>.</span>
                        <span>
                            You will be required to verify your identify before we fulfill your request. To do so, you will need to provide us with certain account information, such as the full name and email address you used to create your account and
                            your most recent activity with us. You can also designate an authorized agent to make a request on your behalf. To do so, you must provide us with written authorization for the agent to act on your behalf, however, you will
                            still need to verify your identity directly with us.
                        </span>
                    </p>
                    <ol start="4">
                        <li>
                            <h2><span>California Online Privacy Protection Act</span></h2>
                        </li>
                    </ol>
                    <p>
                        <span>Because we value your privacy we have taken the necessary precautions to be in compliance with the California Online Privacy Protection Act (&ldquo;</span><span>CalOPPA</span>
                        <span>&rdquo;) and thus will not distribute your personal information to outside parties without your consent or as specifically described above. We also agree that:</span>
                    </p>
                    <ul>
                        <li>
                            <span>users can visit our publicly available website anonymously;</span>
                        </li>
                        <li>
                            <span>we will minimally notify users of any changes to this policy as noted in </span><span>&lrm;Section 1</span><span>, </span><span>Changes to This Privacy Policy</span><span>;</span>
                        </li>
                        <li>
                            <span>users will be able to change their personal information by emailing us at the email in </span><span>&lrm;Section 12</span><span>;</span>
                        </li>
                        <li>

                                <span>we do not honor do not track signals because the functionality of our website requires use of cookies as noted in </span><span>&lrm;Section 2</span><span>,</span>
                                <span>&nbsp;Personal Information That We Collect</span><span>; and</span>

                        </li>
                        <li>
                            <span>we allow third-party behavioral tracking through the use of Analytics Technologies as noted in </span><span>&lrm;Section 5</span><span>, </span><span>Cookie Notice</span><span>.</span>
                        </li>
                    </ul>
                    <ol start="5">
                        <li>
                            <h2><span>California Shine the Light.</span></h2>
                        </li>
                    </ol>
                    <p><span>We do not sell or disclose personal information with third parties for their direct marketing purposes.</span></p>
                    <ol start="9">
                        <li>
                            <h1><span>Protecting Children</span></h1>
                        </li>
                    </ol>
                    <p><span>THIS SITE IS NOT INTENDED FOR CHILDREN UNDER 13 YEARS OF AGE.</span></p>
                    <p>
                        <span>
                            Boeiie does not knowingly collect PII from children under 13. If you are under 13, do not use or provide any information on this Services or provide any information about yourself to us, including your name, postal address,
                            telephone number, email address or any screen name or user name you may use.
                        </span>
                    </p>
                    <p>
                        <span>
                            If we learn we have collected or received PII from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13,
                            please contact us at the email in &ldquo;Contact Information&rdquo; below.
                        </span>
                    </p>
                    <ol start="1">
                        <li>
                            <h2><span>Children&#39;s Privacy</span></h2>
                        </li>
                    </ol>
                    <p>
                        <span>
                            We recognize the need to provide further privacy protections when it comes to children. Some of the Services are directed to children, while other portions of the Services are intended for use by adults and teens age 13 and
                            older.
                        </span>
                    </p>
                    <p>
                        <span>
                            For the Services that are directed to children or that allow children to submit personal information, we will collect, use, and disclose personal information as described in this Privacy Policy, with certain differences and
                            specific practices as described below.
                        </span>
                    </p>
                    <ol start="2">
                        <li>
                            <h2><span>Collection of Information from Children</span></h2>
                        </li>
                    </ol>
                    <p>
                        <span>
                            If we allow children to create an account or otherwise engage with us over time, we will obtain parental consent prior to the collection of their personal information. In order to obtain this consent, we sometimes ask your
                            child to provide a parent&#39;s email address so that we may directly notify you of our privacy practices and obtain your consent. When we do this, we delete the email address they provided if a parent does not timely
                            respond to the request for consent.
                        </span>
                    </p>
                    <p>
                        <span>
                            We may allow children to enter contests, sweepstakes, or other promotions, or receive a one-time communication from us. When we do that, we will collect the child&#39;s first name, email address, and sometimes their state of
                            residence, as well as a parent&#39;s email address to provide you with notice that your child has entered the promotion and an opportunity for you to opt your child out. We will use the collected information solely for that
                            promotion or communication, and then delete the information from our records once it is no longer required. We do not send text messages or other non-email (chat) messages to children.
                        </span>
                    </p>
                    <p>
                        <span>
                            On portions of the Services directed to children, we do not allow users to use social media sharing or login functions or use, or allow our partners to use, automatically collected information, such as IP addresses, cookie
                            identifiers, and other device identifiers, other than for purposes of supporting our internal operations, such as to provide children with access to features and activities on the Services, to customize content and improve
                            the Services, or serve contextual advertising or limit the number of times you or your child sees a particular advertisement.
                        </span>
                    </p>
                    <p><span>We do not allow interest-based advertising on portions of the Services that are directed to children or where we know that the user is a child or a teen under 16.</span></p>
                    <ol start="3">
                        <li>
                            <h2><span>Parental Controls</span></h2>
                        </li>
                    </ol>
                    <p>
                        <span>
                            At any time, you can refuse to permit us to collect personal information from your child and can request that we delete from our records the personal information we have collected. If you have any questions or concerns about
                            our approach to children&#39;s privacy, you want to access the data of your child, or request the deletion of your child&#39;s data, please contact us at privacy@boeiie.com.
                        </span>
                    </p>
                    <p><span>For information about how teens under 16 can request access or deletion of their personal information, please see </span><span>&lrm;Section 8</span><span>, </span><span>California Privacy Notice</span><span>.</span></p>
                    <ol start="10">
                        <li>
                            <h1><span>Access and Update Your Information</span></h1>
                        </li>
                    </ol>
                    <p>
                        <span>
                            Depending on the jurisdiction in which you reside, you may have the rights to access, correct, update, port, delete, restrict, or object to our processing of your PII; the right to complain to a data protection authority
                            about the collection and use of PII; and the right to withdraw your consent to our use of PII.
                        </span>
                    </p>
                    <p>
                        <span>You can manage your individual account and profile settings within the dashboard provided through the Services or you may contact us directly by emailing us at </span><span>privacy@boeiie.com</span>
                        <span>
                            . You can also manage information about your child within the dashboard provided through the Boeiie platform to assist you with responding to requests to access, correct, update, port or delete information that you receive
                            from your Contacts. You can also contact us at any time to update your own marketing preferences.
                        </span>
                    </p>
                    <p>
                        <span>
                            Upon your request, we will provide to you the PII under our control, if any, as well as information about the ways in which that information is being used and disclosed. We use reasonable efforts to ensure that PII we may be
                            using or disclosing is accurate and complete. If you demonstrate that your information is inaccurate or incomplete, the information will be amended as appropriate. We will use commercially reasonable efforts to respond to
                            any request and update your PII, if necessary. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect
                        </span>
                    </p>
                    <p>
                        <span>
                            If your PII is collected or processed on the basis of your consent, you, as a data subject, can withdraw your consent at any time. Withdrawing your consent, however, will not affect the lawfulness of any processing we
                            conducted prior to your withdrawal nor will it affect the processing of your PII conducted in reliance on lawful processing grounds other than consent.
                        </span>
                    </p>
                    <p>
                        <span>
                            We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws. We may ask you to verify your identity in order to help us respond
                            efficiently to your request.
                        </span>
                    </p>
                    <p><span>We take reasonable steps to ensure that the data we collect is reliable for its intended use, accurate, complete and up to date.</span></p>
                    <ol start="11">
                        <li>
                            <h1><span>Governing Law</span></h1>
                        </li>
                    </ol>
                    <p>
                        <span>
                            This Privacy Policy and any disputes related thereto shall be governed by and construed in accordance with the laws of the State of Tennessee, exclusive of its choice of law rules, whose courts shall have exclusive
                            jurisdiction over all disputes arising in connection with this Privacy Policy and the place of performance of this Privacy Policy is agreed by you to be the State of Tennessee.
                        </span>
                    </p>
                    <ol start="12">
                        <li>
                            <h1><span>Contact Information</span></h1>
                        </li>
                    </ol>
                    <p>
                        <span>If you have any questions about our information handling practices, including our practices with respect to you or your child&rsquo;s personal information with respect to the Services, please contact us at </span>
                        <span>privacy@boeiie.com</span><span>&nbsp;or write to us at Boeiie, LLC, 41 Peabody Street, Nashville, TN 37210 Attn: Legal.</span>
                    </p>
                    <p><span></span></p>
                    <p><span></span></p>
                    <p><span>-- End of Privacy Policy --</span></p>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
