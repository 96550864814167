export const LOGIN_USER_SUBMIT_START = 'login-user-submit-start';
export const LOGIN_USER_SUBMIT_SUCCESS = 'login-user-submit-success';
export const LOGIN_USER_SUBMIT_FAIL = 'login-user-submit-fail';

export const loginUserSubmitStart = () => ({
    type: LOGIN_USER_SUBMIT_START
});

export const loginUserSubmitSuccess = (authUser) => ({
    type: LOGIN_USER_SUBMIT_SUCCESS,
    authUser
});

export const loginUserSubmitFail = (errors) => ({
    type: LOGIN_USER_SUBMIT_FAIL,
    errors
});
