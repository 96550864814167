export const EDIT_GROUP_START = 'edit-group-start';
export const EDIT_GROUP_SUCCESS = 'edit-group-success';
export const EDIT_GROUP_FAIL = 'edit-group-fail';

export const editGroupStart = () => ({
    type: EDIT_GROUP_START
});

export const editGroupSuccess = (group) => ({
    type: EDIT_GROUP_SUCCESS,
    group,
});

export const editGroupFail = (errors) => ({
    type: EDIT_GROUP_FAIL,
    errors
});
