import queryString from 'query-string';
import {connect} from 'react-redux';

import {loadMyPosts} from '../../common/action_creators/posts';
import {
    createReaction,
    removeReaction
} from '../../common/action_creators/reactions';
import {
    fetchApiGet,
    fetchApiPost
} from '../../common/utils/api';
import {
    getDemoPosts,
    getDemoNotifications
} from '../../common/utils/demo';
import {isDemo} from '../../common/utils/routing';

import {
    loadNotificationsStart,
    loadNotificationsSuccess,
    loadNotificationsFail,
    viewNotificationStart,
    viewNotificationSuccess,
    viewNotificationFail,
} from './actions';
import Activity from './Activity';
import {POSTS_PAGE_KEY} from './constants';


const loadNotifications = (queryParams={}) => (dispatch) => {
    let url = 'notifications/';
    let hasParams = Object.keys(queryParams).length;

    if (hasParams) {
        url = `notifications/?${queryString.stringify(queryParams)}`;
    }

    if (isDemo()) {
        return Promise.resolve();
    }

    dispatch(loadNotificationsStart());

    return fetchApiGet(url)
        .then(({results: notifications, next, previous}) => (
            dispatch(loadNotificationsSuccess(notifications, next, previous))
        ))
        .catch(({errors}) => (
            dispatch(loadNotificationsFail(errors))
        ));
};

const loadPaginatedActivity = (queryParams={}, activityLabel='posts') => (dispatch) => {
    if (activityLabel === 'posts' && !isDemo()) {
        return dispatch(loadMyPosts(queryParams));
    } else if (activityLabel === 'notifications' && !isDemo()) {
        return dispatch(loadNotifications(queryParams));
    }
};

const initializePage = (pageKey) => (dispatch) => {
    if (pageKey === POSTS_PAGE_KEY) {
        dispatch(loadMyPosts());
    } else {
        dispatch(loadNotifications());
    }
};

const addReaction = (emoji, postId) => (dispatch, getState) => {
    let state = getState();
    let authUserId = state.app.authUser['user_id'];

    return dispatch(createReaction('post', postId, emoji, authUserId));
};

const deleteReaction = (reactionId, postId, targetEntityType='post') => (dispatch, getState) => {
    let state = getState();
    let authUserId = state.app.authUser['user_id'];

    return dispatch(removeReaction(reactionId, postId, authUserId, targetEntityType));
};

const viewNotification = (notificationId) => (dispatch) => {
    dispatch(viewNotificationStart());

    return fetchApiPost('view_notification/', {notification: notificationId})
        .then(({notification}) => dispatch(viewNotificationSuccess(notification)))
        .catch((errors) => dispatch(viewNotificationFail(errors)));
};


const mapStateToProps = (state) => {
    let {
        posts,
        postsNextLink,
        postsPreviousLink,
        notifications,
        notificationsNextLink,
        notificationsPreviousLink
    } = state.activity;
    let {authUser={}} = state.app;
    let postContent;
    let notificationsContent;

    if (isDemo()) {
        postContent = getDemoPosts();
        posts = postContent.results;
        postsNextLink = null;
        postsPreviousLink = null;

        notificationsContent = getDemoNotifications();
        notifications = notificationsContent.results;
        notificationsNextLink = null;
        notificationsPreviousLink = null;
    }

    return {
        authUser,
        posts,
        postsNextLink,
        postsPreviousLink,
        notifications,
        notificationsNextLink,
        notificationsPreviousLink
    };
};

const mapDispatchToProps = (dispatch) => ({
    onPageLoad: (pageKey) => dispatch(initializePage(pageKey)),
    handleAddReaction: (emoji, postId) => dispatch(addReaction(emoji, postId)),
    handleRemoveReaction: (reactionId, postId, targetEntityType) => dispatch(deleteReaction(reactionId, postId, targetEntityType)),
    handleViewNotification: (notificationId) => dispatch(viewNotification(notificationId)),
    onNotificationsLoad: () => dispatch(loadNotifications()),
    onMyPostsLoad: () => dispatch(loadMyPosts()),
    onEditPostSuccess: () => dispatch(loadMyPosts()),
    onDeletePostSuccess: () => dispatch(loadMyPosts()),
    onPaginationClick: (queryParams, activityLabel) => dispatch(loadPaginatedActivity(queryParams, activityLabel))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Activity);
