export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);


export const isRequired = (value) => value ? undefined : 'This field is required.';


export const maxLength = (max, valueTitle) => (value) => {
    let message = `Length of value must be less than ${max} characters.`;

    if (valueTitle) {
        message = `Length of ${valueTitle} must be less than ${max} characters.`;
    }

    if (value && value.length > max) {
        return message;
    }

    return undefined;
};


export const minLength = (min, valueTitle) => (value) => {
    let message = `Length of value must be greater than ${min} characters.`;

    if (valueTitle) {
        message = `Length of ${valueTitle} must be greater than ${min} characters.`;
    }

    if (value && value.length < min) {
        return message;
    }

    return undefined;
};

export const isEmail = (email) => {
    // eslint-disable-next-line
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let message = 'This is not a valid email.';

    if (re.test(email)) {
        return undefined
    }

    return message;
}

/*
 * Source: https://www.regextester.com/93652
 */
export const isURL = (url) => {
    // eslint-disable-next-line
    let re_weburl = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/ig;

    return re_weburl.test(url);
};

export const mapErrorsToForm = (errors={}) => {
    if (!errors || Object.keys(errors).length === 0) {
        return {};
    }

    let formFields = Object.keys(errors);
    let errorMap = {};

    formFields.forEach((errorKey) => {
        errorMap[errorKey] = errors[errorKey][0];
    });

    return errorMap;
};

export const listSubmitErrors = (submitErrors={}) => {
    if (!submitErrors) {
        return [];
    }

    let formFields = Object.keys(submitErrors);
    let errorStrings = [];

    formFields.forEach((field) => {
        errorStrings.push(`${submitErrors[field]}`);
    });

    return errorStrings;
};
