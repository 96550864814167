import {connect} from 'react-redux';
import _ from 'underscore';

import {initializeCategories} from '../../common/actions/categories';
import {
    loadMyCategories,
    createCategoryUserPreferences,
    removeCategoryUserPreferences,
} from '../../common/action_creators/categories';
import {
    getMyCategoriesFromState,
    transformCategoryPreferencesToCategories,
} from '../../common/utils/categories'

import CategoryPreferences from './CategoryPreferences';


const initializePage = (dispatch) => {
    dispatch(initializeCategories());
    dispatch(loadMyCategories());
};

const handleCategorySelect = (category) => (dispatch, getState) => {
    let {categories} = getState();
    let categoryPreferences = getMyCategoriesFromState(categories);
    let preference = _.find(categoryPreferences, (preference) => (
        preference.topic.id === category.id
    ));

    if (preference) {
        dispatch(removeCategoryUserPreferences(preference));
    } else {
        dispatch(createCategoryUserPreferences([{topic: category.id}]));
    }
};

const mapStateToProps = ({categories, app}) => {
    let categoryPreferences = getMyCategoriesFromState(categories);
    let myCategories = transformCategoryPreferencesToCategories(categoryPreferences);
    let authUser = app.authUser;
    let isAuthenticated = !_.isEmpty(authUser);

    return {
        categoryPreferences: myCategories,
        isAuthenticated,
        isVerified: isAuthenticated && !!authUser.is_verified,
    };
};

const mapDispatchToProps = (dispatch) => ({
    handlePageMount: () => dispatch(initializePage),
    handleCategorySelect: (category) => dispatch(handleCategorySelect(category)),
});

export default connect(
mapStateToProps,
mapDispatchToProps
)(CategoryPreferences);
