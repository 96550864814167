import _ from 'lodash';
import {
    LOAD_GROUP_ADMINS_START,
    LOAD_GROUP_ADMINS_SUCCESS,
    LOAD_GROUP_ADMINS_FAIL,
    LOAD_GROUP_MEMBERS_START,
    LOAD_GROUP_MEMBERS_SUCCESS,
    LOAD_GROUP_MEMBERS_FAIL,
    JOIN_GROUP_START,
    JOIN_GROUP_SUCCESS,
    JOIN_GROUP_FAIL,
    UNJOIN_GROUP_START,
    UNJOIN_GROUP_SUCCESS,
    UNJOIN_GROUP_FAIL,
    CLEAR_GROUP_ERROR_STATE,
} from '../../common/actions/groups';

import {
    INITIALIZE_GROUP_MEMBERS,
    REMOVE_GROUP_MEMBER_START,
    REMOVE_GROUP_MEMBER_SUCCESS,
    REMOVE_GROUP_MEMBER_FAIL,
    UPDATE_GROUP_MEMBER_ROLE_START,
    UPDATE_GROUP_MEMBER_ROLE_SUCCESS,
    UPDATE_GROUP_MEMBER_ROLE_FAIL,
} from './actions';


const initializeGroupMembersReducer = (state) => ({
    admins: [],
    adminNextPaginationPage: null,
    membersNextPaginationPage: null,
    members: [],
    errors: null
});

const loadGroupAdminsStartReducer = (state) => ({
    ...state,
});

const loadGroupAdminsSuccessReducer = (state, {admins, adminNextPaginationPage}) => {
    let stateAdmins = state.admins;

    if (!stateAdmins) {
        stateAdmins = [];
    }

    return {
        ...state,
        admins: [...stateAdmins, ...admins],
        adminNextPaginationPage,
        errors: null,
    };
};

const loadGroupAdminsFailReducer = (state, {errors}) => ({
    ...state,
    errors
});

const loadGroupMembersStartReducer = (state) => ({
    ...state,
});

const loadGroupMembersSuccessReducer = (state, {members, membersNextPaginationPage}) => {
    let stateMembers = state.members;

    if (!stateMembers) {
        stateMembers = [];
    }

    return {
        ...state,
        members: [...stateMembers, ...members],
        membersNextPaginationPage,
        errors: null,
    };
};

const loadGroupMembersFailReducer = (state, {errors}) => ({
    ...state,
    errors
});

const joinGroupStartReducer = (state) => ({
    ...state,
    members: _.isEmpty(state.members) ? [] : state.members,
});

const joinGroupSuccessReducer = (state, {member}) => ({
    ...state,
    members: [member, ...state.members],
    errors: null,
});

const joinGroupFailReducer = (state, {errors}) => ({
    ...state,
    errors
});

const unjoinGroupStartReducer = (state) => ({
    ...state,
    members: _.isEmpty(state.members) ? [] : state.members
});

const unjoinGroupSuccessReducer = (state, {member: unjoinedMember}) => ({
    ...state,
    members: _.filter(state.members, (member) => member.id !== unjoinedMember.id),
    errors: null,
});

const unjoinGroupFailReducer = (state, {errors}) => ({
    ...state,
    errors
});

const removeGroupMemberStartReducer = (state) => ({
    ...state
});

const removeGroupMemberSuccessReducer = (state, {member: removedMember}) => ({
    ...state,
    members: _.filter(state.members, (member) => member.id !== removedMember.id),
    errors: null,
});

const removeGroupMemberFailReducer = (state, {errors}) => ({
    ...state,
    errors
});

const updateGroupMemberRoleStartReducer = (state) => ({
    ...state
});

const updateGroupMemberRoleSuccessReducer = (state, {members: updatedMembers}) => {
    updatedMembers.forEach((updatedMember) => {
        const ADMIN_ROLES = ['admin', 'primary_admin'];

        if (ADMIN_ROLES.includes(updatedMember.role)) {
            let memberIndex = _.findIndex(state.members, (member) => member.id === updatedMember.id);
            let memberPreviousState = state.members[memberIndex];

            if (!memberPreviousState) {
                memberIndex = _.findIndex(state.admins, (member) => member.id === updatedMember.id);
                memberPreviousState = state.admins[memberIndex];
            }

            if (ADMIN_ROLES.includes(memberPreviousState.role)) {
                state.admins[memberIndex] = updatedMember;
            } else {
                state.admins = [updatedMember, ...state.admins];
                state.members = _.filter(state.members, (member) => member.id !== updatedMember.id);
            }

        } else {
            let memberIndex = _.findIndex(state.admins, (member) => member.id === updatedMember.id);
            let memberPreviousState = state.admins[memberIndex];

            if (memberIndex < 0) {
                memberIndex = _.findIndex(state.members, (member) => member.id === updatedMember.id);
                memberPreviousState = state.members[memberIndex];
            }

            if (ADMIN_ROLES.includes(memberPreviousState.role)) {
                state.members = [updatedMember, ...state.members];
                state.admins = _.filter(state.admins, (member) => member.id !== updatedMember.id);
            } else {
                state.members[memberIndex] = updatedMember;
            }
        }
    });

    return {
        ...state,
        errors: null,
    };
};

const updateGroupMemberRoleFailReducer = (state, {errors}) => ({
    ...state,
    errors
});

const clearGroupErrorStateReducer = (state) => ({
    ...state,
    errors: null
});

const GROUP_MEMBERS_REDUCER_MAP = {
    [INITIALIZE_GROUP_MEMBERS]: initializeGroupMembersReducer,
    [LOAD_GROUP_ADMINS_START]: loadGroupAdminsStartReducer,
    [LOAD_GROUP_ADMINS_SUCCESS]: loadGroupAdminsSuccessReducer,
    [LOAD_GROUP_ADMINS_FAIL]: loadGroupAdminsFailReducer,
    [LOAD_GROUP_MEMBERS_START]: loadGroupMembersStartReducer,
    [LOAD_GROUP_MEMBERS_SUCCESS]: loadGroupMembersSuccessReducer,
    [LOAD_GROUP_MEMBERS_FAIL]: loadGroupMembersFailReducer,
    [JOIN_GROUP_START]: joinGroupStartReducer,
    [JOIN_GROUP_SUCCESS]: joinGroupSuccessReducer,
    [JOIN_GROUP_FAIL]: joinGroupFailReducer,
    [UNJOIN_GROUP_START]: unjoinGroupStartReducer,
    [UNJOIN_GROUP_SUCCESS]: unjoinGroupSuccessReducer,
    [UNJOIN_GROUP_FAIL]: unjoinGroupFailReducer,
    [REMOVE_GROUP_MEMBER_START]: removeGroupMemberStartReducer,
    [REMOVE_GROUP_MEMBER_SUCCESS]: removeGroupMemberSuccessReducer,
    [REMOVE_GROUP_MEMBER_FAIL]: removeGroupMemberFailReducer,
    [UPDATE_GROUP_MEMBER_ROLE_START]: updateGroupMemberRoleStartReducer,
    [UPDATE_GROUP_MEMBER_ROLE_SUCCESS]: updateGroupMemberRoleSuccessReducer,
    [UPDATE_GROUP_MEMBER_ROLE_FAIL]: updateGroupMemberRoleFailReducer,
    [CLEAR_GROUP_ERROR_STATE]: clearGroupErrorStateReducer,
};

const groupMembersReducer = (initialState={}, action={}) => {
    let reducer = GROUP_MEMBERS_REDUCER_MAP[action.type];
    let nextState = initialState;

    if (reducer) {
        nextState = reducer(initialState, action);
    }

    return nextState;
};

export default groupMembersReducer;
