export const INITIALIZE_COMPOSE_POST_FORM = 'initialize-compose-post-form';
export const CREATE_POST_START = 'create-post-start';
export const EDIT_POST_START = 'edit-post-start';

export const initializeComposePostForm = () => ({
    type: INITIALIZE_COMPOSE_POST_FORM
});

export const createPostStart = () => ({
    type: CREATE_POST_START,
});

export const editPostStart = (post) => ({
    type: EDIT_POST_START,
    post
});
