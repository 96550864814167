export const kebabCase = (str) => str.replace(/\s+/g, '-').toLowerCase();

export const capitalize = (str) => `${str[0].toUpperCase()}${str.slice(1)}`;

export const getCroppedText = (str, start=0, end=80) => {
    let result = '';

    if (str && (str.length > end)) {
        result = `${str.substring(start, end)}...`;
    } else if (str && (str.length < end)) {
        result = str;
    }

    return result;
};
