import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import GlobalHeader from '../../common/components/global_header/connector';
import PostCard from '../../common/components/post_card/connector';
import {generateNotificationRoute} from '../../common/utils/notifications';
import {
    isDemo,
    formatNavigationQuery,
} from '../../common/utils/routing';
import {getCroppedText} from '../../common/utils/strings';

import './Activity.css';
import {
    NOTIFICATIONS_PAGE_KEY,
    POSTS_PAGE_KEY,
} from './constants';


const determineNotificationPhrase = (notification, authUser={}) => {
    let phrase = {};

    if (notification.action_type === 'comment' && notification.target_entity_type === 'post'
        && !notification.action_entity.reply_to
    ) {
        let {
            actor,
            target_entity
        } = notification;
        let actorName = `${actor['first_name']} ${actor['last_name']}`;

        phrase['title'] = `${actorName} commented on the post:`;
        phrase['body'] = getCroppedText(target_entity.title);
    } else if (notification.action_type === 'comment' && notification.target_entity_type === 'comment'
        && notification.action_entity.reply_to
    ) {
        let {
            actor,
            action_entity,
        } = notification;
        let actorName = `${actor['first_name']} ${actor['last_name']}`;

        phrase['title'] = `${actorName} replied to your comment:`;
        phrase['body'] = getCroppedText(action_entity.body);

    } else if (notification.action_type === 'group-member-joined') {
        let {
            actor
        } = notification;
        let actorName = `${actor['first_name']} ${actor['last_name']}`;
        let group = notification['target_entity'];

        phrase['title'] = `${actorName} joined ${group.name}`;

    } else if (notification.action_type === 'group-member-role-assignment') {
        let {
            actor,
            action_entity,
        } = notification;
        let actorName = `${actor['first_name']} ${actor['last_name']}`;
        let group = notification['target_entity'];
        let roleMap = {
            'admin': 'Admin',
            'primary_admin': 'Primary Admin',
            'moderator': 'Moderator',
            'basic': 'Basic'
        };
        let role = roleMap[action_entity.role];

        if (authUser.user_id === actor.id && role) {
            phrase['title'] = `Your role was updated to ${role} in ${group.name}`;
        } else if (authUser.user_id === actor.id && !role) {
            phrase['title'] = `Your role was updated in ${group.name}`;
        } else if (role) {
            phrase['title'] = `${actorName}'s role was updated to ${role} in ${group.name}`;
        } else {
            phrase['title'] = `${actorName}'s role was updated in ${group.name}`;
        }

    } else if (notification.action_type === 'group-details-changed') {
        let {
            actor
        } = notification;
        let actorName = `${actor['first_name']} ${actor['last_name']}`;
        let group = notification['target_entity'];

        phrase['title'] = `${actorName} changed the group details of ${group.name}`;

    } else if (notification.action_type === 'group-new-post') {
        let {
            actor,
            action_entity,
        } = notification;
        let actorName = `${actor['first_name']} ${actor['last_name']}`;
        let group = notification['target_entity'];

        phrase['title'] = `${actorName} posted to ${group.name}`;
        phrase['body'] = getCroppedText(action_entity.title);

    } else if (notification.action_type === 'group-deleted') {
        let {
            actor
        } = notification;
        let actorName = `${actor['first_name']} ${actor['last_name']}`;
        let group = notification['target_entity'];

        phrase['title'] = `${actorName} deleted the ${group.name} group.`;
    } else if (notification.action_type === 'post-reaction') {
        let {
            actor,
            target_entity,
        } = notification;
        let actorName = `${actor['first_name']} ${actor['last_name']}`;

        phrase['title'] = `${actorName} reacted to the post:`;
        phrase['body'] = getCroppedText(target_entity.title);
    } else if (notification.action_type === 'comment-reaction') {
        let {
            actor,
            target_entity,
        } = notification;
        let actorName = `${actor['first_name']} ${actor['last_name']}`;

        phrase['title'] = `${actorName} reacted to the comment:`;
        phrase['body'] = getCroppedText(target_entity.body);
    }

    return phrase;
};

const PostList = ({
    authUser,
    posts=[],
    handleAddReaction,
    handleRemoveReaction,
    onEditPostSuccess,
    onDeletePostSuccess
}) => {
    let postList = posts.map((post, index) => {
        let {user={}} = post;
        let occupations = user['occupations'] || [];
        let occupation = occupations[0] || {}

        return (
            <div key={index} className="post-card-container">
                <PostCard
                    authUser={authUser}
                    post={post}
                    postId={post['id'].toString()}
                    title={post['title']}
                    description={post['body']}
                    author={user}
                    authorId={user.id}
                    authorName={`${user['first_name']} ${user['last_name']}`}
                    authorOccupation={`${occupation['position']} at ${occupation['company']}`}
                    authorProfileImageUrl={user['profile_image_url']}
                    postCreationDate={post['creation_date']}
                    tags={post['tags']}
                    group={post['group']}
                    photoUrl={post['photo']}
                    sharedLink={post['shared_link']}
                    numberOfComments={post['number_of_comments']}
                    metaData={post['meta_data']}
                    handleAddReaction={handleAddReaction}
                    handleRemoveReaction={handleRemoveReaction}
                    hideEditPostButton={authUser['user_id'] !== post.user.id}
                    hideDeletePostButton={authUser['user_id'] !== post.user.id}
                    hideHidePostButton={authUser['user_id'] === post.user.id}
                    onEditPostSuccess={onEditPostSuccess}
                    onDeletePostSuccess={onDeletePostSuccess}
                />
            </div>
        )
    });

    return postList;
};

const NotificationsList = ({
    notifications=[],
    authUser,
    handleViewNotification
}) => {
    let notificationsList = notifications.map((notification, index) => {
        let notificationPhrase = determineNotificationPhrase(notification, authUser);
        let notificationRoute = generateNotificationRoute(notification);
        let onClickAttribute = {};

        if (!notification.viewed) {
            onClickAttribute = {
                onClick: () => handleViewNotification(notification.id),
            };
        }

        let notificationCard = (
            <Link
                key={index}
                className={
                    `activity-card-container ${notification.viewed ? 'viewed' : ''}`
                }
                to={{
                    pathname: notificationRoute,
                    search: formatNavigationQuery()
                }}
                {...onClickAttribute}
            >
                <div className="activity-card">
                    <div className="activity-action-title">
                        <p>{notificationPhrase.title}</p>
                    </div>
                    <div className="activity-title">
                        <h3>{notificationPhrase.body}</h3>
                    </div>
                </div>
            </Link>
        );

        if (notification['target_entity_type'] === 'post') {
            if (isDemo) {
                return notificationCard;
            }

            let notificationLink = '';

            if (notification['target_entity']) {
                let notificationSlugHash = notification['target_entity']['slug_hash'];
                let notificationSlug = notification['target_entity']['slug'];

                notificationLink = `/post/${notificationSlugHash}/${notificationSlug}`;
            }

            return (
                <Link
                    key={index}
                    to={notificationLink}
                >
                    {notificationCard}
                </Link>
            );
        }

        return notificationCard;
    });

    return notificationsList;
};

export default class Activity extends Component {

    constructor(props) {
        super(props);

        this.state = {
            chosenPage: NOTIFICATIONS_PAGE_KEY,
            shouldShowEditPostForm: false,
        };
    }

    componentDidMount() {
        let {onPageLoad} = this.props;
        let {chosenPage} = this.state;

        if (!isDemo()) {
            onPageLoad(chosenPage);
        }
    }

    chosePage = (chosenPage) => {
        let {
            onMyPostsLoad,
            onNotificationsLoad,
        } = this.props;

        if (chosenPage === POSTS_PAGE_KEY) {
            onMyPostsLoad();
        } else {
            onNotificationsLoad();
        }

        this.setState({chosenPage});
    };

    handleEditPost = (post) => {
        this.setState({shouldShowEditPostForm: true});
    };

    handlePaginationButtonClick = (link, activityLabel) => {
        let {onPaginationClick} = this.props;

        if (link) {
            let url = new URL(link);
            let params = queryString.parse(url.search);

            onPaginationClick(params, activityLabel)
                .then(() => window.scroll(0, 0));
        }
    };

    render() {
        let {
            authUser,
            posts,
            postsNextLink,
            postsPreviousLink,
            notifications,
            notificationsNextLink,
            notificationsPreviousLink,
            handleAddReaction,
            handleRemoveReaction,
            handleViewNotification,
            onEditPostSuccess,
            onDeletePostSuccess,
        } = this.props;
        let {chosenPage} = this.state;
        let nextPaginationButton = null;
        let previousPaginationButton = null;

        if (postsNextLink) {
            nextPaginationButton = (
                <button
                    className="pagination-button"
                    onClick={() => this.handlePaginationButtonClick(postsNextLink, 'posts')}
                >
                    Next
                </button>
            );
        }

        if (postsPreviousLink) {
            previousPaginationButton = (
                <button
                    className="pagination-button"
                    onClick={() => this.handlePaginationButtonClick(postsPreviousLink, 'posts')}
                >
                    Previous
                </button>
            );
        }

        let activityList = (
            <div className="post-list-section">
                <div className="post-card-list-container">
                    <PostList
                        authUser={authUser}
                        posts={posts}
                        handleAddReaction={handleAddReaction}
                        handleRemoveReaction={handleRemoveReaction}
                        onEditPostSuccess={onEditPostSuccess}
                        onDeletePostSuccess={onDeletePostSuccess}
                    />
                </div>
                <div className="pagination">
                    <div>
                        {previousPaginationButton}
                    </div>
                    <div>
                        {nextPaginationButton}
                    </div>
                </div>
            </div>
        );

        if (chosenPage === NOTIFICATIONS_PAGE_KEY) {
            previousPaginationButton = null;
            nextPaginationButton = null;

            if (notificationsNextLink) {
                nextPaginationButton = (
                    <button
                        className="pagination-button"
                        onClick={() => this.handlePaginationButtonClick(notificationsNextLink, 'notifications')}
                    >
                        Next
                    </button>
                );
            }

            if (notificationsPreviousLink) {
                previousPaginationButton = (
                    <button
                        className="pagination-button"
                        onClick={() => this.handlePaginationButtonClick(notificationsPreviousLink, 'notifications')}
                    >
                        Previous
                    </button>
                );
            }

            activityList = (
                <div className="notifications-section">
                    <NotificationsList
                        notifications={notifications}
                        authUser={authUser}
                        handleViewNotification={handleViewNotification}
                    />
                    <div className="pagination">
                        <div>
                            {previousPaginationButton}
                        </div>
                        <div>
                            {nextPaginationButton}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div id="activity">
                <GlobalHeader />
                <div className="page-body-container">
                    <div className="header">
                        <h2 className="title">Recent Activity</h2>
                    </div>
                    <div className="body-content">
                        <div className="nav-bar">
                            <div
                                className={`nav-button ${chosenPage === NOTIFICATIONS_PAGE_KEY ? 'selected': null}`}
                                onClick={() => this.chosePage(NOTIFICATIONS_PAGE_KEY)}
                            >
                                Notifications
                            </div>
                            <div
                                className={`nav-button ${chosenPage === POSTS_PAGE_KEY ? 'selected': null}`}
                                onClick={() => this.chosePage(POSTS_PAGE_KEY)}
                            >
                                Your posts
                            </div>
                        </div>
                        <div className="activity-list">
                            {activityList}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Activity.propTypes = {
    authUser: PropTypes.object,
    notifications: PropTypes.array,
    posts: PropTypes.array,
    postsNextLink: PropTypes.string,
    postsPreviousLink: PropTypes.string,
    notificationsNextLink: PropTypes.string,
    notificationsPreviousLink: PropTypes.string,
    handleAddReaction: PropTypes.func.isRequired,
    handleRemoveReaction: PropTypes.func.isRequired,
    handleViewNotification: PropTypes.func.isRequired,
    onPageLoad: PropTypes.func,
    onNotificationsLoad: PropTypes.func,
    onMyPostsLoad: PropTypes.func,
    onEditPostSuccess: PropTypes.func,
    onDeletePostSuccess: PropTypes.func,
    onPaginationClick: PropTypes.func
};
