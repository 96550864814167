export const INITIALIZE_CATEGORIES = 'initialize-categories';

export const initializeCategories = () => ({
    type: INITIALIZE_CATEGORIES
});


export const CREATE_CATEGORY_PREFERENCES_START = 'create-category-preferences-start';
export const CREATE_CATEGORY_PREFERENCES_SUCCESS = 'create-category-preferences-success';
export const CREATE_CATEGORY_PREFERENCES_FAIL = 'create-category-preferences-fail';

export const createCategoryPreferencesStart = () => ({
    type: CREATE_CATEGORY_PREFERENCES_START
});

export const createCategoryPreferencesSuccess = (preferences) => ({
    type: CREATE_CATEGORY_PREFERENCES_SUCCESS,
    preferences,
});

export const createCategoryPreferencesFail = (errors) => ({
    type: CREATE_CATEGORY_PREFERENCES_FAIL,
    errors
});


export const REMOVE_CATEGORY_PREFERENCES_START = 'remove-category-preferences-start';
export const REMOVE_CATEGORY_PREFERENCES_SUCCESS = 'remove-category-preferences-success';
export const REMOVE_CATEGORY_PREFERENCES_FAIL = 'remove-category-preferences-fail';

export const removeCategoryPreferencesStart = () => ({
    type: REMOVE_CATEGORY_PREFERENCES_START
});

export const removeCategoryPreferencesSuccess = (preference) => ({
    type: REMOVE_CATEGORY_PREFERENCES_SUCCESS,
    preference,
});

export const removeCategoryPreferencesFail = (errors) => ({
    type: REMOVE_CATEGORY_PREFERENCES_FAIL,
    errors
});


export const LOAD_CATEGORIES_START = 'load-categories-start';
export const LOAD_CATEGORIES_SUCCESS = 'load-categories-success';
export const LOAD_CATEGORIES_FAIL = 'load-categories-fail';

export const loadCategoriesStart = () => ({
    type: LOAD_CATEGORIES_START
});

export const loadCategoriesSuccess = (categories) => ({
    type: LOAD_CATEGORIES_SUCCESS,
    categories,
});

export const loadCategoriesFail = (errors) => ({
    type: LOAD_CATEGORIES_FAIL,
    errors
});


export const LOAD_MY_CATEGORIES_START = 'load-my-categories-start';
export const LOAD_MY_CATEGORIES_SUCCESS = 'load-my-categories-success';
export const LOAD_MY_CATEGORIES_FAIL = 'load-my-categories-fail';

export const loadMyCategoriesStart = () => ({
    type: LOAD_MY_CATEGORIES_START
});

export const loadMyCategoriesSuccess = (categories) => ({
    type: LOAD_MY_CATEGORIES_SUCCESS,
    categories,
});

export const loadMyCategoriesFail = (errors) => ({
    type: LOAD_MY_CATEGORIES_FAIL,
    errors
});
