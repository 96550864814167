export const LOAD_UNREAD_NOTIFICATIONS_COUNT_START = 'load-unread-notifications-count-start';
export const LOAD_UNREAD_NOTIFICATIONS_COUNT_SUCCESS = 'load-unread-notifications-count-success';
export const LOAD_UNREAD_NOTIFICATIONS_COUNT_FAIL = 'load-unread-notifications-count-fail';

export const loadUnreadNotificationsCountStart = () => ({
    type: LOAD_UNREAD_NOTIFICATIONS_COUNT_START,
});

export const loadUnreadNotificationsCountSuccess = (unreadCount) => ({
    type: LOAD_UNREAD_NOTIFICATIONS_COUNT_SUCCESS,
    unreadCount
});

export const loadUnreadNotificationsCountFail = (errors) => ({
    type: LOAD_UNREAD_NOTIFICATIONS_COUNT_FAIL,
    errors
});
