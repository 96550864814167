import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {deletePost} from '../../action_creators/posts';
import {fetchApiPost} from '../../utils/api';

import {editPostStart} from '../compose_post_form/actions';

import {
    hidePostSubmitStart,
    hidePostSubmitSuccess,
    hidePostSubmitFail
} from '../../actions/posts';
import PostCard from './PostCard';


export const onSubmitHidePostForm = (postId, formValues) => (dispatch) => {
    dispatch(hidePostSubmitStart());

    return fetchApiPost(`posts/${postId}/hide_post/`, formValues)
        .then(({post={}}) => {
            dispatch(hidePostSubmitSuccess(post));

            return post;
        })
        .catch(({errors={}}) => {
            dispatch(hidePostSubmitFail(errors));

            return errors;
        });
};

const handleEditPost = (post, history) => (dispatch) => {
    dispatch(editPostStart(post));

    history.push('/compose_post/');
};

const mapStateToProps = (state, ownProps) => {
    let {
        postId,
        title,
        description,
        author,
        authorId,
        authorName,
        authorProfileImageUrl,
        authorOccupation,
        postCreationDate,
        tags,
        photoUrl,
        sharedLink,
        numberOfComments,
        metaData: {
            'reaction_summary': reactionSummary,
            'reaction_id': reactionId
        },
        handleAddReaction,
        hideEditPostButton,
        hideHidePostButton,
        hideDeletePostButton,
        onEditPostSuccess,
        onDeletePostSuccess,
        onHidePostSuccess,
    } = ownProps;

    return {
        postId,
        title,
        description,
        author,
        authorId,
        authorName,
        authorProfileImageUrl,
        authorOccupation,
        postCreationDate,
        tags,
        photoUrl,
        sharedLink,
        numberOfComments,
        metaData: {
            reactionSummary: [...reactionSummary],
            reactionId
        },
        handleAddReaction,
        hideEditPostButton,
        hideHidePostButton,
        hideDeletePostButton,
        onEditPostSuccess,
        onDeletePostSuccess,
        onHidePostSuccess,
    };
};

const mapDispatchToProps = (dispatch, {history}) => ({
    onSubmitHidePostForm: (postId, formValues) => (
        dispatch(onSubmitHidePostForm(postId, formValues))
    ),
    onDeletePost: (postId) => dispatch(deletePost(postId)),
    onEditPostButtonClick: (post) => (dispatch(handleEditPost(post, history)))

});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PostCard));
