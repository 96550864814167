import React, {useReducer, useState} from 'react';
import PropTypes from 'prop-types';
import {FORM_ERROR} from 'final-form';
import {DateTime} from 'luxon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Form, Field} from 'react-final-form';
import {
    Link,
    Redirect
} from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha"

import GlobalHeader from '../../common/components/global_header/connector';
import {
    MONTH_CHOICES,
    YEAR_CHOICES,
} from '../../common/constants/date';
import {getDaysOfMonthChoices} from '../../common/utils/datetime';
import {
    isEmail,
    isRequired,
    maxLength,
    minLength,
    composeValidators
} from '../../common/utils/validation';

import './Signup.css';


const validateBirthdate = (birthYear, birthMonth, birthDay) => {
    let message = '';
    let birthDateString = `${birthYear}-${birthMonth}-${birthDay}`;
    let birthDateObject = DateTime.fromFormat(birthDateString, 'yyyy-M-d');
    let difference = DateTime.local().diff(birthDateObject, 'years');
    let radix = 10;

    if (!(birthYear && birthMonth && birthDay)) {
        message = 'Must be at least 13 years-old to use this app.';
        return message;
    }

    if (parseInt(difference.toFormat('y'), radix) < 13) {
        message = 'Must be at least 13 years-old to use this app.';
    }

    return message;
};

const validateSignupForm = (formValues) => {
    let errors = {};

    if (formValues.email && (formValues.email !== formValues.confirm_email)) {
        errors.confirm_email = 'Must match email';
    }

    if (formValues.password && (formValues.password !== formValues.confirm_password)) {
        errors.confirm_password = 'Must match password';
    }

    let birthDateValidation = validateBirthdate(
        formValues.birth_year,
        formValues.birth_month,
        formValues.birth_day
    );

    if (birthDateValidation) {
        errors.birth_month = birthDateValidation;
    }

    return errors;
};

const initialState = {
    'birth_month': 1
};

function reducer(state, {field, value}) {
    return {
        ...state,
        [field]: value
    };
}

const SignupForm = ({
    handleCaptchaResponse,
    submitErrors,
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const handleBirthMonthChange = (e, callBack) => {
        let field = e.currentTarget.name;
        let month = e.currentTarget.value;

        dispatch({field, value: parseInt(month, 10)});
        callBack(e);
    };

    return (
        <form className="login-form">
            <Field
                name="first_name"
                validate={composeValidators(isRequired, maxLength(30, 'first name'))}
            >
                {
                    ({input, meta}) => (
                        <div>
                            <input {...input} className="form-input" placeholder="First name" />
                            {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                        </div>
                    )
                }
            </Field>
            <Field
                name="last_name"
                validate={composeValidators(isRequired, maxLength(30, 'last name'))}
            >
                {
                    ({input, meta}) => (
                        <div>
                            <input {...input} className="form-input" placeholder="Last name" />
                            {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                        </div>
                    )
                }
            </Field>
            <Field
                name="email"
                validate={composeValidators(isRequired, isEmail, maxLength(50, 'email'))}
            >
                {
                    ({input, meta}) => (
                        <div>
                            <input {...input} className="form-input" placeholder="Email" />
                            {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                        </div>
                    )
                }
            </Field>
            <Field
                name="confirm_email"
                validate={composeValidators(isRequired, isEmail, maxLength(50, 'email'))}
            >
                {
                    ({input, meta}) => (
                        <div>
                            <input {...input} className="form-input" placeholder="Confirm email" />
                            {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                        </div>
                    )
                }
            </Field>
            <p className="label">Birthdate</p>
            <div className="birthdate-selector-container">
                <div className="field-container">
                    <Field
                        name="birth_month"
                        validate={isRequired}
                    >
                        {
                            ({input, meta}) => {
                                return (
                                    <div>
                                        <select
                                            name={input.name}
                                            onBlur={input.onBlur}
                                            onFocus={input.onFocus}
                                            onChange={(e) => handleBirthMonthChange(e, input.onChange)}
                                            value={state.birth_month}
                                        >
                                            {MONTH_CHOICES.map((choice) => <option key={choice.value} value={choice.value}>{choice.display}</option>)}
                                        </select>
                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                    </div>
                                );
                            }

                        }
                    </Field>
                </div>
                <div className="field-container">
                    <Field
                        name="birth_day"
                    >
                        {
                            ({input, meta}) => (
                                <div>
                                    <select {...input}>
                                        {getDaysOfMonthChoices(state.birth_month).map((choice) => <option key={choice.value} value={choice.value}>{choice.display}</option>)}
                                    </select>
                                    {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                </div>
                            )
                        }
                    </Field>
                </div>
                <div className="field-container">
                    <Field
                        name="birth_year"
                        validate={composeValidators(isRequired)}
                    >
                        {
                            ({input, meta}) => (
                                <div>
                                    <select {...input}>
                                        {YEAR_CHOICES.map((choice) => <option key={choice.value} value={choice.value}>{choice.display}</option>)}
                                    </select>
                                    {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                </div>
                            )
                        }
                    </Field>
                </div>
            </div>
            <Field
                name="password"
                validate={composeValidators(isRequired, minLength(8, 'password'), maxLength(50, 'password'))}
            >
                {
                    ({input, meta}) => (
                        <div>
                            <input {...input} type="password" className="form-input" placeholder="Password" />
                            {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                        </div>
                    )
                }
            </Field>
            <Field
                name="confirm_password"
                validate={composeValidators(isRequired, minLength(8, 'confirm password'), maxLength(50, 'confirm password'))}
            >
                {
                    ({input, meta}) => (
                        <div>
                            <input {...input} type="password" className="form-input" placeholder="Confirm password" />
                            {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                        </div>
                    )
                }
            </Field>
            <div>
                <div>
                    <Field
                        name="accepted_privacy_policy"
                        type="checkbox"
                        validate={isRequired}
                    >
                        {
                    ({input, meta}) => (
                            <div>
                                <div>
                                    <label
                                        htmlFor="accepted-privacy-policy"
                                    >
                                        I accept the Privacy Policy.
                                    </label>
                                    <input {...input} id="accepted-privacy-policy" type="checkbox" />
                                </div>
                                {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                            </div>
                        )
                    }
                    </Field>
                </div>
                <p>
                    <Link
                        to="/privacy-policy/"
                        target="_blank"
                    >
                        Click to review the Privacy Policy
                    </Link>
                </p>
            </div>
            <div>
                <div>
                    <Field
                        name="accepted_terms_of_service"
                        type="checkbox"
                        validate={isRequired}
                    >
                        {
                    ({input, meta}) => (
                            <div>
                                <div>
                                    <label
                                        htmlFor="accepted-tos"
                                    >
                                        I accept the Terms of Service policy.
                                    </label>
                                    <input {...input} id="accepted-tos" type="checkbox" />
                                </div>
                                {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                            </div>
                        )
                    }
                    </Field>
                </div>
                <p>
                    <Link
                        to="/terms-of-service/"
                        target="_blank"
                    >
                        Click to review the Terms of Service policy
                    </Link>
                </p>
            </div>
            <div>
                <div>
                    <Field
                        name="accepted_acceptable_use_policy"
                        type="checkbox"
                        validate={isRequired}
                    >
                        {
                    ({input, meta}) => (
                            <div>
                                <div>
                                    <label
                                        htmlFor="accepted-aup"
                                    >
                                        I accept the Acceptable Use policy.
                                    </label>
                                    <input {...input} id="accepted-aup" type="checkbox" />
                                </div>
                                {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                            </div>
                        )
                    }
                    </Field>
                </div>
                <p>
                    <Link
                        to="/acceptable-use/"
                        target="_blank"
                    >
                        Click to review the Acceptable Use policy
                    </Link>
                </p>
            </div>
            <div>
                <ReCAPTCHA
                    sitekey="6LfbTtUZAAAAAMw3lE0Yfa8oE-we-SJqdAmaazup"
                    onChange={handleCaptchaResponse}
                />
            </div>
            {submitErrors && <span className="error-text">{submitErrors}</span>}
        </form>
    );
};

function Signup({
    isAuthenticated,
    onSubmit
}) {
    const [captchaResponseToken, setCaptchaResponseToken] = useState('');

    if (isAuthenticated) {
        return <Redirect to="/" />
    }

    let submit;

    function handleSignup(formValues) {
        return onSubmit(formValues)
            .then((results) => {
                if (results[FORM_ERROR]) {
                    return results;
                }
            });
    }

    function handleCaptchaResponse(token) {
        setCaptchaResponseToken(token);
    };

    return (
        <div id="signup">
            <GlobalHeader />
            <div className="page-body-container">
                <h2>Create an account</h2>
                <Form
                    onSubmit={(formValues) => handleSignup({...formValues, captchaResponseToken})}
                    validate={validateSignupForm}
                    render={({handleSubmit, submitErrors, ...rest}) => {
                        submit = handleSubmit;

                        return (
                            <SignupForm
                                submitErrors={submitErrors && submitErrors[FORM_ERROR]}
                                handleCaptchaResponse={handleCaptchaResponse}
                            />
                        );
                    }}
                />

                <div className="submit-button-row">
                    <h2 className="submit-form-title">Sign up</h2>
                    <div
                        className="submit-button"
                    >
                        <button onClick={(e) => submit(e)}>
                            <div className="signup-icon">
                                <FontAwesomeIcon icon="arrow-right" color="#fff" size="2x" />
                            </div>
                        </button>
                    </div>
                </div>

                <div className="extra-links-container">
                    <Link
                        className="form-link"
                        to="/login/"
                    >
                        Have an account? Login
                    </Link>
                </div>

            </div>
        </div>
    );
}

Signup.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default Signup;
