import {FORM_STATE_CHANGE} from './actions';


const formStateChangeReducer = (state, {formState}) => ({
    formState
});

const PROFILE_FORM_REDUCER_MAP = {
    [FORM_STATE_CHANGE]: formStateChangeReducer,
};

const profileFormReducer = (initialState={}, action) => {
    let reducer = PROFILE_FORM_REDUCER_MAP[action.type];
    let nextState = initialState;

    if (reducer) {
        nextState = reducer(initialState, action);
    }

    return nextState;
};

export default profileFormReducer;
