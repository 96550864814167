import {DateTime} from 'luxon';

import {
    DAYS_PER_MONTH,
    NUMBER_TO_MONTH,
} from '../constants/date';

export const formatDateTime = (datetime) => {
    let luxonDateTime = DateTime.fromISO(datetime);
    let luxonDuration = luxonDateTime.diffNow('years');
    let duration = Math.floor(Math.abs(luxonDuration.years));
    let durationString = luxonDateTime.toLocaleString(DateTime.DATE_FULL);

    if (duration === 0) {
        luxonDuration = luxonDateTime.diffNow('months');
        duration = Math.floor(Math.abs(luxonDuration.months));
        durationString = `${luxonDateTime.monthLong} ${luxonDateTime.day}`;
    }

    if (duration === 0) {
        luxonDuration = luxonDateTime.diffNow('days');
        duration = Math.floor(Math.abs(luxonDuration.days));
        durationString = `${luxonDateTime.monthLong} ${luxonDateTime.day}`;

        if (duration === 1) {
            durationString = `Yesterday`;
        }
    }

    if (duration === 0) {
        luxonDuration = luxonDateTime.diffNow('hours');
        duration = Math.floor(Math.abs(luxonDuration.hours));
        durationString = `${duration} hours ago`;

        if (duration === 1) {
            durationString = `1 hour ago`;
        }
    }

    if (duration === 0) {
        luxonDuration = luxonDateTime.diffNow('minutes');
        duration = Math.floor(Math.abs(luxonDuration.minutes));
        durationString = `${duration} minutes ago`;

        if (duration === 1) {
            durationString = `1 minute ago`;
        }
    }

    if (duration === 0) {
        luxonDuration = luxonDateTime.diffNow('seconds');
        duration = Math.floor(Math.abs(luxonDuration.seconds));
        durationString = `${duration} seconds ago`;

        if (duration === 1) {
            durationString = `1 second ago`;
        }
    }

    return durationString;
};


export const getDaysOfMonthChoices = (month) => {
    let daysPerMonth = DAYS_PER_MONTH[NUMBER_TO_MONTH[month]];
    let choices = [];

    for (var i = 1; i <= daysPerMonth; i++) {
        choices.push({
            'display': `${i}`,
            'value': i
        });
    }

    return choices;
};
