import queryString from 'query-string';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import _ from 'underscore';

import {createPostStart} from '../../common/components/compose_post_form/actions';
import {loadPosts} from '../../common/action_creators/posts';

import PostListing from './PostListing';


const handlePaginationClick = (queryParams, history) => (dispatch) => {
    history.push(`/?${queryString.stringify(queryParams)}`);
};

const clearComposePostForm = (dispatch) => {
    dispatch(createPostStart());
};

const mapStateToProps = ({app}) => ({
    isAuthenticated: !_.isEmpty(app.authUser),
    isVerified: !_.isEmpty(app.authUser) && !!app.authUser.is_verified,
});

const mapDispatchToProps = (dispatch, {history}) => ({
    loadPosts: (queryParams) => dispatch(loadPosts(queryParams)),
    onCreatePostStart: () => dispatch(clearComposePostForm),
    onCreatePostSuccess: (queryParams) => dispatch(loadPosts(queryParams)),
    onEditPostSuccess: (queryParams) => dispatch(loadPosts(queryParams)),
    onDeletePostSuccess: (queryParams) => dispatch(loadPosts(queryParams)),
    onHidePostSuccess: (queryParams) => dispatch(loadPosts(queryParams)),
    onPaginationClick: (queryParams) => dispatch(handlePaginationClick(queryParams, history)),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PostListing));
