import React, {Component} from 'react';

import './Dropdown.css';


export default class Dropdown extends Component {
    render() {
        return (
            <div className="dropdown">
                {this.props.children}
            </div>
        );
    }
}
