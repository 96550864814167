import {connect} from 'react-redux';

import {initializeGroups} from '../../common/actions/groups';
import {loadMyGroups} from '../../common/action_creators/groups';
import {
    getMyGroupsFromState,
    getMyGroupsPaginationFromState
} from '../../common/utils/groups';

import MyGroups from './MyGroups';


const initializePage = (dispatch) => {
    dispatch(initializeGroups());
    dispatch(loadMyGroups());
};

const mapStateToProps = ({app, groups}) => {
    let {authUser={}} = app;
    let myGroups = getMyGroupsFromState(groups);
    let pagination = getMyGroupsPaginationFromState(groups);
    let hasMoreGroups = groups.myGroups && groups.myGroups.hasMoreGroups;

    return {
        authUser,
        groups: myGroups,
        isLoading: groups.isLoading,
        hasMoreGroups,
        pagination
    };
};

const mapDispatchToProps = (dispatch) => ({
    handlePageMount: () => dispatch(initializePage),
    handleLoadGroups: (params={}) => dispatch(loadMyGroups(params))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyGroups);
