import {connect} from 'react-redux';
import _ from 'underscore';

import {
    loadGroup,
    unjoinGroup,
    joinGroup,
    clearGroupError
} from '../../common/action_creators/groups';
import {fetchApiPost} from '../../common/utils/api';

import {
    editGroupStart,
    editGroupSuccess,
    editGroupFail,
} from './actions';
import GroupPage from './GroupPage';


const initializePage = (slugHash) => (dispatch) => {
    return dispatch(loadGroup(slugHash));
};

const editGroup = (slugHash, formValues) => (dispatch) => {
    dispatch(editGroupStart());
    let {categories, ...rest} = formValues;

    fetchApiPost(`groups/${slugHash}/categories/`, {categories});

    return fetchApiPost(`groups/${slugHash}/`, rest)
        .then(({group}) => {
            return dispatch(editGroupSuccess(group));
        })
        .catch((errors) => {
            return dispatch(editGroupFail(errors));
        });
};

const handleJoinGroup = (slugHash) => (dispatch) => {
    return dispatch(joinGroup(slugHash))
        .then(() => {
            return dispatch(loadGroup(slugHash));
        });
};

const handleUnjoinGroup = (slugHash) => (dispatch) => {
    return dispatch(unjoinGroup(slugHash))
        .then(() => {
            return dispatch(loadGroup(slugHash));
        });
};

const deleteGroup = (slugHash) => (dispatch) => {
    return fetchApiPost(`groups/${slugHash}/delete/`);
};

const translateServerError = (errors) => {
    let error = {};

    if (errors['UNJOIN_PRIMARY_ADMIN']) {
        error = {
            title: 'Cannot leave group',
            message: errors['UNJOIN_PRIMARY_ADMIN']
        }
    } else if (errors['CHANGE_PRIMARY_ADMIN_ROLE']) {
        error = {
            title: 'Cannot change primary admin role',
            message: errors['CHANGE_PRIMARY_ADMIN_ROLE']
        }
    }

    return error;
};

const mapStateToProps = (state) => {
    let {
        group={},
        errors: groupErrors,
    } = state.groupState;
    let {errors: memberErrors} = state.groupMembers;
    let {posts=[]} = state.postListing;
    let errors = groupErrors || memberErrors || {};
    let pageError = translateServerError(errors);
    let {authUser={}} = state.app;

    return {
        authUser,
        isAuthenticated: !_.isEmpty(authUser),
        isVerified: !_.isEmpty(authUser) && !!authUser.is_verified,
        group,
        posts,
        pageError,
    };
};

const mapDispatchToProps = (dispatch, {match: params}) => {
    let {slugHash} = params.params;

    return {
        onPageLoad: () => dispatch(initializePage(slugHash)),
        handleJoinGroup: () => dispatch(handleJoinGroup(slugHash)),
        handleUnjoinGroup: () => dispatch(handleUnjoinGroup(slugHash)),
        handleEditGroup: (formValues) => dispatch(editGroup(slugHash, formValues)),
        handleDeleteGroup: () => dispatch(deleteGroup(slugHash)),
        handleClearGroupError: () => dispatch(clearGroupError),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupPage);
