import {connect} from 'react-redux';

import {initializeGroups} from '../../common/actions/groups';
import {loadGroups} from '../../common/action_creators/groups';
import {
    getAllGroupsFromState,
    getAllGroupsPaginationFromState
} from '../../common/utils/groups';

import AllGroups from './AllGroups';


const initializePage = (dispatch) => {
    dispatch(initializeGroups());
    dispatch(loadGroups({
        me: 'false',
    }));
};

const mapStateToProps = ({groups}) => {
    let allGroups = getAllGroupsFromState(groups);
    let pagination = getAllGroupsPaginationFromState(groups);
    let hasMoreGroups = groups.allGroups && groups.allGroups.hasMoreGroups;

    return {
        groups: allGroups,
        hasMoreGroups,
        pagination,
    };
};

const mapDispatchToProps = (dispatch) => ({
    handlePageMount: () => dispatch(initializePage),
    handleLoadGroups: (params={}) => dispatch(loadGroups({...params, me: false})),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllGroups);
