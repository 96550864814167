import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import {formatNavigationQuery} from '../../utils/routing';
import {formatOccupation} from '../../utils/profile';

import PostCard from '../post_card/connector';
import PostCardCommentThread from '../post_card_comment_thread/connector';

import './PostCardList.css';


const PostList = ({
    authUser,
    posts,
    commentsForPosts,
    replyMap,
    handleAddReaction,
    handleRemoveReaction,
    onEditPostSuccess,
    onDeletePostSuccess,
    onHidePostSuccess,
    onLoadReplies,
}) => {
    let postList = posts.map((post, index) => {
        let {user={}} = post;
        let occupations = user['occupations'] || [];
        let occupation = occupations[0] || {};

        return (
            <div key={post.id} className="post-card-container">
                <PostCard
                    authUser={authUser}
                    post={post}
                    postId={post['id'].toString()}
                    title={post['title']}
                    description={post['body']}
                    author={user}
                    authorId={user.id}
                    authorName={`${user['first_name']} ${user['last_name']}`}
                    authorProfileImageUrl={user['profile_image_url']}
                    authorOccupation={formatOccupation(occupation['position'], occupation['company'])}
                    postCreationDate={post['creation_date']}
                    tags={post['tags']}
                    group={post['group']}
                    photoUrl={post['photo']}
                    sharedLink={post['shared_link']}
                    numberOfComments={post['number_of_comments']}
                    metaData={post['meta_data']}
                    handleAddReaction={handleAddReaction}
                    handleRemoveReaction={handleRemoveReaction}
                    hideEditPostButton={authUser['user_id'] !== user.id}
                    hideHidePostButton={authUser['user_id'] === user.id}
                    hideDeletePostButton={authUser['user_id'] !== user.id}
                    onEditPostSuccess={onEditPostSuccess}
                    onDeletePostSuccess={onDeletePostSuccess}
                    onHidePostSuccess={onHidePostSuccess}
                    onLoadReplies={onLoadReplies}
                />
                <div className="post-card-comment-thread-container">
                    <PostCardCommentThread
                        postId={post.id}
                        postSlugHash={post['slug_hash']}
                        authUser={authUser}
                    />
                </div>
            </div>
        )
    });

    return postList;
};

class PostCardList extends Component {
    static propTypes = {
        authUser: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        posts: PropTypes.array.isRequired,
        nextLink: PropTypes.string,
        previousLink: PropTypes.string,
        commentsForPosts: PropTypes.array.isRequired,
        replyMap: PropTypes.object.isRequired,
        queryParams: PropTypes.object,
        onLoad: PropTypes.func.isRequired,
        handlePageChange: PropTypes.func.isRequired,
        loadCommentsForPosts: PropTypes.func.isRequired,
        handleAddReaction: PropTypes.func.isRequired,
        onEditPostSuccess: PropTypes.func.isRequired,
        onDeletePostSuccess: PropTypes.func.isRequired,
        onHidePostSuccess: PropTypes.func.isRequired,
        onLoadReplies: PropTypes.func.isRequired,
    };

    componentDidMount() {
        let {
            history,
            queryParams={},
            onLoad,
            handlePageChange,
        } = this.props;
        let urlQueryParams = queryString.parse(history.location.search);
        let combinedQueryParams = {
            ...queryParams,
            ...urlQueryParams
        };

        onLoad(combinedQueryParams);

        this.unlistenToHistory = history.listen((location) => {
            urlQueryParams = queryString.parse(location.search);
            combinedQueryParams = {
                ...queryParams,
                ...urlQueryParams
            };

            handlePageChange(combinedQueryParams);
        });
    }

    componentWillUnmount() {
        this.unlistenToHistory();
    }

    handlePaginationClick = () => {
        let postList = document.getElementById('post-card-list');

        postList.scrollIntoView({block: 'start', behavior: 'smooth'});
    };

    render() {
        let {
            authUser,
            posts,
            nextLink,
            previousLink,
            commentsForPosts,
            replyMap,
            location,
            handleAddReaction,
            handleRemoveReaction,
            onEditPostSuccess,
            onDeletePostSuccess,
            onHidePostSuccess,
            onLoadReplies,
        } = this.props;
        let nextPaginationButton = null;
        let previousPaginationButton = null;
        let paginationSection = null;
        let nextURL;
        let nextPage;
        let previousURL;
        let previousPage;

        if (nextLink) {
            let currentQueryParams = queryString.parse(window.location.search);
            let paramsObject = {...currentQueryParams};

            nextURL = new URL(nextLink);
            nextPage = queryString.parse(nextURL.search)['page'];

            if (nextPage) {
                paramsObject['page'] = nextPage;
            }

            let nextSearchParams = formatNavigationQuery(paramsObject);

            nextPaginationButton = (
                <Link
                    className="pagination-button"
                    to={{
                       pathname: location.pathname,
                       search: nextSearchParams
                    }}
                    onClick={this.handlePaginationClick}
                >
                    Next
                </Link>
            );
        }

        if (previousLink) {
            let currentQueryParams = queryString.parse(window.location.search);
            let paramsObject = {...currentQueryParams};

            previousURL = new URL(previousLink);
            previousPage = queryString.parse(previousURL.search)['page'];

            if (previousPage) {
                paramsObject['page'] = previousPage;
            } else {
                paramsObject['page'] = 1;
            }

            let previousSearchParams = formatNavigationQuery(paramsObject);

            previousPaginationButton = (
                <Link
                    className="pagination-button"
                    to={{
                        pathname: location.pathname,
                        search: previousSearchParams
                    }}
                    onClick={this.handlePaginationClick}
                >
                    Previous
                </Link>
            );
        }

        if (nextLink || previousLink) {
            paginationSection = (
                <div className="pagination">
                    <div>
                        {previousPaginationButton}
                    </div>
                    <div>
                        {nextPaginationButton}
                    </div>
                </div>
            );
        }

        return (
            <div id="post-card-list">
                <PostList
                    authUser={authUser}
                    posts={posts}
                    commentsForPosts={commentsForPosts}
                    replyMap={replyMap}
                    handleAddReaction={handleAddReaction}
                    handleRemoveReaction={handleRemoveReaction}
                    onEditPostSuccess={onEditPostSuccess}
                    onDeletePostSuccess={onDeletePostSuccess}
                    onHidePostSuccess={onHidePostSuccess}
                    onLoadReplies={onLoadReplies}
                />
                {paginationSection}
            </div>
        );
    }
}

export default withRouter(PostCardList);
