import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSmile} from '@fortawesome/free-regular-svg-icons';
import Popper from '@material-ui/core/Popper';

import {
    EMOJI_MAP,
    EMOJI_API_LIST,
    EMOJI_TITLE,
} from '../../constants/emoji';
import {isMobileDevice} from '../../utils/device';

import AccountVerificationPrompt from '../account_verification_prompt/AccountVerificationPrompt';
import LoginPrompt from '../login_prompt/LoginPrompt';

import './EmojiSelector.css';


let setTimeoutId = null;

export default class EmojiSelector extends Component {
    static propTypes = {
        isVerified: PropTypes.bool.isRequired,
        isAuthenticated: PropTypes.bool.isRequired,
        currentReaction: PropTypes.object,
        handleAddReaction: PropTypes.func,
        handleRemoveReaction: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            loginPromptIsOpen: false,
            accountVerifyPromptIsOpen: false,
        };
    }

    componentWillUnmount() {
        clearTimeout(setTimeoutId);
    }

    handleMouseEnterPopperButton = (event) => {
        clearTimeout(setTimeoutId);
        this.setState({
            'anchorEl': event.currentTarget,
        });
    };

    handleMouseLeavePopperButton = (event) => {
        setTimeoutId = setTimeout(() => {
            this.setState({'anchorEl': null});
        }, 1000);
    };

    handleMouseEnterContainer = (event) => {
        clearTimeout(setTimeoutId);
    };

    handleMouseLeaveContainer = (event) => {
        setTimeoutId = setTimeout(() => {
            this.setState({'anchorEl': null});
        }, 1000);
    };

    handleTouchStartAnchorButton = (event) => {
        this.setState({'anchorEl': event.currentTarget});
    };

    handleTouchEndAnchorButton = (event) => {
        clearTimeout(setTimeoutId);
        setTimeoutId = setTimeout(() => {
            this.setState({'anchorEl': null});
        }, 3000);
    };

    handleTouchStartPopperContainer = (event) => {
        this.setState({'anchorEl': null});
    };

    handleTouchEndPopperContainer = (event) => {
        setTimeout(() => {
            this.setState({'anchorEl': null});
        }, 3000);
    };

    handleClickReactionIconSelector = (event, reactionId) => {
        let {
            isAuthenticated,
            handleRemoveReaction
        } = this.props;

        if (!isAuthenticated) {
            this.handleOpenLoginPrompt();
            return;
        }

        if (reactionId) {
            if (!isAuthenticated) {
                clearTimeout(setTimeoutId);
                this.setState({'anchorEl': null});
                return;
            }

            handleRemoveReaction(reactionId)
                .then(() => {
                    clearTimeout(setTimeoutId);
                    this.setState({'anchorEl': null});
                });
        } else {
            this.setState({'anchorEl': event.currentTarget});
            setTimeout(() => {
                clearTimeout(setTimeoutId);
                this.setState({'anchorEl': null});
            }, 3000);
        }
    };

    handleClickReaction = (emoji) => {
        let {
            isAuthenticated,
            isVerified,
            handleAddReaction
        } = this.props;

        if (!isAuthenticated) {
            this.handleOpenLoginPrompt();
            clearTimeout(setTimeoutId);
            this.setState({'anchorEl': null});
            return;
        }

        if (isAuthenticated && !isVerified) {
            this.handleOpenAccountVerifyPrompt();
            clearTimeout(setTimeoutId);
            this.setState({'anchorEl': null});
            return;
        }

        handleAddReaction(emoji)
            .then(() => {
                clearTimeout(setTimeoutId);
                this.setState({'anchorEl': null});
            });
    };

    handleCloseLoginPrompt = () => {
        this.setState({loginPromptIsOpen: false});
    };

    handleOpenLoginPrompt = () => {
        let {isAuthenticated} = this.props;

        if (!isAuthenticated) {
            this.setState({loginPromptIsOpen: true});
        }
    };

    handleCloseAccountVerifyPrompt = () => {
        this.setState({accountVerifyPromptIsOpen: false});
    };

    handleOpenAccountVerifyPrompt = () => {
        let {
            isAuthenticated,
            isVerified
        } = this.props;

        if (isAuthenticated && !isVerified) {
            this.setState({accountVerifyPromptIsOpen: true});
        }
    };;

    render() {
        let {currentReaction={}} = this.props;
        let {
            anchorEl,
            loginPromptIsOpen,
            accountVerifyPromptIsOpen
        } = this.state;

        const id = Boolean(anchorEl) ? 'simple-popper' : undefined;

        let emojiIcons = EMOJI_API_LIST.map((emoji, index) => (
            <button
                key={index}
                onClick={() => this.handleClickReaction(emoji)}
            >
                {EMOJI_MAP[emoji]}
            </button>
        ));

        let desktopAnchorButtonAttributes = {
            onMouseEnter: this.handleMouseEnterPopperButton,
            onMouseLeave: this.handleMouseLeavePopperButton,
            onClick: (event) => this.handleClickReactionIconSelector(event, currentReaction.reactionId),
        };
        let desktopPopperAttributes = {
            onMouseEnter: this.handleMouseEnterContainer,
            onMouseLeave: this.handleMouseLeaveContainer
        };
        let mobileAnchorButtonAttributes = {
            onClick: (event) => this.handleClickReactionIconSelector(event, currentReaction.reactionId),
        };
        let mobilePopperAttributes = {
            //onTouchStart: this.handleTouchStartPopperContainer,
            //onTouchEnd: this.handleTouchEndPopperContainer
        };
        let anchorButtonAttributes = isMobileDevice() ? mobileAnchorButtonAttributes : desktopAnchorButtonAttributes;
        let popperAttributes = isMobileDevice() ? mobilePopperAttributes : desktopPopperAttributes;

        return (
            <span className="emoji-selector">
                <button
                    aria-describedby={id}
                    type="button"
                    className="add-emoji-button"
                    {...anchorButtonAttributes}
                >
                    {
                        currentReaction.emojiType ?
                        <span
                            className="select-icon reaction-is-set"
                        >
                            <span className="emoji-symbol">{currentReaction.emojiType}</span>
                            <span
                                className={`emoji-title ${currentReaction.emojiTitle}`}
                            >
                                {EMOJI_TITLE[currentReaction.emojiTitle]}
                            </span>
                        </span> :
                        <span
                            className="select-icon"
                        >
                            <FontAwesomeIcon className="smile-icon" icon={faSmile} />
                            <FontAwesomeIcon className="plus-icon" icon="plus" size="sm" />
                        </span>
                    }
                </button>

                <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="top">
                    <div
                        className="add-emoji-container"
                        {...popperAttributes}
                    >
                        {emojiIcons}
                    </div>
                </Popper>
                <LoginPrompt
                    isOpen={loginPromptIsOpen}
                    title="Add your reaction to join the conversation."
                    subtitle="After logging in, you can share your reaction."
                    handleClose={this.handleCloseLoginPrompt}
                />
                <AccountVerificationPrompt
                    isOpen={accountVerifyPromptIsOpen}
                    title="Verify your account."
                    subtitle="In order to add a reaction, verify your account by clicking the button below, and visit the email."
                    handleClose={this.handleCloseAccountVerifyPrompt}
                />
            </span>
        );
    }
}
