import React, {Component} from 'react';
import PropTypes from 'prop-types';

import GlobalHeader from '../../common/components/global_header/connector';
import GroupsList from '../../common/components/groups_list/GroupsList';

import './MyGroups.css';


export default class MyGroups extends Component {
    static propTypes = {
        groups: PropTypes.array.isRequired,
        pagination: PropTypes.object,
        isLoading: PropTypes.bool,
        hasMoreGroups: PropTypes.bool,
        handlePageMount: PropTypes.func.isRequired,
        handleLoadGroups: PropTypes.func,
    };

    componentDidMount() {
        let {handlePageMount} = this.props;

        handlePageMount();
    };

    render() {
        let {
            groups,
            pagination,
            isLoading,
            hasMoreGroups,
            handleLoadGroups,
        } = this.props;

        return (
            <div id="my-groups">
                <GlobalHeader />
                <div className="page-body-container">
                    <h2 className="page-title">My Groups</h2>
                    <GroupsList
                        groups={groups}
                        pagination={pagination}
                        isLoading={isLoading}
                        hasMoreGroups={hasMoreGroups}
                        handleLoadGroups={handleLoadGroups}
                    />
                </div>
            </div>
        );
    }
}
