export const SAVE_POST_START = 'save-post-start';
export const SAVE_POST_SUCCESS = 'save-post-success';
export const SAVE_POST_FAIL = 'save-post-fail';

export const LOAD_POST_LISTING_START = 'load-post-list-start';
export const LOAD_POST_LISTING_SUCCESS = 'load-post-list-success';
export const LOAD_POST_LISTING_FAIL = 'load-post-list-fail';

export const LOAD_MY_POSTS_START = 'load-my-posts-start';
export const LOAD_MY_POSTS_SUCCESS = 'load-my-posts-success';
export const LOAD_MY_POSTS_FAIL = 'load-my-posts-fail';

export const DELETE_POST_START = 'delete-post-start';
export const DELETE_POST_SUCCESS = 'delete-post-success';
export const DELETE_POST_FAIL = 'delete-post-fail';


export const savePostStart = () => ({
    type: SAVE_POST_START
});

export const savePostSuccess = (post) => ({
    type: SAVE_POST_SUCCESS,
    post
});

export const savePostFail = (errors) => ({
    type: SAVE_POST_FAIL,
    errors
});

export const loadPostListingStart = (urlQueryParams) => ({
    type: LOAD_POST_LISTING_START,
    urlQueryParams
});

export const loadPostListingSuccess = (
    {results, next, previous},
    infiniteScrollEnabled = false
) => ({
    type: LOAD_POST_LISTING_SUCCESS,
    posts: results,
    nextLink: next,
    previousLink: previous,
    infiniteScrollEnabled,
});

export const loadPostListingFail = (errors) => ({
    type: LOAD_POST_LISTING_FAIL,
    errors
});

export const loadMyPostsStart = (tags=null) => ({
    type: LOAD_POST_LISTING_START
});

export const loadMyPostsSuccess = (posts, nextLink, previousLink) => ({
    type: LOAD_MY_POSTS_SUCCESS,
    posts,
    postsNextLink: nextLink,
    postsPreviousLink: previousLink
});

export const loadMyPostsFail = (errors) => ({
    type: LOAD_MY_POSTS_FAIL,
    errors
});

export const deletePostStart = () => ({
    type: DELETE_POST_START
});

export const deletePostSuccess = (post) => ({
    type: DELETE_POST_SUCCESS,
    post
});

export const deletePostFail = (error) => ({
    type: DELETE_POST_FAIL,
    error
});


export const HIDE_POST_SUBMIT_START = 'hide-post-submit-start';
export const HIDE_POST_SUBMIT_SUCCESS = 'hide-post-submit-success';
export const HIDE_POST_SUBMIT_FAIL = 'hide-post-submit-fail';


export const hidePostSubmitStart = () => ({
    type: HIDE_POST_SUBMIT_START
});

export const hidePostSubmitSuccess = (post) => ({
    type: HIDE_POST_SUBMIT_SUCCESS,
    post
});

export const hidePostSubmitFail = (errors) => ({
    type: HIDE_POST_SUBMIT_FAIL,
    errors
});
