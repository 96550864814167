import queryString from 'query-string';

import {
    createCommentStart,
    createCommentSuccess,
    editCommentStart,
    editCommentSuccess,
    editCommentFail,
    hideCommentStart,
    hideCommentSuccess,
    hideCommentFail,
    deleteCommentStart,
    deleteCommentSuccess,
    deleteCommentFail,
    loadCommentsForPostStart,
    loadCommentsForPostSuccess,
    loadCommentsForPostFail,
    loadCommentsFromPostsStart,
    loadCommentsFromPostsSuccess,
    loadCommentsFromPostsFail,
    loadRepliesStart,
    loadRepliesSuccess
} from '../actions/comments';
import {
    fetchApiGet,
    fetchApiPost,
} from '../utils/api';


export const loadCommentsForPost = (postId, postSlugHash, options={}) => (dispatch) => {
    let {limit, offset, order='desc'} = options;
    let urlOptions = {limit, offset};
    let url = `posts/${postSlugHash}/comments/?${queryString.stringify(urlOptions)}`;

    dispatch(loadCommentsForPostStart(postId));

    return fetchApiGet(url)
        .then(({comments, next, previous, total_count}) => {
            return dispatch(loadCommentsForPostSuccess(
                postId.toString(),
                comments,
                next,
                previous,
                total_count,
                order
            ));
        })
        .catch(({errors}) => {
            return dispatch(loadCommentsForPostFail(postId, errors));
        });
};

export const loadCommentsFromPosts = (postIds) => (dispatch) => {
    let url = `comments/?${queryString.stringify(
        {'posts': postIds.join('-')},
    )}`;

    dispatch(loadCommentsFromPostsStart(postIds));

    return fetchApiGet(url)
        .then(({posts_comments}) => {
            return dispatch(loadCommentsFromPostsSuccess(posts_comments))
        })
        .catch(({errors}) => {
            return dispatch(loadCommentsFromPostsFail(errors));
        });
};

export const loadReplies = (postId, postSlugHash, parentCommentId) => (dispatch) => {
    dispatch(loadRepliesStart(postId, parentCommentId));

    return fetchApiGet(`posts/${postSlugHash}/comments/${parentCommentId}/replies/`)
        .then(({comments}) => {
            dispatch(loadRepliesSuccess(postId, parentCommentId, comments));
        });
};

export const createComment = (postSlugHash, comment) => (dispatch) => {
    dispatch(createCommentStart());

    return fetchApiPost(`posts/${postSlugHash}/comments/`, comment)
        .then(({comment}) => {
            dispatch(createCommentSuccess(comment));

            return Promise.resolve(comment);
        });
};

export const editComment = (postId, commentId, value) => (dispatch) => {
    dispatch(editCommentStart());

    let data = {'body': value};

    return fetchApiPost(`comments/${commentId}/`, data)
        .then(({comment}) => {
            return dispatch(editCommentSuccess(comment));
        })
        .catch(({errors}) => {
            return dispatch(editCommentFail());
        });
};


export const deleteComment = (postId, commentId) => (dispatch) => {
    dispatch(deleteCommentStart());

    return fetchApiPost(`comments/${commentId}/delete/`)
        .then(({comment}) => {
            return dispatch(deleteCommentSuccess(comment));
        })
        .catch(({errors}) => {
            return dispatch(deleteCommentFail());
        });
};

export const hideComment = (postId, commentId, formValues) => (dispatch) => {
    dispatch(hideCommentStart());

    return fetchApiPost(`comments/${commentId}/hide_comment/`, formValues)
        .then(({comment}) => {
            return dispatch(hideCommentSuccess(comment));
        })
        .catch(({errors}) => {
            return dispatch(hideCommentFail());
        });
};

export const handleNewCommentFromSocket = (postId, comment) => (dispatch) => {
    let parentCommentId = comment['reply_to'];

    if (parentCommentId) {
        return dispatch(loadReplies(postId, parentCommentId));
    } else {
        return dispatch(createCommentSuccess(comment));
    }
};
