import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './GroupSummaryCard.css';


export default class GroupSummaryCard extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        description: PropTypes.string
    };

    render() {
        let {
            title,
            description
        } = this.props;
        let descriptionText = null;

        if (description) {
            descriptionText = (<p className="description">{description}</p>);
        }

        return (
            <div className="group-summary-card">
                <h3 className="title">{title}</h3>
                {descriptionText}
            </div>
        );
    }

}
