import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import _ from 'underscore';

import GlobalHeader from '../../common/components/global_header/connector';
import AccountVerificationPrompt from '../../common/components/account_verification_prompt/AccountVerificationPrompt';
import LoginPrompt from '../../common/components/login_prompt/LoginPrompt';
import PostCardList from '../../common/components/post_card_list/connector';
import {
    SOCKET,
    EMIT_EVENT_JOIN_ROOM,
    EMIT_EVENT_NEW_COMMENT,
} from '../../common/constants/realtime';
import {formatNavigationQuery} from '../../common/utils/routing';
import {capitalize} from '../../common/utils/strings';

import './PostListing.css';


const POST_FILTER_TYPES = [
    'all',
    'relevant',
    'none'
];

export default class PostListing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loginPromptIsOpen: false,
            accountVerifyPromptIsOpen: false,
        };
    }

    componentDidMount() {
        let {handleNewCommentFromSocket} = this.props;

        SOCKET.on(EMIT_EVENT_NEW_COMMENT, (data) => {
            let {
                postId,
                comment
            } = data;

            handleNewCommentFromSocket(postId, comment);
        });
    }

    connectToSocketRoom = () => {
        SOCKET.emit(EMIT_EVENT_JOIN_ROOM, {
            postId: 'post-listing-page',
        });
    };

    showPostForm = (postFormKey) => {
        let {
            history,
            isVerified,
            isAuthenticated,
            onCreatePostStart
        } = this.props;

        if (!isAuthenticated) {
            this.handleOpenLoginPrompt();
            return;
        }

        if (isAuthenticated && !isVerified) {
            this.handleOpenAccountVerifyPrompt();
            return
        }

        onCreatePostStart();

        history.push({
            pathname: '/compose_post/',
            search: formatNavigationQuery({'ref': '/posts/'})
        });
    };

    getPostsFilter = () => {
        let {location} = this.props;
        let urlParams = queryString.parse(location.search);
        let postsFilter = 'all';

        if (urlParams['tags']) {
            let formattedTag = urlParams['tags'].split('-');

            formattedTag = _.reduce(formattedTag, (memo, word, index) => {
                if (index === 0) {
                    return capitalize(word);
                }

                return `${memo} ${capitalize(word)}`;
            }, '');
            postsFilter = formattedTag;
        } else if (urlParams['posts_filter'] === 'all') {
            postsFilter = 'all';
        } else if (!urlParams['posts_filter'] || urlParams['posts_filter'] === 'relevant') {
            postsFilter = 'relevant';
        }

        return postsFilter;
    };

    handleCloseLoginPrompt = () => {
        this.setState({loginPromptIsOpen: false});
    };

    handleOpenLoginPrompt = () => {
        let {isAuthenticated} = this.props;

        if (!isAuthenticated) {
            this.setState({loginPromptIsOpen: true});
        }
    };

    handleCloseAccountVerifyPrompt = () => {
        this.setState({accountVerifyPromptIsOpen: false});
    };

    handleOpenAccountVerifyPrompt = () => {
        let {
            isAuthenticated,
            isVerified,
        } = this.props;

        if (isAuthenticated && !isVerified) {
            this.setState({accountVerifyPromptIsOpen: true});
        }
    };

    render() {
        let {
            isAuthenticated,
            location,
        } = this.props;
        let {
            loginPromptIsOpen,
            accountVerifyPromptIsOpen
        } = this.state;
        let urlParams = queryString.parse(location.search);
        let postsFilter = this.getPostsFilter();
        let showAllPosts = postsFilter === 'all';
        let showRelevantPosts = postsFilter === 'relevant';
        let tagFilterButton = null;
        let postsFilterSection = null;

        if (postsFilter && !(postsFilter in POST_FILTER_TYPES) && urlParams['tags']) {
            tagFilterButton = (
                <div
                    className="tag-filter-button-container"
                >
                    <Link
                        className="close-button"
                        to={{
                            pathname: '/posts/',
                            search: formatNavigationQuery()
                        }}
                    >
                        <FontAwesomeIcon
                            icon="times"
                            color="#333"
                            size="sm"
                        />
                    </Link>
                    <span className="tag-name">
                        {postsFilter}
                    </span>
                </div>
            )
        }

        if (tagFilterButton) {
            postsFilterSection = tagFilterButton;
        } else if (isAuthenticated) {
            postsFilterSection = (
                <div>
                    <Link
                        className={`filter-link ${showRelevantPosts ? 'selected' : ''}`}
                        to={{
                            pathname: '/posts/',
                            search: formatNavigationQuery({
                                'posts_filter': 'relevant'
                            })
                        }}
                    >
                        Relevant
                    </Link>
                    <Link
                        className={`filter-link ${showAllPosts ? 'selected' : ''}`}
                        to={{
                            pathname: '/posts/',
                            search: formatNavigationQuery({
                                'posts_filter': 'all'
                            })
                        }}
                    >
                        All
                    </Link>
                </div>
            );
        }

        return (
            <div id="post-listing">
                <GlobalHeader />
                <div className="page-body-container">
                    <div className="secondary-header-row">
                        <div onClick={this.showPostForm}>
                            <div className="compose-button">
                                <div className="icon-container">
                                    <FontAwesomeIcon icon="plus" color="#ff9800" size="lg" />
                                </div>
                                <p className="text">Compose</p>
                            </div>
                        </div>
                        <div className="filters-container">
                            {postsFilterSection}
                        </div>
                    </div>
                    <div className="post-list-section">
                        <div className="post-card-list-container">
                            <PostCardList
                                queryParams={{
                                    posts_filter: postsFilter,
                                }}
                            />
                        </div>
                    </div>
                </div>
                <LoginPrompt
                    isOpen={loginPromptIsOpen}
                    title="Create a post to push the conversation."
                    subtitle="After logging in, you can create a post."
                    handleClose={this.handleCloseLoginPrompt}
                />
                <AccountVerificationPrompt
                    isOpen={accountVerifyPromptIsOpen}
                    title="Verify your account."
                    subtitle="In order to complete this action, verify your account by clicking the button below, and visit the email."
                    handleClose={this.handleCloseAccountVerifyPrompt}
                />
            </div>
        );
    }
}

PostListing.propTypes = {
    isVerified: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    onCreatePostSuccess: PropTypes.func.isRequired,
};
