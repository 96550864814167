import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#000',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = ({
    title,
    isOpen,
    OpenButton,
    ContentComponent,
    PrimaryButton,
    SecondaryButton
}) => {
    const classes = useStyles();
    let primaryButton = null;
    let secondaryButton = null;

    if (PrimaryButton) {
        primaryButton = (<PrimaryButton />);
    }

    if (SecondaryButton) {
        secondaryButton = (<SecondaryButton />);
    }

    return (
        <div className="full-screen-dialog">
            <OpenButton />
            <Dialog
                open={isOpen}
                TransitionComponent={Transition}
                fullScreen
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            {title}
                        </Typography>
                        {primaryButton}
                        {secondaryButton}
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <ContentComponent />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default FullScreenDialog;
