import {
    INITIALIZE_COMMENT_THREAD,
    LOAD_COMMENTS_FOR_POST_START,
    LOAD_COMMENTS_FOR_POST_SUCCESS,
    LOAD_COMMENTS_FOR_POST_FAIL,
    LOAD_REPLIES_START,
    LOAD_REPLIES_SUCCESS,
    LOAD_REPLIES_FAIL
} from '../../actions/comments';

import {
    LOAD_MORE_COMMENTS_START,
    LOAD_MORE_COMMENTS_SUCCESS,
    LOAD_MORE_COMMENTS_FAIL,
} from './actions';


const initializeCommentThreadReducer = (state, {postId}) => ({
    ...state,
    [`posts/${postId}/`]: {
        comments: [],
        isLoadingComments: false,
        hasMoreComments: false,
        totalCount: 0
    }
});

const loadCommentsStartReducer = (state, {postId}) => ({
    ...state,
    [`posts/${postId}/`]: {
        ...state[`posts/${postId}/`],
        isLoadingComments: true,
        hasMoreComments: false,
        totalCount: 0
    }
});

const loadCommentsSuccessReducer = (
    state,
    {
        postId,
        comments,
        totalCount,
        nextPaginationLink,
    }
) => {
    let postKey = `posts/${postId}/`;
    let oldStateComments = state[postKey].comments || [];
    let hasMoreComments = !!nextPaginationLink;

    return {
        ...state,
        [postKey]: {
            ...state[postKey],
            isLoadingComments: false,
            hasMoreComments,
            comments: [...oldStateComments, ...comments],
            totalCount
        }
    };
};

const loadCommentsFailReducer = (state, {postId, errors}) => ({
    ...state,
    [`posts/${postId}/`]: {
        ...state[`posts/${postId}/`],
        isLoadingComments: false,
        hasMoreComments: false,
        errors
    }
});

const loadMoreCommentsStartReducer = (state, {postId}) => ({
    ...state,
    [`posts/${postId}/`]: {
        ...state[`posts/${postId}/`],
        isLoadingMoreComments: true,
        hasMoreComments: true,
        comments: state.comments
    }
});

const loadMoreCommentsSuccessReducer = (state, {postId, comments, hasMoreComments}) => ({
    ...state,
    [`posts/${postId}/`]: {
        ...state[`posts/${postId}/`],
        isLoadingMoreComments: false,
        comments: [
            ...state.comments,
            ...comments
        ],
        hasMoreComments
    }
});

const loadMoreCommentsFailReducer = (state, {postId, errors}) => ({
    ...state,
    [`posts/${postId}/`]: {
        ...state[`posts/${postId}/`],
        isLoadingMoreComments: false,
        hasMoreComments: false,
        errors
    }
});

const loadRepliesStartReducer = (state, {postId}) => ({
    ...state,
    [`posts/${postId}/`]: {
        ...state[`posts/${postId}/`],
        isLoadingReplies: true,
        replyMap: {},
    }
});

const loadRepliesSuccessReducer = (state, {postId, parentCommentId, replies}) => ({
    ...state,
    [`posts/${postId}/`]: {
        ...state[`posts/${postId}/`],
        isLoadingReplies: false,
        replyMap: {
            ...state.replyMap,
            [`comment-${parentCommentId}`]: replies
        }
    }
});

const loadRepliesFailReducer = (state, {postId, errors}) => ({
    ...state,
    [`posts/${postId}/`]: {
        ...state[`posts/${postId}/`],
        isLoadingReplies: false,
        errors
    }
});

const COMMENTS_REDUCER_MAP = {
    [INITIALIZE_COMMENT_THREAD]: initializeCommentThreadReducer,
    [LOAD_COMMENTS_FOR_POST_START]: loadCommentsStartReducer,
    [LOAD_COMMENTS_FOR_POST_SUCCESS]: loadCommentsSuccessReducer,
    [LOAD_COMMENTS_FOR_POST_FAIL]: loadCommentsFailReducer,
    [LOAD_MORE_COMMENTS_START]: loadMoreCommentsStartReducer,
    [LOAD_MORE_COMMENTS_SUCCESS]: loadMoreCommentsSuccessReducer,
    [LOAD_MORE_COMMENTS_FAIL]: loadMoreCommentsFailReducer,
    [LOAD_REPLIES_START]: loadRepliesStartReducer,
    [LOAD_REPLIES_SUCCESS]: loadRepliesSuccessReducer,
    [LOAD_REPLIES_FAIL]: loadRepliesFailReducer
};

const commentThreadReducer = (initialState={}, action={}) => {
    let reducer = COMMENTS_REDUCER_MAP[action.type];
    let nextState = initialState;

    if (reducer) {
        nextState = reducer(initialState, action);
    }

    return nextState;
};

export default commentThreadReducer;
