import {connect} from 'react-redux';
import _ from 'underscore';

import {savePost} from '../../action_creators/posts';

import ComposePostForm from './ComposePostForm';


const mapStateToProps = (state) => {
    let {
        composePostForm={},
        app
    } = state;
    let authUser = app.authUser || {};
    let isAuthenticated = !_.isEmpty(authUser);

    return {
        ...composePostForm.post,
        isAuthenticated,
        isVerified: isAuthenticated && !!authUser.is_verified,
    };
};

const mapDispatchToProps = (dispatch) => ({
    savePost: (formValues, slugHash) => (
        dispatch(savePost(formValues, slugHash))
    )
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComposePostForm);
