import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {
    Link,
    Route,
} from 'react-router-dom';
import {Form, Field} from 'react-final-form';
import _ from 'lodash';

import AccountVerificationPrompt from '../../common/components/account_verification_prompt/AccountVerificationPrompt';
import AlertDialog from '../../common/components/alert_dialog/AlertDialog';
import ErrorDialog from '../../common/components/error_dialog/ErrorDialog';
import FullScreenDialog from '../../common/components/full_screen_dialog/FullScreenDialog';
import GlobalHeader from '../../common/components/global_header/connector';
import GroupForm from '../../common/components/group_form/GroupForm';
import LoginPrompt from '../../common/components/login_prompt/LoginPrompt';
import {
    fetchApiGet,
    fetchApiPost
} from '../../common/utils/api';
import {setOGTagsToDefault} from '../../common/utils/app';
import {categoriesToFormValues} from '../../common/utils/categories';
import {formatNavigationQuery} from '../../common/utils/routing';

import GroupMembers from '../group_members/connector';

import GroupPosts from './GroupPosts';
import './GroupPage.css';


const updateOGTagsForGroup = (group) => {
    let ogElements = document.getElementsByClassName('boeiie-og-element');
    let ogElementsLength = ogElements.length;

    for (let i=0; i < ogElementsLength; i++) {
        let element = ogElements[i];
        let attribute = element.getAttribute('property');

        if (attribute === 'og:url') {
            element.content = `https://boeiie.com/groups/${group.slug_hash}/${group.slug}/`;
        } else if (attribute === 'og:title') {
            element.content = `${group.name} | Boeiie.com`;
        } else if (attribute === 'og:description') {
            if (group.description) {
                element.content = `${group.description} | Boeiie.com`;
            }
        }
    }
};

const GroupMembersRoute = ({slugHash, history}) => (
    <Route exact path={'/groups/:slugHash/:groupSlug/members/'}>
        <GroupMembers
            slugHash={slugHash}
            history={history}
        />
    </Route>
);

const GroupPostsRoute = ({slugHash, history}) => (
    <Route exact path={'/groups/:slugHash/:groupSlug/'}>
        <GroupPosts
            slugHash={slugHash}
            history={history}
        />
    </Route>
);

export default class GroupPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            groupEditFormIsOpen: false,
            groupDeleteDialogIsOpen: false,
            inviteMemberDialogIsOpen: false,
            loginPromptIsOpen: false,
            accountVerifyPromptIsOpen: false,
            categoryOptions: []
        };
    }

    componentDidMount() {
        this.props.onPageLoad()
            .then(({group}) => {
                document.title = `${group.name} | Boeiie`;

                updateOGTagsForGroup(group);
            });

        fetchApiGet('tags/')
            .then(({tags}) => {
                this.setState({categoryOptions: tags});
            });
    }

    componentWillUnmount() {
        setOGTagsToDefault();
    }

    transformFormValues = (formValues) => {
        let result = {'categories': []};

        Object.keys(formValues).forEach((key) => {
            if (key.indexOf('category-') === 0 && formValues[key]) {
                result.categories.push(key.split('category-')[1]);
            } else {
                result[key] = formValues[key];
            }
        });

        return result;
    };

    handleSubmitGroupForm = (formValues) => {
        let {handleEditGroup} = this.props;
        let formattedValues = this.transformFormValues(formValues);

        handleEditGroup(formattedValues)
            .then(() => {
                this.closeGroupEditForm();
            });
    };

    handleJoinGroupClick = () => {
        let {
            isVerified,
            isAuthenticated,
            handleJoinGroup
        } = this.props;

        if (!isAuthenticated) {
            this.handleOpenLoginPrompt();
            return;
        }

        if (isAuthenticated && !isVerified) {
            this.handleOpenAccountVerifyPrompt();
            return;
        }

        handleJoinGroup();
    };

    handleUnjoinGroupClick = () => {
        let {handleUnjoinGroup} = this.props;

        handleUnjoinGroup();
    };

    handleConfirmGroupDeleteDialog = (closeDialogFunction) => {
        let {
            history,
            handleDeleteGroup
        } = this.props;

        handleDeleteGroup()
            .then(() => {
                closeDialogFunction();
                history.push('/groups/');
            });
    };

    handleCancelGroupDeleteDialog = (closeDialogFunction) => {
        closeDialogFunction();
    };

    openGroupEditForm = () => {
        let {
            isAuthenticated,
            isVerified,
        } = this.props;

        if (isAuthenticated && !isVerified) {
            this.handleOpenAccountVerifyPrompt();
            return;
        }

        this.setState({groupEditFormIsOpen: true});
    };

    closeGroupEditForm = () => {
        this.setState({groupEditFormIsOpen: false});
    };

    setInviteMemberDialogState = (inviteMemberDialogIsOpen) => {
        let {
            isAuthenticated,
            isVerified,
        } = this.props;

        if (inviteMemberDialogIsOpen && isAuthenticated && !isVerified) {
            this.handleOpenAccountVerifyPrompt();
            return;
        }

        this.setState({inviteMemberDialogIsOpen});
    };

    handleCloseLoginPrompt = () => {
        this.setState({loginPromptIsOpen: false});
    };

    handleOpenLoginPrompt = () => {
        let {isAuthenticated} = this.props;

        if (!isAuthenticated) {
            this.setState({loginPromptIsOpen: true});
        }
    };

    handleCloseAccountVerifyPrompt = () => {
        this.setState({accountVerifyPromptIsOpen: false});
    };

    handleOpenAccountVerifyPrompt = () => {
        let {
            isAuthenticated,
            isVerified,
        } = this.props;

        if (isAuthenticated && !isVerified) {
            this.setState({accountVerifyPromptIsOpen: true});
        }
    };

    render() {
        let {
            group,
            match={},
            history,
            pageError,
            handleClearGroupError,
        } = this.props;
        let {
            groupEditFormIsOpen,
            categoryOptions,
            loginPromptIsOpen,
            inviteMemberDialogIsOpen,
            accountVerifyPromptIsOpen
        } = this.state;
        let {
            name: groupName,
            description: groupDescription,
            categories: groupCategories=[],
        } = group;
        let slugHash = match.params.slugHash;
        let joinButton = null;

        if (group.is_member) {
            joinButton = <button className="join-button" onClick={this.handleUnjoinGroupClick}>Unjoin</button>;
        } else if (!group.is_member) {
            joinButton = <button className="unjoin-button" onClick={this.handleJoinGroupClick}>Join</button>;
        }

        let submitCallBack;

        const handleSendInvitation = (formValues) => {
            return fetchApiPost(`groups/${slugHash}/invite/`, formValues)
                .then(() => {
                    this.setInviteMemberDialogState(false);
                });
        };

        const InviteMemberForm = () => {
            return (
                <Form
                    onSubmit={handleSendInvitation}
                >
                    {
                        (props) => {
                            submitCallBack = props.handleSubmit;

                            return (
                                <form
                                    onSubmit={props.handleSubmit}
                                >
                                    <div>
                                        <Field
                                            className="invitation-email-input"
                                            component="input"
                                            placeholder="Enter a email address"
                                            name="email"
                                            type="email"
                                        />
                                    </div>
                                </form>
                            );
                        }
                    }
                </Form>
            );
        };

        let InviteMemberDialogButton = () => (
            <button
                type="button"
                onClick={() => this.setInviteMemberDialogState(true)}
            >
                Invite a member
            </button>
        );
        let InviteMemberDialogPrimaryButton = () => (
            <button
                className="invitation-dialog-action-button"
                onClick={() => this.setInviteMemberDialogState(false)}
            >
                Close
            </button>
        );
        let InviteMemberDialogSecondaryButton = () => (
            <button
                className="invitation-dialog-action-button"
                type="submit"
                onClick={(e) => submitCallBack(e)}
            >
                Send Invitation
            </button>
        );
        let InviteMemberDialogContentWrapper = () => (
            <InviteMemberForm />
        );

        return (
            <div id="group-page">
                <GlobalHeader />
                <div className="page-body-container">
                    <div className="group-header-section">
                        <h2>{groupName}</h2>
                        <p>{groupDescription}</p>
                    </div>
                    <div className="group-nav-bar-section">
                        <div >
                            <Link
                                className="nav-link"
                                to={{
                                    pathname: `/groups/${slugHash}/${group['slug']}/`,
                                    search: formatNavigationQuery()
                                }}
                            >
                                Discussions
                            </Link>
                            <Link
                                className="nav-link"
                                to={{
                                    pathname: `/groups/${slugHash}/${group['slug']}/members/`,
                                    search: formatNavigationQuery()
                                }}
                            >
                                Members
                            </Link>
                        </div>
                        <div>
                            {joinButton}
                        </div>
                    </div>

                    <div className="group-second-button-row">
                        {
                            group.auth_user && group.auth_user.is_admin ?
                            <div>
                                <button
                                    className="edit-group-button"
                                    onClick={this.openGroupEditForm}>
                                    Edit Group
                                </button>
                            </div> : null
                        }
                        {
                            group.auth_user && group.auth_user.is_primary_admin ?
                            <div>
                                <AlertDialog
                                    buttonText="Delete Group"
                                    dialogTitle={`Delete ${groupName}`}
                                    contentDescription="Are you sure you want to delete this group?"
                                    primaryButtonText="Cancel"
                                    handlePrimaryButtonClick={this.handleCancelGroupDeleteDialog}
                                    secondaryButtonText="Delete group"
                                    handleSecondaryButtonClick={this.handleConfirmGroupDeleteDialog}
                                />
                            </div> : null
                        }
                        {
                            group.is_member ?
                            <div>
                                <FullScreenDialog
                                    title="Invite a member"
                                    isOpen={inviteMemberDialogIsOpen}
                                    OpenButton={InviteMemberDialogButton}
                                    ContentComponent={InviteMemberDialogContentWrapper}
                                    PrimaryButton={InviteMemberDialogPrimaryButton}
                                    SecondaryButton={InviteMemberDialogSecondaryButton}
                                />
                            </div> : null
                        }
                    </div>

                    <ErrorDialog
                        open={!_.isEmpty(pageError)}
                        dialogTitle={pageError.title}
                        errorMessage={pageError.message}
                        primaryButtonText="Close"
                        handlePrimaryButtonClick={handleClearGroupError}
                        handleClose={handleClearGroupError}
                    />

                    <GroupForm
                        formTitle={`Edit group`}
                        initialValues={
                            {
                                name: groupName,
                                description: groupDescription,
                                ...categoriesToFormValues(groupCategories),
                            }
                        }
                        formIsOpen={groupEditFormIsOpen}
                        categoryOptions={categoryOptions}
                        handleCancelForm={this.closeGroupEditForm}
                        handleSubmitForm={this.handleSubmitGroupForm}
                    />

                    <div className="body-content">
                        <GroupMembersRoute slugHash={slugHash} history={history} />
                        <GroupPostsRoute slugHash={slugHash} history={history} />
                    </div>
                </div>
                <LoginPrompt
                    isOpen={loginPromptIsOpen}
                    title="Join this group to keep up with the conversation."
                    subtitle="After logging in, you can join this group."
                    handleClose={this.handleCloseLoginPrompt}
                />
                <AccountVerificationPrompt
                    isOpen={accountVerifyPromptIsOpen}
                    title="Verify your account."
                    subtitle="In order to complete this action, verify your account by clicking the button below, and visit the email."
                    handleClose={this.handleCloseAccountVerifyPrompt}
                />
            </div>
        );
    }

}

GroupPage.propTypes = {
    group: PropTypes.object.isRequired,
    posts: PropTypes.array,
    members: PropTypes.array,
    pageError: PropTypes.object,
    handleClearGroupError: PropTypes.func,
    handleJoinGroup: PropTypes.func,
    handleEditGroup: PropTypes.func,
    handleDeleteGroup: PropTypes.func,
    handleInviteMember: PropTypes.func,
};
