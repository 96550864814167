export const ADD_TAG_FILTER = 'add-tag-filter';
export const REMOVE_TAG_FILTER = 'remove-tag-filter';

export const addTagFilter = (tag) => ({
    type: ADD_TAG_FILTER,
    tag
});

export const removeTagFilter = (tag) => ({
    type: REMOVE_TAG_FILTER,
    tag
});
