import _ from 'underscore';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Form, Field} from 'react-final-form';

import {fetchApiGet} from '../../utils/api';
import {isURL} from '../../utils/validation';

import ComposeHeader from '../compose_header/ComposeHeader';
import PreviewLink from '../preview_link/PreviewLink';

import './ShareLinkForm.css';


const LinkForm = ({
    linkUrl,
    onChange,
    onPaste
}) => (
    <form>
        <div>
            <Field
                name="share_link"
                render={({input}) => (
                    <input
                        {...input}
                        placeholder="https://sharesomethingcool.com"
                        value={linkUrl}
                        onChange={onChange}
                        onPaste={onPaste}
                    />
                )}
            />
        </div>
    </form>
);


export default class ShareLinkForm extends Component {

    constructor(props) {
        super(props);

        let {linkPreviewData = {}} = props;
        let {linkUrl} = linkPreviewData || '';

        this.state = {
            linkUrl,
            previewData: linkPreviewData || {},
            showPreviewLink: !!linkUrl,
            hasValidUrl: false
        };

        this.debouncedRequestPreview = _.debounce(this.requestPreview, 1000);
    }

    requestPreview = (url) => {
        if (!isURL(url)) return;

        fetchApiGet(`preview_link?l=${url}`)
            .then(({data}) => {
                if (data) {
                    this.setState({
                        previewData: {
                            ...data
                        },
                        showPreviewLink: true,
                        hasValidUrl: true
                    });
                }
            });
    };

    handleUrlChange = (e) => {
        let newUrl = e.target.value;

        this.setState({
            linkUrl: newUrl
        });

        this.debouncedRequestPreview(newUrl);
    };

    render() {
        let {
            onCancel,
            onSubmit
        } = this.props;
        let {
            linkUrl,
            previewData,
            showPreviewLink,
            hasValidUrl
        } = this.state;
        let previewLink = null;

        if (showPreviewLink) {
            previewLink = (
                <div className="link-preview-container">
                    <PreviewLink
                        {...previewData}
                    />
                </div>
            );
        }

        return (
            <div className="share-link-form">
                <div className="compose-header-container">
                    <ComposeHeader
                        primaryButtonTitle="Cancel"
                        primaryButtonOnClick={onCancel}
                        secondaryButtonTitle="Add"
                        secondaryButtonOnClick={() => onSubmit(previewData)}
                        secondaryButtonIsDisabled={!hasValidUrl}
                    />
                </div>
                <div className="body">
                    <Form
                        onSubmit={() => {}}
                        render={({handleSubmit}) => {
                            return (
                                <LinkForm
                                    linkUrl={linkUrl}
                                    onChange={this.handleUrlChange}
                                    onPaste={this.loadLinkPreview}
                                />
                            );
                        }}
                    />

                    {previewLink}
                </div>
            </div>
        );
    }
}

ShareLinkForm.propTypes = {
    linkPreviewData: PropTypes.object,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func
};
