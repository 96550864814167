export const LOAD_NOTIFICATIONS_START = 'load-notifications-start';
export const LOAD_NOTIFICATIONS_SUCCESS = 'load-notifications-success';
export const LOAD_NOTIFICATIONS_FAIL = 'load-notifications-fail';

export const loadNotificationsStart = () => ({
    type: LOAD_NOTIFICATIONS_START
});

export const loadNotificationsSuccess = (notifications, next='', previous='') => ({
    type: LOAD_NOTIFICATIONS_SUCCESS,
    notificationsPreviousLink: previous,
    notificationsNextLink: next,
    notifications,
});

export const loadNotificationsFail = (errors) => ({
    type: LOAD_NOTIFICATIONS_FAIL,
    errors
});


export const VIEW_NOTIFICATION_START = 'view-notification-start';
export const VIEW_NOTIFICATION_SUCCESS = 'view-notification-success';
export const VIEW_NOTIFICATION_FAIL = 'view-notification-fail';

export const viewNotificationStart = () => ({
    type: VIEW_NOTIFICATION_START
});

export const viewNotificationSuccess = (notificationId) => ({
    type: VIEW_NOTIFICATION_SUCCESS,
    notificationId
});

export const viewNotificationFail = (errors) => ({
    type: VIEW_NOTIFICATION_FAIL,
    errors
});

