import queryString from 'query-string';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import {fetchApiGet} from '../../common/utils/api';

import AccountVerificationPrompt from '../../common/components/account_verification_prompt/AccountVerificationPrompt';
import GlobalHeader from '../../common/components/global_header/connector';
import GroupForm from '../../common/components/group_form/GroupForm';
import GroupSummaryCard from '../../common/components/group_summary_card/GroupSummaryCard';
import LoginPrompt from '../../common/components/login_prompt/LoginPrompt';
import {formatNavigationQuery} from '../../common/utils/routing';

import './Groups.css';


class Groups extends Component {
    static propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
        isVerified: PropTypes.bool.isRequired,
        myGroups: PropTypes.array,
        allGroups: PropTypes.array,
        hasMoreGroups: PropTypes.bool,
        handlePageLoad: PropTypes.func.isRequired,
        handleCreateGroup: PropTypes.func.isRequired,
        handlePaginationClick: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            createGroupFormIsOpen: false,
            categoryOptions: [],
            loginPromptIsOpen: false,
            accountVerifyPromptIsOpen: false,
        };
    }

    componentDidMount() {
        let {
            history,
            handlePageLoad,
        } = this.props;

        handlePageLoad();

        fetchApiGet('tags/')
            .then(({tags}) => {
                this.setState({categoryOptions: tags});
            });

        this.unlistenToHistory = history.listen((location) => {
            window.scrollTo(0, 0);
        });
    }

    componentWillUnmount() {
        this.unlistenToHistory();
    }

    openCreateGroupForm = () => {
        let {
            isVerified,
            isAuthenticated
        } = this.props;

        if (!isAuthenticated) {
            this.handleOpenLoginPrompt();
            return;
        }

        if (isAuthenticated && !isVerified) {
            this.handleOpenAccountVerifyPrompt();
            return;
        }

        this.setState({createGroupFormIsOpen: true});
    };

    closeCreateGroupForm = () => {
        this.setState({createGroupFormIsOpen: false});
    };

    transformFormValues = (formValues) => {
        let result = {'categories': []};

        Object.keys(formValues).forEach((key) => {
            if (key.indexOf('category-') === 0 && formValues[key]) {
                result.categories.push(key.split('category-')[1]);
            } else {
                result[key] = formValues[key];
            }
        });

        return result;
    };

    createNewGroup = (formValues) => {
        let {
            isAuthenticated,
            handleCreateGroup
        } = this.props;
        let formattedValues = this.transformFormValues(formValues);

        if (!isAuthenticated) {
            this.handleOpenLoginPrompt();
            return;
        }

        handleCreateGroup(formattedValues)
            .then(() => {
                this.closeCreateGroupForm();
            });
    };

    handlePaginationButtonClick = (link) => {
        let {handlePaginationClick} = this.props;

        if (link) {
            let url = new URL(link);
            let params = queryString.parse(url.search);

            handlePaginationClick(params);
        }
    };

    handleCloseLoginPrompt = () => {
        this.setState({loginPromptIsOpen: false});
    };

    handleOpenLoginPrompt = () => {
        let {isAuthenticated} = this.props;

        if (!isAuthenticated) {
            this.setState({loginPromptIsOpen: true});
        }
    };

    handleCloseAccountVerifyPrompt = () => {
        this.setState({accountVerifyPromptIsOpen: false});
    };

    handleOpenAccountVerifyPrompt = () => {
        let {
            isAuthenticated,
            isVerified,
        } = this.props;

        if (isAuthenticated && !isVerified) {
            this.setState({accountVerifyPromptIsOpen: true});
        }
    };

    render() {
        let {
            allGroups,
            myGroups,
        } = this.props;
        let {
            categoryOptions,
            loginPromptIsOpen,
            createGroupFormIsOpen,
            accountVerifyPromptIsOpen
        } = this.state;
        let allGroupsList = null;
        let myGroupsList = null;

        if (!_.isEmpty(myGroups)) {
            myGroupsList = myGroups.map((group, index) => (
                <Link
                    key={group.id}
                    className="group-link"
                    to={{
                        pathname: `/groups/${group['slug_hash']}/${group['slug']}/`,
                        search: formatNavigationQuery()
                    }}
                >
                    <div className="group-card-container">
                        <GroupSummaryCard
                            title={group.name}
                            description={group.description}
                        />
                    </div>
                </Link>
            ));
        }

        if (!_.isEmpty(allGroups)) {
            allGroupsList = allGroups.map((group, index) => (
                <Link
                    key={group.id}
                    className="group-link"
                    to={{
                        pathname: `/groups/${group['slug_hash']}/${group['slug']}/`,
                        search: formatNavigationQuery()
                    }}
                >
                    <div className="group-card-container">
                        <GroupSummaryCard
                            title={group.name}
                            description={group.description}
                        />
                    </div>
                </Link>
            ));
        }

        return (
            <div id="groups">
                <GlobalHeader />
                <GroupForm
                    formTitle="Create a group"
                    formIsOpen={createGroupFormIsOpen}
                    categoryOptions={categoryOptions}
                    handleCancelForm={this.closeCreateGroupForm}
                    handleSubmitForm={this.createNewGroup}
                />
                <div className="page-body-container">
                    <div className="secondary-header-row">
                        {
                            myGroupsList ?
                            <div>
                                <h2 className="group-list-title">My Groups</h2>
                            </div> : null
                        }
                        <button
                            className="create-group-button"
                            onClick={this.openCreateGroupForm}
                        >
                            <div className="icon-container">
                                <FontAwesomeIcon icon="plus" color="#ff9800" size="lg" />
                            </div>
                            <p className="text">Create a group</p>
                        </button>
                    </div>

                    {
                        myGroupsList ?
                        <div className="group-list-section">
                            {myGroupsList}
                            <Link
                                className="see-all-link"
                                to={{
                                    pathname: `/my-groups/`,
                                    search: formatNavigationQuery()
                                }}
                            >
                                See all
                            </Link>
                        </div> : null
                    }

                    <div className="group-list-section">
                        <h2 className="group-list-title">All Groups</h2>
                        {allGroupsList}
                        <Link
                            className="see-all-link"
                            to={{
                                pathname: `/all-groups/`,
                                search: formatNavigationQuery()
                            }}
                        >
                            See all
                        </Link>
                    </div>
                </div>
                <LoginPrompt
                    isOpen={loginPromptIsOpen}
                    title="Create a group to push the conversation."
                    subtitle="After logging in, you can create a group."
                    handleClose={this.handleCloseLoginPrompt}
                />
                <AccountVerificationPrompt
                    isOpen={accountVerifyPromptIsOpen}
                    title="Verify your account."
                    subtitle="In order to complete this action, verify your account by clicking the button below, and visit the email."
                    handleClose={this.handleCloseAccountVerifyPrompt}
                />
            </div>
        );
    }
}

export default withRouter(Groups);
