import {connect} from 'react-redux';
import _ from 'underscore';

import SignupConfirmation from './SignupConfirmation';


const mapStateToProps = ({app}) => ({
    isAuthenticated: !_.isEmpty(app.authUser),
    isVerified: !_.isEmpty(app.authUser) && app.authUser.is_verified
});

export default connect(
    mapStateToProps,
    null
)(SignupConfirmation);
