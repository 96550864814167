import PropTypes from 'prop-types';
import React, {Component, useState, useEffect} from 'react';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import {
    Form,
    Field
} from 'react-final-form';
import {Link} from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import GlobalHeader from '../../common/components/global_header/connector';
import SimpleDialog from '../../common/components/simple_dialog/SimpleDialog';
import {
    fetchApiGet,
    fetchApiPost
} from '../../common/utils/api';
import {isDemo} from '../../common/utils/routing';
import {
    isRequired,
    maxLength,
    minLength,
    isEmail,
    composeValidators,
    listSubmitErrors
} from '../../common/utils/validation';

import './Account.css';


const validatePasswordForm = (formValues) => {
    let {
        'new_password': newPassword,
        'confirm_new_password': confirmNewPassword
    } = formValues;
    let errors = {};

    if (newPassword !== confirmNewPassword) {
        errors['confirm_new_password'] = 'Must match new password.';
    }

    return errors;
};

const ChangePasswordForm = ({
    onCancel,
    onSubmit,
    handleSubmit,
    submissionErrors
}) => {
    return (
        <form onSubmit={handleSubmit}>
            <div className="input-section-container">
                <p className="label">Old password</p>
                <Field
                    name="old_password"
                    validate={composeValidators(isRequired, maxLength(50, 'old_password'))}
                    render={({input, meta}) => (
                        <div>
                            <input {...input} className="attribute-input" type="password" />
                            {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                        </div>
                    )}
                />
            </div>
            <div className="input-section-container">
                <p className="label">New password</p>
                <Field
                    name="new_password"
                    validate={composeValidators(
                        isRequired,
                        maxLength(50, 'new_password'),
                        minLength(8, 'new_password')
                    )}
                    render={({input, meta}) => (
                        <div>
                            <input {...input} className="attribute-input" type="password" />
                            {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                        </div>
                    )}
                />
            </div>
            <div className="input-section-container">
                <p className="label">Confirm new password</p>
                <Field
                    name="confirm_new_password"
                    validate={composeValidators(isRequired, maxLength(50, 'confirm_new_password'))}
                    render={({input, meta}) => (
                        <div>
                            <input {...input} className="attribute-input" type="password" />
                            {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                        </div>
                    )}
                />
            </div>
            <div className="button-row">
                <button
                    className="form-execute-button cancel-button"
                    onClick={onCancel}
                >
                    Cancel
                </button>
                <button
                    className="form-execute-button submit-button"
                    type="submit"
                >
                    Submit
                </button>
            </div>
            <div className="submissionErrors">
                {submissionErrors.map((error, index) => (
                    <p key={index} className="error-message">{error}</p>
                ))}
            </div>
        </form>
    );
};

const NotificationSettings = ({
    userSlugHash,
    shouldReceiveNotificationEmails,
}) => {
    const [state, setState] = useState({
        'receive_notification_emails': shouldReceiveNotificationEmails,
    });
    let emailNotificationSwitchLabel;

    if (state['receive_notification_emails']) {
        emailNotificationSwitchLabel = 'Yes';
    } else {
        emailNotificationSwitchLabel = 'No';
    }

    useEffect(() => {
        setState({receive_notification_emails: shouldReceiveNotificationEmails})
    }, [shouldReceiveNotificationEmails]);

    const handleChange = (event) => {
        let fieldName = event.target.name;
        let checked = event.target.checked;
        let data = {[fieldName]: checked};

        fetchApiPost(`users/${userSlugHash}/profile/`, data)
            .then(() => {
                setState({...state, [fieldName]: checked});
            });
    };

    return (
        <div className="notification-preferences">
            <p className="label">Notification preferences</p>
            <div className="preference-row-container">
                <div>
                    <p className="row-label">Do you want to receive email notifications?</p>
                </div>
                <div>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={state['receive_notification_emails']}
                                onChange={handleChange}
                                name="receive_notification_emails"
                                color="primary"
                            />
                        }
                        labelPlacement="start"
                        label={emailNotificationSwitchLabel}
                      />
                  </div>
            </div>
        </div>
    );
};

export default class Account extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showEditForm: false,
            showEditPasswordForm: false,
            showPasswordChangeSuccessDialog: false,
            resendVerificationButtonClicked: false,
            passwordFormSubmissionErrors: [],
            userProfile: {},
        };
    }

    componentDidMount() {
        let {authUser} = this.props;

        if (authUser && authUser['slug_hash']) {
            fetchApiGet(`users/${authUser['slug_hash']}/profile/`)
                .then(({profile}) => {
                    this.setState({userProfile: profile});
                });
        }
    }

    handleEditButtonClick = () => {
        this.setState({showEditForm: true});
    };

    handleCancelEditButtonClick = (resetForm) => {
        resetForm();

        this.setState({showEditForm: false});
    };

    handleEditPasswordClick = () => {
        this.setState({showEditPasswordForm: true});
    };

    handleCancelEditPasswordClick = (e) => {
        e.preventDefault();

        this.setState({showEditPasswordForm: false});
    };

    handleChangePasswordSubmit = (formValues, form) => {
        let {onChangePasswordFormSubmit} = this.props;

        onChangePasswordFormSubmit(formValues)
            .then(({user}) => {
                this.setState({
                    showEditPasswordForm: false,
                    showPasswordChangeSuccessDialog: true,
                    passwordFormSubmissionErrors: [],
                });
            })
            .catch(({errors}) => {
                this.setState({
                    passwordFormSubmissionErrors: listSubmitErrors(errors)
                });
            });
    };

    handleFormSubmit = (formValues, form) => {
        let {onAccountSettingsFormSubmit} = this.props;

        return onAccountSettingsFormSubmit(formValues)
            .then(() => {
                this.setState({showEditForm: false});
            });
    };

    handleClosePasswordChangeDialog = () => this.setState({showPasswordChangeSuccessDialog: false});

    handleConfirmDeleteAccount = (onClose) => {
        let {onDeleteAccount} = this.props;

        onDeleteAccount()
            .then(() => {
                onClose();
            });
    };

    handleDeleteAccountButtonClick = () => {
        confirmAlert({
            buttons: [
                {
                    label: 'Delete Account'
                },
                {
                    label: 'Cancel'
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: ({onClose}) => {
                return (
                    <div className="delete-account-dialog">
                        <h2>Delete Account</h2>
                        <p>Are you sure you want to delete your account? This action is permanent, and you will not be able to recover your account.</p>
                        <div className="button-row">
                            <button className="cancel-button" onClick={onClose}>Cancel</button>
                            <button
                                className="confirm-button"
                                onClick={() => this.handleConfirmDeleteAccount(onClose)}
                            >
                                Delete Account
                            </button>
                        </div>
                    </div>
                );
            }
        })
    };

    handleVerificationButtonClick = () => {
        fetchApiGet('resend-account-verification/')
            .then(() => {
                this.setState({resendVerificationButtonClicked: true});
            });
    };

    render() {
        let {
            authUser,
            userId,
            firstname,
            lastname,
            email,
        } = this.props;
        let {
            showEditForm,
            showEditPasswordForm,
            showPasswordChangeSuccessDialog,
            passwordFormSubmissionErrors,
            resendVerificationButtonClicked,
            userProfile
        } = this.state;
        let {
            receive_notification_emails: receiveNotificationEmails,
        } = userProfile;
        let editButton = (
            <button
                className="edit-button"
                onClick={this.handleEditButtonClick}
            >
                <img
                    className="edit-icon"
                    src="/media/ui_icons/edit.png"
                    alt="Edit"
                />
                <span className="edit-button-title">Edit</span>
            </button>
        );
        let editPasswordButton = (
            <button
                className="edit-button"
                onClick={this.handleEditPasswordClick}
            >
                <img
                    className="edit-icon"
                    src="/media/ui_icons/edit.png"
                    alt="Edit"
                />
                <span className="edit-button-title">Edit</span>
            </button>
        );
        let submitEditForm;
        let formExecuteButtons = null;
        let resetForm;
        let editPasswordForm = null;
        let accountVerificationSection = null;
        let handleDeleteAccount = this.handleDeleteAccountButtonClick;

        if (isDemo()) {
            handleDeleteAccount = () => {};
            editButton = null;
            editPasswordButton = null;
        }

        if (showEditForm) {
            editButton = null;

            formExecuteButtons = (
                <div className="form-execute-buttons">
                    <button
                        className="form-execute-button cancel-button"
                        onClick={() => this.handleCancelEditButtonClick(resetForm)}
                    >
                        Cancel
                    </button>
                    <button
                        className="form-execute-button submit-button"
                        onClick={(e) => submitEditForm()}
                    >
                        Update Account
                    </button>
                </div>
            );
        }

        if (showEditPasswordForm) {
            editPasswordForm = (
                <Form
                    onSubmit={this.handleChangePasswordSubmit}
                    validate={validatePasswordForm}
                    render={({handleSubmit}) => {
                        return (
                            <ChangePasswordForm
                                onCancel={this.handleCancelEditPasswordClick}
                                handleSubmit={handleSubmit}
                                submissionErrors={passwordFormSubmissionErrors}
                            />
                        );
                    }}
                />
            );
        } else {
            editPasswordForm = (
                <div className="attribute-section">
                    <div className="label-row">
                        <p className="label">Change password</p>
                        {editPasswordButton}
                    </div>
                    <p className="password-placeholder">**********</p>
                </div>
            );
        }

        if (!authUser.is_verified) {
            accountVerificationSection = (
                <div className="section">
                    <p className="label" style={{'color': '#ff5400'}}>Your account is not verified!</p>
                    <p>Click the button below to send a verification code to your email.</p>
                    <button
                        className="resend-verification-button"
                        onClick={this.handleVerificationButtonClick}
                    >
                        Email me verification link
                    </button>
                </div>
            );
        }

        if (resendVerificationButtonClicked) {
            accountVerificationSection = (
                <div className="section">
                    <p className="label" style={{'color': '#ff5400'}}>Your account is not verified!</p>
                    <button
                        className="resend-verification-button"
                        disabled
                    >
                        The verification code was sent to your email.
                    </button>
                </div>
            );
        }

        return (
            <div id="account">
                <GlobalHeader />
                <div className="page-body-container">
                    <div className="header">
                        <h2 className="title">Account Settings</h2>
                    </div>
                    <div className="body-content">
                        <div className="basic-info-form">
                            <Form
                                onSubmit={this.handleFormSubmit}
                                initialValues={{
                                    'first_name': firstname,
                                    'last_name': lastname,
                                    email
                                }}
                            >
                                {props => {
                                    submitEditForm = props.handleSubmit;
                                    resetForm = props.form.reset;

                                    return (
                                        <form>
                                            <div className="input-section-container">
                                                {
                                                    showEditForm ?
                                                    (
                                                        <div>
                                                            <div className="label-row">
                                                                <p className="label">First Name</p>
                                                            </div>
                                                            <Field
                                                                name="first_name"
                                                                validate={composeValidators(isRequired, maxLength(30, 'first name'))}
                                                                render={({input, meta}) => (
                                                                    <div>
                                                                        <input {...input} className="attribute-input" placeholder="First Name" />
                                                                        <p>{meta.error && meta.touched && <span className="error-text">{meta.error}</span>}</p>
                                                                    </div>
                                                                )}
                                                            />
                                                            <div className="label-row">
                                                                <p className="label">Last Name</p>
                                                            </div>
                                                            <Field
                                                                name="last_name"
                                                                validate={composeValidators(isRequired, maxLength(30, 'last name'))}
                                                                render={({input, meta}) => (
                                                                    <div>
                                                                        <input {...input} className="attribute-input" placeholder="Last Name" />
                                                                        <p>{meta.error && meta.touched && <span className="error-text">{meta.error}</span>}</p>
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                    )
                                                    :
                                                    <div>
                                                        <div className="label-row">
                                                            <p className="label">Name</p>
                                                            {editButton}
                                                        </div>
                                                        <p className="attribute-value">{`${firstname} ${lastname}`}</p>
                                                    </div>
                                                }
                                            </div>
                                            <div className="input-section-container">
                                                <div className="label-row">
                                                    <p className="label">Email Address</p>
                                                    {editButton}
                                                </div>
                                                {
                                                    showEditForm ?
                                                    (
                                                        <Field
                                                            name="email"
                                                            validate={composeValidators(isRequired, isEmail, maxLength(30, 'email'))}
                                                            render={({input, meta}) => (
                                                                <div>
                                                                    <input {...input} className="attribute-input" placeholder="Email Address" />
                                                                    <p>{meta.error && meta.touched && <span className="error-text">{meta.error}</span>}</p>
                                                                </div>
                                                            )}
                                                        />
                                                    )
                                                    :
                                                    <p className="attribute-value">{email}</p>
                                                }
                                            </div>
                                        </form>
                                    );
                                }}
                            </Form>
                            {formExecuteButtons}
                        </div>
                        <NotificationSettings
                            shouldReceiveNotificationEmails={!!receiveNotificationEmails}
                            userSlugHash={authUser['slug_hash']}
                            userId={userId}
                        />
                        {accountVerificationSection}
                        <div className="section">
                            <p className="label">Privacy and personal information</p>
                            <p>How we collect and manage data is described in the privacy section.</p>
                            <Link to="/account/privacy/">Click to review</Link>
                        </div>
                        {editPasswordForm}
                        <SimpleDialog
                            open={showPasswordChangeSuccessDialog}
                            handleClose={this.handleClosePasswordChangeDialog}
                            title="Success!"
                            description="Your password has been successfully updated."
                            buttonText="Ok"
                        />
                        <div className="attribute-section delete-account-section">
                            <p className="label">Delete your account</p>
                            <p>Once you delete your account you will not be able to recover it. Please be certain.</p>
                            <button
                                className="delete-account-button"
                                onClick={handleDeleteAccount}
                            >
                                Delete Account
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Account.propTypes = {
    authUser: PropTypes.object.isRequired,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    onAccountSettingsFormSubmit: PropTypes.func,
    onChangePasswordFormSubmit: PropTypes.func,
    onDeleteAccount: PropTypes.func
};
