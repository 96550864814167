import _ from 'underscore';

import {
    SAVE_POST_START,
    SAVE_POST_SUCCESS,
    SAVE_POST_FAIL
} from '../../actions/posts';
import {
    ADD_TAG_FILTER,
    REMOVE_TAG_FILTER
} from '../tag_filters/actions';

import {
    INITIALIZE_COMPOSE_POST_FORM,
    CREATE_POST_START,
    EDIT_POST_START
} from './actions';


const initializeFormReducer = (state) => ({
    ...state,
    tags: [],
    post: {}
});

const createPostStartReducer = (state) => ({
    ...state,
    post: {}
});

const editPostStartReducer = (state, {post}) => ({
    ...state,
    post
});

const saveStartReducer = (state, action) => ({
    ...state,
    post: {}
});

const saveSuccessReducer = (state) => ({
    ...state,
    post: {},
    tags: []
});

const saveFailReducer = (state, {errors}) => ({
    ...state,
    errors
});

const addTagReducer = (state, action) => ({
    ...state,
    tags: [...state.tags, action.tag]
});

const removeTagReducer = (state, action) => ({
    ...state,
    tags: _.filter(state.tags, (tag) => action.tag.id === tag.id)
});

const COMPOSE_POST_REDUCER_MAP = {
    [INITIALIZE_COMPOSE_POST_FORM]: initializeFormReducer,
    [CREATE_POST_START]: createPostStartReducer,
    [EDIT_POST_START]: editPostStartReducer,
    [SAVE_POST_START]: saveStartReducer,
    [SAVE_POST_SUCCESS]: saveSuccessReducer,
    [SAVE_POST_FAIL]: saveFailReducer,
    [ADD_TAG_FILTER]: addTagReducer,
    [REMOVE_TAG_FILTER]: removeTagReducer,
};

const composePostReducer = (initialState={}, action) => {
    let reducer = COMPOSE_POST_REDUCER_MAP[action.type];
    let nextState = initialState;

    if (reducer) {
        nextState = reducer(initialState, action);
    }

    return nextState;
};

export default composePostReducer;
