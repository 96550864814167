import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {isDemo} from '../../utils/routing';

import CommentCard from '../comment_card/connector';
import CommentForm from '../comment_form/CommentForm';

import './PostCardCommentThread.css';


export default class PostCardCommentThread extends Component {
    static propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
        postSlugHash: PropTypes.string.isRequired,
        comments: PropTypes.array.isRequired,
        commentsLimit: PropTypes.number,
        commentsOffset: PropTypes.string,
        hasPreviousComments: PropTypes.bool.isRequired,
        onLoadReplies: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            hideShowRepliesButtonMap: {}
        };
    }

    handleReplySend = (value, replyToId=null) => {
        if (isDemo()) {
            return;
        }

        let {
            postSlugHash,
            handleCreateCommentSubmit
        } = this.props;
        let data = {body: value};

        if (replyToId) {
            data['reply_to'] = replyToId;
        }

        return handleCreateCommentSubmit(postSlugHash, data)
            .then((comment) => {
                return Promise.resolve(comment);
            });
    };

    handleCommentSubmit = (postId, formValues) => {
        let {
            handleCreateCommentSubmit,
            postSlugHash
        } = this.props;

        return handleCreateCommentSubmit(postSlugHash, formValues)
            .then((comment) => {
                return Promise.resolve(comment);
            });
    };

    handleViewRepliesClick = (postId, parentCommentId) => {
        let {
            postSlugHash,
            onLoadReplies
        } = this.props;

         return onLoadReplies(postId, postSlugHash, parentCommentId)
            .then(() => {
                this.setState({
                    hideShowRepliesButtonMap: {
                        [parentCommentId]: true
                    }
                });

                return Promise.resolve();
            });
    };

    handleLoadPreviousComments = () => {
        let {
            postId,
            postSlugHash,
            commentsLimit,
            commentsOffset,
            loadCommentsForPost
        } = this.props;

        loadCommentsForPost(
            postId,
            postSlugHash,
            commentsLimit,
            commentsOffset,
        );
    };

    render() {
        let {
            postId,
            authUser={},
            replyMap={},
            comments,
            isVerified,
            isAuthenticated,
            hasPreviousComments,
            onEditCommentSubmit,
            onHideCommentSubmit,
            onDeleteComment,
        } = this.props;
        let {hideShowRepliesButtonMap} = this.state;
        let threadList = comments.map((parentComment, commentThreadIndex) => {
            let {
                author: parentAuthor,
                body: parentCommentBody,
                'creation_date': parentCreationDate,
                id: parentCommentId
            } = parentComment;
            let parentAuthorName = `${parentAuthor['first_name']} ${parentAuthor['last_name']}`;
            let replies = replyMap[`posts/${postId}/comments/${parentCommentId}/`];
            let replyThread = null;

            if (replies) {
                replyThread = (
                    <div className="children-comment-section">
                        {
                            replies.map((reply, replyIndex) => {
                                let {author} = reply;
                                let authorName = `${author['first_name']} ${author['last_name']}`;

                                return (
                                    <div key={replyIndex} className="child-comment">
                                        <CommentCard
                                            postId={postId}
                                            comment={reply}
                                            commentId={reply.id}
                                            authorId={author.id}
                                            authorName={authorName}
                                            authorTitle={author['profile_headline']}
                                            authorProfileImageUrl={author['profile_image_url']}
                                            commentBody={reply.body}
                                            commentCreationDate={reply['creation_date']}
                                            replyToId={parentCommentId}
                                            metaData={reply['meta_data']}
                                            hideShowRepliesButton={true}
                                            hideEditCommentButton={authUser['user_id'] !== author.id}
                                            hideDeleteCommentButton={authUser['user_id'] !== author.id}
                                            hideHideCommentButton={authUser['user_id'] === author.id}
                                            onReply={(value) => this.handleReplySend(value, parentCommentId)}
                                            onEditCommentSubmit={onEditCommentSubmit}
                                            onHideCommentSubmit={onHideCommentSubmit}
                                            onDeleteComment={onDeleteComment}
                                        />
                                    </div>
                                );
                            })
                        }
                    </div>
                );
            }

            return (
                <div key={commentThreadIndex} className="parent-thread">
                    <div className="parent-comment-section">
                        <CommentCard
                            postId={postId}
                            comment={parentComment}
                            commentId={parentCommentId}
                            authorId={parentAuthor.id}
                            authorName={parentAuthorName}
                            authorTitle={parentAuthor['profile_headline']}
                            authorProfileImageUrl={parentAuthor['profile_image_url']}
                            commentBody={parentCommentBody}
                            commentCreationDate={parentCreationDate}
                            replyToId={parentCommentId}
                            metaData={parentComment['meta_data']}
                            numberOfReplies={parentComment['number_of_replies']}
                            hideShowRepliesButton={hideShowRepliesButtonMap[parentCommentId]}
                            hideEditCommentButton={authUser['user_id'] !== parentAuthor.id}
                            hideDeleteCommentButton={authUser['user_id'] !== parentAuthor.id}
                            hideHideCommentButton={authUser['user_id'] === parentAuthor.id}
                            onReply={(value) => this.handleReplySend(value, parentCommentId)}
                            onViewRepliesClick={() => this.handleViewRepliesClick(postId, parentCommentId)}
                            onEditCommentSubmit={onEditCommentSubmit}
                            onHideCommentSubmit={onHideCommentSubmit}
                            onDeleteComment={onDeleteComment}
                        />
                    </div>
                    {replyThread}
                </div>
            );

        });

        return (
            <div className="post-card-comment-thread">
                <div className="load-previous-comments-container">
                    {
                        hasPreviousComments ?
                        <button
                            className="load-previous-comments-button"
                            onClick={this.handleLoadPreviousComments}
                        >
                            View previous
                        </button> : null
                    }
                </div>
                {threadList}
                <div className="comment-form-container">
                    <CommentForm
                        isVerified={isVerified}
                        isAuthenticated={isAuthenticated}
                        postId={postId}
                        onSubmit={this.handleCommentSubmit}
                    />
                </div>
            </div>
        );
    }
}
