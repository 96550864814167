import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import {Link} from 'react-router-dom';

import {formatNavigationQuery} from '../../utils/routing';

import './LoginPrompt.css';


function LoginPrompt({
    isOpen,
    title,
    subtitle,
    handleClose
}) {
    let [openState, setOpen] = useState(isOpen);

    function handleCloseState() {
        setOpen(false);
        handleClose(false);
    }

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    return (
        <Dialog
            open={openState}
            maxWidth="lg"
        >
            <div className="login-prompt-container">
                <div>
                    <IconButton
                        onClick={() => handleCloseState()}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <h2 className="logo">Boeiie</h2>
                <div className="title-container">
                    {
                        title ?
                        <h3 className="title">{title}</h3> : null
                    }
                    {
                        subtitle ?
                        <p className="sub-title">{subtitle}</p> : null
                    }
                </div>
                <Link
                    className="link-button login"
                    to={{
                        pathname: '/login/',
                        search: formatNavigationQuery()
                    }}
                >
                    Login
                </Link>
                <Link
                    className="link-button signup"
                    to={{
                        pathname: '/signup/',
                        search: formatNavigationQuery()
                    }}
                >
                    Sign up
                </Link>
            </div>
        </Dialog>
    );
}

export default LoginPrompt;
