export const INITIALIZE_GROUP_MEMBERS = 'initialize-group-members';

export const initializeGroupMembers = () => ({
    type: INITIALIZE_GROUP_MEMBERS,
});


export const REMOVE_GROUP_MEMBER_START = 'remove-group-member-start';
export const REMOVE_GROUP_MEMBER_SUCCESS = 'remove-group-member-success';
export const REMOVE_GROUP_MEMBER_FAIL = 'remove-group-member-fail';

export const removeGroupMemberStart = () => ({
    type: REMOVE_GROUP_MEMBER_START
});

export const removeGroupMemberSuccess = (member) => ({
    type: REMOVE_GROUP_MEMBER_SUCCESS,
    member,
});

export const removeGroupMemberFail = (errors) => ({
    type: REMOVE_GROUP_MEMBER_FAIL,
    errors
});


export const UPDATE_GROUP_MEMBER_ROLE_START = 'update-group-member-role-start';
export const UPDATE_GROUP_MEMBER_ROLE_SUCCESS = 'update-group-member-role-success';
export const UPDATE_GROUP_MEMBER_ROLE_FAIL = 'update-group-member-role-fail';

export const updateGroupMemberRoleStart = () => ({
    type: UPDATE_GROUP_MEMBER_ROLE_START
});

export const updateGroupMemberRoleSuccess = (members) => ({
    type: UPDATE_GROUP_MEMBER_ROLE_SUCCESS,
    members,
});

export const updateGroupMemberRoleFail = (errors) => ({
    type: UPDATE_GROUP_MEMBER_ROLE_FAIL,
    errors
});
