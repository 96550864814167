import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {
    Form,
    Field
} from 'react-final-form';

import GlobalHeader from '../../common/components/global_header/connector';
import {
    composeValidators,
    isRequired,
    minLength,
    maxLength,
    listSubmitErrors
} from '../../common/utils/validation';

import NoMatchPage from '../no_match/NoMatch';

import './Admin.css';


const generateRandomPassword = () => {
    let sampleLettersAndDigits = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let sampleLettersAndDigitsLength = sampleLettersAndDigits.length;
    let samplePunctuation = '!@#$%^+.&*?-_!@#$.%+^&*?-_!@#$%.^&*+?-_';
    let sample = sampleLettersAndDigits + samplePunctuation;
    let sampleLength = sample.length;
    let passwordLength = 12;
    let password = '';
    let passwordPart1 = '';
    let passwordPart2 = '';

    for (var i = 0; i < 2; i++) {
        passwordPart1 += sampleLettersAndDigits.charAt(Math.floor(Math.random() * sampleLettersAndDigitsLength));
    }

    for (var j = i; j < passwordLength; j++) {
        passwordPart2 += sample.charAt(Math.floor(Math.random() * sampleLength));
    }

    password = passwordPart1 + passwordPart2;

    return password
};

export default class Admin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submissionErrors: []
        };
    }

    componentDidMount(props) {
        let {onLoad} = this.props;

        onLoad();
    }

    handleSubmit = (formValues, {reset, ...rest}) => {
        let {onCreateNewUserSubmit} = this.props;

        return onCreateNewUserSubmit(formValues)
            .then(({user}) => {
                if (user) {
                    reset();
                    this.setState({
                        submissionErrors: []
                    });
                }
            })
            .catch(({errors}) => {
                this.setState({
                    submissionErrors: listSubmitErrors(errors)
                });
            });
    };

    render() {
        let {
            isAdmin,
            adminLoading
        } = this.props;
        let {submissionErrors} = this.state;
        let createUserSubmit;
        let randomPassword = generateRandomPassword();

        if (adminLoading) {
            return null;
        }

        if (!adminLoading && !isAdmin) {
            return (<NoMatchPage />);
        }

        return (
            <div id="admin">
                <GlobalHeader />
                <div className="page-wrapper">
                    <h3>Create New User</h3>
                    <Form
                        onSubmit={this.handleSubmit}
                        validate={formValues => {
                            let errors = {};

                            if (formValues.password !== formValues['confirm_password']) {
                                errors['confirm_password'] = 'Must match password.'
                            }

                            if (formValues.email !== formValues['confirm_email']) {
                                errors['confirm_email'] = 'Must match email.'
                            }

                            return errors;
                        }}
                        initialValues={{
                            password: randomPassword,
                            confirm_password: randomPassword
                        }}
                        render={({handleSubmit}) => {
                            createUserSubmit = handleSubmit;

                            return (
                                <form>
                                    <div>
                                        <p className="label">First Name</p>
                                    </div>
                                    <div className="field-container">
                                        <Field
                                            name="first_name"
                                            validate={composeValidators(isRequired, maxLength(30, 'first_name'))}
                                        >
                                            {
                                                ({input, meta}) => (
                                                    <div>
                                                        <input {...input} className="input-field" />
                                                        {
                                                            meta.error &&
                                                            meta.touched &&
                                                            <span className="error-message">{meta.error}</span>
                                                        }
                                                    </div>
                                                )
                                            }
                                        </Field>
                                    </div>
                                    <div>
                                        <p className="label">Last Name</p>
                                    </div>
                                    <div className="field-container">
                                        <Field
                                            name="last_name"
                                            validate={composeValidators(isRequired, maxLength(30, 'last_name'))}
                                        >
                                            {
                                                ({input, meta}) => (
                                                    <div>
                                                        <input {...input} className="input-field" />
                                                        {
                                                            meta.error &&
                                                            meta.touched &&
                                                            <span className="error-message">{meta.error}</span>
                                                        }
                                                    </div>
                                                )
                                            }
                                        </Field>
                                    </div>
                                    <div>
                                        <p className="label">Email</p>
                                    </div>
                                    <div className="field-container">
                                        <Field
                                            name="email"
                                            validate={composeValidators(isRequired, maxLength(50, 'email'))}
                                        >
                                            {
                                                ({input, meta}) => (
                                                    <div>
                                                        <input {...input} className="input-field" />
                                                        {
                                                            meta.error &&
                                                            meta.touched &&
                                                            <span className="error-message">{meta.error}</span>
                                                        }
                                                    </div>
                                                )
                                            }
                                        </Field>
                                    </div>
                                    <div>
                                        <p className="label">Confirm Email</p>
                                    </div>
                                    <div className="field-container">
                                        <Field
                                            name="confirm_email"
                                            validate={composeValidators(isRequired, maxLength(50, 'confirm_email'))}
                                        >
                                            {
                                                ({input, meta}) => (
                                                    <div>
                                                        <input {...input} className="input-field" />
                                                        {
                                                            meta.error &&
                                                            meta.touched &&
                                                            <span className="error-message">{meta.error}</span>
                                                        }
                                                    </div>
                                                )
                                            }
                                        </Field>
                                    </div>
                                    <div>
                                        <p className="label">Password</p>
                                    </div>
                                    <div className="field-container">
                                        <Field
                                            name="password"
                                            validate={composeValidators(
                                                isRequired,
                                                maxLength(50, 'password'),
                                                minLength(8, 'password')
                                            )}
                                        >
                                            {
                                                ({input, meta}) => (
                                                    <div>
                                                        <input {...input} className="input-field" />
                                                        {
                                                            meta.error &&
                                                            meta.touched &&
                                                            <span className="error-message">{meta.error}</span>
                                                        }
                                                    </div>
                                                )
                                            }
                                        </Field>
                                    </div>
                                    <div>
                                        <p className="label">Confirm Password</p>
                                    </div>
                                    <div className="field-container">
                                        <Field
                                            name="confirm_password"
                                            validate={composeValidators(
                                                isRequired,
                                                maxLength(50, 'confirm_password'),
                                                minLength(8, 'confirm_password')
                                            )}
                                        >
                                            {
                                                ({input, meta}) => (
                                                    <div>
                                                        <input {...input} className="input-field" />
                                                        {
                                                            meta.error &&
                                                            meta.touched &&
                                                            <span className="error-message">{meta.error}</span>
                                                        }
                                                    </div>
                                                )
                                            }
                                        </Field>
                                    </div>
                                    <div>
                                        <p className="label">Is Admin?</p>
                                    </div>
                                    <div className="field-container">
                                        <Field className="select-field" name="is_admin" defaultValue="no" component="select">
                                            <option value="no">No</option>
                                            <option value="yes">Yes</option>
                                        </Field>
                                    </div>
                                </form>
                            );
                        }}
                    />
                    <button className="submit-button" onClick={(e) => createUserSubmit()}>Create User</button>
                    <div className="submissionErrors">
                        {submissionErrors.map((error, index) => (
                            <p key={index} className="error-message">{error}</p>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}


Admin.propTypes = {
    isAdmin: PropTypes.bool,
    onCreateNewUserSubmit: PropTypes.func
};
