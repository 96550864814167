import {ACCEPTED_IMAGE_TYPES} from '../constants/media';


export const STORAGE_KEY = 'boeiie_app';

export let API_SERVER_HOSTNAME = '';

if (process.env.NODE_ENV === 'production') {
    API_SERVER_HOSTNAME = '/api';
} else if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_IS_LOCAL === 'true') {
    API_SERVER_HOSTNAME = 'http://127.0.0.1:8081/api';
} else if (process.env.NODE_ENV === 'development') {
    API_SERVER_HOSTNAME = 'http://127.0.0.1:8002';
}

export const fetchApiGet = (path) => {
    let authUser = localStorage.getItem(STORAGE_KEY);
    let url = `${API_SERVER_HOSTNAME}/${path}`;
    let authToken;

    if (authUser) {
        authUser = JSON.parse(authUser);
        authToken = `Token ${authUser.token}`;
    }

    return fetch(
        url,
        {
            method: 'GET',
            headers: {
                'Authorization': authToken
            }
        }
    ).then(response => {
        if (response.status >= 400 && response.status <= 600) {
            return response.json().then(err => {throw err;});
        }

        return response.json();
    })
    .catch((rejection) => Promise.reject(rejection));
};

export const fetchApiPost = (path, data, options={}, hasFormData=false) => {
    let authUser = localStorage.getItem(STORAGE_KEY);
    let url = `${API_SERVER_HOSTNAME}/${path}`;
    let headers = {
        'content-type': 'application/json',
        ...options.headers
    };
    let body;

    if (authUser) {
        authUser = JSON.parse(authUser);
        headers['Authorization'] = `Token ${authUser.token}`;
    }

    if (headers['content-type'] === 'application/json' && !hasFormData) {
        body = JSON.stringify(data);
    } else {
        body = data;
    }

    if (hasFormData) {
        delete headers['content-type'];

        body = buildFormData(data);
    }

    return fetch(
        url,
        {
            ...options,
            headers,
            method: 'POST',
            body
        }
    ).then((response) => {
        if (response.status >= 400 && response.status <= 600) {
            return response.json().then(err => {throw err;});
        }

        return response.json();
    })
    .catch((rejection) => Promise.reject(rejection));
};


export const fetchApiDelete = (path) => {
    let authUser = localStorage.getItem(STORAGE_KEY);
    let url = `${API_SERVER_HOSTNAME}/${path}`;
    let authToken;

    if (authUser) {
        authUser = JSON.parse(authUser);
        authToken = `Token ${authUser.token}`;
    } else {
        return Promise.reject({
            'errors': {
                'message': 'User must be authenticated to make this request.'
            }
        });
    }

    return fetch(
        url,
        {
            method: 'DELETE',
            headers: {
                'Authorization': authToken
            }
        }
    ).then(response => {
        if (response.status >= 400 && response.status <= 600) {
            return Promise.reject({'errors': {
                'message': 'There was an error.'
            }});
        }

        return Promise.resolve();
    })
    .catch((rejection) => Promise.reject(rejection));
};


function isFileImage(file) {
    const acceptedImageTypes = ACCEPTED_IMAGE_TYPES;

    return file && acceptedImageTypes.includes(file['type'])
}

const buildFormData = (data) => {
    let formData = new FormData();
    let keys = Object.keys(data);

    keys.forEach((key) => {
        if (isFileImage(data[key])) {
            let file = data[key];

            formData.append(key, file, file.name);
        } else {
            formData.append(key, data[key]);
        }
    });

    return formData;
};
