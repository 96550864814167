export const getAllGroupsFromState = (groupsState) => {
    let {
        allGroups={},
        byIds={}
    } = groupsState;
    let result = [];

    if (allGroups.list) {
        allGroups.list.forEach((groupId) => {
            result.push(byIds[groupId]);
        });
    }

    return result;
};

export const getMyGroupsFromState = (groupsState) => {
    let {
        myGroups={},
        byIds={}
    } = groupsState;
    let result = [];

    if (myGroups.list) {
        myGroups.list.forEach((groupId) => {
            result.push(byIds[groupId]);
        });
    }

    return result;
};

export const getAllGroupsPaginationFromState = (groupsState) => {
    let {
        allGroups={},
    } = groupsState;

    return allGroups.pagination || {next: {}, previous: {}};
};

export const getMyGroupsPaginationFromState = (groupsState) => {
    let {
        myGroups={},
    } = groupsState;

    return myGroups.pagination || {next: {}, previous: {}};
};
