import {
    LOAD_GROUP_START,
    LOAD_GROUP_SUCCESS,
    LOAD_GROUP_FAIL,
    JOIN_GROUP_START,
    JOIN_GROUP_SUCCESS,
    JOIN_GROUP_FAIL,
    UNJOIN_GROUP_START,
    UNJOIN_GROUP_SUCCESS,
    UNJOIN_GROUP_FAIL,
    CLEAR_GROUP_ERROR_STATE,
} from '../../common/actions/groups';

import {
    EDIT_GROUP_START,
    EDIT_GROUP_SUCCESS,
    EDIT_GROUP_FAIL,
} from './actions';


const loadGroupStartReducer = (state) => ({
    ...state
});

const loadGroupSuccessReducer = (state, {group}) => ({
    ...state,
    group,
    errors: null,
});

const loadGroupFailReducer = (state, {errors}) => ({
    ...state,
    errors
});

const editGroupStartReducer = (state) => ({
    ...state
});

const editGroupSuccessReducer = (state, {group}) => ({
    ...state,
    group,
    errors: null,
});

const editGroupFailReducer = (state, {errors}) => ({
    ...state,
    errors
});

const joinGroupStartReducer = (state) => ({
    ...state
});

const joinGroupSuccessReducer = (state, {member}) => ({
    ...state,
    errors: null,
});

const joinGroupFailReducer = (state, {errors}) => ({
    ...state,
});

const unjoinGroupStartReducer = (state) => ({
    ...state
});

const unjoinGroupSuccessReducer = (state, {member}) => ({
    ...state,
    errors: null,
});

const unjoinGroupFailReducer = (state, {errors}) => ({
    ...state,
    errors
});

const clearGroupErrorStateReducer = (state) => ({
    ...state,
    errors: null
});

const GROUP_PAGE_REDUCER_MAP = {
    [LOAD_GROUP_START]: loadGroupStartReducer,
    [LOAD_GROUP_SUCCESS]: loadGroupSuccessReducer,
    [LOAD_GROUP_FAIL]: loadGroupFailReducer,
    [EDIT_GROUP_START]: editGroupStartReducer,
    [EDIT_GROUP_SUCCESS]: editGroupSuccessReducer,
    [EDIT_GROUP_FAIL]: editGroupFailReducer,
    [JOIN_GROUP_START]: joinGroupStartReducer,
    [JOIN_GROUP_SUCCESS]: joinGroupSuccessReducer,
    [JOIN_GROUP_FAIL]: joinGroupFailReducer,
    [UNJOIN_GROUP_START]: unjoinGroupStartReducer,
    [UNJOIN_GROUP_SUCCESS]: unjoinGroupSuccessReducer,
    [UNJOIN_GROUP_FAIL]: unjoinGroupFailReducer,
    [CLEAR_GROUP_ERROR_STATE]: clearGroupErrorStateReducer,
};

const groupPageReducer = (initialState={}, action={}) => {
    let reducer = GROUP_PAGE_REDUCER_MAP[action.type];
    let nextState = initialState;

    if (reducer) {
        nextState = reducer(initialState, action);
    }

    return nextState;
};

export default groupPageReducer;
