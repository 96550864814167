import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import GlobalHeader from '../../common/components/global_header/connector';

import './NoMatch.css';


export default class NoMatchPage extends Component {
    render() {
        return (
            <div id="no-match">
                <GlobalHeader />
                <div className="content-body-wrapper">
                    <div className="banner-container">
                        <h2>Oooops...</h2>
                        <h3>Sorry, this page does not exist.</h3>
                    </div>
                    <p>
                        <Link
                            style={{'color': 'blue', 'textDecoration': 'underline'}}
                            to="/"
                        >
                            Return to home.
                        </Link>
                    </p>
                </div>
            </div>
        );
    }
}
