export const CREATE_REACTION_START = 'create-reaction-start';
export const CREATE_REACTION_SUCCESS = 'create-reaction-success';
export const CREATE_REACTION_FAIL = 'create-reaction-fail';

export const createReactionStart = (targetEntityType, targetEntityId, reactionEmojiType) => ({
    type: CREATE_REACTION_START,
    targetEntityType,
    targetEntityId,
    reactionEmojiType
});

export const createReactionSuccess = (reaction, authUserId) => ({
    type: CREATE_REACTION_SUCCESS,
    reaction,
    authUserId
});

export const createReactionFail = (errors) => ({
    type: CREATE_REACTION_FAIL,
    errors
});


export const REMOVE_REACTION_START = 'remove-reaction-start';
export const REMOVE_REACTION_SUCCESS = 'remove-reaction-success';
export const REMOVE_REACTION_FAIL = 'remove-reaction-fail';

export const removeReactionStart = (reactionId) => ({
    type: REMOVE_REACTION_START,
    reactionId
});

export const removeReactionSuccess = (reactionId, targetEntityId, authUserId, targetEntityType) => ({
    type: REMOVE_REACTION_SUCCESS,
    reactionId,
    targetEntityId,
    targetEntityType,
    authUserId
});

export const removeReactionFail = (errors) => ({
    type: REMOVE_REACTION_FAIL,
    errors
});
