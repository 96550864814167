import queryString from 'query-string';


export const isDemo = () => {
    let params = queryString.parse(window.location.search);

    return params['app_mode'] === 'demo';
};

export const formatNavigationQuery = (otherParams={}) => {
    let result = '';

    if (isDemo()) {
        let joinedParams = queryString.stringify({
            ...otherParams,
            'app_mode': 'demo'
        });

        result = `?${joinedParams}`;
    } else {
        result = `?${queryString.stringify({
            ...otherParams,
        })}`;
    }

    return result;
};

export const getRedirectRoute = (urlSearchString) => {
    let urlParams = queryString.parse(urlSearchString);
    let redirectPageId = urlParams['redirect-page-id'];
    let redirectSlug = urlParams['redirect-slug'];
    const REDIRECT_PAGE_MAP = {
        'account': '/account/',
        'post': `/post/${redirectPageId}/`,
        'groups': `/groups/`,
        'group-page': `/groups/${redirectPageId}/${redirectSlug}/`,
        'group-members': `/groups/${redirectPageId}/${redirectSlug}/members/`,
    };
    let route = REDIRECT_PAGE_MAP[urlParams['redirect-page']] || '/';

    return route;
};
