export const SIGNUP_USER_SUBMIT_START = 'signup-user-submit-start';
export const SIGNUP_USER_SUBMIT_SUCCESS = 'signup-user-submit-success';
export const SIGNUP_USER_SUBMIT_FAIL = 'signup-user-submit-fail';

export const signupUserSubmitStart = () => ({
    type: SIGNUP_USER_SUBMIT_START
});

export const signupUserSubmitSuccess = (authUser) => ({
    type: SIGNUP_USER_SUBMIT_SUCCESS,
    authUser
});

export const signupUserSubmitFail = (errors) => ({
    type: SIGNUP_USER_SUBMIT_FAIL,
    errors
});
