import React from 'react';
import {Link} from 'react-router-dom';

import GlobalHeader from '../../common/components/global_header/connector';

import './TermsOfService.css';


function TermsOfService() {
    return (
        <div id="terms-of-service">
            <GlobalHeader />
            <div className="page-body-container">
                <div className="content-container">
                    <p><strong>THESE TERMS OF USE CONTAIN AN ARBITRATION CLAUSE AND A CLASS ACTION WAIVER CLAUSE.</strong></p>
                    <p>
                        <u>
                            THIS TERMS OF SERVICE AGREEMENT IS SUBJECT TO ARBITRATION. YOU ACKNOWLEDGE AND AGREE THAT WHILE YOU MAY STILL PURSUE CLAIMS AGAINST US, WITH A FEW EXCEPTIONS (FOR EXAMPLE, IF YOU SUBMIT A VALID OPT-OUT NOTICE AS DESCRIBED IN
                            &lrm;SECTION 15(g)), ALL DISPUTES BETWEEN YOU AND BOEIIE INC. WILL ULTIMATELY BE RESOLVED in a BINDING ARBITRATION proceeding (and not in a a court), only on an individual basis (and not as a class action), and without a JURY (SEE &lrm;SECTION 15 FOR
                            MORE INFORMATION ON DISPUTE RESOLUTION).
                        </u>
                        <strong>PLEASE READ THESE TERMS OF USE CAREFULLY TO UNDERSTAND YOUR RIGHTS AND RESPONSIBILITIES.</strong>
                    </p>
                    <div className="center">
                        <h1>Boeiie</h1>
                        <h3>Boeiie Terms of Service</h3>
                        <p><i>Last updated: October 3, 2020</i></p>
                        <br />
                        <p><span >PLEASE READ THESE TERMS OF SERVICE CAREFULLY.</span></p>
                    </div>
                    <div className="box">
                        <p>
                            <span>These Terms of Service (the &ldquo;</span><span >TOS</span><span>&rdquo;) set forth the legally binding terms and conditions that govern your use of the Services (as defined see </span>
                            <span >&lrm;Section 1(a)</span><span>) fully controlled and operated by Boeiie, LLC (&ldquo;</span><span >Boeiie,</span><span>&rdquo; &ldquo;</span><span >we,</span><span>&rdquo; &ldquo;</span>
                            <span >us,</span><span>&rdquo; or &ldquo;</span><span >our</span><span >&rdquo;).</span>
                        </p>
                        <p>
                            <span>By clicking on &ldquo;</span><span >I agree</span>
                            <span>
                                &rdquo; (or a similar button) or by using or accessing the Services, you agree to be legally bound by the TOS of Boeiie. We reserve the right, at our sole discretion, to change, modify, or otherwise alter the TOS at any time subject
                                to,
                            </span>
                            <span >&lrm;Section 1(b)</span>
                            <span >
                                . Such changes and/or modifications shall become effective immediately upon posting. You are solely responsible for checking to see if these TOS have been updated and your continued use of the Services following the posting of
                                changes and/or modifications will constitute your acceptance of the revised TOS. Please review the TOS frequently for any such changes.
                            </span>
                        </p>
                        <p >
                            <span>
                                We offer the Services to you subject to your acceptance of these TOS and all other operating rules, policies, and procedures that may be published by us to the Services including, but not limited to, our privacy policy (the &ldquo;
                            </span>
                            <span >Privacy Policy,</span><span>&rdquo; available at </span><Link to="/privacy-policy/">https:/boeiie.com/privacy-policy/</Link><span>), acceptable use policy (the &ldquo;</span><span >AUP</span>
                            <span>,&rdquo; available at </span><Link to="/acceptable-use/">https://boeiie.com/acceptable-use/</Link><span>), or any other policy to which you may agree through your acceptance of an Ancillary Agreement (as defined in </span>
                            <span >&lrm;Section 1(c)</span><span>) or other specific agreement presented to you (collectively, the &ldquo;</span><span >Boeiie Policies</span>
                            <span >&rdquo;). Each of the Boeiie Policies is incorporated herein by reference.</span>
                        </p>
                        <p >
                            <span>If you do not agree with all of the following TOS, please do not use the Services. By clicking on &ldquo;</span><span >I agree</span>
                            <span >&rdquo; (or a similar button), you agree to be bound by the TOS.</span>
                        </p>
                    </div>
                    <p><strong>IF YOU DO NOT EXPRESSLY AGREE TO ALL OF THESE TOS, THEN YOU MAY NOT ACCESS OR USE OUR WEBSITE.</strong></p>

                    <h3><span>Section 1. &nbsp;&nbsp;&nbsp;&nbsp; The Services</span></h3>
                    <ol type="a">
                        <li>
                            <p>
                                <strong><u>The Services</u></strong>
                                <span>
                                    . These TOS are for the website located at www.Boeiie.com, any of its subdomains or derivative URLs, and any other software, application, and/or other interactive service provided through those websites that include an
                                    authorized link to the TOS (collectively, the &ldquo;
                                </span>
                                <span >Services</span><span>&rdquo;). By accessing or using the Services, you become a &ldquo;</span><span >User</span>
                                <span >
                                    &rdquo;. We reserve the right, at any time, for any reason, at our sole discretion, and without prior notice to (i) modify, replace, or stop providing; (ii) create usage limits for; or (iii) change, improve, or correct
                                    information, materials, and descriptions on or of the Services or any portions thereof. It is your responsibility and obligation to review the Services periodically for such changes and modifications.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong><u>TOS Subject to Change</u></strong><span>. We reserve the right, at our sole discretion and without prior notice, to amend, modify, or replace the TOS at any time for any reason (each such instance, &ldquo;</span>
                                <span>Updated TOS</span>
                                <span>
                                    &rdquo;). Updated TOS will become effective on the date it we post it. You are solely responsible for frequently checking to see if these TOS have been updated. References to &ldquo;TOS&rdquo; herein, except for in this
                                    sentence, are deemed to be followed by &ldquo;and each subsequent Updated TOS which are incorporated herein by reference.&rdquo; Your continued use of the Services following the posting of any Updated TOS constitutes
                                    acceptance of the Updated TOS. You agree not to contest the admissibility or enforceability of the Services&#39; electronically stored copy of the TOS in any proceeding arising out of or related to the TOS. If there is a
                                    conflict between two versions of the TOS to which you have agreed or been deemed to agree, the more recent version shall take precedence unless it is expressly stated otherwise.
                                </span>
                                <span >&nbsp;WE STRONGLY ADVISE YOU TO FREQUENTLY REVIEW THE SITES FOR ANY CHANGES AND CONTACT YOUR LEGAL ADVISOR WITH ANY QUESTIONS.</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong><u>Ancillary Agreements</u></strong>
                                <span>
                                    . Your use of some services or areas within the Services may be subject to additional specific terms and conditions and/or agreements (that you may be required to affirmatively agree with) between you and us that set out
                                    additional, substantive terms, which terms shall govern specific technical use of the Services and/or your relationship with us when you make use of the Services (each an &ldquo;
                                </span>
                                <span>Ancillary Agreement</span>
                                <span>
                                    &rdquo;). These TOS will continue to apply to you even after you have agreed to an Ancillary Agreement. If there is a conflict between these TOS and any or the Boeiie Policies or Ancillary Agreements, these TOS shall take
                                    precedence unless expressly stated otherwise in the Services Policy or Ancillary Agreement. References to TOS herein, except for those references in this &lrm;
                                </span>
                                <span >Section 1(c)</span><span>&nbsp;are deemed to be followed by &ldquo;and/or each of the Boeiie Policies and Ancillary Agreements which are incorporated herein by reference.&rdquo;</span>
                            </p>
                        </li>
                    </ol>

                    <h3>Section 2. &nbsp;&nbsp;&nbsp;&nbsp; Eligibility for Use of the Services; User Access; and Registration.</h3>
                    <ol type="a">
                        <li>
                            <p>
                                <strong><u>Eligibility to Use the Services.</u> </strong>
                                You may access, browse, and use the publicly available portions of our Services and Services without registering as a User, however, certain features are only accessible to registered Users.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong><u>Services Intended for U.S.-Based Users.</u> </strong>
                                <span>
                                    The Services is controlled and operated from facilities within the United States. We make no representations that the Services is appropriate or available for use in any other country, territory, or jurisdiction. You shall
                                    not access the Services from territories where the content, including, but not limited to, any Services software, design, text, images, photographs, infographics, illustrations, audio clips, video clips, artwork, graphic
                                    material, information, materials, alerts, texts, articles, assessments, checklists, forms, ratings, design, data, source code, analytics, photos, software, and other copyrightable or otherwise legally protectable elements of
                                    the Services, and the selection, sequence, &ldquo;look and feel&rdquo;, and arrangements thereof, and trademarks, service marks, trade names, and/or other information (collectively, &ldquo;
                                </span>
                                <span >Content</span>
                                <span>
                                    &rdquo;) on the Services are prohibited. It is your sole responsibility to be aware of and to observe all applicable laws and regulations of your country, territory, or jurisdiction of residence and you are must satisfy
                                    yourself before accessing the Services that you are allowed to access the Services under those laws and regulations.
                                </span>
                                <strong> IF YOU ARE LOCATED OUTSIDE OF THE UNITED STATES, YOUR USE OR ACCESS THE SITES SOLELY AT YOUR OWN RISK AND INITIATIVE.</strong>
                            </p>
                        </li>
                        <li >
                            <p>User Access and Registration.</p>
                            <ol type="i" start="1">
                                <li >
                                    <p>
                                        <span>
                                            If we offer user registration on a website, application, or other interactive service, there may be a few options for you to register including, but not limited to, third-party social platform sign-on (further described in
                                            our Privacy Policy). In each case, you may be required to either use a pre-existing username and password or select a new username and password combination. Additional information may be requested and/or required during the
                                            registration process. Once you have registered, we may recognize and permit you to login you when you visit the same website, application, or other interactive service across devices (such as when you login). For your online
                                            privacy and security, if other people have access to your devices, we recommend logging out after visiting any website, application, or other interactive service that is associated with your account.
                                        </span>
                                    </p>
                                </li>
                                <li >
                                    <p>
                                        <span>
                                            You represent and warrant that all information that you provide or will provide shall be true, accurate, current, and complete. We and our affiliates and agents are entitled to rely upon the information you provide as true,
                                            accurate, current, and complete without any independent verification. We reserve the right to suspend or terminate your account if any information provided during the registration process or thereafter proves to be untrue,
                                            inaccurate, not current, or incomplete. You shall indemnify, defend, and hold Boeiie and any Boeiie Associates harmless against any Claim (as defined in
                                        </span>
                                        <span >&lrm;Section 14</span><span>) arising out of or related to the truth, accuracy, currentness, and completeness of the information you provide. &ldquo;</span><span >Boeiie Associates</span>
                                        <span >
                                            &rdquo; means any of Boeiie&rsquo;s officers, directors, employees, agents, representatives, licensors, advertisers, suppliers, and operational service providers, or any of their respective officers, directors, employees,
                                            agents, representatives, licensors, advertisers, suppliers, and operational service providers.
                                        </span>
                                    </p>
                                </li>
                                <li >
                                    <p>
                                        <span >
                                            We reserve the right, at any time, for any reason, at our sole discretion, and without prior notice to refuse registration of or cancel any user account. You agree that all information you provide to register with the
                                            Services or otherwise, including, but not limited to, through the use of any interactive features on the Services, is governed by our Privacy Policy, and you consent to all actions we take with respect to your information
                                            consistent with our Privacy Policy. We reserve the right to remove any Content or terminate, limit, or restrict your access to the Services and/or the licenses granted herein for any reason, at any time, and in our sole
                                            discretion. We also have the right to disable any user name, password, or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you
                                            have violated any provision of these TOS.
                                        </span>
                                    </p>
                                </li>
                            </ol>
                        </li>

                        <li>
                            <p>
                                <strong><u>Additional Features</u></strong>
                                <span>
                                    . We may also provide you with access to certain features or functions that we may offer on the Services, such as the option to participate in newsletters, commenting, posting, and creating, editing, and maintaining member
                                    profile and/or group pages. To sign up for or participate in these features or functions, you may be required to provide us with certain information about you in order for us to personalize and/or allow you to use such
                                    features and functions. For example, if you order any of our goods or services through an e-commerce portion of the Services, in addition to your name and contact information, we or our payment processing providers may also
                                    collect and/or maintain your billing address, shipping address, selections, credit card or other payment information, order number, and information about your computer&#39;s internet address and the referring URL from which
                                    you may have been referred or directed the Services. The Services may also provide features, functions, and/or services powered by a third-party social media network or other third party. For example, information may be
                                    disclosed to a third party through plug-ins offered by such third party such as authentication, &ldquo;likes,&rdquo; &ldquo;shares,&rdquo; or commenting features. Please take care when using any third-party service and
                                    familiarize yourself with the third party&#39;s policies and agreements, if any, that govern such use. To learn how we may use the information we collect during the registration process, please read our Privacy Policy.
                                </span>
                            </p>
                        </li>
                    </ol>

                    <h3>Section 3. &nbsp;&nbsp;&nbsp;&nbsp; Posts</h3>

                    <ol type="a" start="1">
                        <li >
                            <p>
                                <span >User Contributions</span>
                                <span>
                                    . Portions of the Services may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, public and private user posts and comments, public and private groups, posting, commenting, and
                                    other interactive features (collectively, &ldquo;
                                </span>
                                <span >Interactive Services</span>
                                <span>
                                    &rdquo;) that allow users to post, submit, publish, display, or transmit comments, messages, or otherwise exchange information, ideas, opinions, photographs, images, video, creative works, or any other material (each a
                                    &ldquo;
                                </span>
                                <span >Post</span><span >&rdquo;) along with their username. Posts are considered non-confidential and non-proprietary. You represent and warrant that: </span>
                            </p>

                            <ol type="i" start="1">
                                <li>
                                    <p>
                                        <span>
                                            you have all necessary rights in and to all Posts you provide and all information they contain and that such Posts shall not infringe any proprietary or other rights of third parties or contain any libelous, tortious, or
                                            otherwise unlawful information. If your Post incorporates the name, voice, likeness, and/or image of any individual, you represent and warrant that you have the right to grant Boeiie permission to use such name, voice,
                                            likeness, and/or image of such individual appearing in the Post in all media throughout the world in perpetuity.
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <span>
                                            you own or control all rights in and to your Posts and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors, and
                                            assigns; and
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p><span >all of your Posts do and will comply with these TOS. </span></p>
                                </li>
                            </ol>

                            <p>
                                You understand and acknowledge that you are responsible for any Posts you submit or contribute, and you, not we, have fully responsibility for such content, including its legality, reliability, accuracy, and appropriateness. Boeiie
                                will fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity of anyone posting any such information or materials on the Services. You shall indemnify, defend, and hold
                                Boeiie and any Boeiie Associates harmless against any Claim arising out of or related to the Content that you post to the Services. All Posts must comply with the Content Standards set out in <u>Section 4</u>.
                            </p>
                        </li>

                        <li>
                            <p>
                                <strong><u>Non-Commercial Use</u></strong>
                                <span >
                                    . Posts may be used for noncommercial purposes only. Users may not post any information intended to promote and/or generate revenue for any third party business activity. Although Boeiie periodically monitors the content
                                    posted on the Services, we are not and will not be responsible for the views or opinions expressed by third parties. Nonetheless, we will make every effort to ensure that Posts best serve the interests of all users and,
                                    therefore, we reserve the right to refuse, edit, or delete comments or messages that violate the above-referenced rules, as well as revoke the privileges of users who do not comply with such rules.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong><u>Moderators</u></strong>. Some Users may be awarded special privileges to help facilitate and manage groups (&ldquo;<span>Moderators</span>
                                <span>
                                    &rdquo;). Moderators may delete posts and comments or expel other Users from a group in addition to other rights that may be available to them. We are not responsible, or liable to any third party, for the choices or
                                    behavior of Moderators. Moderators are not Boeiie Associates.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong><u>License to Posts</u></strong>
                                <span >
                                    . By submitting a Post, you hereby authorize us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns and other Services users to use, reproduce, modify, perform,
                                    display, distribute, and otherwise disclose to third parties any such material according to your account settings including, but not limited to, in whole or in part, on a royalty-free basis, throughout the universe in
                                    perpetuity in any and all media, now known or hereafter devised, alone, or together or as part of other material of any kind or nature, including without limitation commercial use on and advertising and promotion of the
                                    Services. Without limiting the foregoing, we have the right (but not the obligation) to use and change the Posts in any manner that we determine, with or without the Username. In no event, however, shall we assume or have
                                    any responsibility or liability for the manner in which the Post is exploited or for any claim, damages, or losses resulting from their use and/or appearance on the Servicess or elsewhere.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong><u>No Endorsement</u></strong>
                                <span >
                                    . The information presented on or through the Services is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on
                                    such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Services, or by anyone who may be informed of
                                    any of its contents. The Services may include content provided by third parties, including materials provided by other users or third-party licensors, syndicators, aggregators, and/or reporting services. All statements
                                    and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by us, are solely the opinions and the responsibility of the person or entity
                                    providing those materials. Posts do not reflect the views of Boeiie. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by you or any other user of the
                                    Services. We neither represent nor endorse the accuracy or reliability of any advice, opinion, statement, suggestion, or other information offered by third parties that is displayed or distributed throughout our website.
                                    Users acknowledge that any reliance upon any such advice, opinion, statement, suggestion, or information shall be at your sole risk.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong><u>California Removal Requests</u></strong>
                                <span>
                                    . Users of the Services who are California residents and under 18 years of age may request and obtain removal of Posts that they themselves post by emailing us at privacy@boeiie.com. All requests must (i) be labeled
                                    &ldquo;California Removal Request&rdquo; in the email subject line and (ii) provide a description of the content or information in your Post that you want removed and information reasonably sufficient to permit us to locate
                                    the material, including the name and URL (if applicable) of the website, application, or other interactive service. We will not accept removal requests except as stated in this &lrm;
                                </span>
                                <span >Section 3(f)</span>
                                <span >
                                    . We are not responsible for notices that are not labeled or sent properly and we are not obligated to respond if you do not provide complete information. Requests for removal do not ensure complete or comprehensive removal
                                    of the content or information from the Services. For example, content that you have posted may be republished or reposted by another user or third party.
                                </span>
                            </p>
                        </li>

                    </ol>

                    <h3>Section 4. &nbsp;&nbsp;&nbsp;&nbsp; Text Messages and Promotions.</h3>

                    <ol type="a" start="1">
                        <li>
                            <p>
                                <strong><u>Text Messages</u></strong>
                                <span>
                                    . Consistent with local law, appropriately aged visitors may have the opportunity to register for special promotions, services, news, programming, or information delivered via SMS or MMS messaging services (collectively,
                                    &ldquo;
                                </span>
                                <span >Text</span><span>&nbsp;</span><span >Messages</span>
                                <span>
                                    &rdquo;) on wireless devices such as mobile phones. Except for the first message in which you may opt in to such services, we will not send any Text Messages until we have obtained your consent in accordance with the nature
                                    of the Text Service and applicable laws, rules, and regulations (&ldquo;
                                </span>
                                <span >Opting-In</span>
                                <span >
                                    &rdquo;). The information requested or transmitted as part of the registration process includes your wireless telephone number and may include other information, such as your preferences regarding goods or services, other
                                    survey information, and/or an email address. Depending on the Text Message and the information collected, you may also be required to confirm your agreement to the TOS (including, but not limited to, the Privacy Policy). You
                                    acknowledge, understand, and agree that by Opting-In, you may be charged by your wireless carrier for receiving or sending Text Messages. The standard messaging rates of your wireless carrier shall apply to all Text
                                    Messages. Under no circumstances shall we be responsible for any wireless service charges incurred by you or by a person that has access to your wireless device, telephone number, or email address. If you change or
                                    deactivate your wireless telephone number, you agree to notify us when your wireless telephone number is no longer associated with you and identify such wireless phone number to ensure that future messages directed to you
                                    are not sent to the person to whom your old number has been or will be assigned. You understand, acknowledge and agree that we may, at our sole discretion and without liability to you or any user, terminate our offer of any
                                    specific Text Message service at any time without advance notice. Boeiie may provide notice of terminations or changes in services on the Services.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong><u>Promotions</u></strong>
                                <span>
                                    . From time to time, we or our suppliers or advertisers may conduct promotions on or through the Services including, but not limited to, auctions, contests, sweepstakes, electronic postcards, or similar (collectively &ldquo;
                                </span>
                                <span >Promotions</span>
                                <span>
                                    &rdquo;). Promotions may have additional terms, conditions, and/or rules, which will be posted or otherwise made available to you and, for purposes of each, are deemed incorporated into and form a part of these TOS (the
                                    &ldquo;
                                </span>
                                <span >Contest Rules</span>
                                <span >&rdquo;). If any conflict arises between these TOS and Contest Rules, the Contest Rules shall govern for purposes of your participation or involvement in the Promotion to which the Contest Rules relate.</span>
                            </p>
                        </li>
                    </ol>

                    <h3>Section 5. &nbsp;&nbsp;&nbsp;&nbsp; Content Standards; Acceptable Use.</h3>

                    <ol type="a" start="1">
                        <li>
                            <p>
                                <u>Content Standards</u>. The following content standards apply all Posts. Posts must comply in their entirety with all applicable federal, state, local and international laws and regulations. Without limiting the foregoing, no Post may:
                            </p>

                            <ul>
                                <li>
                                    <p>
                                        <span>
                                            violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be
                                            in conflict with these TOS and the ancillary agreements;
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <span>
                                            involve uploading, posting, emailing, transmitting, or otherwise making available any Posts that you do not have a right to make available under any law or under contractual or fiduciary relationships (such as inside
                                            information, proprietary, and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements);
                                        </span>
                                    </p>
                                </li>
                                <li >
                                    <p ><span >promote any illegal activity, or advocate, promote, or assist any unlawful act;</span></p>
                                </li>
                                <li >
                                    <p ><span >encourage conduct that would constitute a criminal offense or that gives rise to civil liability;</span></p>
                                </li>
                                <li >
                                    <p ><span >instigates or encourages others to commit illegal activities or cause injury or property damage to any person;</span></p>
                                </li>
                                <li >
                                    <p ><span >contain any personally identifiable information or other information, which might be used to identify or locate a user;</span></p>
                                </li>
                                <li >
                                    <p >
                                        <span >impersonate any person, business, or entity, including Boeiie, Boeiie Associates, moderators, or in any way falsely states or misrepresents your identity or affiliation with any other person or entity;</span>
                                    </p>
                                </li>
                                <li >
                                    <p ><span >involve forging headers or otherwise manipulating identifiers in order to disguise the origin of any Posts;</span></p>
                                </li>
                                <li >
                                    <p ><span >harm minors in any way;</span></p>
                                </li>
                                <li >
                                    <p >
                                        <span >
                                            be threatening, abusive, hateful, defamatory, slanderous, libelous, deceptive, fraudulent, invasive of another&#39;s privacy, tortious, pornographic, vulgar, profane, obscene, or transmit any information or data that we deem
                                            in our sole discretion to be inappropriate for the Services;
                                        </span>
                                    </p>
                                </li>
                                <li >
                                    <p >
                                        <span >
                                            victimize, harasse, &ldquo;stalk,&rdquo; degrade, attack, or intimidate an individual or group of individuals on any basis, including but not limited to religion, gender, sexual orientation, race, ethnicity, age, or
                                            disability;
                                        </span>
                                    </p>
                                </li>
                                <li >
                                    <p >
                                        <span >
                                            disrupt the normal flow of dialogue, cause a screen to &ldquo;scroll&rdquo; faster than other users of the Services are able to type, or otherwise act in a manner that negatively affects other users&#39; ability to engage in
                                            real time exchanges;
                                        </span>
                                    </p>
                                </li>
                                <li >
                                    <p >
                                        <span >cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm, annoy, or otherwise interfere with the use or enjoyment of the Services by others;</span>
                                    </p>
                                </li>
                                <li >
                                    <p >
                                        <span >involve commercial activities or sales, such as contests, sweepstakes, other sales promotions, barter, or advertising or promote and/or generate revenue for any third party business activity; or</span>
                                    </p>
                                </li>
                                <li >
                                    <p ><span >violate these TOS or any guidelines or policies posted on the Services.</span></p>
                                </li>
                            </ul>

                        </li>

                        <li >
                            <p>
                                <span>
                                    <u>No Profanity</u>. Boeiie strongly discourages the use of profanity or obscenities in Posts. Any Posts containing prohibited language will not be posted on the Services. For purposes of clarity, &ldquo;masked&rdquo; vulgarity, obscenity, or
                                    profanity (e.g.
                                </span>
                                <span >, </span>
                                <span >
                                    &ldquo;f*cK&rdquo;, &ldquo;@$$&rdquo;, or &ldquo;$#!+&rdquo;) is deemed equivalent to including the actual objectionable word, phrase, or symbol in your Post. Boeiie reserves the right to delete any such material from the
                                    Services and to cooperate fully with any investigation of any person or persons who violate the TOS.
                                </span>
                            </p>
                        </li>
                        <li >
                            <p>
                                <u>Acceptable Use</u><span>. You must agree to our AUP as may be updated by us from time to time and available at </span>
                                <span ><a href="/acceptable-use">https://boeiie.com/acceptable-use/</a></span>
                                <span >.</span>
                                <span>
                                    &nbsp;You may not (i) use the Services in any manner that could damage, disable, overburden, or impair our servers or networks; (ii) use the Services for service bureau or time-sharing purposes or in any other way allow
                                    third parties to exploit the Services; (iii) provide Services passwords or other log-in information to any third party; (iv) attempt to gain unauthorized access to any services, user accounts, computer systems, or networks
                                    through hacking, password mining, or any other means; (v) share nonpublic Services features or Content with any third party; (vi) access the Services in order to (1) build a product or service that competes with or uses
                                    similar ideas, features, functions, or graphics, or (3) copy any ideas, features, functions, or graphics of the Services; or (vii) engage in web scraping or data scraping on or related to the Services including, but not
                                    limited to, collection of information through (1) any software that simulates human activity or (2) any bot or web crawler. If we suspect any breach of the requirements of this
                                </span>
                                <span >&lrm;Section 5(c)</span>
                                <span>
                                    , we reserve the right to suspend your access to the Services without advanced notice in addition to such other remedies as we may have. Neither these TOS nor our AUP requires that we take any action against you or any other
                                    third party for violating the AUP, this
                                </span>
                                <span >&lrm;Section 5(c)</span>
                                <span >, or these TOS, and we remain free to take any such action we see fit. We may take any legal and technical remedies to prevent the violation of this provision and to enforce these TOS.</span>
                            </p>
                        </li>
                        <li >
                            <p>
                                <span>
                                    <u>User Conduct</u>. You are solely responsible and liable for: (x) your use of the Services including, but not limited to, your conduct, unauthorized-user conduct, and any conduct that would violate the requirements of the TOS applicable to
                                    you; and (y) any use of the Services through your account, whether authorized or unauthorized. In addition:
                                </span>
                            </p>

                            <ol type="i" start="1">
                                <li >
                                    <p>
                                        <i>Unauthorized Access.</i>
                                        <span >
                                            You must take reasonable steps to prevent unauthorized access to the Services including, but not limited to, by protecting your passwords and other log-in information. You are solely responsible (1) for maintaining the
                                            confidentiality of your password and your account, and (2) for periodically changing your password to maintain security. You shall not share your password, let anyone else access your account, or do anything else that might
                                            jeopardize the security of your account. You shall not transfer your account to anyone without first getting our express written permission. You acknowledge and agree that you are liable for any damages or losses to Boeiie
                                            and any other user by any use of your account, either authorized or unauthorized and you shall indemnify, defend, and hold Boeiie and any Boeiie Associates harmless against any Claim arising out of or related to your failure
                                            to maintain the confidentiality of your password and account.
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i>Requirement to Notify of Compromised Account</i>
                                        <span>
                                            . You must notify us immediately of any known or suspected unauthorized use of the Services or breach of its security and you shall use your best efforts to stop any unauthorized use. If you suspect that unauthorized access
                                            to your account or the Services has occurred including, but not limited to, if you have concerns that your username or password may have been compromised, you must notify us in writing through a secure method (which may not
                                            be through your Services account) at
                                        </span>
                                        <span> privacy@boeiie.com</span><span >&nbsp;</span>
                                        <span>&nbsp;</span><span >as soon as practicable, but in no case in less than 2 hours from the discovery of any unauthorized use of your account or any other breach of security.</span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i>Compliance with Laws</i>
                                        <span>
                                            . You acknowledge and agree that you are liable for compliance with all applicable laws including, but not limited to, laws governing the protection of personally identifiable information and other laws applicable to the
                                            protection of electronic data. You are solely responsible for ensuring compliance with the laws of your specific jurisdiction, as well as any restrictions that you may be subject to by a department of your local government
                                            or any state or federal government agency or department whether within the United States or elsewhere.
                                        </span>
                                    </p>
                                </li>
                            </ol>

                        </li>

                        <li>
                            <p>
                                <span>
                                    <u>Content Use Limitations</u>. Your use of the Services and its Content, whether supplied by us, any other User, or any other third party may only be used for the lawful and intended purposes expressly authorized by us. Any misuse or unauthorized use of
                                    the Services, the Content, or other violations of the TOS, may violate applicable law, including, but not limited to, copyright laws (including the Digital Millennium Copyright Act), trademark laws, laws of privacy, laws of
                                    publicity, identity theft, and communications statutes and regulations. You agree and authorize Boeiie to terminate your account and access to the Services at any time with or without notice and report you to the appropriate
                                    authorities and/or other interested parties, such as a claimed intellectual property owner if we become aware of any such misuse or unauthorized use.
                                </span>
                            </p>
                        </li>

                        <li>
                            <p>Linking to the Services and Social Media Features.</p>

                            <ol type="i" start="1">
                                <li>
                                    <p>
                                        <span>
                                            You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of
                                            association, approval, or endorsement on our part without our express written consent.
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <span>
                                            The Services may provide certain social media features that enable you to: (1) link from your own or certain third-party websites to certain content on the Services; (2) send emails or other communications with certain
                                            content, or links to certain content, on the Services; and (3) cause limited portions of content on the Services to be displayed or appear to be displayed on your own or certain third-party websites ((1), (2), and (3),
                                            collectively, &ldquo;
                                        </span>
                                        <span >Social Media Features</span><span >&rdquo;).</span>
                                    </p>
                                </li>
                                <li >
                                    <p>
                                        <span>
                                            You may use the Social Media Features solely as they are provided by us and solely with respect to the content they are displayed with. However, unless explicitly allowed via your use of the Social Media Features as provided
                                            by us, you must not: (1) establish a link from any website that is not owned by you; (2) cause the Services or portions of the Services to be displayed, or appear to be displayed by, for example, framing, deep linking or
                                            in-line linking, on any other site; (3) link to any part of the Services other than the homepage; or (4) otherwise take any action with respect to the materials on the Services that is inconsistent with any other provision
                                            of these TOS. You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking permission without notice.
                                        </span>
                                    </p>
                                </li>
                                <li >
                                    <p><span >We may disable all or any Social Media Features and any links at any time without notice in our discretion.</span></p>
                                </li>
                            </ol>

                        </li>

                    </ol>

                    <h3>Section 6. &nbsp;&nbsp;&nbsp;&nbsp; Term and Termination.</h3>

                    <ol type="a" start="1">
                        <li >
                            <p>
                                <span>
                                    <u>Effective Date</u>. These TOS shall be effective as of the latter of (1) the date on which you first accessed or used the Services and, by so accessing or using, accepted these TOS, or (2) the date following your first access or use of the
                                    Services after which we posted Updated TOS to the Services as recorded at the top of these TOS.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <span>
                                    <u>Suspension</u>. We may permanently or temporarily terminate or suspend your access to the Services, for any reason or for no reason, at our sole discretion, with or without notice or liability, including, but not limited to, if in our
                                    sole determination you violate any provision of the TOS. You agree that neither Boeiie, nor any of its subsidiaries or affiliates shall be liable to you or to any third party for any such modification, suspension, or
                                    discontinuance. You shall indemnify, defend, and hold Boeiie and any Boeiie Associates harmless against any Claim arising out of or related to such suspension or termination.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <span>
                                    <u>Effects of Termination</u>. Upon termination of the TOS or your access to the Services, for any reason or no reason, you will continue to be bound by each of the terms in the TOS which, by their nature, should survive termination, including, but not
                                    limited to, ownership provisions, intellectual property, warranty disclaimers, indemnification, limitations of liability, dispute resolution, and any other provision that must survive to fulfill its essential purpose.
                                </span>
                            </p>
                        </li>
                    </ol>

                    <h3>Section 7. &nbsp;&nbsp;&nbsp;&nbsp; Fees; Cancellations.</h3>

                    <ol type="a" start="1">
                        <li >
                            <p>
                                <u>Fees</u><span>. Use of the Services may be subject to certain fees or charges (each a &ldquo;</span><span >Fee</span>
                                <span>&rdquo;). Any such Fees are subject to the terms of the applicable terms of purchase presented to you at the time of payment (each a &ldquo;</span><span >Purchase Agreement</span>
                                <span>
                                    &rdquo;). If you are a User who has contracted with us via a Purchase Agreement, you are bound by the restrictions of that agreement with respect to your use of the Services and you may have certain additional liabilities
                                    and restrictions; please see the Purchase Agreement for information regarding such additional restrictions.
                                </span>
                            </p>
                        </li>
                        <li >
                            <p>
                                <u>Cancellations</u><span>. If you purchase a subscription (each a &ldquo;</span><span >Subscription</span>
                                <span>
                                    &rdquo;), the terms to which you agree when you subscribe, which are incorporated herein by reference, shall apply to your Subscription. In the event of any inconsistency or conflict between the Subscription terms and these
                                    TOS, the terms of the Subscription will control. If you choose to cancel a Subscription, you can continue to use the features of your Subscription following cancellation until the end of your applicable billing period. You
                                    will not be charged for the Subscription again unless you reactivate. Payments for Subscriptions are non-refundable and there are no refunds for partially used subscription periods. We may choose to provide a refund,
                                    discount, or other consideration (&ldquo;
                                </span>
                                <span >Credits</span>
                                <span >
                                    &rdquo;) to some or all of our consumers at any time, for any reason. The amount and form of such Credits and the decision to provide them are at Boeiie&rsquo;s sole discretion, and the provision of Credits in one instance
                                    does not entitle you or any other consumer to receive Credits in the future under any circumstances.
                                </span>
                            </p>
                        </li>
                    </ol>

                    <h3>Section 8. &nbsp;&nbsp;&nbsp;&nbsp; Digital Millennium Copyright Notice.</h3>

                    <ol type="a" start="1">
                        <li>
                            <p>
                                <u>Digital Millennium Copyright Act (DMCA) Claims</u>
                                <span>
                                    . We take the intellectual property rights of others seriously and require that our Users do the same. The DMCA established a process for addressing claims of copyright infringement that we have implemented for the Services.
                                    If you own a copyright or have authority to act on behalf of a copyright owner and want to report a claim that a third party is infringing that material on or through the Services, we will expeditiously take appropriate
                                    action once you send a notice to our copyright agent (see
                                </span>
                                <span >Section &lrm;(c)</span>
                                <span>
                                    ) that includes all of the following: (i) a description of the copyrighted work or material you claim is infringing and that you want removed or access to which you want disabled; (ii) the URL or other location of that
                                    material sufficient for us to locate the material; (iii) your address, telephone number, and email address; (iv) the following statement: &ldquo;I have a good faith belief that the use of the copyrighted material I am
                                    complaining of is not authorized by the copyright owner, its agent, or the law (
                                </span>
                                <span >e.g.</span><span >,</span><span >&nbsp;</span>
                                <span >
                                    as a fair use)&rdquo;; (v) the following statement: &ldquo;The information in this notice is accurate and, under penalty of perjury, I am the owner, or authorized to act on behalf of the owner, of the copyright or of an
                                    exclusive right that is allegedly infringed&rdquo;; and (vi) an electronic or physical signature of the owner of the copyright or a person authorized to act on the owner&#39;s behalf. Your failure to comply with all of the
                                    requirements of Section 512(c)(3) of the DMCA, may result in your DMCA Notice being ineffective. Please be aware that if you knowingly materially misrepresent that material or activity on the Services is infringing your
                                    copyright, you may be held liable for damages (including costs and attorneys&#39; fees) under Section 512(f) of the DMCA.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <u>Counter Notification Procedures</u>
                                <span>. If you believe that material you posted on the Services was removed or access to it was disabled by mistake or misidentification, you may file a counter notification with us (a &quot;</span>
                                <span >Counter Notice</span>
                                <span >
                                    &quot;) by submitting written notification to our copyright agent designated above. Pursuant to the DMCA, the Counter Notice must include all of the following: (i) a description of the copyrighted work or material you claim
                                    has been removed or to which access has been disabled; (ii) your address, telephone number, and email address; (iii) the following statement: &ldquo;I have a good faith belief that material identified above was removed or
                                    disabled as a result of a mistake or misidentification of the material to be removed or disabled&rdquo;; (iv) the following statement: &ldquo;I consent to the jurisdiction of the Federal District Court for the judicial
                                    district in which my address is located (or any judicial district in which the Services may be found if my residence is outside the United States) and I will accept service from the person (or an agent of that person) who
                                    provided the Services with the complaint at issue&rdquo;; and (v) your electronic or physical signature. The DMCA allows us to restore the removed content if the party filing the original DMCA Notice does not file a court
                                    action against you within ten business days of receiving the copy of your Counter Notice. Please be aware that if you knowingly materially misrepresent that material or activity on the Services was removed or disabled by
                                    mistake or misidentification, you may be held liable for damages (including costs and attorneys&#39; fees) under Section 512(f) of the DMCA.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <u>Designated Copyright Agent</u>
                                <span>
                                    . You may address such claims to our designated copyright agent by sending (i) mail to the attention of &ldquo;Copyright Agent&rdquo; or (ii) email with the subject line &ldquo;Copyright Agent&rdquo; to the respective
                                    address listed in
                                </span>
                                <span >Section &lrm;Section 12(a)(ii)</span><span >.</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <u>Removal upon Request</u>
                                <span>
                                    . You acknowledge, accept, and agree that if we receive a notice of a claim of copyright infringement, we may immediately remove the identified materials from our Services without liability to you or any other party and we
                                    will refer the claims of the Complaining Party to the United States Copyright Office for adjudication as provided in the DMCA.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <u>Repeat Infringers</u>
                                <span>
                                    . We may, in appropriate circumstances and at our sole discretion, disable or terminate the accounts of users who may be repeat infringers. This process does not limit our ability to pursue any other remedies we may have to
                                    address suspected infringement.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <u>Preceding Procedure for Internal Use Only</u>
                                <span>
                                    . Please note that this procedure is exclusively for notifying us and our affiliates that you believe your copyrighted material has been infringed. The preceding requirements are intended to comply with our rights and
                                    obligations under the DMCA, including 17 U.S.C. &sect;&nbsp;512(c), but do not constitute legal advice. IT IS ADVISABLE TO CONTACT AN ATTORNEY REGARDING YOUR RIGHTS AND OBLIGATIONS UNDER THE DMCA AND OTHER APPLICABLE LAWS.
                                    Please forward any allegations that other intellectual property right is being infringed to legal@boeiie.com. We may, in our sole discretion, terminate the accounts of those who are accused of copyright infringement or other intellectual property rights. You shall indemnify, defend, and hold Boeiie and any Boeiie Associates
                                    harmless against any Claim arising out of or related to removal of offerings under this Section 8.
                                </span>
                            </p>
                        </li>
                    </ol>

                    <h3>Section 9. &nbsp;&nbsp;&nbsp;&nbsp; Intellectual Property; Licenses.</h3>

                    <ol type="a" start="1">
                        <li>
                            <p>
                                <u>Our Property</u>
                                <span>
                                    . All Content on the Services are either our property or used with permission and you recognize that the Services, its Content, and all its components are protected by copyright and other laws. We retain all right, title,
                                    and interest in and to the Services, including, but not limited to, all software used to provide the Services, all logos, and all trademarks reproduced through the Services, and these TOS do not grant you any intellectual
                                    property rights in the Services, or any of its components. The trademarks, logos, and/or slogans displayed on the Services, including the mark Boeiie (collectively &ldquo;
                                </span>
                                <span >Trademarks</span>
                                <span>
                                    &rdquo;), are owned by us and/or others. You shall not construe anything on the Services as granting any license or right to use Trademarks without our express written permission or the express written permission of the
                                    third-party owner of the applicable Trademarks. Your misuse of Trademarks, or any other Content on the Services, except as provided in the TOS, is strictly prohibited. We will aggressively enforce our intellectual property
                                    rights to the fullest extent of the law.
                                </span>
                            </p>
                        </li>
                        <li >
                            <p>
                                <u>Feedback and Suggestions</u>
                                <span>
                                    . You may voluntarily post, submit, or otherwise communicate to us any questions, comments, suggestions, ideas, original or creative materials, or any other information about Boeiie or our products or services (collectively,
                                    &ldquo;
                                </span>
                                <span >Feedback</span>
                                <span >
                                    &rdquo;). You understand that we may use such Feedback for any purpose, commercial or otherwise, without acknowledgment or compensation to you, including, but not limited to, to develop, copy, publish, or improve the
                                    Feedback in Company&rsquo;s sole discretion. Nothing will or may restrict our right to use, profit from, disclose, publish, keep secret, or otherwise exploit Feedback, without compensating or crediting you. By providing us
                                    Feedback, you irrevocably assign to us any right, title, and interest you may have in such Feedback and explicitly and irrevocably waive any and all claims relating to any past, present, or future moral rights,
                                    artists&rsquo; rights, or any other similar rights worldwide in or to such Feedback. You understand that Boeiie has not, will not, and does not agree to treat any Feedback that you provides to us as confidential
                                </span>
                            </p>
                        </li>
                        <li >
                            <p><span >Licenses.</span></p>

                            <ol type="i" start="1">
                                <li>
                                    <p>
                                        <i>Limited License to Users</i>
                                        <span>. We grant you a limited, revocable, non-exclusive, non-transferable license to view, copy, and print Content on the Services for personal, non-commercial purposes.</span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i>Limited License to Search Engines</i>
                                        <span>
                                            . We grant the operators of public search engines a limited, non-exclusive, non-transferable license to copy materials from publicly available areas of the Services solely for creating publicly available, searchable indices
                                            of the Services. You may not use any robot, spider, or other automatic device, process, or means to access the Services for any purpose, including monitoring or copying any of the material on the Services. In addition, you
                                            must respect any robots.txt protocols and exclusions.
                                        </span>
                                    </p>
                                </li>
                                <li >
                                    <p>
                                        <i>License to Your Content</i>
                                        <span>
                                            . By providing Content on the Services, you grant us a royalty-free, non-exclusive, worldwide license, under your intellectual property rights, to copy, reformat, index, modify, display, distribute, and put to commercial and
                                            other uses the Content and any of your intellectual property that you provide or post to the Services. We shall pay you no compensation with respect to our use such Content. We neither represent nor warrant that your use of
                                            materials displayed on the Services will not infringe the rights of third parties not owned or affiliated with us. You further grant all Users a limited, revocable, non-exclusive, non-transferable license to view, copy, and
                                            print Content you post to the Services for their personal, non-commercial purposes.
                                        </span>
                                    </p>
                                </li>
                            </ol>

                        </li>
                    </ol>

                    <h3>Section 10. &nbsp;&nbsp;&nbsp;&nbsp; Ads and Malware.</h3>

                    <ol type="a" start="1">
                        <li >
                            <p>
                                We take great care and pride in creating the Services. We continuously try to look for technical glitches that affect how the Services works and upon finding them we will fix them. Unfortunately, we cannot ensure that the
                                Services works on every device and some glitches beyond our control may exist that affect how you see the Services. If you experience any unusual behavior, content, or ads on the Services, it may be the result of malware on
                                your computer. Malware is a term used to broadly classify a form of software, which is installed in a computer system with malicious intentions, usually without the owner&#39;s knowledge or permission. Malware may include
                                viruses, key loggers, malicious content, rogue programs, and Trojan horses, among others. We continuously work to ensure that the Services is working properly and absent any malware. Please take your own precautions to clean
                                and protect your device and monitor and prevent installations of malware on your computer system. We are not and will not be responsible for the effects of any third-party software including malware on your device.
                            </p>
                        </li>
                    </ol>

                    <h3>Section 11. &nbsp;&nbsp;&nbsp;&nbsp; Third-Party Links.</h3>

                    <ol type="a" start="1">
                        <li >
                            <p>
                                <u>Third-Party Links</u>
                                <span>
                                    . The appearance, availability, or your use of URLs or hyperlinks referenced or included anywhere on the Services or any other form of link or re-direction of your connection to, with or through the Services (each a &ldquo;
                                </span>
                                <span >Third-Party Link</span>
                                <span >
                                    &rdquo;), does not constitute an endorsement by, nor does it incur any obligation, responsibility, or liability on our part, or on the part of any of our successors and assigns, or Boeiie Associates. We do not verify,
                                    endorse, or have any responsibility for any such third-party sites, their business practices (including their privacy policies), or any goods or services associated with or obtained in connection with any such site, even if
                                    our logos or sponsorship identification is on the third-party site as part of a co-branding or promotional arrangement. If any third-party site obtains or collects personal information or other information from you, in no
                                    event shall we assume or have any responsibility or liability. Please read our Privacy Policy, which describes how we collect and use your personal information and other information and certain of our relationships. Your
                                    correspondence or business dealings with, or participation in promotions of, third-party service providers and advertisers found on or through the Services, including payment and delivery of related goods or services, and
                                    any other terms, conditions, warranties, or representations associated with such dealings, are solely between you and such third-party service provider or advertiser, as the case may be. You agree that, to the fullest extent
                                    permissible pursuant to applicable law, Boeiie shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such third-party service
                                    providers and advertisers on the Services. YOU AGREE THAT ACCESS AND USE OF THIRD-PARTY LINKS, INCLUDING THE INFORMATION, MATERIAL, PRODUCTS, AND SERVICES ON THIRD-PARTY LINKS OR AVAILABLE THROUGH THIRD-PARTY LINKS, IS
                                    SOLELY AT YOUR OWN RISK AND DISCRETION.
                                </span>
                            </p>
                        </li>
                    </ol>

                    <h3>Section 12. Notification; Communication.</h3>

                    <ol type="a" start="1">
                        <li >
                            <p><span>Notification.</span></p>
                            <ol type="i" start="1">
                                <li >
                                    <p>
                                        <u >Notification to You</u>
                                        <span >
                                            . You acknowledge and agree (1) that we may send communications to you verbally or via any mailing address, email, telephone, or facsimile number provided by you to us through your account; (2) to notify us of any changes in
                                            your address or contact details; (3) that all communications will be deemed delivered to you when sent and not when received; (4) that we may provide notifications, including those regarding modifications to the TOS, whether
                                            such notifications are required by law or are for marketing or other business related purposes, to you via email notice, written or hard copy notice, or through posting of such notice on the Services, as determined by us in
                                            our sole discretion; and (5) that you may opt out of certain means of notification as described in the TOS, provided however, that we are not responsible for any automatic filtering or blocking you or your network provider
                                            may apply including, but not limited to, to email notifications we send to the email address provided to us or via settings or options you may choose in your browser.
                                        </span>
                                    </p>
                                </li>
                                <li >
                                    <p>
                                        <u >Notification to Us</u><span>. You shall send notices pursuant to the TOS (1) by mail to: Boeiie, LLC, 41 Peabody Street, Nashville, TN 37210 Attn: Legal or (2) by email to: </span>
                                        <span >legal@boeiie.com</span><span >&nbsp;</span>
                                        <span>
                                            or to such other addresses as we may provide to you through the TOS or other means of communication in the future. We will deem notices received by us upon the earlier of (x) actual receipt or delivery in person, (y) by
                                            certified mail return receipt requested; or (z) by email once you have received a written confirmation of receipt in the same thread as your original email; provided, however, that automatic replies will not constitute a
                                            written confirmation under this &lrm;Section 12(a)(ii).
                                        </span>
                                    </p>
                                </li>
                            </ol>
                        </li>
                    </ol>

                    <h3><span >Section 13. &nbsp;&nbsp;&nbsp;&nbsp; Disclaimer of Liability.</span></h3>

                    <ol type="a" start="1">
                        <li >
                            <p>
                                <u >USE AT YOUR OWN RISK</u>
                                <span >
                                    . YOU AGREE THAT ANY RELIANCE UPON ANY CONTENT OR INFORMATION DISTRIBUTED THROUGH OR ACCESSED FROM THE SITES IS AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMISSIBLE BY LAW, IN NO EVENT SHALL BOEIIE OR BOEIIE ASSOCIATES (AS
                                    DEFINED IN &lrm;Section 2(c)(ii)) BE LIABLE FOR DAMAGES UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE, OR ANY OTHER LEGAL OR EQUITABLE THEORY ARISING OUT OF OR RELATED TO YOUR ACCESS TO, OR USE OF THE SITES. YOUR SOLE
                                    REMEDY FOR DISSATISFACTION WITH THE SITES IS TO STOP USING THE SITES. IF USING MATERIALS FROM THE SITES RESULT IN THE NEED FOR SERVICING, REPAIR, OR CORRECTION OF EQUIPMENT OR DATA, YOU AGREE AND CONSENT TO ASSUME ANY SUCH
                                    COSTS.
                                </span>
                            </p>
                        </li>
                        <li >
                            <p>
                                <u >EXCLUSIONS IN SOME JURISDICTIONS</u><span>. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSIONS OF IMPLIED WARRANTIES INCLUDED HEREIN, SO THE EXCLUSIONS IN THIS </span><span >&lrm;Section 13</span>
                                <span>&nbsp;MAY NOT APPLY TO YOU.</span>
                            </p>
                        </li>
                        <li >
                            <p>
                                <u >SITES PROVIDED AS IS</u>
                                <span >
                                    . YOU ACKNOWLEDGE AND AGREE THAT TO THE FULLEST EXTENT PERMITTED BY LAW BOEIIE PROVIDES AND YOU ACCEPT THE SITES ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS WITH NO REPRESENTATION OR WARRANTY OF ANY KIND,
                                    EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, OR ANY IMPLIED WARRANTY ARISING FROM STATUTE,
                                    COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE OF TRADE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, BOEIIE: (i) HAS NO OBLIGATION TO INDEMNIFY OR DEFEND YOU AGAINST CLAIMS RELATED TO INFRINGEMENT OF INTELLECTUAL
                                    PROPERTY; (ii) DOES NOT REPRESENT OR WARRANT THAT THE SITES WILL PERFORM WITHOUT INTERRUPTION OR THAT THE SITES WILL BE FROM INACCURACIES, FAILURES OF PERFORMANCE, ERRORS, OMISSIONS, INTERRUPTIONS, DEFECTS, DELAYS IN
                                    OPERATION, TRANSMISSIONS, COMPUTER VIRUSES, OR LINE OR SERVICE FAILURES AND WE SHALL ASSUME NO RESPONSIBILITY FOR, AND SHALL NOT BE LIABLE FOR, ANY DAMAGES TO YOUR COMPUTER EQUIPMENT OR OTHER PROPERTY ON ACCOUNT OF YOUR
                                    ACCESS TO OR USE OF THE SITES OR YOUR DOWNLOADING OF ANY CONTENT FROM THE SITES; (iii) DOES NOT REPRESENT OR WARRANT THAT THE SITES IS SECURE FROM HACKING OR OTHER UNAUTHORIZED INTRUSION OR THAT USER DATA WILL REMAIN PRIVATE
                                    OR SECURE; AND (iv) NEITHER ENDORSES NOR REPRESENTS THE COMPLETENESS, RELIABILITY, OR ACCURACY OF ANY CONTENT OR INFORMATION DISTRIBUTED THROUGH OR ACCESSED FROM THE SITES, AND WE NEITHER PERFORMED ANY INVESTIGATION INTO
                                    SUCH INFORMATION NOR SHALL WE BE LIABLE FOR ANY INVESTMENT DECISIONS MADE BASED UPON SUCH INFORMATION.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <u >AVAILABILITY</u><span >.</span>
                                <span >
                                    &nbsp;YOU ACKNOWLEDGE AND AGREE THAT TO THE FULLEST EXTENT PERMITTED BY LAW BOEIIE DOES NOT AND SHALL NOT GUARANTEE AND WE EXPRESSLY EXCLUDE ALL LIABILITY FOR DAMAGES ARISING OUT OF, RELATING TO, OR REGARDING YOUR USE,
                                    DELAY, OR ANY UNAVAILABILITY OF THE SITES AND ITS CONTENT, INCLUDING, BUT NOT LIMITED TO, ANY LOSS OF MONEY, SUSPENSION OR TERMINATION OF YOUR ACCOUNT, OR FOR ANY DAMAGE CAUSED TO YOUR COMPUTER, COMPUTER SOFTWARE, PROGRAMS,
                                    AND THE DATA THEREON. UNDER NO CIRCUMSTANCES WILL BOEIIE OR BOEIIE ASSOCIATES BE LIABLE FOR ANY DAMAGES INCLUDING, BUT NOT LIMITED TO, GENERAL, SPECIAL, PUNITIVE, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR ANY OTHER
                                    DAMAGES (INCLUDING, BUT NOT LIMITED TO, LOST PROFITS OR BUSINESS INTERRUPTION) OF ANY KIND WHETHER IN AN ACTION IN CONTRACT, TORT, OR NEGLIGENCE ARISING OUT OF OR RELATED TO THE USE OR INABILITY TO USE ANY PART OF THE SITES
                                    OR ANY THIRD-PARTY LINK OR IN CONNECTION WITH OR AS A RESULT OF ANY INACCURACY, FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION, TRANSMISSION, COMPUTER VIRUS, OR LINE OR SERVICE FAILURE,
                                    EVEN IF WE ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, LOSSES, AND/OR EXPENSES.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <u >USER CONTENT</u><span >.</span>
                                <span >
                                    &nbsp;YOU ACKNOWLEDGE AND AGREE THAT TO THE FULLEST EXTENT PERMITTED BY LAW BOEIIE DOES NOT AND SHALL NOT BE RESPONSIBLE AND WE EXPRESSLY EXCLUDE ALL REPRESENTATION AND WARRANTIES, EXPRESS OR IMPLIED, ARISING OUT OF,
                                    RELATING TO, OR REGARDING ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY USER.
                                </span>
                            </p>
                        </li>
                        <li >
                            <p>
                                <u >LIMITATION OF LIABILITY</u>
                                <span>
                                    . LIABILITY ARISING OUT OF OR RELATED TO THESE TOS OR THE USE OF THE SITES WILL NOT EXCEED ONE HUNDRED DOLLARS. IN NO EVENT WILL BOEIIE BE LIABLE TO YOU FOR ANY CONSEQUENTIAL, INDIRECT, SPECIAL, INCIDENTAL, OR PUNITIVE
                                    DAMAGES ARISING OUT OF OR RELATED TO THESE TOS OR THE USE OF THE SITES. THE LIABILITIES LIMITED BY THIS
                                </span>
                                <span >&lrm;Section 13</span>
                                <span>
                                    &nbsp;APPLY: (i) TO LIABILITY FOR NEGLIGENCE; (ii) REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, STRICT PRODUCT LIABILITY, OR OTHERWISE; (iii) EVEN IF BOEIIE IS ADVISED IN ADVANCE OF THE POSSIBILITY OF THE
                                    DAMAGES IN QUESTION AND EVEN IF SUCH DAMAGES WERE FORESEEABLE; AND (iv) EVEN IF YOUR REMEDIES FAIL OF THEIR ESSENTIAL PURPOSE. IF&nbsp;APPLICABLE&nbsp;LAW LIMITS THE APPLICATION OF THE PROVISIONS OF THIS
                                </span>
                                <span >&lrm;Section 13(f)</span>
                                <span>
                                    , YOU AGREE AND CONSENT THAT BOEIIE&rsquo;S TOTAL LIABILITY FOR ALL DAMAGES, LOSSES, OR CAUSES OF ACTION OF ANY KIND OR NATURE SHALL BE LIMITED TO COMPENSATORY DAMAGES AND LIMITED TO THE GREATEST EXTENT PERMITTED BY
                                    APPLICABLE LAW LIABILITY WILL BE LIMITED TO THE MAXIMUM EXTENT PERMISSIBLE. FOR CLARITY, THE LIABILITY LIMITS AND OTHER RIGHTS STATED IN THIS
                                </span>
                                <span >&lrm;Section 13(f)</span><span >&nbsp;SHALL APPLY LIKEWISE TO BOEIIE ASSOCIATES.</span>
                            </p>
                        </li>
                    </ol>

                    <h3>Section 14. &nbsp;&nbsp;&nbsp;&nbsp; Indemnification.</h3>

                    <ol type="a" start="1">
                        <li >
                            <p>
                                <span>You agree to indemnify, defend, and hold Boeiie</span><span >&nbsp;</span>
                                <span>and Boeiie Associates harmless from and against any and all claims, actions, proceedings, losses, expenses, damages, and costs including, but not limited to, reasonable attorneys&#39; fees (each a &ldquo;</span>
                                <span >Claim</span>
                                <span>
                                    &rdquo;) resulting from any breach or violation of the TOS by you or arising out of or related to your use of the Services including, but not limited to, your Posts, any use of the Services&rsquo; content, services, and
                                    products other than as expressly authorized in these TOS and regardless of whether or not such Claims arise out of or are related to our negligence. For clarity, with respect to this
                                </span>
                                <span >&lrm;Section 14</span>
                                <span >
                                    &nbsp;and all other sections which expressly provide indemnification of Boeiie by you, Claims shall include, but are not limited to, Claims by you: (i) related to unauthorized disclosure or exposure of personally
                                    identifiable information or other private information; (ii) related to infringement or violation of a copyright, trademark, trade secret, or privacy or confidentiality right by written material, images, logos, or other
                                    Content uploaded to the Services through a User account; and (iii) that use of the Services through a User account harasses, defames, or defrauds a third party or (iv) violated the CAN-Spam Act of 2003 or any other law or
                                    restriction on electronic advertising. We reserve the right to assume, at our sole expense, the exclusive defense and control of any such Claim and all negotiations for settlement or compromise, and you agree to fully
                                    cooperate with us in the defense of any such negotiations, as requested by us.
                                </span>
                            </p>
                        </li>
                    </ol>

                    <h3>Section 15. &nbsp;&nbsp;&nbsp;&nbsp; Dispute Resolution.</h3>

                    <ol type="a" start="1">
                        <li>
                            <p>
                                <u>Agreement to Amicably Resolve Disputes</u><span>. In the event of any Claim, controversy, or alleged dispute between you and Boeiie or Boeiie Associates (each a &ldquo;</span><span >Dispute</span>
                                <span>&rdquo;), and except to the extent necessary for an IP Protection Action, you hereby agree to attempt in good faith to amicably to resolve any Dispute through the following procedures. &ldquo;</span>
                                <span >IP Protection Action</span>
                                <span>
                                    &rdquo; means any action to seek injunctive or other equitable relief in a court of competent jurisdiction solely to prevent the actual or threatened infringement, misappropriation, or violation of a party&rsquo;s
                                    copyrights, trademarks, trade secrets, patents, or other intellectual property rights.
                                </span>
                                <strong>
                                    TO THE FULLEST EXTENT PERMITTED BY LAW, FAILURE TO FOLLOW THESE PROCEDURES SHALL CONSTITUTE YOUR WAIVER OF YOUR RIGHTS TO ANY FURTHER PROCEEDING WITH RESPECT TO ANY DISPUTE, DIRECTLY OR INDIRECTLY ARISING OUT OF OR RELATED
                                    TO THE TOS OR YOUR USE OF THE SITES. YOU UNDERSTAND THAT ABSENT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO FURTHER DISPUTE RESOLUTION PROCEEDINGS INCLUDING THE RIGHT TO ARBITRATION OR LITIGATION.
                                </strong>
                            </p>
                        </li>
                        <li >
                            <p>
                                <u >Dispute Conference</u>
                                <span>. In the event of a Dispute, either party may call for a resolution conference by sending written notice to the other at the address provided by you via your account information or by us at the address in </span>
                                <span >Section &lrm;Section 12(a)(ii)</span><span>. The parties&rsquo; shall meet in person or by telephone (&ldquo;</span><span >Dispute Conference</span>
                                <span >
                                    &rdquo;) within 3 business days of their designation and shall negotiate in good faith to resolve the dispute. The Dispute Conference must commence within 30 days following obtaining knowledge of the cause of action forming
                                    the basis of the Dispute. Failure to commence a Dispute Conference within the 30-day period constitutes a waiver of your rights to any further proceeding. Except to the extent necessary for an IP Protection Action, neither
                                    party shall initiate mediation, arbitration, or litigation until 30 business days after the Dispute Conference.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <u >Mediation</u><span >.</span>
                                <span >
                                    &nbsp;If the Dispute remains unresolved following the Dispute Conference, a party may attempt to resolve such dispute through mediation under the auspices of the American Arbitration Association in Davidson County,
                                    Tennessee, at that party&rsquo;s sole expense. Mediation must commence within 90 days following obtaining knowledge of the cause of action forming the basis of the Dispute. Failure to commence mediation within the 90-day
                                    period constitutes a waiver of your rights to any further proceeding. Except to the extent necessary for an IP Protection Action, neither party may initiate arbitration or litigation until 30 days after mediation.
                                </span>
                            </p>
                        </li>
                        <li >
                            <p>
                                <u >Arbitration</u>
                                <span>. If the Dispute remains unresolved following mediation, a party may attempt to resolve such dispute through arbitration under the auspices of the American Arbitration Association (&ldquo;</span>
                                <span >AAA</span>
                                <span >
                                    &rdquo;) in Davidson County, Tennessee, at that party&rsquo;s sole expense, subject to the following: (i) Parties agree to submit any Dispute for resolution by final binding arbitration after serving written notice, which
                                    notice shall state in detail the controversy, question, claim, or alleged breach along with details regarding the parties&rsquo; attempt to resolve such Dispute through the preceding procedures. Upon such notice and attempts
                                    to resolve, a party may then commence an arbitration proceeding, before a single arbitrator to be selected by the AAA, under the rules of the AAA under its Commercial Arbitration Rules. Arbitration must commence within 6
                                    months following obtaining knowledge of the cause of action forming the basis of the Dispute. Failure to commence arbitration within the 6-month period constitutes a waiver of your rights to any further proceeding. (ii) In
                                    any arbitration and subject to the ultimate discretion of the presiding arbitrator, each side will be limited to a maximum of one day of argument (including rebuttal), and the parties agree in good faith to minimize
                                    discovery burdens (e.g. confine the scope to actual areas in dispute and limit the topics and number of pages on which information is requested to matters directly relevant). (iii) Any decision of the arbitrator shall be
                                    final and binding and may not be appealed to any court of competent jurisdiction, or otherwise, except upon claim of fraud or corruption, provided however, that implementation of such decision shall in no way be delayed or
                                    otherwise impaired pending the outcome of any such appeal. Any court having jurisdiction thereof may enter judgment upon the award rendered in such arbitration. (iv) Notwithstanding the foregoing, you agree that the
                                    following matters shall not be subject to binding arbitration, at our sole election: (1) any Dispute arising out of or related to allegations of criminal activity; (2) any Disputes concerning our intellectual property
                                    rights; and (3) any claim for injunctive relief.
                                </span>
                            </p>
                        </li>
                        <li >
                            <p>
                                <u >Litigation</u>
                                <span >
                                    . A court of competent jurisdiction located within Davidson County, Tennessee shall decide any IP Protection Action or any other Dispute which is not subject to arbitration. Each party hereby waives any claim that such venue
                                    is improper or inconvenient.
                                </span>
                            </p>
                        </li>
                        <li >
                            <p>
                                <u >No Joint Disputes or Class Actions</u><span>. You agree that any claim, controversy, or alleged dispute between you and us, our members, or our affiliates (&ldquo;</span><span >Dispute</span>
                                <span >
                                    &rdquo;), will be limited between you, individually, and us. To the fullest extent allowable by law, you agree that no dispute resolution proceeding of any kind, whether mediation, arbitration, or litigation shall be joined
                                    with any other party or decided on a class action basis.
                                </span>
                            </p>
                        </li>
                    </ol>

                    <h3>Section 16. &nbsp;&nbsp;&nbsp;&nbsp; Right to Opt Out of Mandatory Arbitration and Class Action Waiver.</h3>

                    <ol type="a" start="1">
                        <li >
                            <p>
                                <span>IF YOU DO NOT WISH TO BE BOUND BY THE MANDATORY ARBITRATION AND CLASS ACTION WAIVER PROVISIONS IN </span><span >&lrm;SECTION 15</span><span>, YOU MUST NOTIFY Boeiie IN WRITING (THE &ldquo;</span>
                                <span >Opt-Out Notice</span>
                                <span>
                                    &rdquo;), WHERE THE OPT-OUT NOTICE MEETS ALL OF THE FOLLOWING REQUIREMENTS: The Opt-Out Notice must: (i) be sent by first class mail, postage prepaid, certified and return receipt requested or sent by overnight courier
                                    service to the address listed in &lrm;
                                </span>
                                <span >Section 12(a)(ii)</span>
                                <span >
                                    ; (ii) be postmarked or deposited with the overnight courier service no later than 45 days after the date you accept the TOS for the first time, unless a longer period is required by applicable law; however, if you are or
                                    become a user of more than one Boeiie website, application, or other interactive service, you must provide the Opt-Out Notice within the earliest deadline applicable for any such website, application or other interactive
                                    service for which you are or become a user; (iii) include your first and last name, address, phone number, email address and, if applicable, your username if you are a registered user of a Boeiie website, application, or
                                    other interactive service along with an identification of the applicable website, application, or other interactive service for each such username; and (iv) include a statement that you do not agree to the mandatory
                                    arbitration and class action waiver. We will use the information included in the Opt-Out Notice to record, process, maintain, and administer your opt-out of the mandatory arbitration and class action waiver provisions and
                                    not for marketing purposes. If the Opt-Out Notice meets all of the above requirements, you will be deemed to have opted out of the mandatory arbitration and class action waiver provisions with respect to all Boeiie websites,
                                    applications, or other interactive. A valid Opt-Out Notice applies only to the individual identified in such notice as opting out.
                                </span>
                            </p>
                        </li>
                    </ol>

                    <h3>Section 17. &nbsp;&nbsp;&nbsp;&nbsp; Miscellaneous.</h3>

                    <ol type="a" start="1">
                        <li>
                            <p>
                                <u >Confidentiality of Information</u>
                                <span >
                                    . You should not post any confidential or proprietary information to publicly available portions of the Services. However, as a User, you shall protect all Content with the same degree of care you use to protect your own
                                    information of similar nature and importance, but in no case with less than reasonable care. In cases where Content is marked &ldquo;Confidential&rdquo; or any nonpublic information relating to any party&rsquo;s technology,
                                    customers or potential customers, business plans, promotional and marketing activities, finances or other business affairs, or any other nonpublic, sensitive information, you should consider the information a trade secret or
                                    otherwise confidential and utilize the same efforts to protect and maintain its secrecy that are reasonable under the circumstances to protect and maintain the secrecy of your own information of similar nature and
                                    importance.
                                </span>
                            </p>
                        </li>
                        <li >
                            <p><u >No Third-Party Beneficiaries</u><span >. Except as expressly provided in the TOS there shall be no third-party beneficiaries to the TOS.</span></p>
                        </li>
                        <li >
                            <p>
                                <u >Assignment &amp; Successors</u>
                                <span>. You may not assign any of your rights or delegate any of your responsibilities or obligations hereunder without our express written consent. Except to the extent forbidden in this &lrm;</span>
                                <span >Section 17(c)</span><span >, these TOS will be binding upon and inure to the benefit of the parties&rsquo; respective successors and assigns.</span>
                            </p>
                        </li>
                        <li >
                            <p>
                                <u >Severability</u>
                                <span >
                                    . To the extent permitted by applicable law, the parties hereby waive any provision of law that would render any clause of the TOS invalid or otherwise unenforceable in any respect. If a provision of the TOS is held to be
                                    invalid or otherwise unenforceable, you agree to allow us to replace such void or unenforceable provision of the TOS with a valid and enforceable provision that will achieve, to the extent possible, the economic, business,
                                    and other purposes of such void or unenforceable provision and each such provision will be interpreted to fulfill its intended purpose to the maximum extent permitted by applicable law. The remaining provisions of the TOS
                                    will continue in full force and effect.
                                </span>
                            </p>
                        </li>
                        <li >
                            <p>
                                <u >No Waiver</u>
                                <span >
                                    . Neither party will be deemed to have waived any of its rights under the TOS by lapse of time or by any statement or representation other than by an authorized representative in an explicit written waiver. A waiver of a
                                    breach of the TOS shall not be construed as a waiver of any continuing or succeeding breach of the TOS, the TOS themselves, or a waiver of any right under these TOS.
                                </span>
                            </p>
                        </li>
                        <li >
                            <p>
                                <u >Choice of Law; Jurisdiction; Waiver of Jury Trial</u>
                                <span>
                                    . All Claims arising out of or related to the TOS and/or the use of the Services shall be governed solely by the internal laws of the State of Tennessee, including, but not limited to, applicable federal law and without
                                    reference to any conflicts of law principle that would apply the substantive laws of another jurisdiction to the parties&rsquo; rights or duties or other international laws. The parties consent to the personal and exclusive
                                    jurisdiction of the federal and state courts located in Davidson County, Tennessee. You hereby agree to the personal and exclusive jurisdiction and venue of such courts.
                                </span>
                                <strong>
                                     TO THE FULLEST EXTENT PERMITTED BY LAW, YOU HEREBY WAIVE YOUR RIGHT TO A TRIAL BY JURY WITH RESPECT TO ANY CLAIM ARISING OUT OF OR RELATED TO THE TOS AND/OR THE USE OF THE SITES. YOU UNDERSTAND THAT ABSENT THIS PROVISION,
                                    YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL.
                                </strong>
                                <span>&nbsp;This </span><span >&lrm;Section 16(f)</span>
                                <span >&nbsp;governs all claims arising out of or related to the TOS and/or the use of the Services, including, but not limited to, negligence, contract, tort, strict product liability, or otherwise.</span>
                            </p>
                        </li>
                        <li >
                            <p>
                                <u >Interpretation</u>
                                <span >
                                    . The titles and headings have been inserted for convenience of reference only and are not intended to summarize or otherwise describe the subject matter of the paragraphs and shall not be given any consideration in the
                                    construction of these TOS. &ldquo;Include(s)&rdquo; and &ldquo;including&rdquo; shall be deemed to be followed by &ldquo;but not limited to.&rdquo; &ldquo;Or&rdquo; is not exclusive. &ldquo;Herein&rdquo; refers to the TOS as
                                    a whole. These TOS shall be construed without regard to any presumption, rule of construction, or interpretation against the drafting party.
                                </span>
                            </p>
                        </li>
                        <li >
                            <p>
                                <u >Entire Agreement</u>
                                <span >
                                    . These TOS set forth the entire agreement of the parties and supersedes all prior or contemporaneous writings, negotiations, and discussions with respect to its subject matter. Neither party has relied upon any such prior
                                    or contemporaneous communications.
                                </span>
                            </p>
                        </li>
                    </ol>
                    <p className="center">
                        <strong >
                            IF YOU DO NOT EXPRESSLY AGREE TO ALL OF THESE TOS,<br />
                            THEN YOU MAY NOT ACCESS OR USE OUR WEBSITE.
                        </strong>
                    </p>
                    <p className="center" ><span >-- End of Terms of Service --</span></p>
                    <p ><span ></span></p>
                    <p ><span ></span></p>
                </div>
            </div>
        </div>
    );
}

export default TermsOfService;
