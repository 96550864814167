import {connect} from 'react-redux';

import {updateAuthUser} from '../../common/actions/auth';
import {STORAGE_KEY} from '../../common/utils/api';

import AccountVerification from './AccountVerification';


const setAuthUserIsVerified = (dispatch) => {
    let authUserString = localStorage.getItem(STORAGE_KEY);
    let isAuthenticated = !!authUserString;

    if (!isAuthenticated) return;

    let authUser = JSON.parse(authUserString);

    authUser['is_verified'] = true;
    localStorage.setItem(STORAGE_KEY, JSON.stringify(authUser));
    dispatch(updateAuthUser(authUser));
};

const mapDispatchToProps = (dispatch) => ({
    setAuthUserIsVerified: () => dispatch(setAuthUserIsVerified),
});

export default connect(
    null,
    mapDispatchToProps
)(AccountVerification);
