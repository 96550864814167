import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'underscore';

import {formatNavigationQuery} from '../../utils/routing';

import GroupSummaryCard from '../group_summary_card/GroupSummaryCard';

import './GroupsList.css';


export default class GroupsList extends Component {
    static propTypes = {
        groups: PropTypes.array,
        pagination: PropTypes.object,
        hasMoreGroups: PropTypes.bool,
        handleLoadGroups: PropTypes.func,
        handleMount: PropTypes.func,
    };

    handleLoadMoreGroups = (page) => {
        let {
            handleLoadGroups,
            pagination
        } = this.props;

        handleLoadGroups({
            limit: pagination.next.limit,
            offset: pagination.next.offset,
        });
    };

    render() {
        let {
            groups,
            hasMoreGroups
        } = this.props;
        let groupsList = (<h3>Empty</h3>);

        if (!_.isEmpty(groups)) {
            groupsList = groups.map((group, index) => (
                <Link
                    key={group.id}
                    className="group-link"
                    to={{
                        pathname: `/groups/${group['slug_hash']}/${group['slug']}/`,
                        search: formatNavigationQuery()
                    }}
                >
                    <div className="group-card-container">
                        <GroupSummaryCard
                            title={group.name}
                            description={group.description}
                        />
                    </div>
                </Link>
            ));
        }

        return (
            <div className="groups-list">
                <InfiniteScroll
                    pageStart={1}
                    loadMore={this.handleLoadMoreGroups}
                    hasMore={hasMoreGroups}
                    initialLoad={true}
                    loader={<div className="loader" key={0}>Loading ...</div>}
                >
                    {groupsList}
                </InfiniteScroll>
            </div>
        );
    }
}
