import _ from 'underscore';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Form, Field} from 'react-final-form';
import {Link} from 'react-router-dom';

import {EMOJI_MAP} from '../../constants/emoji';
import {formatDateTime} from '../../utils/datetime';
import {
    formatNavigationQuery,
    isDemo
} from '../../utils/routing';
import {kebabCase} from '../../utils/strings';

import AccountVerificationPrompt from '../account_verification_prompt/AccountVerificationPrompt';
import Dropdown from '../dropdown/Dropdown';
import EmojiSelector from '../emoji_selector/EmojiSelector';
import PreviewLink from '../preview_link/PreviewLink';
import TagButton from '../tag_button/connector';

import './PostCard.css';


const HideForm = () => {
    return (
        <form>
            <div className="other-reason-input-container">
                <Field
                    className="other-reason-input"
                    name="other"
                    component="input"
                    placeholder="Other reason..."
                />
            </div>
        </form>
    );
};


class OptionsDropdown extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showHideForm: false,
            showOtherReasonForm: false,
            selectedReasons: {},
            shouldShowEditPostForm: false
        };
    }

    handleHideClick = () => {
        this.setState({showHideForm: true});
    };

    handleRemoveHideFormClick = () => {
        this.setState({showHideForm: false});
    };

    handleHideReasonClick = (reason) => {
        let {
            showOtherReasonForm,
            selectedReasons
        } = this.state;

        selectedReasons[reason] = !selectedReasons[reason];

        if (reason === 'other') {
            showOtherReasonForm = !showOtherReasonForm;
        }

        this.setState({
            showOtherReasonForm,
            selectedReasons
        });
    };

    handleFormSubmit = (otherReasonInputValue) => {
        let {selectedReasons} = this.state;
        let {
            onHideOptionFormSubmit,
            onSubmitHidePostForm,
            postId
        } = this.props;
        let formValues = {};
        let selectedReasonKeys = Object.keys(selectedReasons);
        let reasonTypes = [];

        if (otherReasonInputValue) {
            formValues['reason_description'] = otherReasonInputValue['other'];
        }

        selectedReasonKeys.forEach((key) => {
            if (selectedReasons[key]) {
                reasonTypes.push(key);
            }
        });

        formValues['reason_types'] = reasonTypes.join(',');

        onSubmitHidePostForm(postId, formValues)
            .then(() => {
                this.setState({
                    showHideForm: false,
                    showOtherReasonForm: false,
                    selectedReasons: {}
                });

                onHideOptionFormSubmit();
            });
    };

    render() {
        let {
            showHideForm,
            showOtherReasonForm,
            selectedReasons
        } = this.state;
        let {
            onOptionsClick,
            postId,
            hideEditPostButton,
            hideHidePostButton,
            hideDeletePostButton
        } = this.props;
        let selectedReasonStyle = {backgroundColor: '#888', color: '#fff'};
        let editPostButton = (
            <button
                className="option-button"
                onClick={() => onOptionsClick('edit', postId)}
            >
                <img
                    className="option-icon"
                    src="/media/ui_icons/edit.png"
                    alt=""
                />
                <span>Edit Post</span>
            </button>
        );
        let hidePostButton = (
            <button
                className="option-button"
                onClick={() => this.handleHideClick('hide')}
            >
                <img
                    className="option-icon"
                    src="/media/ui_icons/eye-disabled.png"
                    alt=""
                />
                <span>Hide Post</span>
            </button>
        );
        let deletePostButton = (
            <button
                className="option-button"
                onClick={() => onOptionsClick('delete', postId)}
            >
                <img
                    className="option-icon"
                    src="/media/ui_icons/trash.png"
                    alt=""
                />
                <span>Delete Post</span>
            </button>
        );

        if (hideEditPostButton) {
            editPostButton = null;
        }

        if (hideHidePostButton) {
            hidePostButton = null;
        }

        if (hideDeletePostButton) {
            deletePostButton = null;
        }

        let options = (
            <div>
                {editPostButton}
                {hidePostButton}
                {deletePostButton}
            </div>
        );
        let content = options;
        let submit = this.handleFormSubmit;

        if (showHideForm) {
            content = (
                <div className="hide-options-form">
                    <div className="hide-form-header">
                        <button onClick={this.handleRemoveHideFormClick}>
                            <img
                                className="back-arrow-icon"
                                src="/media/ui_icons/arrow-left.png"
                                alt="Back"
                            />
                        </button>
                    </div>
                    <div className="hide-reasons-container">
                        <button
                            className="hide-reason-button"
                            style={
                                selectedReasons['spam'] ? selectedReasonStyle : null
                            }
                            onClick={() => this.handleHideReasonClick('spam')}
                        >
                            Spam
                        </button>
                        <button
                            className="hide-reason-button"
                            style={
                                selectedReasons['not-relevant'] ? selectedReasonStyle : null
                            }
                            onClick={() => this.handleHideReasonClick('not-relevant')}
                        >
                            Not relevant
                        </button>
                        <button
                            className="hide-reason-button"
                            style={
                                selectedReasons['violence'] ? selectedReasonStyle : null
                            }
                            onClick={() => this.handleHideReasonClick('violence')}
                        >
                            Violence
                        </button>
                        <button
                            className="hide-reason-button"
                            style={
                                selectedReasons['illegal'] ? selectedReasonStyle : null
                            }
                            onClick={() => this.handleHideReasonClick('illegal')}
                        >
                            Illegal
                        </button>
                        <button
                            className="hide-reason-button"
                            style={
                                selectedReasons['other'] ? selectedReasonStyle : null
                            }
                            onClick={() => this.handleHideReasonClick('other')}
                        >
                            Other
                        </button>
                    </div>
                    {
                        !showOtherReasonForm ? null :
                            <Form
                                onSubmit={(otherInput) => this.handleFormSubmit(otherInput)}
                                render={({handleSubmit}) => {
                                    submit = handleSubmit;
                                    return (
                                        <HideForm
                                        />
                                    );
                                }}
                            />
                    }
                    <div className="hide-form-footer">
                        <button
                            className="submit-button"
                            onClick={() => submit()}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            );
        }

        if (isDemo()) {
            return (
                <Dropdown>
                    <div>
                        <p style={{padding: '10px', margin: '0px'}}>
                            <strong>Demo Mode:</strong> You cannot access edit controls unless you are logged-in.
                        </p>
                    </div>
                </Dropdown>
            );
        }

        return (
            <Dropdown>
                {content}
            </Dropdown>
        );
    }
};

OptionsDropdown.propTypes = {
    postId: PropTypes.string,
    hideEditPostButton: PropTypes.bool,
    hideHidePostButton: PropTypes.bool,
    hideDeletePostButton: PropTypes.bool,
    onOptionsClick: PropTypes.func,
    onHideOptionFormSubmit: PropTypes.func,
    onSubmitHidePostForm: PropTypes.func,
    onHidePostSuccess: PropTypes.func
};

export default class PostCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showOptionsDropdown: false,
            shouldShowEditPostForm: false,
            accountVerifyPromptIsOpen: false,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleInsideClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleInsideClick, false);
    }

    handleInsideClick = (e) => {
        if (this.node && !this.node.contains(e.target)) {
            this.handleClickOutside();
        }
    };

    handleClickOutside = () => {
        this.setState({
            showOptionsDropdown: false
        });
    };

    handleMoreOptionsClick = () => {
        let {
            isVerified,
            isAuthenticated
        } = this.props;
        let {showOptionsDropdown} = this.state;

        if (isAuthenticated && !isVerified && !showOptionsDropdown) {
            this.handleOpenAccountVerifyPrompt();
            return;
        }

        this.setState({
            showOptionsDropdown: !showOptionsDropdown,
        });
    };

    handleOptionClick = (option) => {
        let {
            post,
            postId,
            title,
            description,
            tags,
            group,
            sharedLink = {},
            photoUrl = '',
            onEditPostButtonClick,
            onDeletePost,
            onDeletePostSuccess,
            history
        } = this.props;

        if (option === 'delete') {
            onDeletePost(postId)
                .then((result) => {
                    if (onDeletePostSuccess) {
                        onDeletePostSuccess();
                    }
                });
        } else if (option === 'edit') {
            this.setState({
                shouldShowEditPostForm: true,
                showOptionsDropdown: false
            }, () => {
                let ref = history.location.pathname;
                let linkPreviewData = null;

                if (!_.isEmpty(sharedLink)) {
                    linkPreviewData = {
                        linkUrl: sharedLink['link_url'],
                        imageUrl: sharedLink['image_url'],
                        title: sharedLink['title'],
                        description: sharedLink['description']
                    };
                }

                onEditPostButtonClick({
                    post,
                    postId,
                    tags,
                    group,
                    linkPreviewData,
                    photo: photoUrl,
                    initialValues: {
                        title,
                        body: description
                    }
                });

                history.push(`/compose_post/?ref=${ref}`);
            });
        }
    };

    onHideOptionFormSubmit = () => {
        let {onHidePostSuccess} = this.props;

        if (onHidePostSuccess) {
            onHidePostSuccess();
        }

        this.setState({showOptionsDropdown: false});
    };

    closePostForm = (postFormKey) => {
        this.setState({[postFormKey]: false}, () => {
            document.body.style.overflow = '';
        });
    };

    handleEditPostSubmit = () => {
        let {onEditPostSuccess} = this.props;

        if (onEditPostSuccess) {
            onEditPostSuccess();
        }

        this.closePostForm('shouldShowEditPostForm');
    };

    handleCloseAccountVerifyPrompt = () => {
        this.setState({accountVerifyPromptIsOpen: false});
    };

    handleOpenAccountVerifyPrompt = () => {
        let {
            authUser
        } = this.props;
        let isAuthenticated = !_.isEmpty(authUser);
        let isVerified = !_.isEmpty(authUser) && authUser.is_verified;

        if (isAuthenticated && !isVerified) {
            this.setState({accountVerifyPromptIsOpen: true});
        }
    };

    render() {
        let {
            authUser,
            post={},
            postId,
            title,
            description,
            author,
            authorName,
            authorProfileImageUrl,
            group,
            tags=[],
            photoUrl,
            sharedLink,
            postCreationDate,
            numberOfComments,
            metaData,
            location,
            handleAddReaction,
            handleRemoveReaction,
            hideEditPostButton,
            hideHidePostButton,
            hideDeletePostButton,
            onSubmitHidePostForm,
            onHidePostSuccess
        } = this.props;
        let {
            showOptionsDropdown,
            accountVerifyPromptIsOpen,
        } = this.state;
        let commentsTitle = `${numberOfComments} Comments`;
        let authorSlugHash = post['user'] && post['user']['slug_hash'];
        let tagButtons = tags.map((tag) => {
            let tagUrl = `/posts/`;
            let groupParam = {};
            let postFiltersParam = {'post_filters': 'none'};
            let groupUrl = '';

            if (group) {
                groupUrl = `/groups/${group.slug}/`;
            }

            if (!_.isEmpty(group) && (location.pathname === groupUrl)) {
                tagUrl = groupUrl;
                groupParam['group'] = group.slug;
                postFiltersParam = {};
            }

            return (
                <div key={tag.id} className="tag-button-container">
                    <Link to={{
                        pathname: tagUrl,
                        search: formatNavigationQuery({
                            tags: kebabCase(tag.name),
                            ...postFiltersParam,
                            ...groupParam,
                        })
                    }}>
                        <TagButton tag={tag} />
                    </Link>
                </div>
            );
        });
        let moreOptionsDropdown = null;
        let linkPreview = null;
        let photoSection = null;
        let tagButtonSection = null;
        let reactionSummaryIcons = null;
        let currentUserReaction = {};
        let groupLink = null;
        let headerTextComponent = null;
        let consolidatedReaction = _.reduce(metaData.reactionSummary, (memo, reaction) => {
            if (reaction.type in memo) {
                memo[reaction.type]['total'] += reaction.total;
                if (!memo[reaction.type]['has_reacted']) {
                    memo[reaction.type]['has_reacted'] = reaction['user_id'] === authUser['user_id'];
                }

            } else {
                memo[reaction.type] = {
                    'total': reaction.total,
                    'has_reacted': reaction['user_id'] === authUser['user_id'],
                };
            }

            if (memo[reaction.type]['has_reacted']) {
                currentUserReaction = {
                    'emojiType': EMOJI_MAP[reaction.type],
                    'emojiTitle': reaction.type,
                    'reactionId': metaData.reactionId,
                    'targetEntityType': 'post',
                };
            }

            return memo;
        }, {});

        if (sharedLink) {
            let linkUrlObj = new URL(sharedLink['link_url']);

            linkPreview = (
                <div className="link-preview-section">
                    <PreviewLink
                        linkUrl={sharedLink['link_url']}
                        hostname={linkUrlObj.hostname}
                        imageUrl={sharedLink['image_url']}
                        title={sharedLink['title']}
                        description={sharedLink['description']}
                    />
                </div>
            );
        }

        if (photoUrl) {
            photoSection = (
                <div className="post-photo-container">
                    <img className="post-photo" src={photoUrl} alt="" />
                </div>
            );
        }

        if (showOptionsDropdown) {
            moreOptionsDropdown = (
                <OptionsDropdown
                    postId={postId}
                    hideEditPostButton={hideEditPostButton}
                    hideHidePostButton={hideHidePostButton}
                    hideDeletePostButton={hideDeletePostButton}
                    onOptionsClick={this.handleOptionClick}
                    onHideOptionFormSubmit={this.onHideOptionFormSubmit}
                    onSubmitHidePostForm={onSubmitHidePostForm}
                    onHidePostSuccess={onHidePostSuccess}
                />
            );
        }

        if (!_.isEmpty(tags)) {
            tagButtonSection = (
                <div className="tags-section">
                    {tagButtons}
                </div>
            );
        }

        if (group) {
            groupLink = (
                <span>
                    <span className="dot-separator">&#183;</span>
                    <Link
                        className="post-group-link"
                        to={{
                            pathname: `/groups/${group['slug_hash']}/${group['slug']}/`,
                            search: formatNavigationQuery()
                        }}
                    >
                        <span>community/ </span>
                        <span className="group-name">{group['name']}</span>
                    </Link>
                </span>
            );
        }

        if (title) {
            headerTextComponent = (
                <Link
                    className="post-link-element"
                    to={{
                        pathname: `/post/${post['slug_hash']}/${post['slug'] || ''}/`,
                        search: formatNavigationQuery()
                    }}
                >
                    <h2 className="title">{title}</h2>
                </Link>
            );
        } else {
            headerTextComponent = (
                <Link
                    className="post-link-element"
                    to={{
                        pathname: `/post/${post['slug_hash']}/${post['slug']}/`,
                        search: formatNavigationQuery()
                    }}
                >
                    <p className="description">{description}</p>
                </Link>
            );
        }

        reactionSummaryIcons = Object.keys(consolidatedReaction).map((emojiType, index) => (
            <span key={index} className={`reaction-emoji ${consolidatedReaction[emojiType]['has_reacted'] ? 'has-reacted' : ''}`}>
                <span className="emoji-icon">{EMOJI_MAP[emojiType]}</span>
                <span className="reaction-count">{consolidatedReaction[emojiType]['total']}</span>
            </span>
        ));

        return (
            <div className="post-card">
                <div className="first-row">
                    <div>
                        <span className="post-creation-date">{formatDateTime(postCreationDate)}</span>
                        {groupLink}
                    </div>
                    {
                        !_.isEmpty(authUser) ?
                        <div className="more-options-icon-wrapper" ref={node => this.node = node}>
                            <button onClick={this.handleMoreOptionsClick}>
                                <img
                                    className="more-options-icon"
                                    src="/media/ui_icons/more.png"
                                    alt="More"
                                />
                            </button>
                            {moreOptionsDropdown}
                        </div> : null
                    }
                </div>
                <div className="header-text-section">
                    {headerTextComponent}
                </div>
                {tagButtonSection}
                <Link
                    to={{
                        pathname: `/profile/${authorSlugHash}/`,
                        search: formatNavigationQuery()
                    }}
                >
                    <div className="author-section">
                        <div className="avatar-section">
                            <div className="post-card-avatar">
                                {
                                    authorProfileImageUrl ?
                                    <img className="post-card-avatar-img" src={authorProfileImageUrl} alt="" />
                                    : null
                                }
                            </div>
                        </div>
                        <div className="author-data-section">
                            <p className="author-name">{authorName}</p>
                            {
                                author['profile_headline'] ?
                                (<p className="author-summary">{author['profile_headline']}</p>)
                                : null
                            }
                        </div>
                    </div>
                </Link>
                {photoSection}
                {linkPreview}
                <div className="post-meta-data-section">
                    <div className="reaction-summary">
                        {reactionSummaryIcons}
                    </div>
                    <div className="num-comments-section">
                        <Link
                            className="post-link-element"
                            to={{
                                pathname: `/post/${post['slug_hash']}/${post['slug']}/`,
                                search: formatNavigationQuery()
                            }}
                        >
                            <div className="num-comments-button">
                                <p>{commentsTitle}</p>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="reaction-select-section-container">
                    <div className="reaction-select-section">
                        <div className="reaction-selector-button-container">
                            <EmojiSelector
                                currentReaction={currentUserReaction}
                                isAuthenticated={!_.isEmpty(authUser)}
                                isVerified={authUser.is_verified}
                                handleAddReaction={(emoji) => handleAddReaction(emoji, postId)}
                                handleRemoveReaction={(reactionId) => handleRemoveReaction(reactionId, postId, 'post')}
                            />
                        </div>
                        <div className="reaction-selector-button-container">
                            <Link
                                className="comment-button"
                                to={{
                                    pathname: `/post/${post['slug_hash']}/${post['slug'] || ''}/`,
                                    search: formatNavigationQuery()
                                }}
                            >
                                <div>
                                    <span>
                                        <img
                                            className="comment-box-icon"
                                            src="/media/ui_icons/chatbox.png"
                                            alt="Leave a comment"
                                        />
                                    </span>
                                    <span>Comment</span>
                                </div>
                            </Link>
                        </div>
                        <div className="reaction-selector-button-container">
                            <Link
                                className="view-post-link"
                                to={{
                                    pathname: `/post/${post['slug_hash']}/${post['slug'] || ''}/`,
                                    search: formatNavigationQuery()
                                }}
                            >
                                <span>View Post</span>
                            </Link>
                        </div>
                    </div>
                </div>
                <AccountVerificationPrompt
                    isOpen={accountVerifyPromptIsOpen}
                    title="Verify your account."
                    subtitle="In order to complete this action, verify your account by clicking the button below, and visit the email."
                    handleClose={this.handleCloseAccountVerifyPrompt}
                />
            </div>
        );
    }
}

PostCard.propTypes = {
    authUser: PropTypes.object,
    post: PropTypes.object.isRequired,
    postId: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    group: PropTypes.object,
    tags: PropTypes.array,
    sharedLink: PropTypes.object,
    photoUrl: PropTypes.string,
    author: PropTypes.object.isRequired,
    authorName: PropTypes.string,
    authorId: PropTypes.number,
    authorProfileImageUrl: PropTypes.string,
    numberOfComments: PropTypes.number,
    authorOccupation: PropTypes.string,
    postCreationDate: PropTypes.string,
    metaData: PropTypes.object,
    hideEditPostButton: PropTypes.bool,
    hideHidePostButton: PropTypes.bool,
    hideDeletePostButton: PropTypes.bool,
    handleAddReaction: PropTypes.func.isRequired,
    handleRemoveReaction: PropTypes.func.isRequired,
    onDeletePost: PropTypes.func,
    onDeletePostSuccess: PropTypes.func,
    onEditPostButtonClick: PropTypes.func,
    onEditPostSuccess: PropTypes.func,
    onSubmitHidePostForm: PropTypes.func,
    onHidePostSuccess: PropTypes.func
};
