import React from 'react';
import {
    Redirect,
    useParams
} from 'react-router-dom';

import './GroupInvitation.css';


export default function GroupInvitation({
    isAuthenticated
}) {
    let {inviteToken} = useParams();
    let decodedToken = atob(inviteToken);
    let decodedParts = decodedToken.split(',');
    let groupSlugHash = decodedParts[1];
    let groupSlug = decodedParts[2];

    if (groupSlugHash) {
        return (
            <Redirect
                to={`/groups/${groupSlugHash}/${groupSlug}/`}
            />
        );
    } else {
        return (
            <Redirect
                to={{
                    pathname: '/',
                }}
            />
        );
    }
}
