export const PROFILE_FORM_SAVE_SUBMIT_START = 'profile-form-save-submit-start';
export const PROFILE_FORM_SAVE_SUBMIT_SUCCESS = 'profile-form-save-submit-success';
export const PROFILE_FORM_SAVE_SUBMIT_FAIL = 'profile-form-save-submit-fail';

export const FORM_STATE_CHANGE = 'form-state-change';

export const profileSaveFormStart = () => ({
    type: PROFILE_FORM_SAVE_SUBMIT_START
});

export const profileSaveFormSuccess = (profile) => ({
    type: PROFILE_FORM_SAVE_SUBMIT_SUCCESS,
    profile
});

export const profileSaveFormFail = (errors) => ({
    type: PROFILE_FORM_SAVE_SUBMIT_FAIL,
    errors
});

export const formStateChange = (formState) => ({
    type: FORM_STATE_CHANGE,
    formState
});
