import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog({
    buttonText,
    dialogTitle,
    contentDescription,
    primaryButtonText,
    handlePrimaryButtonClick,
    secondaryButtonText,
    handleSecondaryButtonClick
}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const primaryButtonClick = () => {
        handlePrimaryButtonClick(() => setOpen(false));
    };

    const secondaryButtonClick = () => {
        handleSecondaryButtonClick(() => setOpen(false))
    };

    return (
        <span>
            <button className="alert-dialog-button" onClick={handleClickOpen}>
                {buttonText}
            </button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {contentDescription}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={primaryButtonClick} color="primary">
                        {primaryButtonText}
                    </Button>
                    <Button onClick={secondaryButtonClick} color="primary" autoFocus>
                        {secondaryButtonText}
                    </Button>
                </DialogActions>
            </Dialog>
        </span>
    );
}
