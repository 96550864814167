import {connect} from 'react-redux';
import _ from 'underscore';

import GlobalFooter from './GlobalFooter';


const mapStateToProps = ({app}) => ({
    isAuthenticated: !_.isEmpty(app.authUser),
});

export default connect(
    mapStateToProps,
    null
)(GlobalFooter);
