import _ from 'lodash';

import {
    LOAD_POST_LISTING_START,
    LOAD_POST_LISTING_SUCCESS,
    LOAD_POST_LISTING_FAIL,
    DELETE_POST_SUCCESS,
    HIDE_POST_SUBMIT_SUCCESS,
} from '../../common/actions/posts';
import {
    CREATE_REACTION_SUCCESS,
    REMOVE_REACTION_SUCCESS,
} from '../../common/actions/reactions';


const loadStartReducer = (state, {urlQueryParams={}}) => ({
    ...state,
    posts: [],
    comments: [],
    postIds: [],
    isLoadingPostListing: true,
    urlQueryParams
});

const loadSuccessReducer = (state, {
    posts,
    nextLink,
    previousLink,
    infiniteScrollEnabled
}) => {
    if (infiniteScrollEnabled) {
        return {
            ...state,
            isLoadingPostListing: false,
            posts: [...state.posts, ...posts],
            nextLink,
            previousLink
        };
    }

    return {
        ...state,
        isLoadingPostListing: false,
        posts,
        nextLink,
        previousLink
    };
};

const loadFailReducer = (state, {errors}) => ({
    ...state,
    isLoadingPostListing: false,
    errors
});

const deletePostSuccessReducer = (state, {post: deletedPost}) => ({
    ...state,
    isLoadingPostListing: false,
    posts: _.filter(state.posts, (post) => post.id !== deletedPost.id)
});

const hidePostSuccessReducer = (state, {post: hiddenPost}) => ({
    ...state,
    isLoadingPostListing: false,
    posts: _.filter(state.posts, (post) => post.id !== hiddenPost.id)
});

const createReactionSuccessReducer = (state, {reaction, authUserId}) => {
    // Check if a reaction is already set.
    if (reaction['target_entity_type'] === 'post') {
        let oldPostIndex = _.findIndex(state.posts, (post) => post.id === reaction['target_entity_id']);

        if (oldPostIndex === -1) {
            return state;
        }

        let oldPost = {...state.posts[oldPostIndex]};

        let oldPostReactionSummaryIndex = _.findIndex(oldPost['meta_data']['reaction_summary'], (reaction) => {
            return reaction['user_id'] === authUserId;
        });
        let postHasExistingReaction = oldPostReactionSummaryIndex > -1;

        if (postHasExistingReaction) {
            let oldPostReactionSummary = oldPost['meta_data']['reaction_summary'][oldPostReactionSummaryIndex];

            oldPostReactionSummary['type'] = reaction['reaction_emoji_type'];
            oldPost['meta_data']['reaction_summary'][oldPostReactionSummaryIndex] = oldPostReactionSummary;

            state.posts[oldPostIndex] = oldPost;

            return {
                ...state,
                posts: [...state.posts],
            };
        } else {
            oldPost['meta_data']['reaction_summary'].push({
                type: reaction['reaction_emoji_type'],
                total: 1,
                'user_id': authUserId,
            });
            oldPost['meta_data']['reaction_id'] = reaction.id
            state.posts[oldPostIndex] = oldPost;

            return {
                ...state,
                posts: [...state.posts]
            };
        }
    }

    return state;
};

const removeReactionSuccessReducer = (state, {reactionId, targetEntityId, authUserId, targetEntityType}) => {
    if (targetEntityType === 'post') {
        let postIndex = _.findIndex(state.posts, (post) => post.id.toString() === targetEntityId);

        if (postIndex === -1) {
            return state
        }

        let post = {...state.posts[postIndex]};

        post['meta_data']['reaction_summary'] = _.filter(post['meta_data']['reaction_summary'], (summary) => {
            return summary['user_id'] !== authUserId;
        });
        post['meta_data']['reaction_id'] = null;

        state.posts[postIndex] = post;

        return {
            ...state,
            posts: [...state.posts]
        };
    }

    return state;
};

const POST_LISTING_REDUCER_MAP = {
    [LOAD_POST_LISTING_START]: loadStartReducer,
    [LOAD_POST_LISTING_SUCCESS]: loadSuccessReducer,
    [LOAD_POST_LISTING_FAIL]: loadFailReducer,
    [DELETE_POST_SUCCESS]: deletePostSuccessReducer,
    [HIDE_POST_SUBMIT_SUCCESS]: hidePostSuccessReducer,
    [CREATE_REACTION_SUCCESS]: createReactionSuccessReducer,
    [REMOVE_REACTION_SUCCESS]: removeReactionSuccessReducer,
};

const postListingReducer = (initialState={}, action={}) => {
    let reducer = POST_LISTING_REDUCER_MAP[action.type];
    let nextState = initialState;

    if (reducer) {
        nextState = reducer(initialState, action);
    }

    return nextState;
};

export default postListingReducer;
