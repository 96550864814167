import React, {Component} from 'react';

import GlobalHeader from '../../common/components/global_header/connector';

import './About.css';


export default class About extends Component {
    render() {
        return (
            <div id="about">
                <GlobalHeader />
                <div className="page-body-container">
                    <p className="description">
                        Boeiie is a community of sports fans, participants, and enthusiasts who love to talk shop, debate, and banter about who's the G.O.A.T or why their team will win the championship this year.
                    </p>
                    <p className="description">
                        Let people know your opinion on tonight's game. Keep up with the latest news and reactions from fans world-wide. Follow a discussion. Hear what others in your town are saying. Invite your friends to join in.
                    </p>
                    <p className="description">
                        Boeiie is the #1 destination for sports talk and interaction amongst fans, and we want you to join in on the fun.
                    </p>
                </div>
            </div>
        );
    }
}
