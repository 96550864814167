import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'underscore';

import {fetchApiGet} from '../../utils/api';
import {formatNavigationQuery} from '../../utils/routing';

import './GroupSelector.css';


export default class GroupSelector extends Component {
    static propTypes = {
        groups: PropTypes.array.isRequired,
        handleSearchChange: PropTypes.func.isRequired,
        handleGroupClick: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            groups: [],
            shouldShowJoinGroupLink: false
        };
    }

    componentDidMount() {
        fetchApiGet('groups/?me=true&limit=15')
            .then(({results}) => {
                if (_.isEmpty(results)) {
                    this.setState({shouldShowJoinGroupLink: true})
                } else {
                    this.setState({groups: results});
                }
            });
    }

    handleChange = (event) => {
        event.persist();

        let searchQuery = event.currentTarget.value;

        fetchApiGet(`groups/search/?search_query=${searchQuery}`)
            .then(({results}) => {
                this.setState({groups: results});
            });
    }

    render() {
        let {
            groups,
            shouldShowJoinGroupLink
        } = this.state;
        let {handleGroupClick} = this.props;
        let groupsList = groups.map((group) => {
            return (
                <button
                    key={group.id}
                    className="group-button"
                    type="button"
                    onClick={() => handleGroupClick(group)}
                >
                    <div className="name">
                        {group.name}
                    </div>
                    <div className="description">
                        {group.description}
                    </div>
                </button>
            );
        });
        let contentSection = (
            <div className="search-content-section">
                <input
                    className="search-bar"
                    placeholder="Search..."
                    onChange={this.handleChange}
                />
                {groupsList}
            </div>
        );

        if (shouldShowJoinGroupLink) {
            contentSection = (
                <div className="join-group-section">
                    <Link
                        className="join-group-link"
                        to={{
                            pathname: '/groups/',
                            search: formatNavigationQuery()
                        }}
                    >
                        Join a group
                    </Link>
                    <p className="join-group-subtitle">You have not joined any groups. In order to create a post, join the group that you want to share this post.</p>
                </div>
            );
        }

        return (
            <div className="group-selector">
                <h3 className="content-title">My Groups</h3>
                {contentSection}
            </div>
        );
    }
}
