import {connect} from 'react-redux';
import _ from 'underscore';

import {
    STORAGE_KEY,
    fetchApiGet,
} from '../common/utils/api';

import {
    loadAuthUserStart,
    loadAuthUserSuccess,
    loadAuthUserFail
} from './actions';

import App from './App';


const initializeApp = (dispatch) => {
    dispatch(loadAuthUserStart());

    let storageData = localStorage.getItem(STORAGE_KEY);

    storageData = JSON.parse(storageData) || {};

    let authToken = storageData['token'];

    if (authToken) {
        fetchApiGet(`authtoken-verify/`)
            .then((result) => {
                if (result.success) {
                    localStorage.setItem(STORAGE_KEY, JSON.stringify(result.success.data));
                    dispatch(loadAuthUserSuccess(result.success.data));
                }
            })
            .catch(() => {
                localStorage.removeItem(STORAGE_KEY);
                dispatch(loadAuthUserFail({'errors': 'Need a valid token!'}));
            });
    } else {
        localStorage.removeItem(STORAGE_KEY);
        dispatch(loadAuthUserFail({'errors': 'Need a valid token!'}));
    }
};

const mapStateToProps = ({app}) => {
    return {
        isLoading: app.isLoading === undefined ? true : app.isLoading,
        isAuthenticated: !_.isEmpty(app.authUser) ? !!app.authUser.token : false
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLoad: () => dispatch(initializeApp)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
