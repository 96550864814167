import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Form, Field} from 'react-final-form';
import {Link} from 'react-router-dom';
import Textarea from 'react-textarea-autosize';
import _ from 'underscore';

import {EMOJI_MAP} from '../../constants/emoji';
import Dropdown from '../dropdown/Dropdown';
import EmojiSelector from '../emoji_selector/EmojiSelector';
import AccountVerificationPrompt from '../account_verification_prompt/AccountVerificationPrompt';
import LoginPrompt from '../login_prompt/LoginPrompt';
import ReplyInput from '../reply_input/ReplyInput';

import {formatDateTime} from '../../utils/datetime';

import './CommentCard.css';


const formatViewReplyButtonTitle = (numberOfReplies) => {
    let title = '0 replies';

    if (numberOfReplies === 1) {
        title = 'View 1 reply';
    } else if (numberOfReplies > 1) {
        title = `View ${numberOfReplies} replies`;
    }

    return title;
}

const HideForm = () => {
    return (
        <form>
            <div className="other-reason-input-container">
                <Field
                    className="other-reason-input"
                    name="other"
                    component="input"
                    placeholder="Other reason..."
                />
            </div>
        </form>
    );
};

const EditCommentForm = () => {
    return (
        <form>
            <Field
                name="comment"
                render={({input}) => (
                    <Textarea
                        {...input}
                        className="edit-comment-textarea"
                    />
                )}
            />
        </form>
    );
};

class OptionsDropdown extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showHideForm: false,
            showOtherReasonForm: false,
            selectedReasons: {}
        };
    }

    handleHideClick = () => {
        this.setState({showHideForm: true});
    };

    handleRemoveHideFormClick = () => {
        this.setState({showHideForm: false});
    };

    handleHideReasonClick = (reason) => {
        let {
            showOtherReasonForm,
            selectedReasons
        } = this.state;

        selectedReasons[reason] = !selectedReasons[reason];

        if (reason === 'other') {
            showOtherReasonForm = !showOtherReasonForm;
        }

        this.setState({
            showOtherReasonForm,
            selectedReasons
        });
    };

    handleFormSubmit = (otherReasonInputValue) => {
        let {selectedReasons} = this.state;
        let {
            postId,
            commentId,
            onHideCommentSubmit,
            hideOptionsDropdown
        } = this.props;
        let formValues = {};
        let selectedReasonKeys = Object.keys(selectedReasons);
        let reasonTypes = [];

        if (otherReasonInputValue) {
            formValues['reason_description'] = otherReasonInputValue['other'];
        }

        selectedReasonKeys.forEach((key) => {
            if (selectedReasons[key]) {
                reasonTypes.push(key);
            }
        });

        formValues['reason_types'] = reasonTypes.join(',');

        onHideCommentSubmit(postId, commentId, formValues)
            .then(() => {
                this.setState({
                    showHideForm: false,
                    showOtherReasonForm: false,
                    selectedReasons: {}
                });

                hideOptionsDropdown();
            });
    };

    render() {
        let {
            showHideForm,
            showOtherReasonForm,
            selectedReasons
        } = this.state;
        let {
            commentId,
            hideEditCommentButton,
            hideHideCommentButton,
            hideDeleteCommentButton,
            onOptionsClick
        } = this.props;
        let selectedReasonStyle = {backgroundColor: '#888', color: '#fff'};
        let editCommentButton = (
            <button
                className="option-button"
                onClick={() => onOptionsClick('edit')}
            >
                <img
                    className="option-icon"
                    src="/media/ui_icons/edit.png"
                    alt=""
                />
                <span>Edit Comment</span>
            </button>
        );
        let hideCommentButton = (
            <button
                className="option-button"
                onClick={() => this.handleHideClick('hide')}
            >
                <img
                    className="option-icon"
                    src="/media/ui_icons/eye-disabled.png"
                    alt=""
                />
                <span>Hide Comment</span>
            </button>
        );
        let deleteCommentButton = (
            <button
                className="option-button"
                onClick={() => onOptionsClick('delete', commentId)}
            >
                <img
                    className="option-icon"
                    src="/media/ui_icons/trash.png"
                    alt=""
                />
                <span>Delete Comment</span>
            </button>
        );

        if (hideEditCommentButton) {
            editCommentButton = null;
        }

        if (hideHideCommentButton) {
            hideCommentButton = null;
        }

        if (hideDeleteCommentButton) {
            deleteCommentButton = null;
        }

        let options = (
            <div>
                {editCommentButton}
                {hideCommentButton}
                {deleteCommentButton}
            </div>
        );
        let content = options;
        let submit = this.handleFormSubmit;

        if (showHideForm) {
            content = (
                <div className="hide-options-form">
                    <div className="hide-form-header">
                        <button onClick={this.handleRemoveHideFormClick}>
                            <img
                                className="back-arrow-icon"
                                src="/media/ui_icons/arrow-left.png"
                                alt="Back"
                            />
                        </button>
                    </div>
                    <div className="hide-reasons-container">
                        <button
                            className="hide-reason-button"
                            style={
                                selectedReasons['spam'] ? selectedReasonStyle : null
                            }
                            onClick={() => this.handleHideReasonClick('spam')}
                        >
                            Spam
                        </button>
                        <button
                            className="hide-reason-button"
                            style={
                                selectedReasons['not-relevant'] ? selectedReasonStyle : null
                            }
                            onClick={() => this.handleHideReasonClick('not-relevant')}
                        >
                            Not relevant
                        </button>
                        <button
                            className="hide-reason-button"
                            style={
                                selectedReasons['violence'] ? selectedReasonStyle : null
                            }
                            onClick={() => this.handleHideReasonClick('violence')}
                        >
                            Violence
                        </button>
                        <button
                            className="hide-reason-button"
                            style={
                                selectedReasons['illegal'] ? selectedReasonStyle : null
                            }
                            onClick={() => this.handleHideReasonClick('illegal')}
                        >
                            Illegal
                        </button>
                        <button
                            className="hide-reason-button"
                            style={
                                selectedReasons['other'] ? selectedReasonStyle : null
                            }
                            onClick={() => this.handleHideReasonClick('other')}
                        >
                            Other
                        </button>
                    </div>
                    {
                        !showOtherReasonForm ? null :
                            <Form
                                onSubmit={(otherInput) => this.handleFormSubmit(otherInput)}
                                render={({handleSubmit}) => {
                                    submit = handleSubmit;
                                    return (
                                        <HideForm
                                        />
                                    );
                                }}
                            />
                    }
                    <div className="hide-form-footer">
                        <button
                            className="submit-button"
                            onClick={() => submit()}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            );
        }

        return (
            <Dropdown>
                {content}
            </Dropdown>
        );
    }
};

OptionsDropdown.propTypes = {
    postId: PropTypes.string,
    commentId: PropTypes.string,
    hideEditCommentButton: PropTypes.bool,
    hideDeleteCommentButton: PropTypes.bool,
    hideHideCommentButton: PropTypes.bool,
    onOptionsClick: PropTypes.func,
    onHideCommentSubmit: PropTypes.func,
    hideOptionsDropdown: PropTypes.func
};


export default class CommentCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showReplyInput: false,
            showOptionsDropdown: false,
            showCommentEditForm: false,
            shouldHideRepliesButton: false,
            loginPromptIsOpen: false,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleInsideClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleInsideClick, false);
    }

    handleInsideClick = (e) => {
        if (this.node && !this.node.contains(e.target)) {
            this.handleClickOutside();
        }
    };

    handleClickOutside = () => {
        this.setState({
            showOptionsDropdown: false
        });
    };

    handleReplyClick = () => {
        let {
            isAuthenticated,
            isVerified
        } = this.props;

        if (!isAuthenticated) {
            this.handleOpenLoginPrompt();
            return;
        }

        if (isAuthenticated && !isVerified) {
            this.handleOpenAccountVerifyPrompt();
            return;
        }

        this.setState({showReplyInput: true});
    };

    handleMoreOptionsClick = () => {
        let {
            isVerified,
            isAuthenticated,
        } = this.props;
        let {showOptionsDropdown} = this.state;

        if (isAuthenticated && !isVerified) {
            this.handleOpenAccountVerifyPrompt();
            return;
        }

        this.setState({showOptionsDropdown: !showOptionsDropdown});
    };

    handleOptionClick = (option, commentId) => {
        let {
            postId,
            onDeleteComment
        } = this.props;

        if (option === 'delete') {
            onDeleteComment(postId, commentId)
                .then((result) => {
                    this.setState({
                        showOptionsDropdown: false,
                    });
                });
        } else if (option === 'edit') {
            this.setState({
                showOptionsDropdown: false,
                showCommentEditForm: true
            });
        } else if (option === 'hide') {

        }
    };

    hideOptionsDropdown = () => {
        this.setState({showOptionsDropdown: false});
    };

    handleEditCommentFormSubmit = (postId, commentId, value) => {
        let {
            isVerified,
            isAuthenticated,
            onEditCommentSubmit,
        } = this.props;

        if (!isAuthenticated) {
            this.handleOpenLoginPrompt();
            this.setState({
                showOptionsDropdown: false,
                showCommentEditForm: false
            });
            return;
        }

        if (isAuthenticated && !isVerified) {
            this.handleOpenAccountVerifyPrompt();
            this.setState({
                showOptionsDropdown: false,
                showCommentEditForm: false
            });
            return;
        }

        onEditCommentSubmit(postId, commentId, value.comment)
            .then((comment) => {
                this.setState({
                    showOptionsDropdown: false,
                    showCommentEditForm: false
                });
            });
    };

    handleEditCommentFormCancel = () => {
        this.setState({showCommentEditForm: false});
    };

    handleViewRepliesButtonClick = (event) => {
        let {onViewRepliesClick} = this.props;

        onViewRepliesClick(event)
            .then(() => {
                this.setState({shouldHideRepliesButton: true});
            });
    };

    handleCloseLoginPrompt = () => {
        this.setState({loginPromptIsOpen: false});
    };

    handleOpenLoginPrompt = () => {
        let {authUser} = this.props;
        let isAuthenticated = !_.isEmpty(authUser);

        if (!isAuthenticated) {
            this.setState({loginPromptIsOpen: true});
        }
    };

    handleCloseAccountVerifyPrompt = () => {
        this.setState({accountVerifyPromptIsOpen: false});
    };

    handleOpenAccountVerifyPrompt = () => {
        let {
            isAuthenticated,
            isVerified,
        } = this.props;

        if (isAuthenticated && !isVerified) {
            this.setState({accountVerifyPromptIsOpen: true});
        }
    };

    render() {
        let {
            authUser,
            postId,
            comment,
            commentId,
            authorName,
            authorTitle,
            authorProfileImageUrl,
            commentBody,
            commentCreationDate,
            metaData={},
            hideShowRepliesButton,
            hideEditCommentButton,
            hideDeleteCommentButton,
            hideHideCommentButton,
            isAuthenticated,
            isVerified,
            numberOfReplies,
            onReply,
            onHideCommentSubmit,
            handleAddReaction,
            handleRemoveReaction,
        } = this.props;
        let {
            showReplyInput,
            showOptionsDropdown,
            showCommentEditForm,
            shouldHideRepliesButton,
            loginPromptIsOpen,
            accountVerifyPromptIsOpen,
        } = this.state;
        let replyInput = null;
        let moreOptionsDropdown = null;
        let submit;
        let authorSlugHash = comment['author']['slug_hash'];
        let commentEditForm = (
            <div>
                <Form
                    onSubmit={(value) => this.handleEditCommentFormSubmit(postId, commentId, value)}
                    render={({handleSubmit}) => {
                        submit = handleSubmit;
                        return (
                            <EditCommentForm />
                        );
                    }}
                    initialValues={{
                        'comment': commentBody
                    }}
                />
                <div className="comment-edit-form-buttons-container">
                    <button
                        className="comment-edit-form-button cancel-button"
                        onClick={this.handleEditCommentFormCancel}
                    >
                        Cancel
                    </button>
                    <button
                        className="comment-edit-form-button"
                        onClick={(e) => submit()}
                    >
                        Submit
                    </button>
                </div>
            </div>
        );

        if (showOptionsDropdown) {
            moreOptionsDropdown = (
                <OptionsDropdown
                    postId={postId}
                    commentId={commentId}
                    hideEditCommentButton={hideEditCommentButton}
                    hideDeleteCommentButton={hideDeleteCommentButton}
                    hideHideCommentButton={hideHideCommentButton}
                    hideOptionsDropdown={this.hideOptionsDropdown}
                    onOptionsClick={this.handleOptionClick}
                    onHideCommentSubmit={onHideCommentSubmit}
                />
            );
        }

        if (showReplyInput) {
            replyInput = (
                <ReplyInput
                    isAuthenticated={isAuthenticated}
                    isVerified={isVerified}
                    placeholder="Write a reply..."
                    onSend={onReply}
                />
            );
        }

        let viewRepliesButton = null;

        if (shouldHideRepliesButton) {
            viewRepliesButton = null;
        } else if (numberOfReplies > 0) {
            viewRepliesButton = (
                <button
                    className="view-replies-button"
                    onClick={this.handleViewRepliesButtonClick}
                >
                    {formatViewReplyButtonTitle(numberOfReplies)}
                </button>
            );
        }
        let currentUserReaction = {};
        let consolidatedReaction = _.reduce(metaData['reaction_summary'], (memo, reaction) => {
            if (reaction.type in memo) {
                memo[reaction.type]['total'] += reaction.total;
                if (!memo[reaction.type]['has_reacted']) {
                    memo[reaction.type]['has_reacted'] = reaction['user_id'] === authUser['user_id'];
                }

            } else {
                memo[reaction.type] = {
                    'total': reaction.total,
                    'has_reacted': reaction['user_id'] === authUser['user_id'],
                };
            }

            if (memo[reaction.type]['has_reacted']) {
                currentUserReaction = {
                    'emojiType': EMOJI_MAP[reaction.type],
                    'emojiTitle': reaction.type,
                    'reactionId': metaData['reaction_id'],
                    'targetEntityType': 'comment',
                };
            }

            return memo;
        }, {});
        let reactionSummaryIcons = Object.keys(consolidatedReaction).map((emojiType, index) => (
            <span key={index} className={`reaction-emoji ${consolidatedReaction[emojiType]['has_reacted'] ? 'has-reacted' : ''}`}>
                <span className="emoji-icon">{EMOJI_MAP[emojiType]}</span>
                <span className="reaction-count">{consolidatedReaction[emojiType]['total']}</span>
            </span>
        ));

        return (
            <div className="comment-card">
                <div className="first-row">
                    <Link to={`/profile/${authorSlugHash}/`}>
                        <div className="author-section">
                            <div className="avatar-section">
                                <div className="comment-card-avatar">
                                    {
                                        authorProfileImageUrl ?
                                        <img className="comment-card-avatar-img" src={authorProfileImageUrl} alt="" />
                                        : null
                                    }
                                </div>
                            </div>
                            <div className="author-data-section">
                                <p className="author-name">{authorName}</p>
                                {
                                    authorTitle ? (<p className="author-summary">{authorTitle}</p>) : null
                                }
                            </div>
                        </div>
                    </Link>
                    {
                        !_.isEmpty(authUser) ?
                        <div className="more-options-icon-wrapper" ref={node => this.node = node}>
                            <button onClick={this.handleMoreOptionsClick}>
                                <img
                                    className="more-options-icon"
                                    src="/media/ui_icons/more.png"
                                    alt="More"
                                />
                            </button>
                            {moreOptionsDropdown}
                        </div> : null
                    }
                </div>
                {
                    showCommentEditForm ?
                    commentEditForm :
                    (
                        <div>
                            <div className="post-description-section">
                                <p className="comment-body">{commentBody}</p>
                            </div>
                            <div className="reaction-summary">
                                {reactionSummaryIcons}
                            </div>
                            <div className="post-meta-data-section">
                                <div className="left-side">
                                    <div>
                                        <EmojiSelector
                                            isAuthenticated={isAuthenticated}
                                            isVerified={isVerified}
                                            currentReaction={currentUserReaction}
                                            handleAddReaction={(emoji) => handleAddReaction(emoji, commentId)}
                                            handleRemoveReaction={(reactionId) => handleRemoveReaction(reactionId, commentId)}
                                        />
                                    </div>
                                    <div>
                                        <button
                                            className="reply-button"
                                            onClick={this.handleReplyClick}
                                        >
                                            <img
                                                className="reply-icon"
                                                src="/media/ui_icons/reply.png"
                                                alt="reply"
                                            />
                                            <p>Reply</p>
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <p>{formatDateTime(commentCreationDate)}</p>
                                </div>
                            </div>
                            {replyInput}
                        </div>
                    )
                }
                {
                    hideShowRepliesButton ? null :
                    viewRepliesButton
                }
                <LoginPrompt
                    isOpen={loginPromptIsOpen}
                    title="Comment to join the conversation."
                    subtitle="After logging in, you can add a comment."
                    handleClose={this.handleCloseLoginPrompt}
                />
                <AccountVerificationPrompt
                    isOpen={accountVerifyPromptIsOpen}
                    title="Verify your account."
                    subtitle="In order to complete this action, verify your account by clicking the button below, and visit the email."
                    handleClose={this.handleCloseAccountVerifyPrompt}
                />
            </div>
        );
    }
}

CommentCard.propTypes = {
    authUser: PropTypes.object,
    postId: PropTypes.number,
    comment: PropTypes.object.isRequired,
    commentId: PropTypes.number,
    authorId: PropTypes.number,
    authorName: PropTypes.string,
    authorTitle: PropTypes.string,
    authorProfileImageUrl: PropTypes.string,
    commentBody: PropTypes.string,
    metaData: PropTypes.object,
    commentCreationDate: PropTypes.string,
    replyToId: PropTypes.number,
    numberOfReplies: PropTypes.number,
    hideShowRepliesButton: PropTypes.bool,
    hideEditCommentButton: PropTypes.bool,
    hideDeleteCommentButton: PropTypes.bool,
    hideHideCommentButton: PropTypes.bool,
    isAuthenticated: PropTypes.bool.isRequired,
    isVerified: PropTypes.bool.isRequired,
    onReply: PropTypes.func,
    onViewRepliesClick: PropTypes.func,
    onEditCommentSubmit: PropTypes.func,
    onHideCommentSubmit: PropTypes.func,
    onDeleteComment: PropTypes.func
};
