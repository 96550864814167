import React, {useState, useEffect} from 'react';
import {Form, Field, FormSpy} from 'react-final-form';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import _ from 'underscore';

import './GroupForm.css';

import {isRequired} from '../../utils/validation';

import CategorySelector from '../category_selector/CategorySelector';
import FullScreenDialog from '../full_screen_dialog/FullScreenDialog';


const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#000',
  },
  formTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const expandCategoryFormValues = (formValues, allCategoies) => {
    let result = [];
    let formKeys = Object.keys(formValues);

    if (!_.isEmpty(formValues) && !_.isEmpty(allCategoies)) {
        formKeys.forEach((key) => {
            if (key.indexOf('category-') === 0 && formValues[key]) {
                let category = _.find(allCategoies, (category) => category.id.toString() === key.split('category-')[1]);

                result.push(category);
            }
        });
    }

    return result;
};

const GroupForm = ({
    initialValues={},
    categoryOptions,
    formTitle,
    formIsOpen,
    handleCancelForm,
    handleSubmitForm
}) => {
    const classes = useStyles();
    let submit;
    let [categorySelectorIsOpen, setCategorySelectorState] = useState(false);
    let defaultSelectedCategories = expandCategoryFormValues(initialValues, categoryOptions);
    let [groupCategories, setGroupCategories] = useState([]);

    useEffect(() => {
        if (_.isEmpty(groupCategories) && !_.isEmpty(defaultSelectedCategories)) {
            setGroupCategories(defaultSelectedCategories);
        }
    }, [initialValues, groupCategories, defaultSelectedCategories]);

    let groupCategoryButtons = groupCategories.map((category) => {
        return (
            <div key={category.id} className="category-button">
                {category.name}
            </div>
        );
    });
    const validateCategories = (formValues) => {
        let errors = {};
        let categoryIsSelected = _.find(Object.keys(formValues), (key) => (
            Boolean(key.indexOf('category-') === 0 && formValues[key])
        ));

        if (!categoryIsSelected) {
            errors['categories'] = "Select a category"
        }

        return errors;
    };
    const handleChange = (category) => {
        let categoryIsSelected = _.findIndex(
            groupCategories,
            (selected) => selected.id === category.id
        ) > -1;

        if (categoryIsSelected) {
            let newState = _.filter(groupCategories, (selectedCategory) => (
                selectedCategory.id !== category.id
            ));

            setGroupCategories(newState);

        } else {
            let newState = [...groupCategories, category];

            setGroupCategories(newState);
        }

    };
    const handleCloseCategoriesSelector = () => {
        setCategorySelectorState(false);
    };
    const CategorySelectorWrapper = () => (
        <CategorySelector
            categories={categoryOptions}
            defaultSelectedCategories={groupCategories}
            onChange={(category) => handleChange(category)}
        />
    );
    const closeCategoriesButton = () => (
        <Button color="inherit" onClick={handleCloseCategoriesSelector}>
            Close
        </Button>
    );
    const OpenButton = () => (
        <Button color="inherit" onClick={() => setCategorySelectorState(true)}>
            Add Categories
        </Button>
    )

    return (
        <Dialog
            open={formIsOpen}
            TransitionComponent={Transition}
            fullScreen
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.formTitle}>
                        {formTitle}
                    </Typography>
                    <Button color="inherit" onClick={handleCancelForm}>
                        Cancel
                    </Button>
                    <Button color="inherit" onClick={(e) => submit(e)}>
                        save
                    </Button>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Form
                    onSubmit={handleSubmitForm}
                    initialValues={initialValues}
                    validate={validateCategories}
                    render={({handleSubmit, errors, ...rest}) => {
                        submit = handleSubmit;

                        return (
                            <form className="create-group-form">
                                <div className="group-name-input-container">
                                    <Field
                                        name="name"
                                        validate={isRequired}
                                        render={({input, meta}) => (
                                            <span>
                                                <input
                                                    {...input}
                                                    className="group-name-input"
                                                    placeholder="Name of the group"
                                                />
                                            {
                                                meta.error && meta.touched &&
                                                <span className="error-message">{meta.error}</span>
                                            }
                                            </span>
                                        )}
                                    />
                                </div>
                                <div className="group-description-input-container">
                                    <Field
                                        name="description"
                                        render={({input}) => (
                                            <textarea
                                                {...input}
                                                className="group-description-textarea"
                                                placeholder="Describe this group. What is its purpose?"
                                            >
                                            </textarea>
                                        )}
                                    />
                                </div>
                                <div>
                                    <FullScreenDialog
                                        title="Cateogries"
                                        isOpen={categorySelectorIsOpen}
                                        OpenButton={OpenButton}
                                        ContentComponent={CategorySelectorWrapper}
                                        PrimaryButton={closeCategoriesButton}
                                        handleClickOpen={() => setCategorySelectorState(true)}
                                        handleCloseDialog={() => setCategorySelectorState(false)}
                                    />
                                    <FormSpy subscription={{errors: true, submitting: true}}
                                        render={({errors, submitting}) => {
                                            if (errors.categories) {
                                                return (
                                                    <div className="error-message">{errors.categories}</div>
                                                );
                                            }

                                            return <div></div>
                                        }}
                                    />
                                    <div className="group-category-buttons-section">
                                        {groupCategoryButtons}
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

export default GroupForm;
