import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import {fetchApiGet} from '../../utils/api';

import './AccountVerificationPrompt.css';


function AccountVerificationPrompt({
    isOpen,
    title,
    subtitle,
    handleClose
}) {
    let [openState, setOpen] = useState(isOpen);
    let [resendButtonIsClicked, setResendButtonState] = useState(false);

    function handleCloseState() {
        setOpen(false);
        handleClose(false);
    }

    function handleResendButtonClick() {
        fetchApiGet('resend-account-verification/')
            .then(() => setResendButtonState(true));
    }

    let sendButton = (
        <button
            className="send-button"
            onClick={handleResendButtonClick}
        >
            Send account verification email
        </button>
    );

    if (resendButtonIsClicked) {
        sendButton = (
            <button
                className="send-button"
                disabled
            >
                Verification email sent!
            </button>
        );
    }

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    return (
        <Dialog
            open={openState}
            maxWidth="lg"
        >
            <div className="account-verification-prompt-container">
                <div>
                    <IconButton
                        onClick={() => handleCloseState()}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <h2 className="logo">Boeiie</h2>
                <div className="title-container">
                    {
                        title ?
                        <h3 className="title">{title}</h3> : null
                    }
                    {
                        subtitle ?
                        <p className="sub-title">{subtitle}</p> : null
                    }
                </div>
                <div className="button-container">
                    {sendButton}
                </div>
            </div>
        </Dialog>
    );
}

export default AccountVerificationPrompt;
