import _ from 'underscore';


export const getAllCategoriesFromState = (categoriesState) => {
    let {
        allCategories=[],
        byIds={}
    } = categoriesState;
    let result = [];

    allCategories.forEach((categoryId) => {
        result.push(byIds[categoryId]);
    });

    return result;
};

export const getMyCategoriesFromState = (categoriesState) => {
    let {
        myCategoryPreferences=[],
    } = categoriesState;
    let result = [];

    myCategoryPreferences.forEach((preference) => {
        result.push(preference);
    });

    return result;
};

export const categoriesToFormValues = (categories) => {
    let result = {};

    categories.forEach((category) => (
        result[`category-${category.id}`] = true
    ));

    return result;
};

export const transformCategoryPreferencesToCategories = (preferences=[]) => {
    let result = _.pluck(preferences, 'topic');

    return result;
};
