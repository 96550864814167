import {connect} from 'react-redux';

import {loadAuthUserSuccess} from '../../app/actions';
import {
    STORAGE_KEY,
    fetchApiGet,
    fetchApiPost
} from '../../common/utils/api';

import Admin from './Admin';


const initializePage = (dispatch) => {
    let storageData = localStorage.getItem(STORAGE_KEY);

    storageData = JSON.parse(storageData) || {};

    return fetchApiGet('me/')
        .then(({user}) => {
            if (storageData['token']) {
                dispatch(loadAuthUserSuccess({
                    ...storageData,
                    ...user
                }));
            }
        });
};

const createNewUser = (formValues) => (dispatch) => {
    return fetchApiPost('create-user/', formValues)
};

const mapStateToProps = (state) => ({
    adminLoading: state.app.authUser === undefined || state.app.authUser['is_admin'] === undefined,
    isAdmin: state.app.authUser ? state.app.authUser['is_admin'] : false
});

const mapDispatchToProps = (dispatch) => ({
    onLoad: () => dispatch(initializePage),
    onCreateNewUserSubmit: (formValues) => dispatch(createNewUser(formValues))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Admin);
