import PropTypes from 'prop-types';
import React, {Component} from 'react';
import _ from 'underscore';

import {fetchApiGet} from '../../utils/api';
import ComposeHeader from '../compose_header/ComposeHeader';

import './TagFilters.css';


const ALPHABETS = [
'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K',
'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V',
'W', 'X', 'Y', 'Z'
];


export default class TagFilters extends Component {

    constructor(props) {
        super(props);

        let {defaultTags} = this.props;

        this.state = {
            allTags: [],
            tempChosenTags: [],
            chosenTags: defaultTags || []
        };
    }

    componentDidMount() {
        fetchApiGet('tags/')
            .then(({tags}) => {
                this.setState({
                    allTags: tags,
                });
            });
    }

    handleButtonClick = (tag) => {
        let {
            chosenTags,
            tempChosenTags
        } = this.state;
        let inTempChosenTags = false;
        let isChosen = _.find(chosenTags, (chosenTag) => {
            return chosenTag.id === tag.id;
        });

        if (!isChosen) {
            isChosen = _.find(tempChosenTags, (chosenTag) => {
                return chosenTag.id === tag.id;
            });

            if (isChosen) {
                inTempChosenTags = true;
            }
        }

        if (isChosen && inTempChosenTags) {
            tempChosenTags = _.filter(tempChosenTags, (chosenTag) => {
                return chosenTag.id !== tag.id;
            });
        } else if (isChosen && !inTempChosenTags) {
            chosenTags = _.filter(chosenTags, (chosenTag) => {
                return chosenTag.id !== tag.id;
            });
        } else if ((tempChosenTags.length + chosenTags.length) < 3) {
            tempChosenTags.push(tag);
        }

        this.setState({
            tempChosenTags,
            chosenTags
        });
    };

    handleSelect = () => {
        let {onSelect} = this.props;
        let {
            chosenTags,
            tempChosenTags
        } = this.state;
        let allChosenTags = _.union(chosenTags, tempChosenTags);

        this.setState({
            chosenTags: allChosenTags,
            tempChosenTags: []
        });

        onSelect(allChosenTags);
    };

    handleCancel = () => {
        let {onCancel} = this.props;

        this.setState({
            tempChosenTags: []
        });

        onCancel();
    };

    render() {
        let {
            allTags,
            tempChosenTags,
            chosenTags
        } = this.state;
        let alphabeticalSectionMap = {};
        let allChosenTags = _.union(chosenTags, tempChosenTags);

        allTags.forEach((tag) => {
            if (!alphabeticalSectionMap[tag.name[0]]) {
                alphabeticalSectionMap[tag.name[0]] = [tag];
            } else {
                alphabeticalSectionMap[tag.name[0]].push(tag);
            }
        });

        let alphabeticalSections = ALPHABETS.map((alphabet) => {
            if (!alphabeticalSectionMap[alphabet]) return null;

            return (
                <div key={alphabet} className="alphabet-section">
                    <h3 id={alphabet}>{alphabet}</h3>
                    {
                        alphabeticalSectionMap[alphabet].map((tag) => {
                            let chosen = null;
                            let isChosen = _.find(allChosenTags, (chosenTag) => {
                                return chosenTag.id === tag.id;
                            });

                            if (isChosen) {
                                chosen = 'chosen';
                            }

                            if (tag.name[0] === alphabet) {
                                return (
                                    <div key={tag.name} className="tag-button-container">
                                        <div
                                            className={`tag-button ${chosen}`}
                                            onClick={() => this.handleButtonClick(tag)}
                                        >
                                            {tag.name}
                                        </div>
                                    </div>
                                );
                            }

                            return null;
                        })
                    }
                </div>
            );
        });

        let alphabetIndexList = ALPHABETS.map((alphabet) => {
            if (alphabeticalSectionMap[alphabet]) {
                return (
                    <span key={alphabet} className="alphabet-index">{alphabet}</span>
                );
            } else {
                return (
                    <span key={alphabet} className="disabled-index alphabet-index">{alphabet}</span>
                );
            }
        });

        return (
            <div id="tag-filters">
                <div className="compose-header-container">
                    <ComposeHeader
                        primaryButtonTitle="Cancel"
                        primaryButtonOnClick={this.handleCancel}
                        secondaryButtonTitle="Add"
                        secondaryButtonOnClick={this.handleSelect}
                    />
                </div>
                <div className="content-body">
                    <div className="content-body-inner">
                        <div className="alphabet-index-container">
                            {alphabetIndexList}
                        </div>
                        <div className="tag-list-container">
                            {alphabeticalSections}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

TagFilters.propTypes = {
    onCancel: PropTypes.func,
    onSelect: PropTypes.func,
    defaultTags: PropTypes.array
};
