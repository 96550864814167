import _ from 'underscore';

import {SAVE_POST_SUCCESS} from '../../common/actions/posts';
import {
    CREATE_REACTION_SUCCESS,
    REMOVE_REACTION_SUCCESS,
} from '../../common/actions/reactions';

import * as post_actions from './actions';


const loadPostStartReducer = (state) => ({
    ...state,
    isLoadingPost: true,
    post: {}
});

const loadPostSuccessReducer = (state, {post={}}) => ({
    ...state,
    isLoadingPost: false,
    post
});

const loadPostFailReducer = (state, {error}) => ({
    ...state,
    isLoadingPost: false,
    post: {},
    error
});

const savePostSuccessReducer = (state, {post}) => ({
    ...state,
    post
});

const createReactionSuccessReducer = (state, {reaction, authUserId}) => {
    if (!state.post) {
        return state;
    }

    // Check if a reaction is already set.
    if (reaction['target_entity_type'] === 'post') {
        let oldPost = {
            ...state.post
        };

        let oldPostReactionSummaryIndex = _.findIndex(oldPost['meta_data']['reaction_summary'], (reaction) => {
            return reaction['user_id'] === authUserId;
        });
        let postHasExistingReaction = oldPostReactionSummaryIndex > -1;

        if (postHasExistingReaction) {
            let oldPostReactionSummary = oldPost['meta_data']['reaction_summary'][oldPostReactionSummaryIndex];

            oldPostReactionSummary['type'] = reaction['reaction_emoji_type'];
            oldPost['meta_data']['reaction_summary'][oldPostReactionSummaryIndex] = oldPostReactionSummary;

            return {
                ...state,
                post: oldPost,
            };
        } else {
            oldPost['meta_data']['reaction_summary'].push({
                type: reaction['reaction_emoji_type'],
                total: 1,
                'user_id': authUserId,
            });
            oldPost['meta_data']['reaction_id'] = reaction.id


            return {
                ...state,
                post: oldPost
            };
        }
    }

    return state;
};

const removeReactionSuccessReducer = (state, {reactionId, targetEntityId, authUserId}) => {
    if (!state.post) {
        return state;
    }

    state.post['meta_data']['reaction_summary'] = _.filter(state.post['meta_data']['reaction_summary'], (summary) => {
        return summary['user_id'] !== authUserId;
    });
    state.post['meta_data']['reaction_id'] = null;

    let newPost = {
        ...state.post
    };

    return {
        ...state,
        post: newPost
    };
};

const POST_REDUCER_MAP = {
    [post_actions.LOAD_POST_START]: loadPostStartReducer,
    [post_actions.LOAD_POST_SUCCESS]: loadPostSuccessReducer,
    [post_actions.LOAD_POST_FAIL]: loadPostFailReducer,
    [SAVE_POST_SUCCESS]: savePostSuccessReducer,
    [CREATE_REACTION_SUCCESS]: createReactionSuccessReducer,
    [REMOVE_REACTION_SUCCESS]: removeReactionSuccessReducer,
};

const postDetailsReducer = (initialState={}, action={}) => {
    let reducer = POST_REDUCER_MAP[action.type];
    let nextState = initialState;

    if (reducer) {
        nextState = reducer(initialState, action);
    }

    return nextState;
};

export default postDetailsReducer;
