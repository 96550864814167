import _ from 'underscore';

import {
    INITIALIZE_CATEGORIES,
    CREATE_CATEGORY_PREFERENCES_SUCCESS,
    REMOVE_CATEGORY_PREFERENCES_SUCCESS,
    LOAD_CATEGORIES_START,
    LOAD_CATEGORIES_SUCCESS,
    LOAD_CATEGORIES_FAIL,
    LOAD_MY_CATEGORIES_START,
    LOAD_MY_CATEGORIES_SUCCESS,
    LOAD_MY_CATEGORIES_FAIL,
} from '../actions/categories';


const initializeCategoriesReducer = (state) => ({
    byIds: {},
    allCategories: [],
    myCategoryPreferences: [],
    errors: null
});

const createCategoryPreferencesSuccessReducer = (state, {preferences}) => {
    let nextState = {
        ...state,
        myCategoryPreferences: [],
        errors: null
    };

    preferences.forEach((preference) => {
        nextState.byIds[preference.topic.id] = preference.topic;
        nextState.myCategoryPreferences.push(preference);
    });

    return nextState;
};

const removeCategoryPreferencesSuccessReducer = (state, {preference}) => {
    let nextState = {
        ...state,
        errors: null
    };

    nextState.myCategoryPreferences = _.filter(nextState.myCategoryPreferences, (myPreference) => {
        return myPreference.id !== preference.id;
    });

    return nextState;
};

const loadCategoriesStartReducer = (state) => ({
    ...state
});

const loadCategoriesSuccessReducer = (state, {categories}) => {
    let nextState = {
        ...state,
        errors: null
    };

    categories.forEach((category) => {
        nextState.byIds[category.id] = category;
        nextState.allCategories.push(category.id);
    });

    return nextState;
};

const loadCategoriesFailReducer = (state, {errors}) => ({
    ...state,
    errors
});

const loadMyCategoriesStartReducer = (state) => ({
    ...state
});

const loadMyCategoriesSuccessReducer = (state, {categories}) => {
    let nextState = {
        ...state,
        errors: null
    };

    categories.forEach((preference) => {
        nextState.byIds[preference.topic.id] = preference.topic;
        nextState.myCategoryPreferences.push(preference);
    });

    return nextState;
};

const loadMyCategoriesFailReducer = (state, {errors}) => ({
    ...state,
    errors
});

const CATEGORIES_REDUCER_MAP = {
    [INITIALIZE_CATEGORIES]: initializeCategoriesReducer,
    [CREATE_CATEGORY_PREFERENCES_SUCCESS]: createCategoryPreferencesSuccessReducer,
    [REMOVE_CATEGORY_PREFERENCES_SUCCESS]: removeCategoryPreferencesSuccessReducer,
    [LOAD_CATEGORIES_START]: loadCategoriesStartReducer,
    [LOAD_CATEGORIES_SUCCESS]: loadCategoriesSuccessReducer,
    [LOAD_CATEGORIES_FAIL]: loadCategoriesFailReducer,
    [LOAD_MY_CATEGORIES_START]: loadMyCategoriesStartReducer,
    [LOAD_MY_CATEGORIES_SUCCESS]: loadMyCategoriesSuccessReducer,
    [LOAD_MY_CATEGORIES_FAIL]: loadMyCategoriesFailReducer,
};

const CategoriesReducer = (initialState={}, action={}) => {
    let reducer = CATEGORIES_REDUCER_MAP[action.type];
    let nextState = initialState;

    if (reducer) {
        nextState = reducer(initialState, action);
    }

    return nextState;
};

export default CategoriesReducer;
