export const INITIALIZE_GROUPS = 'initialize-groups';

export const initializeGroups = () => ({
    type: INITIALIZE_GROUPS
});


export const LOAD_GROUPS_START = 'load-groups-start';
export const LOAD_GROUPS_SUCCESS = 'load-groups-success';
export const LOAD_GROUPS_FAIL = 'load-groups-fail';

export const loadGroupsStart = () => ({
    type: LOAD_GROUPS_START
});

export const loadGroupsSuccess = (groups, next='', previous='', options={}) => ({
    type: LOAD_GROUPS_SUCCESS,
    groupsPreviousLink: previous,
    groupsNextLink: next,
    groups,
    options,
});

export const loadGroupsFail = (errors) => ({
    type: LOAD_GROUPS_FAIL,
    errors
});


export const LOAD_MY_GROUPS_START = 'load-my-groups-start';
export const LOAD_MY_GROUPS_SUCCESS = 'load-my-groups-success';
export const LOAD_MY_GROUPS_FAIL = 'load-my-groups-fail';

export const loadMyGroupsStart = () => ({
    type: LOAD_MY_GROUPS_START
});

export const loadMyGroupsSuccess = (groups, next='', previous='', options={}) => ({
    type: LOAD_MY_GROUPS_SUCCESS,
    groupsPreviousLink: previous,
    groupsNextLink: next,
    groups,
    options
});

export const loadMyGroupsFail = (errors) => ({
    type: LOAD_MY_GROUPS_FAIL,
    errors
});


export const LOAD_GROUP_START = 'load-group-start';
export const LOAD_GROUP_SUCCESS = 'load-group-success';
export const LOAD_GROUP_FAIL = 'load-group-fail';

export const loadGroupStart = () => ({
    type: LOAD_GROUP_START
});

export const loadGroupSuccess = (group) => ({
    type: LOAD_GROUP_SUCCESS,
    group,
});

export const loadGroupFail = (errors) => ({
    type: LOAD_GROUP_FAIL,
    errors
});


export const LOAD_GROUP_MEMBERS_START = 'load-group-members-start';
export const LOAD_GROUP_MEMBERS_SUCCESS = 'load-group-members-success';
export const LOAD_GROUP_MEMBERS_FAIL = 'load-group-members-fail';

export const loadGroupMembersStart = () => ({
    type: LOAD_GROUP_MEMBERS_START
});

export const loadGroupMembersSuccess = (members, membersNextPaginationPage) => ({
    type: LOAD_GROUP_MEMBERS_SUCCESS,
    members,
    membersNextPaginationPage,
});

export const loadGroupMembersFail = (errors) => ({
    type: LOAD_GROUP_MEMBERS_FAIL,
    errors
});

export const LOAD_GROUP_ADMINS_START = 'load-group-admins-start';
export const LOAD_GROUP_ADMINS_SUCCESS = 'load-group-admin-success';
export const LOAD_GROUP_ADMINS_FAIL = 'load-group-admins-fail';

export const loadGroupAdminsStart = () => ({
    type: LOAD_GROUP_ADMINS_START
});

export const loadGroupAdminsSuccess = (admins, adminNextPaginationPage) => ({
    type: LOAD_GROUP_ADMINS_SUCCESS,
    admins: admins || [],
    adminNextPaginationPage,
});

export const loadGroupAdminsFail = (errors) => ({
    type: LOAD_GROUP_ADMINS_FAIL,
    errors
});


export const CREATE_GROUP_START = 'create-group-start';
export const CREATE_GROUP_SUCCESS = 'create-group-success';
export const CREATE_GROUP_FAIL = 'create-group-fail';

export const createGroupStart = () => ({
    type: CREATE_GROUP_START
});

export const createGroupSuccess = (group) => ({
    type: CREATE_GROUP_SUCCESS,
    group,
});

export const createGroupFail = (errors) => ({
    type: CREATE_GROUP_FAIL,
    errors
});


export const JOIN_GROUP_START = 'join-group-start';
export const JOIN_GROUP_SUCCESS = 'join-group-success';
export const JOIN_GROUP_FAIL = 'join-group-fail';

export const joinGroupStart = () => ({
    type: JOIN_GROUP_START
});

export const joinGroupSuccess = (member) => ({
    type: JOIN_GROUP_SUCCESS,
    member,
});

export const joinGroupFail = (errors) => ({
    type: JOIN_GROUP_FAIL,
    errors
});


export const UNJOIN_GROUP_START = 'unjoin-group-start';
export const UNJOIN_GROUP_SUCCESS = 'unjoin-group-success';
export const UNJOIN_GROUP_FAIL = 'unjoin-group-fail';

export const unjoinGroupStart = () => ({
    type: UNJOIN_GROUP_START
});

export const unjoinGroupSuccess = (member) => ({
    type: UNJOIN_GROUP_SUCCESS,
    member,
});

export const unjoinGroupFail = (errors) => ({
    type: UNJOIN_GROUP_FAIL,
    errors
});


export const CLEAR_GROUP_ERROR_STATE = 'clear-group-error-state';
export const clearGroupErrorState = () => ({
    type: CLEAR_GROUP_ERROR_STATE
});
