import {connect} from 'react-redux';

import GroupInvitation from './GroupInvitation';


const mapStateToProps = ({app}) => ({
    isAuthenticated: !!(app.authUser && app.authUser.token),
});

export default connect(
    mapStateToProps,
    null
)(GroupInvitation);
