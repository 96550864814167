export const LOAD_POST_START = 'load-post-start';
export const LOAD_POST_SUCCESS = 'load-post-success';
export const LOAD_POST_FAIL = 'load-post-fail';

export const loadPostStart = () => ({
    type: LOAD_POST_START
});

export const loadPostSuccess = ({post}) => ({
    type: LOAD_POST_SUCCESS,
    post
});

export const loadPostFail = (error) => ({
    type: LOAD_POST_FAIL,
    error
});
