export const NOTIFICATION_ACTION_TYPE_POST = 'post';
export const NOTIFICATION_ACTION_TYPE_COMMENT = 'comment';
export const NOTIFICATION_ACTION_TYPE_REPLY = 'reply';
export const NOTIFICATION_ACTION_TYPE_GROUP_MEMBER_JOINED = 'group-member-joined';
export const NOTIFICATION_ACTION_TYPE_GROUP_MEMBER_ROLE_ASSIGNMENT = 'group-member-role-assignment';
export const NOTIFICATION_ACTION_TYPE_GROUP_NEW_POST = 'group-new-post';
export const NOTIFICATION_ACTION_TYPE_GROUP_DETAILS_CHANGED = 'group-details-changed';
export const NOTIFICATION_ACTION_TYPE_GROUP_DELETED = 'group-deleted';
export const NOTIFICATION_ACTION_TYPE_COMMENT_REACTION = 'comment-reaction';
export const NOTIFICATION_ACTION_TYPE_POST_REACTION = 'post-reaction';

export const NOTIFICATION_TARGET_ENTITY_TYPE_COMMENT = 'comment';

export const NOTIFICATION_ROUTE_GENERATOR_MAP = {
    [NOTIFICATION_ACTION_TYPE_GROUP_MEMBER_JOINED]: (slugHash, slug) => (
        `/groups/${slugHash}/${slug}/members/`
    ),
    [NOTIFICATION_ACTION_TYPE_GROUP_MEMBER_ROLE_ASSIGNMENT]: (slugHash, slug) => (
        `/groups/${slugHash}/${slug}/members/`
    ),
    [NOTIFICATION_ACTION_TYPE_GROUP_NEW_POST]: (slugHash, slug) => (
        slug ? `/post/${slugHash}/${slug}/` : `/post/${slugHash}/`
    ),
    [NOTIFICATION_ACTION_TYPE_GROUP_DETAILS_CHANGED]: (slugHash, slug) => (
        `/groups/${slugHash}/${slug}/`
    ),
    [NOTIFICATION_ACTION_TYPE_GROUP_DELETED]: () => '/groups/',
    [NOTIFICATION_ACTION_TYPE_COMMENT]: (slugHash, slug) => (
        slug ? `/post/${slugHash}/${slug}/` : `/post/${slugHash}/`
    ),
    [NOTIFICATION_ACTION_TYPE_COMMENT_REACTION]: (slugHash, slug) => (
        slug ? `/post/${slugHash}/${slug}/` : `/post/${slugHash}/`
    ),
    [NOTIFICATION_ACTION_TYPE_POST_REACTION]: (slugHash, slug) => (
        slug ? `/post/${slugHash}/${slug}/` : `/post/${slugHash}/`
    ),
    [NOTIFICATION_ACTION_TYPE_POST]: (slugHash, slug) => (
        slug ? `/post/${slugHash}/${slug}/` : `/post/${slugHash}/`
    ),
};
