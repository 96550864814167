import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {
    Link,
    Redirect
} from 'react-router-dom';

import GlobalHeader from '../../common/components/global_header/connector';
import {fetchApiGet} from '../../common/utils/api';

import './SignupConfirmation.css';


function SignupConfirmation({
    isVerified,
    isAuthenticated,
}) {
    let [clickedResendButton, setResendButtonState] = useState(false);

    if (isAuthenticated && isVerified) {
        return <Redirect to="/" />
    }

    const handleResendAccountVerification = () => {
        if (isAuthenticated) {
            fetchApiGet('resend-account-verification/')
                .then((result) => {
                    setResendButtonState(true);
                });
        }
    };

    let resendButtonSection = (
        <div className="resend-button-container">
            <button
                className="resend-button"
                onClick={handleResendAccountVerification}
            >
                Resend account verification email.
            </button>
        </div>
    );

    if (clickedResendButton) {
        resendButtonSection = (
            <div className="resend-button-container">
                <button
                    className="resend-button"
                    disabled
                >
                    A new verification email was sent!
                </button>
            </div>
        );
    }

    if (!isAuthenticated) {
        resendButtonSection = null;
    }

    return (
        <div id="signup-confirmation">
            <GlobalHeader />
            <div className="page-body-container">
                <h2>Signup Confirmation - Verify your account.</h2>
                <h3 className="confirmation-title">Congratulations! You've successfully signed up for Boeiie.</h3>
                <p className="confirmation-instruction-message"><strong>A verification email was sent to the email you created this account. Click the verification link in that email.</strong></p>
                <p>In order to participate in discussions, you must first verify your account.</p>
                {
                    isAuthenticated ?
                    <p>
                        If you do not see an email in 10 minutes, click the button below, or
                        <Link to="/account/" style={{'textDecoration': 'underline'}}> go to your account page and click the account verification resend button.</Link>
                    </p> :
                    <p>
                        If you do not see an email in 10 minutes, <Link to="/account/" style={{'textDecoration': 'underline'}}> go to your account page and click the account verification resend button.</Link>
                    </p>
                }
                {resendButtonSection}
            </div>
        </div>
    );
}

SignupConfirmation.propTypes = {
    isVerified: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
};

export default SignupConfirmation;
