import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {isDemo} from '../../utils/routing';

import './ComposeHeader.css';


export default class ComposeHeader extends Component {

    handlePrimaryButtonClick = (e) => {
        let {primaryButtonOnClick} = this.props;

        primaryButtonOnClick(e);
    };

    render() {
        let {
            primaryButtonTitle,
            secondaryButtonTitle,
            secondaryButtonOnClick,
            secondaryButtonIsDisabled
        } = this.props;
        let secondaryButton = (
             <button
                className="send-post-button"
                onClick={(e) => secondaryButtonOnClick(e)}
            >
                {secondaryButtonTitle}
            </button>
        );

        if (secondaryButtonIsDisabled || isDemo()) {
            secondaryButton = (
                <button
                    className="send-post-button disabled"
                    disabled={secondaryButtonIsDisabled || isDemo()}
                >
                    {secondaryButtonTitle}
                </button>
            );
        }

        return (
            <div className="compose-header">
                <div className="compose-header-inner-container">
                    <div className="right-side">
                        <button
                            className="cancel-button"
                            onClick={this.handlePrimaryButtonClick}
                        >
                            {primaryButtonTitle}
                        </button>
                       {secondaryButton}
                    </div>
                </div>
            </div>
        );
    }
}

ComposeHeader.propTypes = {
    primaryButtonTitle: PropTypes.string,
    primaryButtonOnClick: PropTypes.func,
    secondaryButtonTitle: PropTypes.string,
    secondaryButtonOnClick: PropTypes.func,
    secondaryButtonIsDisabled: PropTypes.bool
};
