export const UPDATE_AUTH_USER = 'update-auth-user';

export const updateAuthUser = (authUser) => ({
    type: UPDATE_AUTH_USER,
    authUser,
});


export const LOGOUT_USER_SUCCESS = 'logout-user-success';

export const logoutUserSuccess = () => ({
    type: LOGOUT_USER_SUCCESS
});
