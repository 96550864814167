export const INITIALIZE_PAGE_START = 'initialize-page-start';
export const INITIALIZE_PAGE_SUCCESS = 'initialize-page-success';
export const INITIALIZE_PAGE_FAIL = 'initialize-page-fail';

export const initializePageStart = () => ({
    type: INITIALIZE_PAGE_START
});

export const initializePageSuccess = (profile) => ({
    type: INITIALIZE_PAGE_SUCCESS,
    profile
});

export const initializePageFail = (errors) => ({
    type: INITIALIZE_PAGE_FAIL,
    errors
});
