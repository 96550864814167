import PropTypes from 'prop-types';
import React, {Component} from 'react';

import './PreviewLink.css';


export default class PreviewLink extends Component {
    render() {
        let {
            linkUrl,
            imageUrl,
            title,
        } = this.props;
        let previewImage = null;
        let previewTitle = null;
        let hostname = null;

        if (!linkUrl) {
            return null;
        }

        let urlObj = new URL(linkUrl);

        hostname = urlObj.hostname;

        if (title) {
            previewTitle = (
                <p className="title">{title}</p>
            );
        }

        if (imageUrl) {
            previewImage = (
                <div className="preview-image-container">
                    <img className="preview-image" src={imageUrl} alt={title} />
                </div>
            );
        }

        return (
            <a
                className="preview-link"
                href={linkUrl}
                target="_blank"
                rel="noopener noreferrer nofollow"
            >
                {previewImage}
                <div className="preview-content-section">
                    {previewTitle}
                    <p className="hostname">{hostname}</p>
                </div>
            </a>
        );
    }
}

PreviewLink.propTypes = {
    linkUrl: PropTypes.string,
    imageUrl: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string
};
