import React, {Component} from 'react';
import PropTypes from 'prop-types';

import GroupsList from '../../common/components/groups_list/GroupsList';
import GlobalHeader from '../../common/components/global_header/connector';

import './AllGroups.css';


export default class AllGroups extends Component {
    static propTypes = {
        groups: PropTypes.array.isRequired,
        hasMoreGroups: PropTypes.bool,
        pagination: PropTypes.object,
        handlePageMount: PropTypes.func.isRequired,
        handleLoadGroups: PropTypes.func,
    };

    componentDidMount() {
        let {handlePageMount} = this.props;

        handlePageMount();
    }

    render() {
        let {
            groups,
            pagination,
            hasMoreGroups,
            handleLoadGroups
        } = this.props;

        return (
            <div id="all-groups">
                <GlobalHeader />
                <div className="page-body-container">
                    <h2 className="page-title">All Groups</h2>
                    <GroupsList
                        groups={groups}
                        pagination={pagination}
                        hasMoreGroups={hasMoreGroups}
                        handleLoadGroups={handleLoadGroups}
                    />
                </div>
            </div>
        );
    }
}
