import {connect} from 'react-redux';
import _ from 'underscore';

import {
    createComment,
    editComment,
    deleteComment,
    hideComment,
    loadReplies,
    loadCommentsForPost
} from '../../action_creators/comments';
import * as commentActions from '../../actions/comments';
import {COMMENT_THREAD_PAGINATION_LIMIT} from '../../constants/comments';
import {
    getCommentsList,
    getVerboseReplyMap
} from '../../utils/comments';

import CommentThread from './CommentThread';


const mapStateToProps = ({app, commentThread, comments}, {postId}) => {
    let {authUser} = app;
    let commentThreadKey = `posts/${postId}/`;
    let {
        hasMoreComments
    } = commentThread[commentThreadKey] || {};
    let commentsList = getCommentsList(comments, commentThreadKey);
    let verboseReplyMap = getVerboseReplyMap(comments, commentThreadKey);

    return {
        authUser,
        isAuthenticated: !_.isEmpty(authUser),
        isVerified: !_.isEmpty(authUser) && !!authUser.is_verified,
        comments: commentsList,
        replyMap: verboseReplyMap,
        hasMoreComments,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: (postId, postSlugHash) => {
        dispatch(commentActions.initializeCommentThread(postId));

        if (postSlugHash) {
            dispatch(loadCommentsForPost(
                postId,
                postSlugHash,
                {
                    limit: COMMENT_THREAD_PAGINATION_LIMIT,
                    offset: 0
                }
            ));
        }
    },
    onLoadReplies: (postId, postSlugHash, parentCommentId) => dispatch(loadReplies(postId, postSlugHash, parentCommentId)),
    onLoadComments: (postId, postSlugHash, page) => dispatch(loadCommentsForPost(
        postId,
        postSlugHash,
        {
            limit: COMMENT_THREAD_PAGINATION_LIMIT,
            offset: page * COMMENT_THREAD_PAGINATION_LIMIT,
            order: 'desc'
        },
    )),
    onNewCommentSubmit: (postSlugHash, comment) => dispatch(createComment(postSlugHash, comment)),
    onEditCommentSubmit: (postId, commentId, value) => dispatch(editComment(postId, commentId, value)),
    onDeleteComment: (postId, commentId) => dispatch(deleteComment(postId, commentId)),
    onHideCommentSubmit: (postId, commentId, reason='') => dispatch(hideComment(postId, commentId, reason)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommentThread);
