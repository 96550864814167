import {connect} from 'react-redux';

import {loadPosts} from '../../common/action_creators/posts';
import {PROFILE} from '../../common/constants/demo';
import {fetchApiGet} from '../../common/utils/api';
import {isDemo} from '../../common/utils/routing';

import {
    initializePageStart,
    initializePageSuccess,
    initializePageFail,
} from './actions'
import ProfilePage from './ProfilePage';


const initializePage = (slugHash, queryParams) => (dispatch) => {
    dispatch(initializePageStart());

    return fetchApiGet(`users/${slugHash}/profile/`)
        .then(({profile}) => {
            return dispatch(initializePageSuccess(profile));
        })
        .catch(({errors}) => {
            return dispatch(initializePageFail(errors));
        });
};

const mapStateToProps = (state, {match}) => {
    let {profile={}} = state.userProfile;
    let {userId} = match.params;
    let posts = state.postListing.posts || [];
    let nextLink = state.postListing.nextLink;
    let previousLink = state.postListing.previousLink;

    if (isDemo()) {
        profile = PROFILE;
    }

    return {
        authUser: state.app.authUser || {},
        profileImageUrl: profile['profile_image_url'] || '',
        profileHeadline: profile['profile_headline'] || '',
        profileHeaderSummary: profile['profile_header_summary'] || '',
        firstName: profile['first_name'] || '',
        lastName: profile['last_name'] || '',
        occupation: profile['occupation'] || {},
        city: profile['city'] || '',
        state: profile['state'] || '',
        nextLink,
        previousLink,
        posts,
        userId
    };
};

const mapDispatchToProps = (dispatch, {match}) => ({
        onLoad: (slugHash, queryParams) => dispatch(initializePage(slugHash, queryParams)),
        onSubmitFormSuccess: () => dispatch(initializePage(match.params.slugHash)),
        loadPosts: (queryParams) => dispatch(loadPosts(queryParams)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfilePage);
