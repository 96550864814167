export const LOAD_AUTH_USER_START = 'load-auth-user-start';
export const LOAD_AUTH_USER_SUCCESS = 'load-auth-user-success';
export const LOAD_AUTH_USER_FAIL = 'load-auth-user-fail';
export const UPDATE_AUTH_USER = 'update-auth-user';

export const loadAuthUserStart = () => ({
    type: LOAD_AUTH_USER_START
});

export const loadAuthUserSuccess = (authUser) => ({
    type: LOAD_AUTH_USER_SUCCESS,
    authUser
});

export const loadAuthUserFail = (errors) => ({
    type: LOAD_AUTH_USER_FAIL,
    errors
});

export const updateAuthUser = (authUser) => ({
    type: UPDATE_AUTH_USER,
    authUser
});
