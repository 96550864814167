import PropTypes from 'prop-types';
import React, {Component} from 'react';
import queryString from 'query-string';

import GlobalHeader from '../../common/components/global_header/connector';
import ProfileEditForm from '../../common/components/profile_edit_form/connector';
import {setOGTagsToDefault} from '../../common/utils/app';
import {
    formatHomeTown,
    formatOccupation
} from '../../common/utils/profile';
import {isDemo} from '../../common/utils/routing';

import './ProfilePage.css';


const updateOGTagsForUser = (profile, slugHash) => {
    let ogElements = document.getElementsByClassName('boeiie-og-element');
    let ogElementsLength = ogElements.length;

    for (let i=0; i < ogElementsLength; i++) {
        let element = ogElements[i];
        let attribute = element.getAttribute('property');

        if (attribute === 'og:url') {
            element.content = `https://boeiie.com/profile/${slugHash}/`;
        } else if (attribute === 'og:title') {
            element.content = `${profile['first_name']} ${profile['last_name']} | Boeiie.com`;
        } else if (attribute === 'og:description') {
            if (profile['profile_headline']) {
                element.content = profile['profile_headline'];
            }
        }
    }
};


export default class ProfilePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showProfileEditForm: false
        };
    }

    componentDidMount() {
        let {
            match,
            onLoad,
            location
        } = this.props;
        let {slugHash} = match.params;
        let queryParams = queryString.parse(location.search);

        if (!isDemo()) {
            onLoad(slugHash, queryParams)
                .then(({profile={}}) => {
                    document.title = `${profile['first_name']} ${profile['last_name']} | Boeiie`;

                    updateOGTagsForUser(profile, slugHash);
                });
        }
    }

    componentWillUnmount() {
        setOGTagsToDefault();
    }

    componentDidUpdate(prevProps) {
        let {
            slugHash,
            onLoad,
        } = this.props;
        let {slugHash: prevSlugHash} = prevProps;

        if (!isDemo() && slugHash !== prevSlugHash) {
            onLoad(slugHash);
        }
    }

    hideProfileEditForm = () => {
        this.setState({showProfileEditForm: false});
    };

    handleEditProfileButtonClick = () => {
        this.setState({showProfileEditForm: true});
    };

    handleSubmitFormSuccess = () => {
        let {onSubmitFormSuccess} = this.props;

        onSubmitFormSuccess();

        this.setState({showProfileEditForm: false});
    };

    render() {
        let {
            match,
            authUser,
            profileImageUrl,
            firstName,
            lastName,
            profileHeadline,
            profileHeaderSummary,
            occupation,
            city,
            state,
        } = this.props;
        let {showProfileEditForm} = this.state;
        let initialValues = {
            profileImageUrl,
            firstName,
            lastName,
            profileHeadline,
            profileHeaderSummary,
            occupation,
            city,
            state
        };
        let editProfileButton = null;

        if (showProfileEditForm) {
            return (
                <ProfileEditForm
                    slugHash={match.params.slugHash}
                    initialValues={initialValues}
                    onCancel={this.hideProfileEditForm}
                    onSubmitFormSuccess={this.handleSubmitFormSuccess}
                />
            );
        }

        if (!isDemo() && authUser['slug_hash'] === match.params.slugHash) {
            editProfileButton = (
                <button
                    className="edit-profile-button"
                    onClick={this.handleEditProfileButtonClick}
                >
                    Edit Profile
                </button>
            );
        }

        return (
            <div id="profile-page">
                <GlobalHeader />
                <div className="page-body-container">
                    <div className="banner-image-container">
                        {
                            profileImageUrl ?
                            <img
                                className="profile-banner-image"
                                src={profileImageUrl}
                                alt=""
                            /> : null
                        }
                    </div>
                    <div className="profile-data-section">
                        <div className="first-row">
                            <div className="user-name-headline-container">
                                <p className="user-name">{`${firstName} ${lastName}`}</p>
                                <p className="user-headline">{profileHeadline}</p>
                            </div>
                            <div className="edit-profile-button-container">
                                {editProfileButton}
                            </div>
                        </div>
                        <div className="profile-data-group">
                            <div className="icon-container">
                                <img
                                    className="icon note-icon"
                                    src="/media/ui_icons/note.png"
                                    alt=""
                                />
                            </div>
                            <div>
                                <p className="header-label">Bio</p>
                                <p className="user-bio">{profileHeaderSummary}</p>
                            </div>
                        </div>
                        <div className="profile-data-group user-occupation-section">
                            <div className="icon-container">
                                <img
                                    className="icon briefcase-icon"
                                    src="/media/ui_icons/briefcase.png"
                                    alt=""
                                />
                            </div>
                            <div>
                                <p className="header-label">Occupation</p>
                                <p>{formatOccupation(occupation.position, occupation.company)}</p>
                            </div>
                        </div>
                        <div className="profile-data-group user-hometown">
                            <div className="icon-container">
                                <img
                                    className="icon location-icon"
                                    src="/media/ui_icons/location.png"
                                    alt=""
                                />
                            </div>
                            <div>
                                <p className="header-label">Hometown</p>
                                <p>{formatHomeTown(city, state)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ProfilePage.propTypes = {
    authUser: PropTypes.object,
    userId: PropTypes.number,
    profileImageUrl: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profileHeadline: PropTypes.string,
    profileHeaderSummary: PropTypes.string,
    company: PropTypes.object,
    city: PropTypes.string,
    state: PropTypes.string,
    posts: PropTypes.array,
    nextLink: PropTypes.string,
    previousLink: PropTypes.string,
    loadPosts: PropTypes.func,
    onLoad: PropTypes.func,
    onSubmitFormSuccess: PropTypes.func
};
