import {connect} from 'react-redux';

import {loadUnreadNotificationsCount} from '../../action_creators/notifications';
import {updateAuthUser} from '../../../app/actions';

import GlobalHeader from './GlobalHeader';


const initializeGlobalHeader = (dispatch, getState) => {
    let {app} = getState();

    if (app.authUser) {
        dispatch(loadUnreadNotificationsCount);
    }
};

const unsetAuthUser = (dispatch) => {
    dispatch(updateAuthUser({}));
};

const mapStateToProps = (state) => {
    let {authUser = {}} = state.app;
    let {numberOfUnreadNotifications = 0} = state.activity;

    return {
        authUser,
        numberOfUnreadNotifications
    };
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: () => dispatch(initializeGlobalHeader),
    unsetAuthUser: () => dispatch(unsetAuthUser),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GlobalHeader);
