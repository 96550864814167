import queryString from 'query-string';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import _ from 'underscore';

import {loadPosts} from '../../common/action_creators/posts';
import {
    createReaction,
    removeReaction
} from '../../common/action_creators/reactions';
import AccountVerificationPrompt from '../../common/components/account_verification_prompt/AccountVerificationPrompt';
import PostCard from '../../common/components/post_card/connector';
import {formatOccupation} from '../../common/utils/profile';
import {formatNavigationQuery} from '../../common/utils/routing';


const PostList = ({
    authUser,
    posts,
    handleAddReaction,
    handleRemoveReaction,
    handleShowComposePostForm,
    onEditPostSuccess,
    onDeletePostSuccess,
    onHidePostSuccess,
    authUserIsGroupAdmin

}) => {
    let postList = posts.map((post, index) => {
        let {user={}} = post;
        let occupations = user['occupations'] || [];
        let occupation = occupations[0] || {};
        let authUserIsPostAuthor = authUser['user_id'] === user.id;

        return (
            <div key={index} className="post-card-container">
                <PostCard
                    authUser={authUser}
                    post={post}
                    postId={post['id'].toString()}
                    title={post['title']}
                    description={post['body']}
                    group={post['group']}
                    author={user}
                    authorId={user.id}
                    authorName={`${user['first_name']} ${user['last_name']}`}
                    authorProfileImageUrl={user['profile_image_url']}
                    authorOccupation={formatOccupation(occupation['position'], occupation['company'])}
                    postCreationDate={post['creation_date']}
                    tags={post['tags']}
                    photoUrl={post['photo']}
                    sharedLink={post['shared_link']}
                    numberOfComments={post['number_of_comments']}
                    metaData={post['meta_data']}
                    handleAddReaction={handleAddReaction}
                    handleRemoveReaction={handleRemoveReaction}
                    hideEditPostButton={authUser['user_id'] !== user.id}
                    hideHidePostButton={authUser['user_id'] === user.id}
                    hideDeletePostButton={!(authUserIsGroupAdmin || authUserIsPostAuthor)}
                    onEditPostSuccess={onEditPostSuccess}
                    onDeletePostSuccess={onDeletePostSuccess}
                    onHidePostSuccess={onHidePostSuccess}
                    handleShowComposePostForm={handleShowComposePostForm}
                />
            </div>
        )
    });

    return postList;
};

class Posts extends Component {
    static propTypes = {
        posts: PropTypes.array.isRequired,
        group: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            accountVerifyPromptIsOpen: false
        };
    }

    componentDidMount() {
        let {
            history,
            onLoad,
            loadPosts,
        } = this.props;

        onLoad();

        this.unlistenToHistory = history.listen((location) => {
            let queryParams = queryString.parse(location.search);
            let {
                page,
                tags
            } = queryParams;

            loadPosts({page, tags});

            window.scrollTo(0, 0);
        });
    }

    componentWillUnmount() {
        this.unlistenToHistory();
    }

    handleCloseAccountVerifyPrompt = () => {
        this.setState({accountVerifyPromptIsOpen: false});
    };

    handleOpenAccountVerifyPrompt = (e) => {
        let {
            isAuthenticated,
            isVerified,
        } = this.props;

        e.preventDefault();

        if (isAuthenticated && !isVerified) {
            this.setState({accountVerifyPromptIsOpen: true});
        }
    };

    render() {
        let {
            authUser,
            slugHash,
            group,
            posts,
            nextLink,
            previousLink,
            isAuthenticated,
            isVerified,
            handleAddReaction,
            handleRemoveReaction,
            onDeletePostSuccess,
            onEditPostSuccess,
            onHidePostSuccess,
        } = this.props;
        let {accountVerifyPromptIsOpen} = this.state;
        let nextPaginationButton = null;
        let previousPaginationButton = null;
        let paginationSection = null;
        let nextURL;
        let nextPage;
        let previousURL;
        let previousPage;

        if (nextLink) {
            let paramsObject = {};

            nextURL = new URL(nextLink);
            nextPage = queryString.parse(nextURL.search)['page'];

            if (nextPage) {
                paramsObject['page'] = nextPage;
            }

            let nextSearchParams = formatNavigationQuery(paramsObject);

            nextPaginationButton = (
                <Link
                    className="pagination-button"
                    to={{
                       pathname: `/groups/${slugHash}/${group['slug']}/`,
                       search: nextSearchParams
                    }}
                >
                    Next
                </Link>
            );
        }

        if (previousLink) {
            let paramsObject = {};

            previousURL = new URL(previousLink);
            previousPage = queryString.parse(previousURL.search)['page'];

            if (previousPage) {
                paramsObject['page'] = previousPage;
            } else {
                paramsObject['page'] = 1;
            }

            let previousSearchParams = formatNavigationQuery(paramsObject);

            previousPaginationButton = (
                <Link
                    className="pagination-button"
                    to={{
                        pathname: `/groups/${slugHash}/${group['slug']}/`,
                        search: previousSearchParams
                    }}
                >
                    Previous
                </Link>
            );
        }

        if (nextLink || previousLink) {
            paginationSection = (
                <div className="pagination">
                    <div>
                        {previousPaginationButton}
                    </div>
                    <div>
                        {nextPaginationButton}
                    </div>
                </div>
            );
        }

        let createPostButton = (
            <Link
                className="create-post-button"
                to={{
                    pathname: '/compose_post/',
                    search: formatNavigationQuery({ref: `/groups/${slugHash}/${group['slug']}/`}),
                    state: {slugHash}
                }}
            >
                <div className="create-post-button-container">
                    <div className="icon-container">
                        <FontAwesomeIcon icon="plus" color="#ff9800" size="lg" />
                    </div>
                    <span>Create Post</span>
                </div>
            </Link>
        );

        if (isAuthenticated && !isVerified) {
            createPostButton = (
                <Link
                    className="create-post-button"
                    onClick={this.handleOpenAccountVerifyPrompt}
                >
                    <div className="create-post-button-container">
                        <div className="icon-container">
                            <FontAwesomeIcon icon="plus" color="#ff9800" size="lg" />
                        </div>
                        <div>
                            <span>Create Post</span>
                        </div>
                    </div>
                </Link>
            );
        }

        return (
            <div className="post-list-section">
                {
                    group.is_member ?
                    createPostButton : null
                }
                <div className="post-card-list-container">
                    <PostList
                        authUser={authUser}
                        posts={posts}
                        handleShowComposePostForm={this.handleShowComposePostForm}
                        handleAddReaction={handleAddReaction}
                        handleRemoveReaction={handleRemoveReaction}
                        onDeletePostSuccess={() => onDeletePostSuccess()}
                        onEditPostSuccess={() => onEditPostSuccess()}
                        onHidePostSuccess={() => onHidePostSuccess()}
                        authUserIsGroupAdmin={group.is_admin}
                    />
                </div>
                {paginationSection}
                <AccountVerificationPrompt
                    isOpen={accountVerifyPromptIsOpen}
                    title="Verify your account."
                    subtitle="In order to complete this action, verify your account by clicking the button below, and visit the email."
                    handleClose={this.handleCloseAccountVerifyPrompt}
                />
            </div>
        );
    }
}

const addReaction = (emoji, postId) => (dispatch, getState) => {
    let state = getState();
    let authUserId = state.app.authUser['user_id'];

    return dispatch(createReaction('post', postId, emoji, authUserId));
};

const deleteReaction = (reactionId, postId) => (dispatch, getState) => {
    let state = getState();
    let authUserId = state.app.authUser['user_id'];
    let targetEntityType = 'post';

    return dispatch(removeReaction(reactionId, postId, authUserId, targetEntityType));
};

const mapStateToProps = (state) => {
    let {posts=[], nextLink, previousLink} = state.postListing;
    let {authUser={}} = state.app;
    let {group={}} = state.groupState;

    return {
        group,
        posts,
        nextLink,
        previousLink,
        authUser,
        isAuthenticated: !_.isEmpty(authUser),
        isVerified: !_.isEmpty(authUser) && !!authUser.is_verified,
    };
};

const mapDispatchToProps = (dispatch, {slugHash, history}) => {
    let queryParams = queryString.parse(history.location.search);
    let page = queryParams['page'];
    let tags = queryParams['tags'];

    return {
        onLoad: () => dispatch(loadPosts({group: slugHash, page, tags})),
        loadPosts: (queryParams) => dispatch(loadPosts({group: slugHash, ...queryParams})),
        handleAddReaction: (emoji, postId) => dispatch(addReaction(emoji, postId)),
        handleRemoveReaction: (reactionId, postId) => dispatch(deleteReaction(reactionId, postId)),
        onDeletePostSuccess: () => {},
        onEditPostSuccess: () => {},
        onHidePostSuccess: () => {},
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Posts);
