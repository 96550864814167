import {connect} from 'react-redux';

import {updateAuthUser} from '../../app/actions';
import {logoutUserSuccess} from '../../common/actions/auth';
import {
    fetchApiPost,
    STORAGE_KEY
} from '../../common/utils/api';
import {USER} from '../../common/constants/demo';
import {isDemo} from '../../common/utils/routing';

import Account from './Account';


const deleteAccount = (history) => (dispatch, getState) => {
    return fetchApiPost(`me/delete/`)
        .then(() => {
            localStorage.removeItem(STORAGE_KEY);
            dispatch(logoutUserSuccess());
            history.push('/signup/');
        });
};

const saveAccountSettings = (formValues) => (dispatch) => {
    return fetchApiPost(`me/update/`, formValues)
        .then(({user}) => {
            let authUser = localStorage.getItem(STORAGE_KEY);

            authUser = JSON.parse(authUser);
            authUser = {
                ...authUser,
                'first_name': user['first_name'],
                'last_name': user['last_name'],
                'email': user['email'],
            }

            localStorage.setItem(STORAGE_KEY, JSON.stringify(authUser));

            dispatch(updateAuthUser(authUser));

            return user;
        })
        .catch(({errors}) => {

        });
};

const changePassword = (formValues) => (dispatch) => {
    return fetchApiPost('change-password/', formValues);
};

const mapStateToProps = ({app={}}) => {
    let {authUser={}} = app;


    if (isDemo()) {
        authUser = USER;
    }

    return {
        authUser,
        userId: authUser['user_id'],
        firstname: authUser['first_name'],
        lastname: authUser['last_name'],
        email: authUser['email']
    };
};

const mapDispatchToProps = (dispatch, {history}) => ({
    onAccountSettingsFormSubmit: (formValues) => dispatch(saveAccountSettings(formValues)),
    onChangePasswordFormSubmit: (formValues) => dispatch(changePassword(formValues)),
    onDeleteAccount: () => dispatch(deleteAccount(history))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Account);
