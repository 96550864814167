import queryString from 'query-string';
import {connect} from 'react-redux';
import _ from 'underscore';

import * as commentActions from '../../actions/comments';
import {
    createComment,
    loadCommentsForPost,
    loadReplies,
} from '../../action_creators/comments';
import {fetchApiPost} from '../../utils/api';
import {
    getCommentDataFromState,
    getCommentsPaginationData,
    getCommentsList,
    getVerboseReplyMap,
    commentThreadHasPreviousComments
} from '../../utils/comments';

import PostCardCommentThread from './PostCardCommentThread';


const loadMoreComments = (postId, postSlugHash, limit, offset) => (dispatch, getState) => {
    let state = getState();
    let options = {
        limit,
        offset,
        order: 'asc',
    };
    let commentData = getCommentDataFromState(state.comments, postId);
    let parsedUrl = {};

    if (commentData.previousPaginationLink) {
        parsedUrl = queryString.parseUrl(commentData.previousPaginationLink);
        if (parsedUrl.query.limit) {
            options.limit = parsedUrl.query.limit;
        }

        if (parsedUrl.query.offset) {
            options.offset = parsedUrl.query.offset;
        } else {
            delete options['offset'];
        }
    }

    return dispatch(loadCommentsForPost(postId, postSlugHash, options));
};

const deleteComment = (postId, commentId) => (dispatch) => {
    commentActions.deleteCommentStart();

    return fetchApiPost(`comments/${commentId}/delete/`)
        .then(({comment}) => {
            dispatch(commentActions.deleteCommentSuccess(comment));
        })
        .catch(({errors}) => {
            dispatch(commentActions.deleteCommentFail());
        });
};

const hideCommentSubmit = (postId, commentId, formValues) => (dispatch) => {
    dispatch(commentActions.hideCommentStart());

    return fetchApiPost(`comments/${commentId}/hide_comment/`, formValues)
        .then(({comment}) => {
            dispatch(commentActions.hideCommentSuccess(comment));
        })
        .catch(({errors}) => {
            dispatch(commentActions.hideCommentFail());
        });
};

const submitEditComment = (postId, commentId, value) => (dispatch) => {
    dispatch(commentActions.editCommentStart());

    let data = {'body': value};

    return fetchApiPost(`comments/${commentId}/`, data)
        .then(({comment}) => {
            dispatch(commentActions.editCommentSuccess(comment));
        })
        .catch(({errors}) => {
            dispatch(commentActions.editCommentFail());
        });
};

const mapStateToProps = (state, ownProps) => {
    let {postId} = ownProps;
    let {comments, app} = state;
    let hasPreviousComments = commentThreadHasPreviousComments(comments, postId);
    let postCommentThreadKey = `posts/${postId}/`;
    let commentsList = getCommentsList(comments, postCommentThreadKey);
    let verboseReplyMap = getVerboseReplyMap(comments, postCommentThreadKey);
    let commentsPaginationData = getCommentsPaginationData(comments, postCommentThreadKey);

    return {
        hasPreviousComments,
        isAuthenticated: !_.isEmpty(app.authUser),
        isVerified: !_.isEmpty(app.authUser) && app.authUser.is_verified,
        comments: commentsList,
        replyMap: verboseReplyMap,
        commentsLimit: commentsPaginationData.limit,
        commentsOffset: commentsPaginationData.offset
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadCommentsForPost: (postId, postSlugHash, limit, offset) => dispatch(loadMoreComments(postId, postSlugHash, limit, offset)),
    handleCreateCommentSubmit: (postSlugHash, comment) => dispatch(createComment(postSlugHash, comment)),
    onLoadReplies: (postId, postSlugHash, parentCommentId) => dispatch(loadReplies(postId, postSlugHash, parentCommentId)),
    onEditCommentSubmit: (postId, commentId, value) => dispatch(submitEditComment(postId, commentId, value)),
    onDeleteComment: (postId, commentId) => dispatch(deleteComment(postId, commentId)),
    onHideCommentSubmit: (postId, commentId, reason='') => dispatch(hideCommentSubmit(postId, commentId, reason)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PostCardCommentThread);
