import {
    INITIALIZE_PAGE_START,
    INITIALIZE_PAGE_SUCCESS,
    INITIALIZE_PAGE_FAIL
} from './actions';


const loadStartReducer = (state) => ({
    ...state,
    isLoading: true
});

const loadSuccessReducer = (state, {profile}) => ({
    ...state,
    isLoading: false,
    profile
});

const loadFailReducer = (state, {errors}) => ({
    ...state,
    isLoading: false,
    errors
});

const PROFILE_REDUCER_MAP = {
    [INITIALIZE_PAGE_START]: loadStartReducer,
    [INITIALIZE_PAGE_SUCCESS]: loadSuccessReducer,
    [INITIALIZE_PAGE_FAIL]: loadFailReducer,
};

const userProfileReducer = (initialState={}, action={}) => {
    let reducer = PROFILE_REDUCER_MAP[action.type];
    let nextState = initialState;

    if (reducer) {
        nextState = reducer(initialState, action);
    }

    return nextState;
};

export default userProfileReducer;
