import queryString from 'query-string';
import _ from 'underscore';

import {
    INITIALIZE_COMMENT_THREAD,
    CREATE_COMMENT_SUCCESS,
    EDIT_COMMENT_SUCCESS,
    DELETE_COMMENT_SUCCESS,
    HIDE_COMMENT_SUCCESS,
    LOAD_COMMENTS_FOR_POST_SUCCESS,
    LOAD_REPLIES_START,
    LOAD_REPLIES_SUCCESS,
    LOAD_REPLIES_FAIL,
    LOAD_COMMENTS_FROM_POSTS_START,
    LOAD_COMMENTS_FROM_POSTS_SUCCESS,
} from '../actions/comments';
import {
    CREATE_REACTION_SUCCESS,
    REMOVE_REACTION_SUCCESS
} from '../actions/reactions';


const initializeCommentThreadReducer = (state) => {
    return {
        byId: {},
        allIds: [],
        isLoadingReplies: false,
        replyMap: {},
        postCommentsMap: {},
    };
};

const loadCommentsFromPostsStartReducer = (state) => ({
    ...state,
});

const loadCommentsFromPostsSuccessReducer = (state, {comments}) => {
    let nextState = {
        isLoadingReplies: false,
        replyMap: {},
        postCommentsMap: {},
        ...state
    };

    comments.forEach((paginatedCommentBlock) => {
        let postCommentsKey = `posts/${paginatedCommentBlock['post_id']}/`;

        nextState.postCommentsMap[postCommentsKey] = {
            'paginationLimit': paginatedCommentBlock['pagination_limit'],
            'previousPageOffset': paginatedCommentBlock['previous_page_offset'],
        };

        if (!nextState.postCommentsMap[postCommentsKey].commentIds) {
            nextState.postCommentsMap[postCommentsKey].commentIds = [];
        }

        paginatedCommentBlock.results.forEach((comment) => {
            nextState['byId'][comment.id] = comment;

            if (!comment['reply_to']) {
                nextState.postCommentsMap[postCommentsKey].commentIds.push(comment.id);
            }
        });

        nextState.postCommentsMap[postCommentsKey].commentIds = (
            _.uniq(nextState.postCommentsMap[postCommentsKey].commentIds)
        );
    });

    return {
        ...nextState
    };
};

const loadCommentsForPostSuccessReducer = (
    state,
    {
        postId,
        comments,
        nextPaginationLink,
        previousPaginationLink,
        order
    }
) => {
    let nextState = {
        isLoadingReplies: false,
        replyMap: {},
        postCommentsMap: {},
        ...state
    };
    let postCommentsKey = `posts/${postId}/`;
    let previousPaginationData = '';

    if (!nextState.postCommentsMap[postCommentsKey]) {
        nextState.postCommentsMap[postCommentsKey] = {
            commentIds: [],
        };
    }

    if (previousPaginationLink) {
        previousPaginationData = queryString.parseUrl(previousPaginationLink);

        let newOffset = previousPaginationData.query.offset;

        if (newOffset === undefined) {
            newOffset = null;
        }

        nextState.postCommentsMap[postCommentsKey] = {
            ...nextState.postCommentsMap[postCommentsKey],
            'previousPageOffset': newOffset
        };
    }

    let commentIds = _.pluck(comments, 'id');

    if (order === 'asc') {
        nextState.postCommentsMap[postCommentsKey].commentIds = [
            ...commentIds,
            ...nextState.postCommentsMap[postCommentsKey].commentIds,
        ];
    } else {
        nextState.postCommentsMap[postCommentsKey].commentIds = [
            ...nextState.postCommentsMap[postCommentsKey].commentIds,
            ...commentIds,
        ];
    }

    nextState.postCommentsMap[postCommentsKey].commentIds = (
        _.uniq(nextState.postCommentsMap[postCommentsKey].commentIds)
    );

    comments.forEach((comment) => {
        nextState['byId'][comment.id] = comment;
    });

    return {
        ...nextState
    };
};

const loadRepliesStartReducer = (state, {postId, parentCommentId}) => ({
    replyMap: {},
    postCommentsMap: {},
    ...state,
    isLoadingReplies: true,
});

const loadRepliesSuccessReducer = (state, {postId, parentCommentId, replies}) => {
    let nextState = {
        replyMap: {},
        postCommentsMap: {},
        ...state,
        isLoadingReplies: false,
    };
    let replyMapKey = `posts/${postId}/comments/${parentCommentId}/`;
    let replyIds = [];

    if (!nextState['replyMap'][replyMapKey]) {
        nextState['replyMap'][replyMapKey] = [];
    }

    replies.forEach((comment) => {
        nextState['byId'][comment.id] = comment;
        replyIds.push(comment.id);
        replyIds = _.uniq(replyIds);
    });

    nextState['replyMap'][replyMapKey] = replyIds;

    return nextState;
};

const loadRepliesFailReducer = (state, {errors}) => ({
    isLoadingReplies: false,
    replyMap: {},
    postCommentsMap: {},
    ...state,
    errors
});

const createCommentSuccessReducer = (state, {comment}) => {
    let newState = {
        isLoadingReplies: false,
        replyMap: {},
        postCommentsMap: {},
        ...state
    };
    let postMapKey = `posts/${comment.post}/`;

    newState.byId = {
        ...newState.byId,
        [comment.id]: comment
    };

    if (comment['reply_to']) {
        let replyMapKey = `posts/${comment.post}/comments/${comment['reply_to']}/`;

        newState.replyMap = {
            ...newState.replyMap,
        };

        if (!newState.replyMap[replyMapKey]) {
            newState.replyMap[replyMapKey] = [];
        }

        newState.replyMap[replyMapKey] = [
            ...newState.replyMap[replyMapKey],
            comment.id
        ];
    } else {
        newState.postCommentsMap = {
            ...newState.postCommentsMap,
            [postMapKey]: {
                ...newState.postCommentsMap[postMapKey],
                commentIds: [
                    ...newState.postCommentsMap[postMapKey].commentIds,
                    comment.id
                ]
            }
        };
    }

    return newState
};

const editCommentSuccessReducer = (state, {comment}) => {
    let newState = {
        isLoadingReplies: false,
        replyMap: {},
        postCommentsMap: {},
        ...state
    };

    newState.byId[comment.id]['body'] = comment.body;

    return newState;
};

const deleteCommentSuccessReducer = (state, {comment}) => {
    let newState = {
        isLoadingReplies: false,
        replyMap: {},
        postCommentsMap: {},
        ...state
    };
    let postId = comment.post.toString();
    let isReply = !!comment['reply_to'];
    let replyMapKey = '';

    if (isReply) {
        replyMapKey = `posts/${postId}/comments/${comment['reply_to']}/`;
        delete newState.byId[comment.id];
        newState.replyMap[replyMapKey] = _.filter(
            newState.replyMap[replyMapKey],
            (commentId) => commentId !== comment.id
        );
    } else {
        replyMapKey = `posts/${postId}/comments/${comment.id}/`;
        newState.replyMap[replyMapKey].forEach((commentId) => {
            delete newState.byId[commentId];
        });
        delete newState.byId[comment.id];
        delete newState.replyMap[replyMapKey];
    }

    return newState;
};

const hideCommentSuccessReducer = (state, {comment}) => {
    let newState = {
        isLoadingReplies: false,
        replyMap: {},
        postCommentsMap: {},
        ...state
    };
    let postId = comment.post.toString();
    let isReply = !!comment['reply_to'];
    let replyMapKey = '';

    if (isReply) {
        replyMapKey = `posts/${postId}/comments/${comment['reply_to']}/`;
        delete newState.byId[comment.id];
        newState.replyMap[replyMapKey] = _.filter(
            newState.replyMap[replyMapKey],
            (commentId) => commentId !== comment.id
        );
    } else {
        replyMapKey = `posts/${postId}/comments/${comment.id}/`;
        newState.replyMap[replyMapKey].forEach((commentId) => {
            delete newState.byId[commentId];
        });
        delete newState.byId[comment.id];
        delete newState.replyMap[replyMapKey];
    }

    return newState;
};

const createReactionSuccessReducer = (state, {reaction, authUserId}) => {
    if (reaction['target_entity_type'] === 'comment') {
        let oldComment = state.byId[reaction['target_entity_id']];
        let oldCommentReactionSummaryIndex = _.findIndex(oldComment['meta_data']['reaction_summary'], (reaction) => {
            return reaction['user_id'] === authUserId;
        });
        let commentHasExistingReaction = oldCommentReactionSummaryIndex > -1;
        if (commentHasExistingReaction) {
            let oldCommentReactionSummary = oldComment['meta_data']['reaction_summary'][oldCommentReactionSummaryIndex];

            oldCommentReactionSummary['type'] = reaction['reaction_emoji_type'];
            oldComment['meta_data']['reaction_summary'][oldCommentReactionSummaryIndex] = oldCommentReactionSummary;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [reaction['target_entity_id']]: oldComment,
                }
            };
        } else {
            oldComment['meta_data']['reaction_summary'].push({
                type: reaction['reaction_emoji_type'],
                total: 1,
                'user_id': authUserId,
            });
            oldComment['meta_data']['reaction_id'] = reaction.id

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [reaction['target_entity_id']]: oldComment,
                }
            };
        }
    }

    return state;
};

const removeReactionSuccessReducer = (state, {reactionId, targetEntityId, authUserId, targetEntityType}) => {
    if (targetEntityType === 'comment') {
        let comment = state.byId[targetEntityId];

        comment['meta_data']['reaction_summary'] = _.filter(comment['meta_data']['reaction_summary'], (summary) => {
            return summary['user_id'] !== authUserId;
        });
        comment['meta_data']['reaction_id'] = null;

        state.byId[targetEntityId] = {...comment};

        return {
            ...state,
        };
    }

    return state;
};

const COMMENTS_REDUCER_MAP = {
    [INITIALIZE_COMMENT_THREAD]: initializeCommentThreadReducer,
    [LOAD_COMMENTS_FROM_POSTS_START]: loadCommentsFromPostsStartReducer,
    [LOAD_COMMENTS_FROM_POSTS_SUCCESS]: loadCommentsFromPostsSuccessReducer,
    [LOAD_REPLIES_START]: loadRepliesStartReducer,
    [LOAD_REPLIES_SUCCESS]: loadRepliesSuccessReducer,
    [LOAD_REPLIES_FAIL]: loadRepliesFailReducer,
    [LOAD_COMMENTS_FOR_POST_SUCCESS]: loadCommentsForPostSuccessReducer,
    [CREATE_COMMENT_SUCCESS]: createCommentSuccessReducer,
    [EDIT_COMMENT_SUCCESS]: editCommentSuccessReducer,
    [DELETE_COMMENT_SUCCESS]: deleteCommentSuccessReducer,
    [HIDE_COMMENT_SUCCESS]: hideCommentSuccessReducer,
    [CREATE_REACTION_SUCCESS]: createReactionSuccessReducer,
    [REMOVE_REACTION_SUCCESS]: removeReactionSuccessReducer,
};


const commentsReducer = (initialState={}, action={}) => {
    let reducer = COMMENTS_REDUCER_MAP[action.type];
    let nextState = initialState;

    if (reducer) {
        nextState = reducer(initialState, action);
    }

    return nextState;
};

export default commentsReducer;
