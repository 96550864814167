import {connect} from 'react-redux';

import {fetchApiPost} from '../../utils/api';

import {
    profileSaveFormStart,
    profileSaveFormSuccess,
    profileSaveFormFail,
    formStateChange
} from './actions';
import ProfileEditForm from './ProfileEditForm';


const saveForm = (slugHash, formValues, hasImage) => (dispatch) => {
    dispatch(profileSaveFormStart());

    return fetchApiPost(`users/${slugHash}/profile/`, formValues, {}, hasImage)
        .then(({profile}) => {
            dispatch(profileSaveFormSuccess(profile));
        })
        .catch(({errors}) => {
            dispatch(profileSaveFormFail(errors));
        });
};

const updateForm = (formState) => (dispatch) => dispatch(formStateChange(formState));

const mapStateToProps = ({app, userProfileForm}) => {
    let {authUser={}} = app;
    let {formState={}} = userProfileForm;
    let {pristine} = formState;

    return {
        userId: authUser['user_id'],
        pristine
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmitForm: (slugHash, formValues, hasImage) => dispatch(saveForm(slugHash, formValues, hasImage)),
        onFormUpdate: (state) => dispatch(updateForm(state))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileEditForm);
