import _ from 'underscore';

import {API_SERVER_HOSTNAME} from './api';

export const generateCommentsPreviousPaginationUrl = (postId, limit, offset) => {
    let url = `${API_SERVER_HOSTNAME}/posts/${postId}/comments/?limit=${limit}&offset=${offset}`;

    return url;
};

export const getCommentDataFromState = (commentsState, postId) => {
    let commentThreadKey = `posts/${postId}/`;
    let commentThreadData = (commentsState.postCommentsMap && commentsState.postCommentsMap[commentThreadKey]) || {};

    return commentThreadData;
};

export const commentThreadHasPreviousComments = (commentsState, postId) => {
    let commentThreadKey = `posts/${postId}/`;
    let commentThreadData = commentsState.postCommentsMap && commentsState.postCommentsMap[commentThreadKey];

    if (_.isEmpty(commentThreadData)) {
        return false;
    }

    return !!(commentThreadData['previousPageOffset']);
};

export const getCommentsList = (commentsState, postCommentThreadKey) => {
    let result = [];

    let postThreadData = (commentsState.postCommentsMap && commentsState.postCommentsMap[postCommentThreadKey]) || (
        {'commentIds': []}
    );

    postThreadData.commentIds.forEach((commentId) => {
        if (commentsState.byId[commentId]) {
            result.push(commentsState.byId[commentId]);
        }
    });

    return result;
};

export const getVerboseReplyMap = (commentsState, postCommentThreadKey) => {
    let result = {};
    let replyMapKey = '';

    if (!commentsState.postCommentsMap) {
        return result;
    }

    if (!commentsState.postCommentsMap[postCommentThreadKey]) {
        return result;
    }

    commentsState.postCommentsMap[postCommentThreadKey].commentIds.forEach((parentCommentId) => {
        replyMapKey = `${postCommentThreadKey}comments/${parentCommentId}/`;


        if (commentsState.replyMap[replyMapKey]) {
            commentsState.replyMap[replyMapKey].forEach((replyId) => {
                if (!result[replyMapKey]) {
                    result[replyMapKey] = [];
                }

                result[replyMapKey].push(commentsState.byId[replyId]);
            });
        }
    });

    return result;
};

export const getCommentsPaginationData = (commentsState, postCommentThreadKey) => {
    let result = {};

    if (!commentsState.postCommentsMap) {
        return result;
    }

    if (!commentsState.postCommentsMap[postCommentThreadKey]) {
        return result;
    }

    result.limit = commentsState.postCommentsMap[postCommentThreadKey].paginationLimit;
    result.offset = commentsState.postCommentsMap[postCommentThreadKey].previousPageOffset;

    return result;
};
