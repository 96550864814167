import {
    loadUnreadNotificationsCountStart,
    loadUnreadNotificationsCountSuccess,
    loadUnreadNotificationsCountFail,
} from '../actions/notifications';

import {fetchApiGet} from '../utils/api';


export const loadUnreadNotificationsCount = (dispatch) => {
    dispatch(loadUnreadNotificationsCountStart());

    return fetchApiGet('get_notification_view_count/')
        .then(({number_of_unviewed_notifications}) => (
            dispatch(loadUnreadNotificationsCountSuccess(number_of_unviewed_notifications))
        ))
        .catch((errors) => (
            dispatch(loadUnreadNotificationsCountFail(errors))
        ));
};
