import queryString from 'query-string';

import {
    loadGroupsStart,
    loadGroupsSuccess,
    loadGroupsFail,
    loadGroupStart,
    loadGroupSuccess,
    loadGroupFail,
    loadMyGroupsStart,
    loadMyGroupsSuccess,
    loadMyGroupsFail,
    loadGroupAdminsStart,
    loadGroupAdminsSuccess,
    loadGroupAdminsFail,
    loadGroupMembersStart,
    loadGroupMembersSuccess,
    loadGroupMembersFail,
    joinGroupStart,
    joinGroupSuccess,
    joinGroupFail,
    unjoinGroupStart,
    unjoinGroupSuccess,
    unjoinGroupFail,
    clearGroupErrorState,
} from '../actions/groups';
import {
    fetchApiGet,
    fetchApiPost
} from '../utils/api';


export const loadGroups = (queryParams={}, options) => (dispatch) => {
    let url = 'groups/';
    let hasPageParam = Object.keys(queryParams).length;

    if (hasPageParam) {
        url = `groups/?${queryString.stringify(queryParams)}`;
    }

    dispatch(loadGroupsStart(queryParams));

    return fetchApiGet(url)
        .then(({results, next, previous}) => {
            return dispatch(loadGroupsSuccess(results, next, previous, options));
        })
        .catch((error) => {
            return dispatch(loadGroupsFail(error));
        });
};

export const loadMyGroups = (queryParams={}, options={}) => (dispatch) => {
    let url = 'groups/?me=true';
    let hasPageParam = Object.keys(queryParams).length;

    if (hasPageParam) {
        url = `groups/?me=true&${queryString.stringify(queryParams)}`;
    }

    dispatch(loadMyGroupsStart(queryParams));

    return fetchApiGet(url)
        .then(({results, next, previous}) => {
            return dispatch(loadMyGroupsSuccess(results, next, previous, options));
        })
        .catch((errors) => {
            return dispatch(loadMyGroupsFail(errors));
        });

};

export const loadGroup = (slugHash) => (dispatch) => {
    dispatch(loadGroupStart());

    return fetchApiGet(`groups/${slugHash}/`)
        .then(({group}) =>{
            return dispatch(loadGroupSuccess(group));
        })
        .catch((errors) => {
            dispatch(loadGroupFail(errors));

            return Promise.reject(errors);
        });
};

export const loadGroupMembers = (slugHash, queryParams={}) => (dispatch) => {
    let url = `groups/${slugHash}/members/?member_roles=basic`;
    let hasPageParam = Object.keys(queryParams).length;

    if (hasPageParam) {
        url = `groups/${slugHash}/members/?member_roles=basic&${queryString.stringify(queryParams)}`;
    }

    dispatch(loadGroupMembersStart());

    return fetchApiGet(url)
        .then(({results, next}) => {
            let queryParams = queryString.parse(next);
            let paginationPage = queryParams['page'];

            return dispatch(loadGroupMembersSuccess(results, paginationPage));
        })
        .catch((errors) => {
            dispatch(loadGroupMembersFail(errors));

            return Promise.reject(errors);
        });
};

export const loadGroupAdmins = (slugHash, queryParams={}) => (dispatch) => {
    let url = `groups/${slugHash}/members/?member_roles=admin,primary_admin`;
    let hasPageParam = Object.keys(queryParams).length;

    if (hasPageParam) {
        url = `groups/${slugHash}/members/?member_roles=admin,primary_admin&${queryString.stringify(queryParams)}`;
    }

    dispatch(loadGroupAdminsStart());

    return fetchApiGet(url)
        .then(({results=[], next}) =>{
            let queryParams = queryString.parse(next);
            let paginationPage = queryParams['page'];

            return dispatch(loadGroupAdminsSuccess(results, paginationPage));
        })
        .catch((errors) => {
            dispatch(loadGroupAdminsFail(errors));

            return Promise.reject(errors);
        });
};

export const joinGroup = (slugHash) => (dispatch) => {
    dispatch(joinGroupStart());

    return fetchApiPost(`groups/${slugHash}/join/`)
        .then(({member}) => {
            return dispatch(joinGroupSuccess(member));
        })
        .catch((errors) => {
            dispatch(joinGroupFail(errors));

            return Promise.reject(errors);
        });
};

export const unjoinGroup = (slugHash) => (dispatch) => {
    dispatch(unjoinGroupStart());

    return fetchApiPost(`groups/${slugHash}/unjoin/`)
        .then(({member}) => {
            return dispatch(unjoinGroupSuccess(member))
        })
        .catch((errors) => {
            dispatch(unjoinGroupFail(errors));

            return Promise.reject(errors);
        });
};

export const clearGroupError = (dispatch) => {
    dispatch(clearGroupErrorState());
};
