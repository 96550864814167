import {FORM_ERROR} from 'final-form';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, {Component, useState} from 'react';
import {Form, Field} from 'react-final-form';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';
import {DropzoneArea} from 'material-ui-dropzone';
import _ from 'underscore';

import {ACCEPTED_IMAGE_TYPES} from '../../constants/media';
import {fetchApiGet} from '../../utils/api';
import {
    isRequired,
    maxLength
} from '../../utils/validation';

import AccountVerificationPrompt from '../account_verification_prompt/AccountVerificationPrompt';
import ComposeHeader from '../compose_header/ComposeHeader';
import FullScreenDialog from '../full_screen_dialog/FullScreenDialog';
import GroupSelector from '../group_selector/GroupSelector';
import PreviewLink from '../preview_link/PreviewLink';
import ShareLinkForm from '../share_link_form/ShareLinkForm';
import TagFilters from '../tag_filters/TagFilters';

import './ComposePostForm.css';


const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

const ComposeHeaderWithRouter = withRouter(ComposeHeader);

const returnToRefLink = (ref, history) => {
    let queryParams = queryString.parse(history.location.search);

    delete queryParams['ref'];
    queryParams = queryString.stringify(queryParams);

    if (ref) {
        history.push({
            pathname: ref,
            search: queryParams
        });
    } else {
        history.push({
            pathname: '/',
            search: queryParams
        });
    }
};

const ErrorBanner = ({
    errors,
    touchedFields,
    submitError
}) => {
    let formFields = Object.keys(errors);
    let errorBanner = null;

    errorBanner = formFields.map((field, index) => {
        if (touchedFields[field]) {
            return (
                <p key={index}>{`${field} - ${errors[field]}`}</p>
            );
        }

        return null;
    });

    if (_.find(errorBanner, (error) => (error !== null))) {
        return (
            <div className="error-banner">
                {errorBanner}
            </div>
        );
    }

    if (submitError) {
        return (
            <div className="error-banner">
                <p>{submitError}</p>
            </div>
        );
    }

    return null
};

const disableEnterKey = (e) => {
    if (e.key.toLowerCase() === 'enter') {
        e.preventDefault();
        return false;
    }
};

const GroupSelectorDialog = ({
    group,
    groupSelectorIsDisabled,
    handleChooseGroup,
}) => {
    const [open, setOpen] = React.useState(false);
    const [chosenGroup, setGroup] = React.useState(null);
    let buttonText = group.name || 'Choose a community';
    const handleClose = () => {
        setOpen(false);
    };
    const handleGroupClick = (group) => {
        setGroup(group);
        handleClose();
        handleChooseGroup(group);
    };
    const GroupSelectorWrapper = () => {
        return (
            <GroupSelector
                handleGroupClick={handleGroupClick}
            />
        );
    }
    const CloseDialogButton = () => (
        <Button color="inherit" onClick={() => setOpen(false)}>
            Close
        </Button>
    );
    const OpenButton = () => (
        <button
            className="dialog-open-button"
            type="button"
            onClick={() => setOpen(true)}
            disabled={groupSelectorIsDisabled}
        >
            <span className="text">
                {buttonText}
            </span>
            <span className="icon-container">
                <FontAwesomeIcon
                    icon="chevron-down"
                    size="1x"
                />
            </span>
        </button>
    );

    if (chosenGroup) {
        buttonText = chosenGroup.name;
    }

    return (
        <div className="group-selector-dialog">
            <FullScreenDialog
                title="Select a group"
                isOpen={open}
                OpenButton={OpenButton}
                handleClickOpen={() => setOpen(true)}
                ContentComponent={GroupSelectorWrapper}
                PrimaryButton={CloseDialogButton}
            />
        </div>
    );
};


const Tags = ({
    tags,
    onRemove
}) => {
    let tagList = tags.map((tag, index) => {
        return (
            <div
                key={index}
                className="tag-button"
            >
                <div className="inner-container">
                    <span
                        className="remove-icon-container"
                        onClick={() => onRemove(tag)}
                    >
                        <img
                            className="remove-icon"
                            src="/media/ui_icons/close-round.png"
                            alt="Close"
                        />
                    </span>
                    <div>
                        {tag.name}
                    </div>
                </div>
            </div>
        );
    });

    return tagList;
};


const ComposeForm = ({
    linkPreview,
    uploadedPhotoPreview,
    handleGroupSelect,
    handleClearTitleField,
    handleTitleFieldButtonClick,
    group,
    groupSelectorIsDisabled,
    errors,
    touchedFields,
    submitError,
    titleFieldIsRendered,
}) => {
    let linkPreviewSection = null;
    let [showTitleField, setShowTitleField] = useState(titleFieldIsRendered);
    let handleTitleModuleClick = (shouldShowTitleField) => {
        setShowTitleField(true);
        handleTitleFieldButtonClick(true);
    };
    let addTitleButton = (
        <div className="add-post-media-button-container">
            <button
                onClick={() => handleTitleModuleClick(true)}
                type="button"
                className="add-post-media-button"
            >
                <div className="inner-container">
                    <div className="icon-container">
                        <FontAwesomeIcon
                            icon="plus"
                            size="1x"
                        />
                    </div>
                    <div>
                        Title
                    </div>
                </div>
            </button>
        </div>
    );
    let handleRemoveTitleClick = () => {
        handleClearTitleField();
        setShowTitleField(false);
        handleTitleFieldButtonClick(false);
    };
    let titleComponent = addTitleButton;

    if (linkPreview) {
        linkPreviewSection = (
            <div className="link-preview-section">
                {linkPreview}
            </div>
        );
    }

    if (showTitleField) {
        titleComponent = (
            <Field
                    name="title"
                    validate={maxLength(400, 'Title')}
                    render={({input}) => (
                        <div className="title-field-section">
                            <textarea
                                {...input}
                                className="post-title-input"
                                placeholder="Give your post a title"
                                onKeyPress={disableEnterKey}
                            >
                            </textarea>
                            <button
                                className="remove-title-button"
                                type="button"
                                onClick={handleRemoveTitleClick}
                            >
                                Remove title
                            </button>
                        </div>
                    )}
                />
        );
    }

    return (
        <form className="compose-form">
            <div className="group-section">
                <Field
                    name="group"
                    validate={isRequired}
                    render={(props) => {
                        return (
                            <GroupSelectorDialog
                                handleChooseGroup={(group) => props.input.onChange(group.id)}
                                group={group}
                                groupSelectorIsDisabled={groupSelectorIsDisabled}
                            />
                        );
                    }}
                />
            </div>
            <div className="title-section">
                {titleComponent}
            </div>
            <div id="photoPreviewRef">
                {uploadedPhotoPreview}
            </div>
            {linkPreviewSection}
            <div className="body-section">
                <Field
                    name="body"
                    validate={maxLength(3000, 'body')}
                    render={({input}) => (
                        <textarea
                            {...input}
                            className="post-body-input"
                            placeholder="Share something..."
                        >
                        </textarea>
                    )}
                />
            </div>
            {<ErrorBanner errors={errors} touchedFields={touchedFields} submitError={submitError} />}
        </form>
    );
};


export default class ComposePostForm extends Component {

    constructor(props) {
        super(props);

        let {
            tags,
            initialValues={},
            linkPreviewData,
            photo,
            group,
            post={},
            isAuthenticated,
            isVerified,
        } = props;

        this.state = {
            showTagFiltersSelector: false,
            shouldShowShareLinkForm: false,
            accountVerifyPromptIsOpen: isAuthenticated && !isVerified,
            tags: tags || [],
            linkPreviewData: linkPreviewData || null,
            uploadedPhotoBinary: null,
            uploadedPhotoFile: null,
            uploadedPhotoUrl: photo || '',
            selectedGroup: group || post.group || {},
            groupSelectorIsDisabled: !!group,
            titleFieldIsRendered: !!initialValues.title,
            initialValues
        };
    }

    componentDidMount() {
        let {
            location
        } = this.props;

        // Reset page scroll to the top. Post module buttons were hidden.
        window.scrollTo(0, 0);

        if (location.state && location.state.slugHash) {
            fetchApiGet(`groups/${location.state.slugHash}/`)
                .then(({group}) => {
                    this.setState({selectedGroup: group});
                });
        }
    }

    showTagFilters = () => {
        let {showTagFiltersSelector} = this.state;

        document.body.style.overflow = 'hidden';

        this.setState({
            showTagFiltersSelector: !showTagFiltersSelector
        });
    };

    closeFilterSelector = () => {
        document.body.style.overflow = '';

        this.setState({
            showTagFiltersSelector: false
        });
    };

    selectTags = (tags) => {
        document.body.style.overflow = '';

        this.setState({
            tags,
            showTagFiltersSelector: false
        });
    };

    removeTag = (removedTag) => {
        let {tags} = this.state;

        tags = _.filter(tags, (tag) => removedTag.id !== tag.id);

        this.setState({tags});
    };

    showShareLinkForm = () => {
        this.setState({
            shouldShowShareLinkForm: true
        });
    };

    hideShareLinkForm = () => {
        this.setState({
            shouldShowShareLinkForm: false
        });
    };

    handlePreviewLinkFormSubmit = (previewData) => {
        this.setState({
            linkPreviewData: previewData,
            shouldShowShareLinkForm: false
        });
    };

    handleRemoveLinkPreview = () => {
        this.setState({
            linkPreviewData: null
        });
    };

    handleGroupSelect = (group) => {
        this.setState({selectedGroup: group})
    };

    handleFormSubmit = (formValues, tags) => {
        let {
            post,
            savePost,
            history,
            isVerified,
            isAuthenticated,
        } = this.props;
        let {
            linkPreviewData,
            uploadedPhotoFile,
            uploadedPhotoUrl,
        } = this.state;
        let postSlugHash = post ? post['slug_hash'] : '';

        if (isAuthenticated && !isVerified) {
            this.handleOpenAccountVerifyPrompt();
            return;
        }

        if (linkPreviewData) {
            formValues['shared_link'] = {
                'link_url': linkPreviewData.linkUrl,
                'image_url': linkPreviewData.imageUrl,
                'title': linkPreviewData.title,
                'description': linkPreviewData.description
            };
        }

        if (uploadedPhotoFile) {
            formValues['photo'] = uploadedPhotoFile;
        } else if (uploadedPhotoUrl) {
            formValues['photo_url'] = uploadedPhotoUrl;
        }

        if (!_.isEmpty(tags)) {
            formValues['tags'] = _.pluck(tags, 'id');
        }

        return savePost(formValues, postSlugHash)
            .then((results) => {
                if (results[FORM_ERROR]) {
                    return results;
                }

                let query = queryString.parse(history.location.search);

                returnToRefLink(query.ref, history);
            });
    };

    handleInputChange = (value, field) => {
        this.setState({[field]: value});
    };

    handleCancelForm = () => {
        let {history} = this.props;
        let {showTagFiltersSelector} = this.state;

        if (showTagFiltersSelector) {
            this.setState({
                showTagFiltersSelector: false
            });
        } else {
            let query = queryString.parse(history.location.search);

            returnToRefLink(query.ref, history);
        }
    };

    handlePhotoUpload = (files) => {
        let photo = files[0];
        let reader = new FileReader();

        reader.addEventListener('load', () => {
            this.setState({
                uploadedPhotoBinary: reader.result,
                uploadedPhotoFile: photo,
            });
        }, false);

        if (photo) {
            reader.readAsDataURL(photo);
        }
    };

    handleRemoveUploadedPhoto = () => {
        this.setState({
            uploadedPhotoBinary: null,
            uploadedPhotoFile: null,
            uploadedPhotoUrl: null,
        });

    };

    handleTitleFieldButtonClick = (titleFieldIsRendered) => {
        this.setState({titleFieldIsRendered});
    };

    validateForm = (titleFieldIsRendered) => (formValues) => {
        let errors = {};
        let {
            title,
            body
        } = formValues;

        if (titleFieldIsRendered && !title && !body) {
            errors['title'] = 'Add a title';
        }

        if (!titleFieldIsRendered && !body) {
            errors['body'] = 'Add a body'
        }

        return errors;
    };

    handleCloseAccountVerifyPrompt = () => {
        let {
            isVerified,
            isAuthenticated,
            history
        } = this.props;

        if (isAuthenticated && !isVerified) {
            let query = queryString.parse(history.location.search);

            returnToRefLink(query.ref, history);
            return;
        }

        this.setState({accountVerifyPromptIsOpen: false});
    };

    handleOpenAccountVerifyPrompt = () => {
        let {
            isAuthenticated,
            isVerified,
        } = this.props;

        if (isAuthenticated && !isVerified) {
            this.setState({accountVerifyPromptIsOpen: true});
        }
    };

    render() {
        let submit;
        let {
            showTagFiltersSelector,
            shouldShowShareLinkForm,
            accountVerifyPromptIsOpen,
            linkPreviewData,
            selectedGroup,
            uploadedPhotoBinary,
            uploadedPhotoUrl,
            tags,
            groupSelectorIsDisabled,
            titleFieldIsRendered,
            initialValues={}
        } = this.state;
        let {
            location
        } = this.props;
        let tagFilterSelector = null;
        let shareLinkForm = null;
        let linkPreview = null;
        let uploadedPhotoPreview = null;
        let photoAddButtonDisabled = Boolean(uploadedPhotoBinary || uploadedPhotoUrl || !_.isEmpty(linkPreviewData));
        let shareLinkButtonDisabled = Boolean(uploadedPhotoBinary || uploadedPhotoUrl || !_.isEmpty(linkPreviewData));
        let photoAddButton = null;
        let disabledPhotoAddButton = (
            <div className="disabled-photo-add-button">
                <button
                    className="add-post-media-button"
                    type="button"
                    disabled
                >
                    <div className="inner-container">
                        <div className="icon-container">
                            <FontAwesomeIcon
                                icon="plus"
                                size="1x"
                            />
                        </div>
                        <div>
                            Photo
                        </div>
                    </div>
                </button>
            </div>
        );
        let enabledPhotoAddButton = (
            <div className="photo-dropzone-button-container">
                <div className="icon-container">
                    <FontAwesomeIcon
                        icon="plus"
                        size="1x"
                    />
                </div>
                <DropzoneArea
                    dropzoneClass="dropzone-button"
                    dropzoneText="Photo"
                    showPreviewsInDropzone={false}
                    maxFileSize={MAX_FILE_SIZE}
                    filesLimit={1}
                    acceptedFiles={ACCEPTED_IMAGE_TYPES}
                    onChange={this.handlePhotoUpload}
                    getFileAddedMessage={() => ('Photo uploaded!')}
                    getFileRemovedMessage={() => ('Photo was removed!')}
                    getDropRejectMessage={() => ('File is too big! 3 MB is max limit.')}
                />
            </div>
        );

        if (photoAddButtonDisabled) {
            photoAddButton = disabledPhotoAddButton;
        } else {
            photoAddButton = enabledPhotoAddButton;
        }

        if (showTagFiltersSelector) {
            tagFilterSelector = (
                <TagFilters
                    onCancel={this.closeFilterSelector}
                    onSelect={this.selectTags}
                    defaultTags={tags}
                />
            );
        }

        if (shouldShowShareLinkForm) {
            shareLinkForm = (
                <ShareLinkForm
                    linkPreviewData={linkPreviewData}
                    onCancel={this.hideShareLinkForm}
                    onSubmit={this.handlePreviewLinkFormSubmit}
                />
            );
        }

        if (uploadedPhotoBinary) {
            uploadedPhotoPreview = (
                <div ref={(node) => (this.photoPreviewRef = node)} className="uploaded-photo-preview-section">
                    <div className="remove-uploaded-photo-button-container">
                        <button
                            className="remove-uploaded-photo-button"
                            type="button"
                            onClick={this.handleRemoveUploadedPhoto}
                        >
                            <FontAwesomeIcon
                                className="remove-uploaded-photo-icon"
                                icon="times"
                            />
                            <span className="remove-uploaded-photo-button-text">
                                Remove
                            </span>
                        </button>
                    </div>
                    <div className="uploaded-photo-preview-container">
                        <img className="uploaded-photo-preview" src={uploadedPhotoBinary} alt="" />
                    </div>
                </div>
            );
        } else if (uploadedPhotoUrl) {
            uploadedPhotoPreview = (
                <div ref={(node) => (this.photoPreviewRef = node)} className="uploaded-photo-preview-section">
                    <div className="remove-uploaded-photo-button-container">
                        <button
                            className="remove-uploaded-photo-button"
                            type="button"
                            onClick={this.handleRemoveUploadedPhoto}
                        >
                            <FontAwesomeIcon
                                className="remove-uploaded-photo-icon"
                                icon="times"
                            />
                            <span className="remove-uploaded-photo-button-text">
                                Remove
                            </span>
                        </button>
                    </div>
                    <div className="uploaded-photo-preview-container">
                        <img className="uploaded-photo-preview" src={uploadedPhotoUrl} alt="" />
                    </div>
                </div>
            );
        }

        if (!_.isEmpty(linkPreviewData)) {
            linkPreview = (
                <div>
                    <div className="remove-link-preview-section">
                        <button
                            className="remove-link-preview-button"
                            type="button"
                            onClick={this.handleRemoveLinkPreview}
                        >
                            <FontAwesomeIcon
                                className="remove-link-preview-icon"
                                icon="times"
                                size="1x"
                            />
                        </button>
                    </div>
                    <PreviewLink {...linkPreviewData} />
                </div>
            );
        }

        return (
            <div id="compose-post-form">
                <div className="compose-post-form-body-container">
                    <div className="header-section">
                        {shareLinkForm}
                        {tagFilterSelector}
                    </div>
                    <div className="form-section">
                        <Form
                            onSubmit={(values) => this.handleFormSubmit(values, tags)}
                            validate={this.validateForm(titleFieldIsRendered)}
                            render={(props) => {
                                submit = props.handleSubmit;

                                return (
                                    <div className="compose-form-wrapper">
                                        <div className="compose-header-container">
                                            <ComposeHeaderWithRouter
                                                primaryButtonTitle="Cancel"
                                                primaryButtonOnClick={this.handleCancelForm}
                                                secondaryButtonTitle="Submit"
                                                secondaryButtonOnClick={(e) => submit()}
                                                secondaryButtonIsDisabled={props.submitting}
                                            />
                                        </div>
                                        <ComposeForm
                                            linkPreview={linkPreview}
                                            uploadedPhotoPreview={uploadedPhotoPreview}
                                            group={selectedGroup}
                                            groupSelectorIsDisabled={groupSelectorIsDisabled}
                                            onChange={this.handleInputChange}
                                            errors={props.errors}
                                            touchedFields={props.touched}
                                            submitError={props.submitError}
                                            handleGroupSelect={this.handleGroupSelect}
                                            handleClearTitleField={props.form.mutators.clearTitleField}
                                            handleTitleFieldButtonClick={this.handleTitleFieldButtonClick}
                                            titleFieldIsRendered={titleFieldIsRendered}
                                        />
                                    </div>
                                );
                            }}
                            initialValues={{
                                group: selectedGroup.id || (location.state && location.state.groupId) || null,
                                ...initialValues,
                            }}
                            mutators={{
                                clearTitleField: (args, state, tools) => {
                                    tools.changeValue(state, 'title', () => '');
                                }
                            }}
                        />
                    </div>
                    <div className="add-module-button-row">
                        <div className="add-post-media-button-container">
                            <button
                                onClick={this.showTagFilters}
                                type="button"
                                className="add-post-media-button"
                            >
                                <div className="inner-container">
                                    <div className="icon-container">
                                        <FontAwesomeIcon
                                            icon="plus"
                                            size="1x"
                                        />
                                    </div>
                                    <div>
                                        Tags
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div className="add-post-media-button-container">
                            {photoAddButton}
                        </div>
                        <div className="add-post-media-button-container">
                            <button
                                onClick={this.showShareLinkForm}
                                className="add-post-media-button"
                                type="button"
                                disabled={shareLinkButtonDisabled}
                            >
                                <div className="inner-container">
                                    <div className="icon-container">
                                        <FontAwesomeIcon
                                            icon="link"
                                            size="1x"
                                        />
                                    </div>
                                    <div>
                                        Share Link
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="tags-container">
                        <Tags
                            tags={tags}
                            onRemove={this.removeTag}
                        />
                    </div>
                </div>
                <AccountVerificationPrompt
                    isOpen={accountVerifyPromptIsOpen}
                    title="Verify your account."
                    subtitle="In order to complete this action, verify your account by clicking the button below, and visit the email."
                    handleClose={this.handleCloseAccountVerifyPrompt}
                />
            </div>
        );
    };
}

ComposePostForm.propTypes = {
    post: PropTypes.object,
    postId: PropTypes.string,
    tags: PropTypes.array,
    linkPreviewData: PropTypes.object,
    initialValues: PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired,
    isVerified: PropTypes.bool.isRequired,
    onLoad: PropTypes.func,
    onSubmitSuccess: PropTypes.func,
    savePost: PropTypes.func
};
