import {
    DEFAULT_OG_TITLE,
    DEFAULT_OG_DESCRIPTION,
    DEFAULT_OG_URL,
    DEFAULT_OG_IMAGE_URL
} from '../constants/app';


export const setOGTagsToDefault = () => {
    let ogElements = document.getElementsByClassName('boeiie-og-element');
    let ogElementsLength = ogElements.length;

    for (let i=0; i < ogElementsLength; i++) {
        let element = ogElements[i];
        let attribute = element.getAttribute('property');

        if (attribute === 'og:url') {
            element.content = DEFAULT_OG_URL;
        } else if (attribute === 'og:title') {
            element.content = DEFAULT_OG_TITLE;
        } else if (attribute === 'og:description') {
            element.content = DEFAULT_OG_DESCRIPTION;
        } else if (attribute === 'og:image') {
            element.content = DEFAULT_OG_IMAGE_URL;
        }
    }
};
