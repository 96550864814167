import {connect} from 'react-redux';

import {loadCategories} from '../../common/action_creators/categories';
import {initializeCategories} from '../../common/actions/categories';
import {getAllCategoriesFromState} from '../../common/utils/categories';

import Categories from './Categories';


const initializePage = (dispatch) => {
    dispatch(initializeCategories());
    dispatch(loadCategories());
};

const mapStateForProps = ({categories}) => {
    let allCategories = getAllCategoriesFromState(categories);

    return {
        categories: allCategories,
    };
};

const mapDispatchForProps = (dispatch) => ({
    handlePageMount: () => dispatch(initializePage),
});

export default connect(
    mapStateForProps,
    mapDispatchForProps
)(Categories);
