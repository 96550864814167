import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles(theme => ({
  iconSection: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  dialogActions: {
    justifyContent: 'center',
  },
  confirmationButton: {
    backgroundColor: '#009c06',
    color: '#fff',
  }
}));

export default function SimpleDialog({
    open,
    handleClose,
    title='',
    description='',
    buttonText=''
}) {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle className="simple-dialog-title">
                <div className={classes.iconSection}>
                    <CheckCircleIcon
                        fontSize="large"
                        style={{color: '#09b409'}}
                    />
                </div>
                <div>
                    {title}
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions
                classes={{
                    root: classes.dialogActions
                }}
            >
                <Button
                    onClick={handleClose}
                    variant="contained"
                    classes={{
                        root: classes.confirmationButton
                    }}
                >
                    {buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    buttonText: PropTypes.string,
};
