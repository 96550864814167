import {FORM_ERROR} from 'final-form';
import queryString from 'query-string';

import {
    savePostStart,
    savePostSuccess,
    savePostFail,
    loadPostListingStart,
    loadPostListingSuccess,
    loadPostListingFail,
    loadMyPostsStart,
    loadMyPostsSuccess,
    loadMyPostsFail,
    deletePostStart,
    deletePostSuccess,
    deletePostFail
} from '../actions/posts';

import {
    fetchApiGet,
    fetchApiPost
} from '../utils/api';


export const loadPosts = (queryParams={}, infiniteScrollEnabled=false) => (dispatch) => {
    let url = 'posts/';
    let hasPageParam = Object.keys(queryParams).length;

    if (hasPageParam) {
        url = `posts/?${queryString.stringify(queryParams)}`;
    }

    dispatch(loadPostListingStart(queryParams));

    return fetchApiGet(url)
        .then((response) => {
            return dispatch(loadPostListingSuccess(response, infiniteScrollEnabled))
        })
        .catch(({errors}) => {
            return dispatch(loadPostListingFail(errors));
        });
};

export const deletePost = (postId) => (dispatch) => {
    dispatch(deletePostStart(postId));

    return fetchApiPost(`posts/${postId}/delete/`)
        .then(({post}) => {
            dispatch(deletePostSuccess(post));
            return post;
        })
        .catch(({errors}) => {
            dispatch(deletePostFail(errors));
            return errors;
        });
};

export const loadMyPosts = (queryParams={}) => (dispatch) => {
    let url = 'posts/me/';
    let hasParams = Object.keys(queryParams).length;

    if (hasParams) {
        url = `posts/me/?${queryString.stringify(queryParams)}`;
    }

    dispatch(loadMyPostsStart());

    return fetchApiGet(url)
        .then(({results: posts, next, previous}) => {
            dispatch(loadMyPostsSuccess(posts, next, previous));
        })
        .catch(({errors}) => {
            dispatch(loadMyPostsFail(errors));
        });
};



export const savePost = (formValues, slugHash='') => (dispatch) => {
    dispatch(savePostStart());

    let url = 'posts/';
    let options = {};
    let hasFormData = !!formValues['photo'];

    if (slugHash) {
        url = `posts/${slugHash}/`;
    }

    return fetchApiPost(url, formValues, options, hasFormData)
        .then(({post}) => {
            dispatch(savePostSuccess(post));
            return post;
        })
        .catch(({errors}) => {
            dispatch(savePostFail(errors))

            return {
                [FORM_ERROR]: 'Something went wrong.'
            };
        });
};
