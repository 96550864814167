import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import _ from 'underscore';

import {
    formatNavigationQuery,
    isDemo
} from '../../utils/routing';

import './DropdownNavMenu.css';


export default class DropdownNavMenu extends Component {
    render() {
        let {
            authUser,
            onLinkClick,
            onLogout
        } = this.props;
        let logoutLink = (
            <li className="logout-link" onClick={onLogout}>
                Logout
            </li>
        );
        let exitDemoLink = null;

        if (isDemo()) {
            logoutLink = null;
            exitDemoLink = (
                <li>
                    <Link to="/login/">
                        Exit Demo Mode
                    </Link>
                </li>
            );
        }

        return (
            <div className="dropdown-nav-menu">
                <ul>
                    <li>
                        <Link
                            to={{
                                pathname: '/',
                                search: formatNavigationQuery()
                            }}
                            onClick={onLinkClick}
                        >
                            Home
                        </Link>
                    </li>
                    {
                        !_.isEmpty(authUser) ?
                        <li>
                            <Link
                                to={{
                                    pathname: '/category-preferences/',
                                    search: formatNavigationQuery()
                                }}
                                onClick={onLinkClick}
                            >
                                Category Preferences
                            </Link>
                        </li> : null
                    }
                    {
                        !_.isEmpty(authUser) ?
                        <li>
                            <Link
                                to={{
                                    pathname: '/account/',
                                    search: formatNavigationQuery()
                                }}
                                onClick={onLinkClick}
                            >
                                Account Settings
                            </Link>
                        </li> : null
                    }
                    <li>
                        <Link
                            to={{
                                pathname: '/about/',
                                search: formatNavigationQuery()
                            }}
                            onClick={onLinkClick}
                        >
                            About
                        </Link>
                    </li>
                    {
                        _.isEmpty(authUser) ?
                        <li>
                            <Link
                                to={{
                                    pathname: '/login/',
                                    search: formatNavigationQuery()
                                }}
                                onClick={onLinkClick}
                            >
                                Login
                            </Link>
                        </li> : null
                    }
                    {
                        _.isEmpty(authUser) ?
                        <li>
                            <Link
                                to={{
                                    pathname: '/signup/',
                                    search: formatNavigationQuery()
                                }}
                                onClick={onLinkClick}
                            >
                                Sign up
                            </Link>
                        </li> : null
                    }
                    {
                        !_.isEmpty(authUser) ?
                        logoutLink : null
                    }
                    {exitDemoLink}
                </ul>
            </div>
        );
    }
}

DropdownNavMenu.propTypes = {
    authUser: PropTypes.object,
    onLinkClick: PropTypes.func
};
