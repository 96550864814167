import queryString from 'query-string';
import {connect} from 'react-redux';
import _ from 'underscore';

import {
    initializeGroups,
    createGroupStart,
    createGroupSuccess,
    createGroupFail
} from '../../common/actions/groups';
import {
    loadGroups,
    loadMyGroups
} from '../../common/action_creators/groups';
import {fetchApiPost} from '../../common/utils/api';
import {
    getAllGroupsFromState,
    getMyGroupsFromState
} from '../../common/utils/groups';

import Groups from './Groups';


const initializePage = (queryParams) => (dispatch) => {
    let options = {hasMoreGroups: false};

    dispatch(initializeGroups());
    dispatch(loadGroups({
        ...queryParams,
        me: 'false'
    }, options));
    dispatch(loadMyGroups(queryParams, options));
};

const createGroup = (formValues) => (dispatch) => {
    dispatch(createGroupStart());
    let {categories, ...rest} = formValues;
    let categoriesData = {categories};

    return fetchApiPost('groups/', rest)
        .then(({group}) => {
            fetchApiPost(`groups/${group['slug_hash']}/categories/`, categoriesData)
            return dispatch(createGroupSuccess(group));
        })
        .catch((errors) => {
            dispatch(createGroupFail(errors));
        });
};

const handlePaginationClick = (queryParams, history) => (dispatch) => {
    history.push(`/groups/?${queryString.stringify(queryParams)}`);
};

const mapStateToProps = ({groups, app}) => {
    let allGroupsList = getAllGroupsFromState(groups);
    let myGroupsList = getMyGroupsFromState(groups);
    let {
        groupsNextLink,
        groupsPreviousLink
    } = groups;
    let hasMoreGroups = false;
    let isAuthenticated = !_.isEmpty(app.authUser);
    let isVerified = isAuthenticated && !!app.authUser.is_verified;

    return {
        isAuthenticated,
        isVerified,
        allGroups: allGroupsList,
        myGroups: myGroupsList,
        groupsNextLink,
        groupsPreviousLink,
        hasMoreGroups
    };
};

const mapDispatchToProps = (dispatch, {history}) => {
    let queryParams = queryString.parse(history.location.search);

    return {
        handlePageLoad: () => dispatch(initializePage(queryParams)),
        handleCreateGroup: (formValues) => dispatch(createGroup(formValues)),
        handlePaginationClick: (queryParams) => dispatch(handlePaginationClick(queryParams, history)),
        loadGroups: (queryParams) => dispatch(loadGroups(queryParams)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Groups);
