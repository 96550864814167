import {
    connectRouter,
    routerMiddleware
} from 'connected-react-router';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faArrowRight,
    faBell,
    faChevronDown,
    faHome,
    faPlus,
    faLink,
    faTimes,
    faUsers,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
    applyMiddleware,
    createStore,
    combineReducers
} from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import './index.css';
import {history} from './app/App';
import App from './app/connector';
import * as serviceWorker from './serviceWorker';

import appReducer from './app/reducer';
import activityReducer from './pages/activity/reducer';
import categoriesReducer from './common/reducers/categories';
import groupsReducer from './common/reducers/groups';
import groupReducer from './pages/group_page/reducer';
import groupMembersReducer from './pages/group_members/reducer';
import postDetailsReducer from './pages/post_details/reducer';
import postListingReducer from './pages/post_listing/reducer';
import composePostFormReducer from './common/components/compose_post_form/reducer';
import commentThreadReducer from './common/components/comment_thread/reducer';
import userProfileReducer from './pages/profile_page/reducer';
import userProfileFormReducer from './common/components/profile_edit_form/reducer';
import commentsReducer from './common/reducers/comments';


library.add(
    faArrowRight,
    faBell,
    faChevronDown,
    faHome,
    faPlus,
    faLink,
    faTimes,
    faUsers,
);

let appliedMiddleware;

if (process.env.NODE_ENV === 'development') {
    // add `redux-logger`
    appliedMiddleware = applyMiddleware(
        logger,
        thunk,
        routerMiddleware(history)
    );
} else {
    appliedMiddleware = applyMiddleware(
        thunk,
        routerMiddleware(history)
    );
}

const store = createStore(
    combineReducers({
        app: appReducer,
        router: connectRouter(history),
        postDetails: postDetailsReducer,
        postListing: postListingReducer,
        composePostForm: composePostFormReducer,
        commentThread: commentThreadReducer,
        comments: commentsReducer,
        activity: activityReducer,
        groups: groupsReducer,
        groupState: groupReducer,
        groupMembers: groupMembersReducer,
        categories: categoriesReducer,
        userProfile: userProfileReducer,
        userProfileForm: userProfileFormReducer
    }),
    appliedMiddleware
);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
