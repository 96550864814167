import io from 'socket.io-client';

let socketUrl = 'http://localhost:8003';

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_IS_LOCAL !== 'true') {
    socketUrl = 'https://boeiie.com';
}

export const SOCKET = io(socketUrl);

export const EMIT_EVENT_JOIN_ROOM = 'join-room';
export const EMIT_EVENT_NEW_COMMENT = 'new-comment';
